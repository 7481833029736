import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import color from '@material-ui/core/colors/amber';

export const styles = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    textTransform: 'none',
    fontFamily: 'Sua dr consulta',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    margin: '0px',
    padding: '2px 0px',
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },
  disabled: {
    color: 'var(--color-neutral-800)',
  },
  focused: {
    color: 'var(--color-neutral-800)',
    cursor: 'default',
  },
  error: {
    color: 'var(--color-neutral-800)',
    cursor: 'default',
  },
  filled: {
    color: 'var(--color-neutral-800)',
    cursor: 'default',
  },
  required: {
    color: 'var(--color-neutral-800)',
    cursor: 'default',
  },
  contained: {
    margin: '0px',
    padding: '0px',
  },
  marginDense: {
    margin: '0px',
    padding: '0px',
  },
  title: {
    fontWeight: 700,
    marginRight: '4px',
  },
  success: {
    color: 'var(--color-feedback-success-900)',
  },
  error: {
    color: 'var(--color-feedback-danger-900)',
  },
  warning: {
    color: 'var(--color-feedback-warning-900)',
  },
  disabled: {
    color: 'var(--color-neutral-900)',
  },
  content: {
    color: 'var(--color-neutral-800) !important',
  },
});

const iconStyles = (theme) => ({
  root: {
    fontSize: '12px !important',
    marginRight: '4px',
  },
  success: {
    color: 'var(--color-feedback-success-900)',
  },
  error: {
    color: 'var(--color-feedback-danger-900)',
  },
  warning: {
    color: 'var(--color-feedback-warning-900)',
  },
  disabled: {
    color: 'var(--color-neutral-900)',
  },
});

const TextFieldHelperIcon = (props) => {
  const { classes, fieldState } = props;

  const getIconName = (fieldState) => {
    switch (fieldState) {
      case 'success':
        return 'check_circle';
      case 'error':
        return 'dangerous';
      case 'warning':
        return 'emergency_home';
      case 'disabled':
        return 'lock';
      default:
        return;
    }
  };
  const iconName = getIconName(fieldState);
  return (
    <Icon
      className={classes[fieldState]}
      classes={{
        root: classes.root,
      }}
    >
      {iconName}
    </Icon>
  );
};

const TextFieldHelperIconWithStyles = withStyles(iconStyles, {
  name: 'CustomTextFieldHelperIcon',
})(TextFieldHelperIcon);

function TextFieldHelper(props) {
  const {
    classes,
    children,
    title,
    showIcon,
    text,
    success,
    warning,
    error,
    disabled,
    ...other
  } = props;
  const getFieldState = () => {
    if (success) return 'success';
    if (warning) return 'warning';
    if (error) return 'error';
    if (disabled) return 'disabled';

    return '';
  };

  const fieldState = getFieldState();
  return (
    <FormHelperText
      {...other}
      component='div'
      classes={{
        root: classes.root,
        disabled: classes.disabled,
        focused: classes.focused,
        error: classes.error,
        filled: classes.filled,
        required: classes.required,
        contained: classes.contained,
        marginDense: classes.marginDense,
      }}
    >
      {showIcon && fieldState && (
        <TextFieldHelperIconWithStyles fieldState={fieldState} />
      )}{' '}
      {title && (
        <span className={[classes.title, classes[fieldState]].join(' ')}>
          {title}:
        </span>
      )}{' '}
      <span className={classes.content}>{children}</span>
    </FormHelperText>
  );
}

TextFieldHelper.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  showIcon: PropTypes.bool,
  success: PropTypes.bool,
  warning: PropTypes.bool,
  error: PropTypes.bool,
};

export default withStyles(styles, { name: 'CustomTextFieldHelper' })(
  TextFieldHelper,
);
