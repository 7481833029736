import React from 'react';

export const FormErrors = ({ formErrors }) => (
  <div className="formErrors">
    {Object.keys(formErrors).map((fieldName, i) => {
      if (formErrors[fieldName].length > 0) {
        let error = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
        return (
          <p key={i} style={{ color: '#ce2f13' }}>
            {error} {formErrors[fieldName]}
          </p>
        );
      } else {
        return '';
      }
    })}
  </div>
);
