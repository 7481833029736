import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Link,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { currencyFormat } from '../../../../util/currencyFormat';
import { banckSlipStatusEnum } from '../utils/banckSlipEnum';

const BankSlipCard = ({ bankSlip }) => (
  <Card className='card'>
    <CardContent className='card-content'>
      <Typography variant='h5' color='primary' gutterBottom>
        Boleto de {moment(bankSlip.created_at).format('MMMM')}
      </Typography>
      <Typography variant='body1'>
        <strong>Emissão:</strong> {moment(bankSlip.created_at).format('DD/MM/YYYY')}
      </Typography>
      <Typography variant='body1'>
        <strong>Vencimento:</strong> {moment(bankSlip.due_at).format('DD/MM/YYYY')}
      </Typography>
      <Typography variant='body1'>
        <strong>Pago em:</strong> {bankSlip.payment_status === 'paid' ? moment(bankSlip.paid_at).format('DD/MM/YYYY') : 'Não pago'}
      </Typography>
      <Typography variant='body1'>
        <strong>Valor:</strong> {currencyFormat(parseFloat(bankSlip.amount))}
      </Typography>
      <Typography variant='body1'>
        <strong>Funcionários ativos:</strong> {bankSlip.qnt_active_accounts}
      </Typography>
      <Typography variant='body1'>
        <strong>Status:</strong> {banckSlipStatusEnum[bankSlip.payment_status]}
      </Typography>
      <Typography variant='body1'>
        <strong>Enviado por email:</strong> {bankSlip.email_sent ? 'Sim' : 'Pendente'}
      </Typography>
    </CardContent>
    <CardActions className='card-button-container'>
      {bankSlip.pdf && (
        <Link href={bankSlip.pdf} rel='noopener' target='_blank' className='card-button'>
          <Button size='small' color='primary'>
            Visualizar Boleto
          </Button>
        </Link>
      )}
    </CardActions>
  </Card>
);

const BankSlipCardList = ({ bankSlips }) => (
  <div className='card-container'>
    {bankSlips.length === 0 ? (
      <Typography variant='body1'>
        Não há boletos disponíveis para este contrato.
      </Typography>
    ) : (
      bankSlips.map((bankSlip, index) => (
        <BankSlipCard key={`${bankSlip.contract_id}-${index}`} bankSlip={bankSlip} />
      ))
    )}
  </div>
);

export { BankSlipCard, BankSlipCardList };
