import React from 'react';
import { ExpandMoreIcon } from '@material-ui/icons';

import {
    ExpansionPanel,
    ExpansionPanelSummary,
    withStyles,
    Divider
  } from '@material-ui/core';

const styles = (theme) => ({
    panel: {
      border: 'none',
      margin: 0,
      padding: 0,
    },
    content: {
      margin: '0px !important',
      padding: 0,
    },
    container: {
      backgroundColor: '#FFFFFF',
      border: 'none',
      margin: '24px 0px',
      padding: '12px 16px',
      borderRadius: 16,
    },
    customExpandIcon: {
      top: '12%',
      padding: 8,
      right: 0,
    },
    customDivider: {
      height: 4,
      backgroundColor: 'var(--color-neutral-300)',
      marginBottom: 24,
    }
  });
  
  function CustomExpansionPanel({ classes, children, summaryData, ...props }) {
    return (
      <ExpansionPanel {...props} className={classes.container}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ root: classes.panel, content: classes.content, expandIcon: classes.customExpandIcon }}
        >
          <ExpansionPanelSummary
            {...summaryData}
            style={{ margin: 0, padding: 0, backgroundColor: 'blue' }}
          />
        </ExpansionPanelSummary>
  
        <Divider variant="fullWidth" className={classes.customDivider} />
        {children}
      </ExpansionPanel>
    );
  }
  
export default withStyles(styles)(CustomExpansionPanel);