import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  h1: {
    fontFamily: 'Sua dr consulta',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '26.4px',
    color: 'var(--color-neutral-700)',
  },
});

const ContainerHeader = ({ title, classes }) => {
  return (
    <Typography variant='h1' className={classes.h1}>
      {title}
    </Typography>
  );
};

export default withStyles(styles)(ContainerHeader);
