import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import CadastroPJ from './routes/cadastroPJ';
import ConsultaPJ from './routes/consultaPJ';
import Consulting from './routes/consulting';
import Employees from './routes/employees';

const PessoaJuridica = ({ match }) => (
  <div>
    <Switch>
      {!localStorage.getItem('token') && (
        <Redirect
          to={{
            pathname: '/signin'
          }}
          push
        />
      )}
      <Route path={`${match.url}/cadastro-pj`} component={CadastroPJ} />

      <Route path={`${match.url}/consulta-pj`} component={ConsultaPJ} />

      <Route path={`${match.url}/consulting/:cnpj`} component={Consulting} />

      <Route path={`${match.url}/employees/:cnpj`} component={Employees} />
    </Switch>
  </div>
);

export default PessoaJuridica;
