import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { modalEmitAlert, modalAlertShowLoading } from '../../../../../actions';
import { SUCCESS, ERROR } from '../../../../../constants/ActionTypes';
import InputMask from 'react-input-mask';
import { withRouter, Link } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Input,
  MenuItem,
  FormControl,
  FormHelperText,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Radio
} from '@material-ui/core';
import moment from 'moment';

import { utils } from '../../../utils';
import { contractService } from '../../../../../services/contractService';
import { pjService } from '../../../../../services/pjService';
import Select from '../../../../../components/ui/select/index.js'
import InputUi from '../../../../../components/ui/TextField/index.js'
import ButtonUi from '../../../../../components/ui/button/Button.js'
import AttachMoneyRounded from '@material-ui/icons/AttachMoneyRounded';
import { ChevronLeftRounded } from '@material-ui/icons';


moment.locale('pt_BR');
class EdicaoContrato extends Component {
  state = {
    useCNPJMask: false,
    taxesDialog: false,
    loading: false,
    error: '',
    cnpj: '',
    company_name: '',
    cnpjError: false,
    lead_generator_document_numberError: false,
    loading: false,
    loadingContract: true,
    checked: [],
    relatedCompanies: [],
    postLoading: false,
    relationsDialog: false,
    accountManagers: [],
    plans: [],
    taxes: [],
    allStatus: [],
    qtd_contracts: 1,
    contract: {
      id: '',
      hired_company_id: 'd6da3612-e433-d10a-174e-2238fbaa0b89',
      name: '',
      signature_date: '',
      effective_date: '',
      contracting_company_id: '',
      contract_status_id: '',
      benefits_started_immediately: false,
      card_recharge_type: '',
      commercial_origin: null,
      account_manager_yalo: '',
      sales_promotor_id: null,
      lead_generator: '',
      lead_generator_document_number: '',
      sale_issue_rate: '',
      company_relations: [],
      should_renew_plan: false,
      should_renew_recharges: false,
      closure_date: '',
      sales_flow: '',
      monthly_payment_responsible: '',
      contract_plans: [
        {
          plan_id: '',
          qtd_plans: 1,
          negotiated_price: '',
          qtd_recharges: 0,
          default_recharge_value: 0
        }
      ],
      contract_taxes: []
    }
  };

  componentDidMount() {
    this.setState({ loading: true });
    contractService.getPlans().then(response => {
      const orderedPlans = _.orderBy(
        response.data.response,
        'name_description',
        'asc'
      );
      this.setState({ plans: orderedPlans });

      contractService.getStatus().then(response => {
        this.setState({ allStatus: response.data.response });
        contractService
          .getContractById(this.props.match.params.id)
          .then(response => this.handleResponse(response));
      });

      contractService.getAccountManagers().then(response => {
        this.setState({ accountManagers: response.data.response });
        contractService.getTaxes().then(response => {
          this.setState({
            taxes: response.data.response
          });
        });
      });
    });
  }

  handleResponse = response => {
    if (response.data.success) {
      this.setState({
        company_name: response.data.response.contracting_company_name,
        cnpj: response.data.response.contracting_company_cnpj,
        qtd_contracts: 1,
        contract: {
          ...this.state.contract,
          contract_taxes:
            response.data.response.contract_taxes.map(tax => {
              return {
                ...tax,
                value: utils.removeNonNumericCharacters(
                  parseFloat(tax.value).toFixed(2)
                )
              };
            }) || [],
          monthly_payment_responsible:
            response.data.response.monthly_payment_responsible || '',
          id: this.props.match.params.id,
          name: response.data.response.name || '',
          signature_date: response.data.response.signature_date
            ? response.data.response.signature_date.slice(0, 10)
            : '',
          effective_date: response.data.response.effective_date
            ? response.data.response.effective_date.slice(0, 10)
            : '',
          contracting_company_id:
            response.data.response.contracting_company_id || '',
          card_recharge_type: response.data.response.card_recharge_type || '',
          contract_status_id: response.data.response.contract_status_id || '',
          commercial_origin: response.data.response.commercial_origin || null,
          account_manager_yalo:
            response.data.response.account_manager_yalo || '',
          sales_promotor_id: response.data.response.sales_promotor_id || null,
          lead_generator: response.data.response.lead_generator || '',
          lead_generator_document_number:
            response.data.response.lead_generator_document_number || '',
          sale_issue_rate: `${response.data.response.sale_issue_rate}` || '',
          company_relations:
            response.data.response.company_relations.map(
              company => company.id
            ) || [],
          contract_plans:
            response.data.response.contract_plans.length === 0
              ? [
                {
                  plan_id: '',
                  qtd_plans: 1,
                  negotiated_price: '',
                  qtd_recharges: 0,
                  default_recharge_value: 0
                }
              ]
              : response.data.response.contract_plans.map(plan => {
                return {
                  plan_id: plan.plan_id,
                  qtd_plans: parseInt(plan.qtd_plans),
                  qtd_recharges: parseInt(plan.qtd_recharges),
                  negotiated_price: parseFloat(plan.negotiated_price),
                  default_recharge_value: parseFloat(
                    plan.default_recharge_value
                  )
                };
              }) || [],
          should_renew_plan: response.data.response.should_renew_plan || false,
          should_renew_recharges:
            response.data.response.should_renew_recharges || false,
          closure_date: response.data.response.closure_date
            ? response.data.response.closure_date.slice(0, 10)
            : '',
          sales_flow: response.data.response.sales_flow || ''
        }
      });
      this.handleRelations();
    }

    this.setState({ loading: false });
  };

  handleMask = value => {
    if (
      this.state.useCNPJMask &&
      value.length <= 14 &&
      this.state.contract.lead_generator_document_number.length <= 12
    ) {
      return this.setState({
        ...this.state,
        useCNPJMask: false,
        contract: {
          ...this.state.contract,
          lead_generator_document_number: utils.removeNonNumericCharacters(
            value
          )
        }
      });
    }
    if (
      this.state.useCNPJMask &&
      value.length >= 16 &&
      this.state.contract.lead_generator_document_number.length <= 12
    ) {
      return this.setState({
        ...this.state,
        useCNPJMask: false,
        contract: {
          ...this.state.contract,
          lead_generator_document_number: utils.removeNonNumericCharacters(
            value
          )
        }
      });
    }
    if (
      this.state.contract.lead_generator_document_number.length === 11 &&
      value.length >= 14
    ) {
      return this.setState({
        ...this.state,
        useCNPJMask: true,
        contract: {
          ...this.state.contract,
          lead_generator_document_number: utils.removeNonNumericCharacters(
            value
          )
        }
      });
    }

    this.setState({
      ...this.state,
      contract: {
        ...this.state.contract,
        lead_generator_document_number:
          utils.removeNonNumericCharacters(value) || ''
      }
    });
  };

  handleRelations = () => {
    pjService
      .getRelationCompanies(utils.removeNonNumericCharacters(this.state.cnpj))
      .then(response => {
        if (response.success) {
          const checked = response.response
            .map(company => {
              if (
                this.state.contract.company_relations.includes(
                  company.companyfilho_id
                )
              ) {
                return company;
              }
              return false;
            })
            .filter(company => company);
          this.setState({
            checked,
            relatedCompanies: response.response
          });
        }
        this.setState({ loadingContract: false });
      });
  };

  setContractPlans(value, name, index) {
    const contract_plans = this.state.contract.contract_plans[0];
    let items = [...this.state.contract.contract_plans];
    let item = { ...items[0] };

    if (name === 'negotiated_price') {
      contract_plans.negotiated_price = value;
    }

    if (name === 'qtd_recharges' || name === 'qtd_plans') {
      value = parseInt(value);
    }

    if (name === 'plan_id') {
      const plan = this.state.plans.filter(plan => plan.id === value)[0];
      contract_plans.plan_id = plan.id;

      contract_plans.negotiated_price = plan.standard_value * 1;
    }

    items[0] = contract_plans;
    this.setState({
      contract: {
        ...this.state.contract,
        contract_plans: items
      }
    });
  }

  setTaxValue = (value, index) => {
    const taxes = this.state.contract.contract_taxes;
    taxes[index].value = utils.removeNonNumericCharacters(value);
    this.setState({
      contract: { ...this.state.contract, contract_taxes: taxes }
    });
  };

  handleRelationsToggle = value => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
      contract: {
        ...this.state.contract,
        company_relations: newChecked.map(company => company.companyfilho_id)
      }
    });
  };

  handleCNPJ = () => {
    if (utils.removeNonNumericCharacters(this.state.cnpj).length === 14) {
      this.setState({ loading: true });
      pjService
        .getPJ(utils.removeNonNumericCharacters(this.state.cnpj))
        .then(response => {
          if (response.success) {
            this.setState({
              loading: false,
              company_name: response.response.company.name,
              cnpjError: false,
              contract: {
                ...this.state.contract,
                contracting_company_id: response.response.company.id
              }
            });

            this.handleRelations();
          } else {
            this.setState({
              loading: false,
              company_name: '',
              cnpjError: true,
              contract: {
                ...this.state.contract,
                contracting_company_id: ''
              }
            });
          }
        });
    } else {
      this.setState({
        loading: false,
        company_name: '',
        cnpjError: true,
        contract: { ...this.state.contract, contracting_company_id: '' }
      });
    }
  };

  handleRelationsToggle = value => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
      contract: {
        ...this.state.contract,
        company_relations: newChecked.map(company => company.companyfilho_id)
      }
    });
  };

  handleTaxesToggle = tax => () => {
    tax.value = utils.removeNonNumericCharacters(
      parseFloat(tax.value).toFixed(2)
    );
    const { contract_taxes } = this.state.contract;
    const currentIndex = contract_taxes.indexOf(
      contract_taxes.find(checked => checked.description === tax.description)
    );
    const newTaxes = [...contract_taxes];
    if (currentIndex === -1) {
      newTaxes.push(tax);
    } else {
      newTaxes.splice(currentIndex, 1);
    }

    this.setState({
      contract: {
        ...this.state.contract,
        contract_taxes: newTaxes
      }
    });
  };

  setPostObj = () => {
    return {
      ...this.state.contract,
      monthly_payment_responsible: this.state.contract
        .monthly_payment_responsible,
      sale_issue_rate: parseFloat(
        this.state.contract.sale_issue_rate.slice(0, -2) +
        '.' +
        this.state.contract.sale_issue_rate.slice(-2)
      ),
      contract_plans: this.state.contract.contract_plans.map(plan => {
        return {
          ...plan,
          qtd_recharges: parseInt(plan.qtd_recharges),
          qtd_plans: parseInt(plan.qtd_plans)
        };
      }),
      contract_taxes: this.state.contract.contract_taxes.map(tax => {
        return {
          financial_id: tax.financial_id,
          value: parseFloat(
            tax.value.toString().slice(0, -2) +
            '.' +
            tax.value.toString().slice(-2)
          ),
          is_visible: true
        };
      })
    };
  };

  defaultState = () => {
    return this.setState({
      postLoading: false,
      loading: false,
      error: '',
      cnpjError: false,
      loading: false
    });
  };

  updateContract = () => {
    this.setState({
      postLoading: true,
      contract: {
        ...this.state.contract
      }
    });

    this.props.modalAlertShowLoading();

    contractService.updateContract(this.setPostObj()).then(response => {
      if (response.data.success) {
        this.setState({
          postLoading: false,
          loading: false,
          error: '',
          cnpjError: false,
          loading: false
        });
        this.props.modalEmitAlert('Contrato editado com sucesso', SUCCESS);
        this.defaultState();
      } else {
        this.setState({
          postLoading: false
        });
        this.props.modalEmitAlert(response.data.message, ERROR);
      }
    });
  };

  render() {
    const cnpjMask = '99.999.999/9999-99';
    const cpfMask = '999.999.999-99999';
    const {
      contract,
      cnpj,
      company_name,
      cnpjError,
      loading,
      lead_generator_document_numberError
    } = this.state;
    return (
      <Grid
        container
        justify='center'
        alignItems='center'
        style={{ padding: 16 }}
      >
        <Typography variant='h1' paragraph>
          Edição Contrato
        </Typography>
        <Grid item xs={12} />
        <Paper style={{ padding: 16 }}>
          <Grid container justify='center' spacing={24} style={{ padding: 16 }}>
            <Grid item xs={12}>
              <Typography variant='h3' color='primary'>
                EMPRESA
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin='normal'>
                <InputUi
                  small={true}
                  name='name'
                  label='Nome do contrato'
                  value={contract.name}
                  variant='outlined'
                  onChange={e =>
                    this.setState({
                      contract: { ...this.state.contract, name: e.target.value }
                    })
                  }
                  type='text'
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} />

            <Grid item xs={4}>
              <FormControl fullWidth margin='normal'>
                <InputUi
                  small={true}

                  name='signature_date'
                  label='Data de assinatura'
                  value={contract.signature_date}
                  variant='outlined'
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={e =>
                    this.setState({
                      contract: {
                        ...this.state.contract,
                        signature_date: utils.capDate(e).target.value
                      }
                    })
                  }
                  type='date'
                  fullWidth
                />


              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin='normal'>
                <InputUi
                  small={true}

                  name='effective_date'
                  label='Data de vigência'
                  type='date'
                  value={contract.effective_date}
                  variant='outlined'
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={e => {
                    this.setState({
                      contract: {
                        ...this.state.contract,
                        effective_date: utils.capDate(e).target.value
                      }
                    });
                  }}
                  fullWidth
                />



              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin='normal'>
                <InputUi
                  small={true}
                  name='closure_date'
                  label='Data de fechamento'
                  value={contract.closure_date}
                  variant='outlined'
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={e =>
                    this.setState({
                      contract: {
                        ...this.state.contract,
                        closure_date: utils.capDate(e).target.value
                      }
                    })
                  }
                  type='date'
                  fullWidth
                />


              </FormControl>
            </Grid>


            <Grid item xs={4}>
              <FormControl fullWidth margin='normal'>
                <InputMask
                  mask='99.999.999/9999-99'
                  disabled={loading}
                  maskChar=''
                  name='cnpj'
                  error={cnpjError}
                  value={cnpj}
                  onChange={e =>
                    this.setState({
                      ...this.state.contract,
                      cnpj: e.target.value
                    })
                  }
                  onBlur={this.handleCNPJ}
                  type='text'
                  fullWidth
                >
                  {() => (
                    <InputUi
                      small={true}
                      id='cnpj'
                      disabled={loading}
                      error={cnpjError}
                      label='CNPJ'
                      name='cnpj'
                      type='text'
                      variant='outlined'
                    />
                  )}
                </InputMask>
                {cnpjError && (
                  <FormHelperText error={true}>
                    CNPJ não cadastrado
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {loading && (
              <div className='loader-view'>
                <CircularProgress
                  mode='indeterminate'
                  style={{ color: '#3A10E0' }}
                />
              </div>
            )}
            <Grid item xs={4}>
              <FormControl fullWidth margin='normal'>
                <InputUi
                  small={true}
                  label='Nome da empresa'
                  name='company_name'
                  value={company_name}
                  variant='outlined'
                  type='text'
                  fullWidth
                  disabled
                />

              </FormControl>
            </Grid>

            <Grid item xs={4}>

              <FormControl fullWidth margin='normal'>
                <InputUi
                  small={true}
                  className={`fake-input${this.state.relatedCompanies.length === 0 ? '-disabled' : ''
                    }`}
                  disabled={true}
                  name='name'
                  label='Empresas Relacionadas'
                  value={
                    this.state.relatedCompanies.length === 0
                      ? 'Nenhuma empresa relacionada.'
                      : `${this.state.checked.length} Selecionadas`
                  }
                  onClick={() =>
                    this.state.relatedCompanies.length > 0
                      ? this.setState({
                        relationsDialog: true
                      })
                      : ''
                  }
                  type='text'
                  variant='outlined'
                  fullWidth
                />
              </FormControl>
            </Grid>

            {/*     <FormControl fullWidth margin='normal'>
                <TextField
                  className={`fake-input${this.state.relatedCompanies.length === 0 ? '-disabled' : ''
                    }`}
                  disabled={true}
                  name='name'
                  label='Empresas Relacionadas'
                  value={
                    this.state.relatedCompanies.length === 0
                      ? 'Nenhuma empresa relacionada'
                      : `${this.state.checked.length} Selecionadas`
                  }
                  onClick={() =>
                    this.state.relatedCompanies.length > 0
                      ? this.setState({ relationsDialog: true })
                      : ''
                  }
                  type='text'
                  variant='outlined'
                  fullWidth
                />
              </FormControl> */}
            <Grid item xs={12}>
              <Typography variant='h3' paragraph color='primary'>
                DADOS DO PLANO
              </Typography>
            </Grid>

            <Grid item xs={4}>

              <FormControl fullWidth margin='normal'>
                <Select
                  select
                  value={contract.account_manager_yalo}
                  label='Gerente de Conta'
                  variant='outlined'
                  name='account_manager_yalo'
                  onChange={e =>
                    this.setState({
                      contract: {
                        ...this.state.contract,
                        account_manager_yalo: e.target.value
                      }
                    })
                  }
                  fullWidth
                  options={this.state.accountManagers.map(manager => (
                    {
                      name: manager.name,
                      value: manager.id
                    }
                  ))}
                >

                </Select>
              </FormControl>

            </Grid>

            <Grid item xs={8} />

            <Grid item xs={6}>
              <FormControl fullWidth margin='normal'>
                <InputUi
                  small={true}

                  label='Gerador do Lead'
                  name='lead_generator'
                  value={contract.lead_generator}
                  variant='outlined'
                  type='text'
                  fullWidth
                  onChange={e =>
                    this.setState({
                      contract: {
                        ...this.state.contract,
                        lead_generator: e.target.value
                      }
                    })
                  }
                />
              </FormControl>

            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth margin='normal'>
                <InputMask
                  mask={this.state.useCNPJMask ? cnpjMask : cpfMask}
                  disabled={loading}
                  maskChar=''
                  name='lead_generator_document_number'
                  error={lead_generator_document_numberError}
                  value={contract.lead_generator_document_number}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={e => this.handleMask(e.target.value)}
                  // onBlur={this.handlelead_generator_document_number}
                  type='text'
                  fullWidth
                >
                  {() => (
                    <InputUi
                      small={true}
                      id='lead_generator_document_number'
                      disabled={loading}
                      error={lead_generator_document_numberError}
                      label='CPF/CNPJ do Gerador do Lead'
                      name='lead_generator_document_number'
                      type='text'
                      variant='outlined'
                    />
                  )}
                </InputMask>
                {lead_generator_document_numberError && (
                  <FormHelperText error={true}>
                    CPF/CNPJ do Gerador do Lead nào cadastrado
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>


            <Grid item xs={4}>
              <FormControl fullWidth margin='normal'>
                <Select
                  select
                  label='Recarga dos Cartões'
                  name='card_recharge_type'
                  value={contract.card_recharge_type}
                  onChange={e =>
                    this.setState({
                      contract: {
                        ...this.state.contract,
                        card_recharge_type: e.target.value
                      }
                    })
                  }
                  fullWidth
                  options={[
                    {
                      value: "Empresa oferece",
                      name: "Empresa oferece"
                    },
                    {
                      name: "Empresa não oferece",
                      value: "Empresa não oferece",
                    }
                  ]}
                />
              </FormControl>

            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin='normal'>
                <Select
                  name='should_renew_plan'
                  label='Renovar assinaturas automaticamente'
                  value={contract.should_renew_plan}
                  variant='outlined'
                  onChange={e =>
                    this.setState({
                      contract: {
                        ...this.state.contract,
                        should_renew_plan: e.target.value
                      }
                    })
                  }
                  options={[
                    {
                      value: true,
                      name: "Sim"
                    },
                    {
                      value: false,
                      name: "Não"
                    }
                  ]}
                >
                  <MenuItem value={true}>Sim</MenuItem>
                  <MenuItem value={false}>Não</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin='normal'>
                <Select
                  name='should_renew_recharges'
                  label='Renovar recargas automaticamente'
                  value={contract.should_renew_recharges}
                  variant='outlined'
                  onChange={e =>
                    this.setState({
                      contract: {
                        ...this.state.contract,
                        should_renew_recharges: e.target.value
                      }
                    })
                  }
                  options={[
                    {
                      name: "Sim",
                      value: true,
                    },
                    {
                      value: false,
                      name: "Não"
                    }
                  ]}

                >
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>

              <FormControl fullWidth margin='normal'>
                <Select
                  select
                  name='sales_flow'
                  label='Contrato deve iniciar no fluxo:'
                  value={this.state.contract.sales_flow}
                  variant='outlined'
                  onChange={e =>
                    this.setState({
                      contract: {
                        ...this.state.contract,
                        sales_flow: e.target.value
                      }
                    })
                  }
                  fullWidth
                  options={[
                    {
                      value: "Implantação",
                      name: "Implantação"
                    },
                    {
                      value: "Recorrência",
                      name: "Recorrência"
                    },
                  ]}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
            </Grid>

            <Grid item xs={12}>
              <Typography paragraph variant='h3' color='primary'>
                ASSINATURAS E VALORES
              </Typography>
            </Grid>

            {this.state.qtd_contracts > 0 &&
              contract.contract_plans.map((contract, index) => (
                <Fragment key={index}>
                  <Grid item xs={4}>
                    <FormControl fullWidth margin='normal'>
                      <Select
                        label='Plano'
                        name='plan_id'
                        value={contract.plan_id}
                        onChange={e => {
                          this.setContractPlans(
                            e.target.value,
                            e.target.name
                            // index
                          )

                        }
                        }
                        InputLabelProps={{
                          shrink: true
                        }}
                        errorMessage={contract.error}
                        options={
                          this.state.plans.map(plan => (
                            {
                              key: plan.id,
                              value: plan.id,
                              name: plan.name_description
                            }
                          )
                          )} />

                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth margin='normal'>
                      <InputUi
                        small={true}
                        label='Quantidade de assinaturas'
                        name='qtd_plans'
                        value={contract.qtd_plans}
                        variant='outlined'
                        onChange={e =>
                          this.setState({
                            contract: {
                              ...this.state.contract,
                              contract_plans: [
                                {
                                  ...this.state.contract.contract_plans[0],
                                  qtd_plans: utils.settingNumber(e)
                                }
                              ]
                            }
                          })
                        }
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{
                          min: '0',
                          step: '1'
                        }}
                        type='number'
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='subtitle1'>
                      <Radio
                        checked={
                          this.state.contract.monthly_payment_responsible ===
                          'company'
                        }
                        onChange={e =>
                          this.setState({
                            ...this.state,
                            contract: {
                              ...this.state.contract,
                              monthly_payment_responsible: e.target.value
                            }
                          })
                        }
                        value='company'
                        name='company-button'
                      />
                      Empresa custeia a mensalidade
                    </Typography>
                    <Typography variant='subtitle1'>
                      <Radio
                        checked={
                          this.state.contract.monthly_payment_responsible ===
                          'employee'
                        }
                        onChange={e =>
                          this.setState({
                            ...this.state,
                            contract: {
                              ...this.state.contract,
                              monthly_payment_responsible: e.target.value
                            }
                          })
                        }
                        value='employee'
                        name='employee-button'
                      />
                      Empresa desconta em folha de pagamento
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth margin='normal'>
                      <InputUi
                        small={true}
                        name='negotiated_price'
                        label='Mensalidade'
                        value={utils.editableMaskMoneyInput(
                          contract.negotiated_price
                        )}
                        variant='outlined'
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={e =>
                          this.setContractPlans(
                            utils.unMaskMoneyInput(e.target.value),
                            e.target.name
                            // index
                          )
                        }
                        startAdornment={
                          <InputAdornment position='start'><AttachMoneyRounded /></InputAdornment>
                        }
                        type='text'
                        fullWidth
                      />  {/* <AttachMoneyRounded/> */}



                    </FormControl>
                  </Grid>
                  <Grid item xs={4} />
                </Fragment>
              ))}
            <Grid item xs={4}>
              <Dialog
                open={this.state.relationsDialog}
                onClose={() => this.setState({ relationsDialog: false })}
              >
                <DialogContent>
                  <List>
                    {this.state.relatedCompanies.map(company => (
                      <ListItem
                        key={company.id}
                        role={undefined}
                        dense
                        button
                        onClick={this.handleRelationsToggle(company)}
                      >
                        <Checkbox
                          checked={this.state.checked.indexOf(company) !== -1}
                          tabIndex={-1}
                          disableRipple
                        />
                        <ListItemText
                          primary={`${company.companyfilho_name
                            } - ${utils.formatCNPJ(company.companyfilho_cnpj)}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant='contained'
                    onClick={() =>
                      this.setState({
                        relationsDialog: false
                      })
                    }
                    color='secondary'
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
          <Grid
            className='mr-3'
            container
            justify='center'
            spacing={16}
            style={{ marginTop: 16, marginBottom: 16, marginRight: 20 }}
          >
            <Grid item>
              <Link
                to={`/app/contratos/detalhes-contrato/${this.props.match.params.id}`}
                style={{ textDecoration: 'none' }}
              >
                <ButtonUi
                  variant='link'
                  color='default'
                  startIcon={<ChevronLeftRounded />}
                  style={{ marginTop: 8 }}
                >
                  Voltar
                </ButtonUi>
              </Link>
            </Grid>
            <Grid item>
              <ButtonUi
                size='md'
                variant='primary'
                onClick={() => this.updateContract()}
              >
                CONFIRMAR
              </ButtonUi>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }
}

export default connect(null, { modalAlertShowLoading, modalEmitAlert })(
  withRouter(EdicaoContrato)
);
