import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

export const hasRole = roles => {
  return _.some(roles, role => role === localStorage.getItem('usertype'));
};

const index = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      hasRole(roles) ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

export default index;
