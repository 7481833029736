import { BASE_URL } from "../config/env";

export const ordersService = {
  getAllOrders,
  getOrder,
  downloadOrder,
  getAllMonthlyResumed,
  getIntegration,
  getContractOrders,
  uploadSubscription,
  updateSubscription,
  getAllRecharges,
  uploadImplantation,
  updateImplantation,
  getBoletoByOrderId,
  getInvoiceByOrderId
};

function getAllOrders(contractId) {
  const token = localStorage.getItem('token');

  return fetch(`${BASE_URL}/orders/all-recharges-resumed`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      contractId
    })
  }).then(response => {
    if (response.status === 200) return response.json();

    return response;
  });
}

function getAllMonthlyResumed(contractId) {
  const token = localStorage.getItem('token');

  return fetch(`${BASE_URL}/orders/all-monthly-resumed`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      contractId
    })
  }).then(response => {
    if (response.status === 200) return response.json();

    return response;
  });
}

function getAllRecharges(contractId) {
  const token = localStorage.getItem('token');

  return fetch(`${BASE_URL}/orders/all-recharges-resumed`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      contractId
    })
  }).then(response => {
    if (response.status === 200) return response.json();

    return response;
  });
}

function getOrder(id) {
  const token = localStorage.getItem('token');
  const body = JSON.stringify({ id });

  return fetch(`${BASE_URL}/orders/get`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }),
    body
  }).then(response => response.json());
}

function downloadOrder(id) {
  const token = localStorage.getItem('token');
  const body = JSON.stringify({ id });

  return fetch(`${BASE_URL}/orders/download`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }),
    body
  }).then(response => {
    if (response.status !== 200) return response.json();
    else return response.json();
  });
}

function getContractOrders(contract_id) {
  const token = localStorage.getItem('token');
  const body = JSON.stringify({ contract_id });

  return fetch(`${BASE_URL}/orders/contract`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }),
    body
  }).then(response => response.json());
}

function getIntegration(id) {
  const token = localStorage.getItem('token');
  return fetch(`${BASE_URL}/integrations/${id}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`
      //'Content-Type': 'application/json'
    })
  }).then(response => response.json());
}

function uploadSubscription(contractId, file, orderId) {
  const token = localStorage.getItem('token');

  let formData = new FormData();
  formData.append('orderFile', file, file.name);
  formData.append('contractId', contractId);
  formData.append('orderId', orderId);

  return fetch(`${BASE_URL}/orders/upload`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`
    }),
    body: formData
  }).then(response => response.json());
}

// DEPRECATED - updateImplantation DOES BOTH UPDATE AND ADD
function updateSubscription(id, path, is_payment_done) {
  const token = localStorage.getItem('token');

  const body = JSON.stringify({ id, path, is_payment_done });
  return fetch(`${BASE_URL}/orders/update-subscription`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }),
    body
  }).then(response => response.json());
}

function uploadImplantation(contractId, file, orderId) {
  const token = localStorage.getItem('token');

  let formData = new FormData();
  formData.append('orderFile', file, file.name);
  formData.append('contractId', contractId);
  formData.append('orderId', orderId);

  return fetch(`${BASE_URL}/orders/upload`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`
    }),
    body: formData
  }).then(response => response.json());
}

function updateImplantation(
  id,
  path,
  is_payment_done,
  benefits_started_immediately
) {
  const token = localStorage.getItem('token');

  const body = JSON.stringify({
    id,
    path,
    is_payment_done,
    benefits_started_immediately
  });

  return fetch(`${BASE_URL}/orders/update`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }),
    body
  }).then(response => response.json());
}

function getBoletoByOrderId(idOrder) {
  const token = localStorage.getItem('token');

  const body = JSON.stringify({ id: idOrder });

  return fetch(`${BASE_URL}/orders/getpdf`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }),
    body
  }).then(response => {
    // Retorna blob case success, or response cause error
    if (response.status !== 200) return response.json();
    else return response.blob();
  });
}

function getInvoiceByOrderId(id) {
  const token = localStorage.getItem('token');
  // const body = JSON.stringify({ id: id });
  return fetch(`${BASE_URL}/orders/prenf/${id}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`
      // 'Content-Type': 'application/json'
    })
    // body
  }).then(response => {
    if (response.status !== 200) return response.json();
    else return response.blob();
  });
}
