import React, { Component, Fragment } from 'react';
import { Grid, } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { pjService } from '../../../../../../services/pjService';
import { utils } from '../../../../utils';

import TextField from '../../../../../../components/ui/TextField';
import CircularProgress from '../../../../../../components/ui/progress/CustomizedCircularProgress';

import { styles } from '../styles';

class MainCompany extends Component {
  state = {
    loading: false,
    successFetch: false,
    emp_princ: {
      empresa: '',
      cnpj: '',
    },
  };

  componentDidMount() {
    this.props.setActiveComponentStep(this);
    if (
      this.props.empPrinc.empresa !== undefined &&
      this.props.empPrinc.cnpj !== false
    ) {
      this.setState({
        successFetch: true,
        emp_princ: this.props.empPrinc,
      });
    }
    this.props.currentComponentStepChange(false);
  }

  updateCurrentState() {
    this.props.setInputs(this.state.emp_princ);
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    this.setState({
      emp_princ: { ...this.state.emp_princ, [name]: value },
    });
  }
  handleCNPJ = () => {
    if (
      utils.removeNonNumericCharacters(this.state.emp_princ.cnpj).length === 14
    ) {
      this.setState({ loading: true });

      pjService
        .getPJ(utils.removeNonNumericCharacters(this.state.emp_princ.cnpj))
        .then((response) => {
          if (response.success) {
            this.setState({
              successFetch: true,
              loading: false,
              emp_princ: {
                ...this.state.emp_princ,
                empresa: response.response.company.name,
              },
              cnpjError: false,
            });
          } else {
            this.setState({
              successFetch: false,
              loading: false,
              company_name: '',
              cnpjError: true,
            });
          }
        });
    }
  };

  render() {
    const { emp_princ, loading, cnpjError } = this.state;
    const { classes, confirmation } = this.props;
    return (
      <Fragment>
        <Grid
          container
          direction='column'
          justify='flex-start'
          alignItems='flex-start'
          spacing={0}
          className={classes.root}
        >
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='CNPJ'
                name='cnpj'
                id='frm_main_company_cnpj'
                type='text'
                mask='99.999.999/9999-99'
                maskOptions={{ maskChar: '' }}
                error={cnpjError}
                disabled={confirmation || loading}
                value={emp_princ.cnpj}
                onChange={e => this.handleInputChange(e)}
                onBlur={() => this.handleCNPJ()}
                endAdornment={ <CircularProgress hidden={!loading} /> }
                formInputHelper={{
                  title: 'CNPJ',
                  text: 'CNPJ não cadastrado e ou inválido.',
                  showIcon: true,
                  isShow: cnpjError && emp_princ.cnpj !== '',
                }}
              />
             </Grid>
            <Grid item xs={8} md={8} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='Nome da empresa'
                name='emp_princ.empresa'
                id='frm_main_company_empresa'
                value={emp_princ.empresa}
                type='text'
                disabled={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(MainCompany);
