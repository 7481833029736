import { SHOW_ALERT, HIDDEN_ALERT, SHOW_LOADING } from '../constants/ActionTypes';

const INIT_STATE = {
  open: false,
  msg: null,
  loading: false,
  typeAlert: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_ALERT:
      state.open = true;
      state.loading = false;
      state.msg = action.payload.msg;
      state.typeAlert = action.payload.typeAlert;
      return {
        ...state
      };
    case SHOW_LOADING:
      state.open = true;
      state.loading = true;

      return {
        ...state
      };
    case HIDDEN_ALERT:
      state.open = false;
      state.msg = null;
      state.typeAlert = null;
      return {
        ...state
      };
    default:
      return state;
  }
};
