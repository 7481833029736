import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

const ConfirmationDialog = ({
  title,
  body,
  confirm = 'Confirmar',
  cancel = 'Cancelar',
  open,
  handleConfirmation,
  handleCancel,
  handleClose,
  loading
}) => {
  const padding = {'padding': '1.5rem 3rem'};

  return (
    <Dialog open={open} onClose={handleClose}>

      <DialogTitle style={padding}>
        <Typography variant='h1' color='textPrimary'>
          {title}
        </Typography>
      </DialogTitle >
      <Divider fullWidth/>
      {body && (
        <Fragment>
          <DialogContent style={padding}>
            <DialogContentText>
              <Typography variant='body2'>{body}</Typography>
            </DialogContentText>
          </DialogContent>
          <Divider />
        </Fragment>
      )}
      <DialogActions > 
        <Button color='default' onClick={handleCancel}>
          {cancel}
        </Button>
        <Button
          diabled={loading}
          color='primary'
          variant='contained'
          onClick={handleConfirmation}
        >
          {loading ? <CircularProgress size={24} color='inherit' /> : confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
