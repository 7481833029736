import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import BilletItem from './subComponents/Billet';


const BanckSlip = ({ match }) => {
  if (!localStorage.getItem('token')) {
    return <Redirect to="/signin" push />;
  }

  return (
    <div>
      <Switch>
        <Route path={`${match.url}`} component={BilletItem} />
      </Switch>
    </div>
  );
};

export default BanckSlip;
