import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ChevronRight';


import LastOrders from './subcomponents/LastOrders';
import LastMonthlyResumed from './subcomponents/LastMonthlyResumed';
import ContactInfo from './subcomponents/ContactInfo';
import ContainerHeader from '../../../components/ContainerHeader';
import Button from '../../../components/ui/button/Button'

export default class Home extends Component {
  componentWillMount() {
    localStorage.getItem('username');
    window.fcWidget.user.setProperties({
      name: localStorage.getItem('username'),
      email: localStorage.getItem('useremail'),
      'Portal - usertype': localStorage.getItem('usertype'),
      'Portal - userid': localStorage.getItem('userid')
    });
  }

  render() {
    return (
      <div className='app-wrapper'>
        {!localStorage.getItem('token') && (
          <Redirect
            to={{
              pathname: '/signin'
            }}
            push
          />
        )}
        
        <Grid container spacing={24}>
          {/* <Grid item xs={6}>
            <LastOrders />
          </Grid> */}
          <Grid item xs={6}>
            <LastMonthlyResumed />
            <div style={{ marginTop: 32 }} />
            <ContactInfo />
          </Grid>
        </Grid>
      </div>
    );
  }
}
