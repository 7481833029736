import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Grid, Button, withStyles } from '@material-ui/core';
import { UserCircleIcon, ExitIcon } from '../../assets/svgIcons';
import { userSignOut } from '../../actions/Auth';

const styles = (theme) => ({
  userProfile: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  userDetail: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing.unit * 2,
  },
  userName: {
    marginBottom: 0,
    color: 'white',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  popupLink: {
    height: 60,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
    padding: '10px 20px',
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: '#E2DFFA',
      color: '#3A10E0',
    },
    '&.active': {
      borderLeft: `5px solid var(--color-brand-primary-900)`,
      backgroundColor: '#3A10E0',
      color: '#FFFFFF',
    },
  },
  navText: {
    marginLeft: theme.spacing.unit,
    fontSize: 20,
    fontWeight: 400,
    fontFamily: 'Sua dr consulta',
    textAlign: 'left',
  },
  iconButton: {
    height: 60,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textTransform: 'none',
    color: 'inherit',
    padding: '10px 20px',
    marginBottom: theme.spacing.unit * 2,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: '#E2DFFA',
      color: '#3A10E0',
    },
  },
  iconActive: {
    fill: 'white', 
  },
  iconInactive: {
    fill: 'currentColor', // Cor padrão do ícone
  },
  iconOnlyItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    
  },
  hiddenText: {
    display: 'none',
  },
  link:{
    justifyContent: 'space-around',
    paddingRight:  27,
  }
});

class UserInfoPopup extends React.Component {
  logout = () => {
    localStorage.removeItem('token');
    window.location = '#/signin';
  };

  name = (name) => {
    if (!name) return;

    if (name.length <= 14) {
      return localStorage.getItem('username');
    }
    return localStorage.getItem('username').slice(0, 15) + '...';
  };

  isActive = (route) => {
    return this.props.location.pathname === route;
  };

  render() {
    const { classes, open } = this.props;
    return (
      <Grid
        container
        direction='column'
        justify='center'
        alignItems='flex-start'
      >
        <Grid item className={open ? classes.userProfile : classes.iconOnlyItem}>
          <Link
            onClick={this.props.handleDrawerClose}
            to='/app/profile'
            className={`${classes.popupLink} ${!open? classes.link:''} ${
              this.isActive('/app/profile') ? 'active' : ''
            }`}
          >
            <UserCircleIcon
              className={
                this.isActive('/app/profile')
                  ? classes.iconActive
                  : classes.iconInactive
              }
            />
            <span className={`${classes.navText} ${!open && classes.hiddenText}`}>Meu perfil</span>
          </Link>
        </Grid>

        <Grid item className={open ? classes.userProfile : classes.iconOnlyItem}>
          <Button
            size='small'
            color='secondary'
            className={`${classes.iconButton} ${!open? classes.link:''}`}
            onClick={() => {
              this.props.userSignOut();
              this.logout();
            }}
          >
            <ExitIcon fill='currentColor' />
            <span className={`${classes.navText} ${!open && classes.hiddenText}`}>Sair</span>
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { user, authUser } = auth;
  return { user, authUser };
};

export default withRouter(
  connect(mapStateToProps, { userSignOut })(withStyles(styles)(UserInfoPopup))
);
