import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import _ from 'lodash';
import {
  Paper,
  Grid,
  Button,
  CircularProgress,
  Divider,
  Dialog,
  DialogContent,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tabs,
  Tab,
  Tooltip
} from '@material-ui/core';
import Select from 'react-select';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { withRouter, Link } from 'react-router-dom';
import YaloSearchFilter from '../../../../../components/YaloSearchFilter';
import ContainerHeader from '../../../../../components/ContainerHeader';
import OrderHeader from '../components/OrderHeader';
import WarningOrderDue from '../../../../../components/WarningOrderDue';

import {
  OrderTableRechargesHead,
  OrderTableNewEmployeesHead,
  OrderTableTerminationsHead,
  OrderTableResume
} from '../components/OrderTable';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Pagination from 'material-ui-flat-pagination';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import './styles.css';
import { withStyles } from '@material-ui/core/styles';
import portalThemeAlt from '../../../../../containers/themes/portalThemeAlt';
import { utils } from '../../../utils';
import { UserActions } from '../../../../../actions';
import { ordersService } from '../../../../../services';
import { modalEmitAlert, ERROR } from '../../../../../actions/ModalAlertActions';

import moment from 'moment';

moment.locale('pt_BR');

const DialogTitle = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 3
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='Close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class ConsultingOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: {},
      remount: false,
      loading: false,
      // Recargas
      hasRecharges: false,
      rechargesOffset: 0,
      rechargesDialog: {
        open: false,
        loading: false,
        success: false,
        errorMessage: '',
        integrated_at: '',
        integration_date: '',
        row: {}
      },
      employeesDialog: {
        open: false,
        loading: false,
        success: false,
        errorMessage: '',
        integrated_at: '',
        integration_date: '',
        row: {}
      },
      // Novos empregados
      hasEmployees: false,
      newEmpOffset: 0,
      // Cancelamentos
      hasTerminations: false,
      terminationsOffset: 0,
      terminationsDialog: {
        open: false,
        loading: false,
        nexo: { loading: false },
        drconsulta: { loading: false }
      },
      tabValue: 0,
      filter: '',
      status_filter: '',
      columnToSort: '',
      columnDirection: 'asc',
      downloadUrl: '',
      pdfDownloadError: false,
      orderDownloadError: false,
      downloadInvoiceUrl: '',
      downloadOrderUrl: '',
      invoiceDownloadError: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    UserActions.getOrder(this.props.match.params.id).then(response => {
      this.handleResponse(response);
      this.downloadBoleto();
      this.downloadOrder();
      this.downloadInvoice();
    });
  }

  downloadOrder = async () => {
    const { order } = this.state;
    const response = await ordersService.downloadOrder(order.id);

    if (!_.isUndefined(response) && response.success === false)
      this.setState({ orderDownloadError: true });
    else this.setState({ downloadOrderUrl: response.response });
  };

  downloadBoleto = async () => {
    const { order } = this.state;
    const resp = await ordersService.getBoletoByOrderId(order.id);

    if (!_.isUndefined(resp) && resp.success === false)
      this.setState({ pdfDownloadError: true });
    else this.setState({ downloadUrl: window.URL.createObjectURL(resp) });
  };

  downloadInvoice = async () => {
    const { order } = this.state;
    const resp = await ordersService.getInvoiceByOrderId(order.id);

    if (!_.isUndefined(resp) && resp.success === false)
      this.setState({ invoiceDownloadError: true });
    else
      this.setState({ downloadInvoiceUrl: window.URL.createObjectURL(resp) });
  };

  componentDidUpdate() {
    if (this.state.remount) {
      UserActions.getOrder(this.props.match.params.id).then(response =>
        this.handleResponse(response)
      );
    }
  }

  handleTabChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  handleRechargesClick = async row => {
    this.setState({
      rechargesDialog: {
        ...this.state.rechargesDialog,
        open: true,
        loading: true
      }
    });
    // Indisponível
    if (!row.response) {
      this.setState({
        rechargesDialog: {
          ...this.state.rechargesDialog,
          row: row,
          open: true,
          loading: false,
          success: false,
          errorMessage: 'ID de integracao indisponivel',
          integrated_at: '',
          integration_date: ''
        }
      });
    }

    // Erro
    if (row.response && !row.response.integration_schedule_id) {
      this.setState({
        rechargesDialog: {
          ...this.state.rechargesDialog,
          row: row,
          open: true,
          loading: false,
          success: false,
          errorMessage:
            typeof row.response.err === 'object'
              ? 'Erro no banco de dados.'
              : row.response.err,
          integrated_at: '',
          integration_date: ''
        }
      });
    } else {
      // Se tem integracao, busca os dados
      ordersService
        .getIntegration(row.response.integration_schedule_id)
        .then(response => {
          if (response.success) {
            this.setState({
              rechargesDialog: {
                ...this.state.rechargesDialog,
                row: row,
                open: true,
                loading: false,
                success: true,
                errorMessage: '',
                integrated_at: response.response.integrated_at,
                integration_date: response.response.integration_date
              }
            });
          } else {
            this.setState({
              rechargesDialog: {
                ...this.state.rechargesDialog,
                row: row,
                open: true,
                loading: false,
                success: false,
                errorMessage: 'Registro não encontrado.',
                integrated_at: '',
                integration_date: ''
              }
            });
          }
        });
    }
  };

  handleEmployeesClick = async row => {
    this.setState({
      employeesDialog: {
        ...this.state.employeesDialog,
        open: true,
        loading: true
      }
    });
    // Indisponível
    if (!row.response) {
      this.setState({
        employeesDialog: {
          ...this.state.employeesDialog,
          row: row,
          open: true,
          loading: false,
          success: false,
          errorMessage: 'ID de integracao indisponivel',
          integrated_at: '',
          integration_date: ''
        }
      });
    } else {
      if (!row.response.success) {
        let error = '';
        if (
          row.response &&
          row.response.err &&
          typeof row.response.err === 'object'
        ) {
          error = 'Erro no banco de dados.';
        } else {
          error =
            row.response &&
            row.response.err &&
            typeof row.response.err === 'string' &&
            row.response.err.includes('This CPF already registe')
              ? 'ESSE CPF já foi registrado.'
              : 'Algum erro ocorreu.';
        }

        this.setState({
          employeesDialog: {
            ...this.state.employeesDialog,
            row: row,
            open: true,
            loading: false,
            success: false,
            errorMessage: error,
            integrated_at: '',
            integration_date: ''
          }
        });
      } else {
        this.setState({
          employeesDialog: {
            ...this.state.employeesDialog,
            row: row,
            open: true,
            loading: false,
            success: true,
            errorMessage: '',
            integrated_at: '',
            integration_date: ''
          }
        });
      }
    }
  };

  handleTerminationsClick = async row => {
    this.setState({
      terminationsDialog: {
        ...this.state.rechargesDialog,
        open: true,
        loading: true
      }
    });

    if (!row.response) row.response = {};
    this.setState({
      terminationsDialog: {
        row: row,
        ...this.state.terminationsDialog,
        open: true,
        nexo: { loading: true },
        drconsulta: { loading: true }
      }
    });

    const terminationsDialog = {
      ...this.state.terminationsDialog,
      row: row,
      nexo: await this.handleNexo(row.response.nexo_integration_id),
      drconsulta: await this.handleDrConsulta(
        row.response.drconsulta_integration_id
      ),
      open: true
    };

    this.setState({ terminationsDialog });
  };

  handleNexo = async id => {
    if (!id) {
      return {
        loading: false,
        integrated_at: 'N/A',
        integration_date: 'N/A',
        error_message: 'ID de integração Nexo indisponível'
      };
    }

    const nexo = await ordersService.getIntegration(id).then(response => {
      return { loading: false, ...response.response };
    });
    return nexo;
  };

  handleDrConsulta = async id => {
    if (!id) {
      return {
        loading: false,
        integrated_at: 'N/A',
        integration_date: 'N/A',
        error_message: 'ID de integração Dr Consulta indisponível.'
      };
    }

    const drConsulta = await ordersService.getIntegration(id).then(response => {
      return { loading: false, ...response.response };
    });
    return drConsulta;
  };

  handleResponse = response => {
    if (response.success)
      this.setState({ order: response.response, loading: false });

    if (this.state.order.recharges.length > 0)
      this.setState({ hasRecharges: true });
    if (this.state.order.new_employees.length > 0)
      this.setState({ hasEmployees: true });
    if (this.state.order.terminations.length > 0)
      this.setState({ hasTerminations: true });
    return;
  };

  handleCloseDialog = () => {
    this.setState({
      rechargesDialog: {
        ...this.state.rechargesDialog,
        row: {},
        open: false,
        loading: false
      },
      employeesDialog: {
        ...this.state.employeesDialog,
        row: {},
        open: false,
        loading: false
      },
      terminationsDialog: {
        ...this.state.terminationsDialog,
        row: {},
        open: false,
        loading: false
      }
    });
  };

  getPersonStatus = person => {
    if (this.state.status_filter === '') return true;

    if (!person.response) {
      if (
        (this.state.order.order_status === 'Pendente de Pagamento' ||
          this.state.order.order_status === 'Pagamento Parcial') &&
        this.state.status_filter.value === 'Pendente'
      )
        return true;
      if (
        !(
          this.state.order.order_status === 'Pendente de Pagamento' ||
          this.state.order.order_status === 'Pagamento Parcial'
        ) &&
        this.state.status_filter.value === 'Processando'
      )
        return true;
    } else {
      if (
        person.response.success === false &&
        this.state.status_filter.value === 'Erro'
      ) {
        return true;
      } else {
        if (
          person.response.success &&
          this.state.status_filter.value === 'Processado'
        )
          return true;
      }
    }

    return false;
  };

  applyFilters = list => {
    return _.chain(list).filter(
      el =>
        // (el.name.toLowerCase().includes(this.state.filter.toLowerCase()) ||
        (el.cpf.includes(this.state.filter) ||
          el.registration.includes(this.state.filter)) &&
        this.getPersonStatus(el)
    );
  };

  handleSort = (event, columnToSort) => {
    let columnDirection = this.state.columnDirection;
    if (this.state.columnToSort === columnToSort)
      columnDirection = this.state.columnDirection === 'asc' ? 'desc' : 'asc';
    this.setState({ columnToSort, columnDirection });
  };

  renderTableRecharges() {
    const { order, columnToSort, columnDirection } = this.state;

    if (!this.state.hasRecharges)
      return (
        <Typography variant='subtitle1' align='center' style={{ margin: 50 }}>
          Não há recargas neste pedido.
        </Typography>
      );

    const themeAlt = createMuiTheme(portalThemeAlt);

    return (
      <Fragment>
        <Table padding='none'>
          <OrderTableRechargesHead
            handleSort={this.handleSort}
            columnDirection={columnDirection}
          />
          <TableBody>
            {this.applyFilters(order.recharges)
              .slice(
                this.state.rechargesOffset,
                this.state.rechargesOffset + 50
              )
              .orderBy([columnToSort], columnDirection)
              .map(row => {
                // Button helper
                let status_button_status;
                if (!row.response) {
                  status_button_status = 'processing';
                } else {
                  row.response.success === true
                    ? (status_button_status = 'success')
                    : (status_button_status = 'error');
                }

                return (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Typography variant='subtitle1' color='secondary'>
                        {utils.handleCPF(row.cpf)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='subtitle1' color='secondary'>
                        {`R$ ${utils.formatCurrency(row.yalo_benefit)}`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {/* Button Pendente */}
                      {status_button_status === 'processing' &&
                        (order.order_status === 'Pendente de Pagamento' ||
                          order.order_status === 'Pagamento Parcial') && (
                          <Button disabled fullWidth variant='outlined'>
                            Pendente
                          </Button>
                        )}

                      {/* Button Processando */}
                      {status_button_status === 'processing' &&
                        order.order_status !== 'Pendente de Pagamento' && (
                          <Button disabled fullWidth variant='outlined'>
                            Processando
                          </Button>
                        )}

                      {/* Button Processado */}
                      {status_button_status === 'success' && (
                        <Button
                          variant='outlined'
                          color='primary'
                          fullWidth
                          onClick={() => this.handleRechargesClick(row)}
                        >
                          Processado
                        </Button>
                      )}

                      {/* Button Processando */}
                      {status_button_status === 'error' && (
                        <MuiThemeProvider theme={themeAlt}>
                          <Button
                            variant='outlined'
                            color='secondary'
                            fullWidth
                            onClick={() => this.handleRechargesClick(row)}
                          >
                            Erro
                          </Button>
                        </MuiThemeProvider>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
              .value()}
          </TableBody>
        </Table>
        <br />
        <Grid container style={{ marginTop: 12 }} justify='space-between'>
          <Pagination
            limit={50}
            offset={this.state.rechargesOffset}
            total={this.applyFilters(order.recharges).length}
            onClick={(e, rechargesOffset) => {
              this.setState({ rechargesOffset });
            }}
          />
          {this.props.location.filter ? (
            <Link
              to={{
                pathname: `/app/pedidos-emitidos`,
                filter: {
                  func: this.props.location.filter,
                  hasFilter: true
                }
              }}
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant='outlined'
                color='primary'
                style={{ marginRight: '16px' }}
              >
                Voltar
              </Button>
            </Link>
          ) : (
            <Link
              to={{
                pathname: `/app/pedidos-emitidos`
              }}
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant='outlined'
                color='primary'
                style={{ marginRight: '16px' }}
              >
                Voltar
              </Button>
            </Link>
          )}
        </Grid>

        {/* Popup do Recargas */}
        <Dialog
          fullWidth
          maxWidth='sm'
          open={this.state.rechargesDialog.open}
          onClose={this.handleCloseDialog}
          aria-labelledby='customized-dialog-title'
        >
          <DialogTitle disableTypography onClose={this.handleCloseDialog}>
            Status
          </DialogTitle>
          {this.state.rechargesDialog.loading ? (
            <Grid container spacing={16} justify='center'>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={16}
                  justify='center'
                  style={{ padding: 30 }}
                >
                  <CircularProgress
                    mode='indeterminate'
                    style={{ color: '#005DF9' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <DialogContent>
              <Typography color='primary' variant='body2'>
                RECARGAS
              </Typography>

              {/* Loaded */}

              {this.state.rechargesDialog.open &&
                !this.state.rechargesDialog.loading && (
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>Nome:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {this.state.rechargesDialog.row.name}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>CPF:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {utils.handleCPF(
                              this.state.rechargesDialog.row.cpf
                            )}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>Conta:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {this.state.rechargesDialog.row.yalo_account}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1' gutterBottom>
                            Benefício:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2' gutterBottom>
                            {`R$ ${utils.formatCurrency(
                              this.state.rechargesDialog.row.yalo_benefit
                            )}`}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>
                            Matrícula:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {this.state.rechargesDialog.row.registration}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Divider
                        style={{
                          marginTop: 16,
                          marginBottom: 16
                        }}
                      />

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>
                            Data de recarga:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {` ${utils.momentFromDate(
                              this.state.rechargesDialog.integrated_at
                            )}`}
                          </Typography>
                        </Grid>
                      </Grid>

                      {this.state.rechargesDialog.errorMessage && (
                        <Fragment>
                          <Divider
                            style={{
                              marginTop: 16,
                              marginBottom: 16
                            }}
                          />

                          <Grid container>
                            <Grid item xs={6}>
                              <Typography variant='subtitle1'>Erro:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant='subtitle2'>
                                {this.state.rechargesDialog.errorMessage}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Fragment>
                      )}
                    </Grid>
                  </Grid>
                )}
            </DialogContent>
          )}
        </Dialog>
      </Fragment>
    );
  }

  renderTableNewEmployees() {
    const { order, columnToSort, columnDirection } = this.state;
    const themeAlt = createMuiTheme(portalThemeAlt);

    if (!this.state.hasEmployees)
      return (
        <Typography variant='subtitle1' align='center' style={{ margin: 50 }}>
          Não há novos empregados neste pedido.
        </Typography>
      );

    return (
      <Fragment>
        <Table padding='dense'>
          <OrderTableNewEmployeesHead
            handleSort={this.handleSort}
            columnDirection={columnDirection}
          />

          <TableBody>
            {this.applyFilters(order.new_employees)
              .slice(this.state.newEmpOffset, this.state.newEmpOffset + 50)
              .orderBy([columnToSort], columnDirection)
              .map(row => {
                let status_button_status;
                if (!row.response) {
                  status_button_status = 'processing';
                } else {
                  row.response.success === true
                    ? (status_button_status = 'success')
                    : (status_button_status = 'error');
                }
                return (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Typography variant='subtitle1' color='secondary'>
                        {row.registration}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='subtitle1' color='secondary'>
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell>{utils.handleCPF(row.cpf)}</TableCell>
                    <TableCell>
                      <Typography variant='subtitle1' color='secondary'>
                        {`R$ ${utils.formatCurrency(row.yalo_benefit)}`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {/* Button Pendente */}
                      {status_button_status === 'processing' &&
                        (order.order_status === 'Pendente de Pagamento' ||
                          order.order_status === 'Pagamento Parcial') && (
                          <Button disabled fullWidth variant='outlined'>
                            Pendente
                          </Button>
                        )}

                      {/* Button Processando */}
                      {status_button_status === 'processing' &&
                        order.order_status !== 'Pendente de Pagamento' && (
                          <Button disabled fullWidth variant='outlined'>
                            Processando
                          </Button>
                        )}

                      {/* Button Processado */}
                      {status_button_status === 'success' && (
                        <Button
                          variant='outlined'
                          color='primary'
                          fullWidth
                          onClick={() => this.handleEmployeesClick(row)}
                        >
                          Processado
                        </Button>
                      )}

                      {/* Button Processando */}
                      {status_button_status === 'error' && (
                        <MuiThemeProvider theme={themeAlt}>
                          <Button
                            variant='outlined'
                            color='secondary'
                            fullWidth
                            onClick={() => this.handleEmployeesClick(row)}
                          >
                            Erro
                          </Button>
                        </MuiThemeProvider>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <br />
        <Pagination
          limit={50}
          offset={this.state.newEmpOffset}
          total={this.applyFilters(order.new_employees).length}
          onClick={(e, newEmpOffset) => {
            this.setState({ newEmpOffset });
          }}
        />

        {/* Popup do Novos funcrionarios */}
        <Dialog
          fullWidth
          maxWidth='md'
          open={this.state.employeesDialog.open}
          onClose={this.handleCloseDialog}
          aria-labelledby='customized-dialog-title'
        >
          <DialogTitle disableTypography onClose={this.handleCloseDialog}>
            Status
          </DialogTitle>
          {this.state.employeesDialog.loading ? (
            <Grid container spacing={16} justify='center'>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={16}
                  justify='center'
                  style={{ padding: 30 }}
                >
                  <CircularProgress
                    mode='indeterminate'
                    style={{ color: '#005DF9' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <DialogContent>
              <Typography color='primary' variant='body2'>
                NOVOS FUNCIONÁRIOS
              </Typography>

              {/* Loaded */}

              {this.state.employeesDialog.open &&
                !this.state.employeesDialog.loading && (
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>
                            Matrícula:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {this.state.employeesDialog.row.registration}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>Nome:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {this.state.employeesDialog.row.name}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>CPF:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {utils.handleCPF(
                              this.state.employeesDialog.row.cpf
                            )}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>Conta:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {this.state.employeesDialog.row.yalo_account}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1' gutterBottom>
                            Benefício:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2' gutterBottom>
                            {`R$ ${utils.formatCurrency(
                              this.state.employeesDialog.row.yalo_benefit
                            )}`}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Divider
                        style={{
                          marginTop: 16,
                          marginBottom: 16
                        }}
                      />

                      {this.state.employeesDialog.errorMessage && (
                        <Fragment>
                          <Divider
                            style={{
                              marginTop: 16,
                              marginBottom: 16
                            }}
                          />

                          <Grid container>
                            <Grid item xs={6}>
                              <Typography variant='subtitle1'>Erro:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant='subtitle2'>
                                {this.state.employeesDialog.errorMessage}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Fragment>
                      )}
                    </Grid>
                  </Grid>
                )}
            </DialogContent>
          )}
        </Dialog>
      </Fragment>
    );
  }

  renderTableTerminations() {
    const { order, columnToSort, columnDirection } = this.state;

    if (!this.state.hasTerminations)
      return (
        <Typography variant='subtitle1' align='center' style={{ margin: 50 }}>
          Não há cancelamentos neste pedido.
        </Typography>
      );

    const themeAlt = createMuiTheme(portalThemeAlt);

    return (
      <Fragment>
        <Table padding='dense'>
          <OrderTableTerminationsHead
            handleSort={this.handleSort}
            columnDirection={columnDirection}
          />
          <TableBody>
            {this.applyFilters(order.terminations)
              .slice(
                this.state.terminationsOffset,
                this.state.terminationsOffset + 50
              )
              .orderBy([columnToSort], columnDirection)
              .map(row => {
                // Button helper
                let status_button_status;
                if (!row.response) {
                  status_button_status = 'processing';
                } else {
                  row.response.success === true
                    ? (status_button_status = 'success')
                    : (status_button_status = 'error');
                }

                return (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Typography variant='subtitle1' color='secondary'>
                        {row.registration}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='subtitle1' color='secondary'>
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='subtitle1' color='secondary'>
                        {utils.handleCPF(row.cpf)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='subtitle1' color='secondary'>
                        {row.termination_date}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {/* Button Processando */}
                      {status_button_status === 'processing' && (
                        <Button disabled fullWidth variant='outlined'>
                          Processando
                        </Button>
                      )}

                      {/* Button Processado */}
                      {status_button_status === 'success' && (
                        <Button
                          variant='outlined'
                          color='primary'
                          fullWidth
                          onClick={() => this.handleTerminationsClick(row)}
                        >
                          Processado
                        </Button>
                      )}

                      {/* Button Processando */}
                      {status_button_status === 'error' && (
                        <MuiThemeProvider theme={themeAlt}>
                          <Button
                            variant='outlined'
                            color='secondary'
                            fullWidth
                            onClick={() => this.handleTerminationsClick(row)}
                          >
                            Erro
                          </Button>
                        </MuiThemeProvider>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <br />
        <Pagination
          limit={50}
          offset={this.state.terminationsOffset}
          total={this.applyFilters(order.terminations).length}
          onClick={(e, terminationsOffset) => {
            this.setState({ terminationsOffset });
          }}
        />

        {/* Popup do Cancelamentos */}
        <Dialog
          fullWidth
          maxWidth='md'
          open={this.state.terminationsDialog.open}
          onClose={this.handleCloseDialog}
          aria-labelledby='customized-dialog-title'
        >
          <DialogTitle disableTypography onClose={this.handleCloseDialog}>
            Status
          </DialogTitle>
          {this.state.terminationsDialog.loading ? (
            <Grid container spacing={16} justify='center'>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={16}
                  justify='center'
                  style={{ padding: 30 }}
                >
                  <CircularProgress
                    mode='indeterminate'
                    style={{ color: '#005DF9' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <DialogContent>
              <Typography color='primary' variant='body2'>
                RECARGAS
              </Typography>

              {/* Loading */}
              {this.state.terminationsDialog.loading && (
                <Typography color='primary' variant='body2'>
                  Carregando...
                </Typography>
              )}

              {/* Loaded */}

              {this.state.terminationsDialog.open &&
                !this.state.terminationsDialog.loading && (
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>
                            Matrícula:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {this.state.terminationsDialog.row.registration}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>Nome:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {this.state.terminationsDialog.row.name}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>CPF:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {utils.handleCPF(
                              this.state.terminationsDialog.row.cpf
                            )}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>Conta:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {this.state.terminationsDialog.row.yalo_account}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1' gutterBottom>
                            Benefício:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2' gutterBottom>
                            {`R$ ${utils.formatCurrency(
                              this.state.terminationsDialog.row.yalo_benefit
                            )}`}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Divider
                        style={{
                          marginTop: 16,
                          marginBottom: 16
                        }}
                      />

                      <Typography color='primary' variant='body2'>
                        Nexo
                      </Typography>

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>
                            Integrado em:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {this.state.terminationsDialog.nexo.integrated_at &&
                              utils.formatDate(
                                moment(
                                  this.state.terminationsDialog.nexo
                                    .integrated_at
                                ).format('DD/MM/YYYY')
                              )}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>
                            Data de integração:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {this.state.terminationsDialog.nexo
                              .integration_date &&
                              utils.formatDate(
                                moment(
                                  this.state.terminationsDialog.nexo
                                    .integration_date
                                ).format('DD/MM/YYYY')
                              )}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>Erro:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {this.state.terminationsDialog.nexo.error_message ||
                              '--'}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>Status:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {this.state.terminationsDialog.nexo.status ===
                            'success'
                              ? 'Sucesso'
                              : 'Erro'}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Typography
                        color='primary'
                        variant='body2'
                        style={{ marginTop: 16 }}
                      >
                        Dr Consulta
                      </Typography>

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>
                            Integrado em:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {this.state.terminationsDialog.drconsulta
                              .integrated_at &&
                              utils.formatDate(
                                moment(
                                  this.state.terminationsDialog.drconsulta
                                    .integrated_at
                                ).format('DD/MM/YYYY')
                              )}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>
                            Data de integração:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {this.state.terminationsDialog.drconsulta
                              .integration_date &&
                              utils.formatDate(
                                moment(
                                  this.state.terminationsDialog.drconsulta
                                    .integration_date
                                ).format('DD/MM/YYYY')
                              )}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>Erro:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {
                              this.state.terminationsDialog.drconsulta
                                .error_message
                            }
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>Status:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>
                            {this.state.terminationsDialog.drconsulta.status}
                          </Typography>
                        </Grid>
                      </Grid>

                      {this.state.terminationsDialog.errorMessage && (
                        <Fragment>
                          <Divider
                            style={{
                              marginTop: 16,
                              marginBottom: 16
                            }}
                          />

                          <Grid container>
                            <Grid item xs={6}>
                              <Typography variant='subtitle1'>Erro:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant='subtitle2'>
                                {this.state.terminationsDialog.errorMessage}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Fragment>
                      )}
                    </Grid>
                  </Grid>
                )}
            </DialogContent>
          )}
        </Dialog>
      </Fragment>
    );
  }

  render() {
    const {
      order,
      downloadUrl,
      pdfDownloadError,
      downloadInvoiceUrl,
      invoiceDownloadError,
      orderDownloadError,
      downloadOrderUrl
    } = this.state;
    const { modalEmitAlert } = this.props;

    const order_resume_table_data = _.pick(
      order,
      `taxes_json`,
      `total_recharges`,
      `total_value_recharges`,
      `total_plans`,
      `total_value_plans`,
      `total_terminations`,
      `total_value_taxes`,
      `value`
    );

    return (
      <div>
        <div className='app-wrapper' style={{ paddingBottom: 0 }}>
          <Grid container justify='space-between'>
            <Grid item>
              <ContainerHeader title='Detalhes do Pedido' />
            </Grid>
            {/* Botao Download */}

            <Grid
              container
              // spacing={4}
              xs={6}
              direction='row'
              justify='flex-end'
              alignItems='center'
            >
              {orderDownloadError ? (
                <Button
                  variant='outlined'
                  color='primary'
                  className='p-5'
                  onClick={() =>
                    modalEmitAlert('Erro ao fazer download do pedido.', ERROR)
                  }
                >
                  <CloudDownload
                    variant='rounded'
                    style={{ marginRight: '10px' }}
                  />
                  Baixar Pedido
                </Button>
              ) : (
                <a
                  className='download-xlsx'
                  href={downloadOrderUrl}
                  download={`Pedido.xlsx`}
                  target='_blank'
                >
                  <Button variant='outlined' color='primary' className='mr-2'>
                    <CloudDownload
                      variant='rounded'
                      style={{ marginRight: '10px' }}
                    />
                    Baixar Pedido
                  </Button>
                </a>
              )}
              {invoiceDownloadError ? (
                <Button
                  variant='outlined'
                  color='primary'
                  className='p-5'
                  onClick={() =>
                    modalEmitAlert(
                      'Erro ao fazer download da nota de adiantemento.',
                      ERROR
                    )
                  }
                >
                  <CloudDownload
                    variant='rounded'
                    style={{ marginRight: '10px' }}
                  />
                  Baixar Nota
                </Button>
              ) : (
                <a
                  className='download-xlsx'
                  href={downloadInvoiceUrl}
                  download={`NotaAdiantamento.pdf`}
                  target='_blank'
                >
                  <Button variant='outlined' color='primary' className='mr-2'>
                    <CloudDownload
                      variant='rounded'
                      style={{ marginRight: '10px' }}
                    />
                    Baixar Nota
                  </Button>
                </a>
              )}
              {pdfDownloadError ? (
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() =>
                    modalEmitAlert('Erro ao fazer download do boleto.', ERROR)
                  }
                >
                  <CloudDownload
                    variant='rounded'
                    style={{ marginRight: '10px' }}
                  />
                  Baixar Boleto
                </Button>
              ) : (
                <a
                  className='download-xlsx'
                  href={downloadUrl}
                  download={`Boleto.pdf`}
                  target='_blank'
                >
                  <Button variant='outlined' color='primary'>
                    <CloudDownload
                      variant='rounded'
                      style={{ marginRight: '10px' }}
                    />
                    Baixar Boleto
                  </Button>
                </a>
              )}
            </Grid>
          </Grid>
          <Grid item style={{ padding: 24 }}>
            <OrderHeader order={order} />
          </Grid>
        </div>
        {this.state.order && this.state.order.order_status === 'Atrasado' && (
          <Grid item style={{ marginBottom: 10 }}>
            <WarningOrderDue order={order} />
          </Grid>
        )}
        <div className='app-wrapper'>
          {/* Loading */}
          {this.state.loading && (
            <Grid container spacing={16} justify='center'>
              <Grid item xs={12}>
                <Grid container spacing={16} justify='center'>
                  <CircularProgress
                    mode='indeterminate'
                    style={{ color: '#005DF9' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          <Paper>
            <Grid container>
              <Grid item xs={12}>
                {order.id && (
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container style={{ padding: 24 }} spacing={24}>
                        <Grid item xs={4}>
                          <YaloSearchFilter
                            onChange={e =>
                              this.setState({
                                filter: e.target.value,
                                rechargesOffset: 0,
                                newEmpOffset: 0,
                                terminationsOffset: 0
                              })
                            }
                            value={this.state.filter}
                            placeholder='Busca por nome, cpf ou matricula'
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Select
                            value={this.state.status_filter}
                            options={[
                              {
                                value: 'Processado',
                                label: 'Processado'
                              },
                              {
                                value: 'Erro',
                                label: 'Erro'
                              },
                              {
                                value: 'Processando',
                                label: 'Processando'
                              },
                              {
                                value: 'Pendente',
                                label: 'Pendente'
                              }
                            ]}
                            onChange={e =>
                              this.setState({
                                status_filter: e,
                                rechargesOffset: 0,
                                newEmpOffset: 0,
                                terminationsOffset: 0
                              })
                            }
                            placeholder='Filtrar por status'
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Grid container justify='flex-end'>
                            <Tooltip title='Limpar Filtros'>
                              <IconButton
                                aria-label='Limpar Filtros'
                                size='small'
                                onClick={() =>
                                  this.setState({
                                    filter: '',
                                    status_filter: '',
                                    newEmpOffset: 0,
                                    rechargesOffset: 0,
                                    terminationsOffset: 0
                                  })
                                }
                              >
                                <CloseIcon variant='round' />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12}>
                        <Tabs
                          value={this.state.tabValue}
                          onChange={this.handleTabChange}
                          indicatorColor='primary'
                          textColor='primary'
                          variant='fullWidth'
                        >
                          <Tab label={`Resumo do Pedido`} />
                          <Tab label={`Recargas (${order.recharges.length})`} />
                        </Tabs>
                        <Divider />

                        {/* Resumo do Pedido */}
                        {this.state.tabValue === 0 && (
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              style={{
                                margin: 24,
                                marginBottom: 32
                              }}
                            >
                              <OrderTableResume {...order_resume_table_data} />
                            </Grid>
                          </Grid>
                        )}

                        {/* Recargas */}
                        {this.state.tabValue === 1 && (
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              style={{
                                margin: 24
                              }}
                            >
                              {this.renderTableRecharges()}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
        </div>
      </div>
    );
  }
}
export default connect(null, { modalEmitAlert })(withRouter(ConsultingOrder));
