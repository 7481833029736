import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';

import { utils } from '../../utils';

class UploadSuccessTable extends Component {
  state = {
    tabValue: 0,
    expiration_date: '',
    discount: '0.00'
  };

  componentDidMount() {
    this.setDefaultDate();
  }

  setDefaultDate = () => {
    let date = new Date();
    date = new Date(date.getTime() + 86400000 * 2);
    if (date.getDay() === 0) {
      date = new Date(date.getTime() + 86400000);
    } else if (date.getDay() === 6) {
      date = new Date(date.getTime() + 86400000 * 2);
    }

    this.setState({
      expiration_date:
        `${date.getFullYear()}-` +
        `0${date.getMonth() + 1}-`.slice(-3) +
        `0${date.getDate()}`.slice(-2)
    });
  };

  handleTab = (event, tabValue) => {
    this.setState({ tabValue });
  };

  formatCNPJ(cnpj) {
    cnpj = `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(
      5,
      8
    )}/${cnpj.slice(8, 12)}-${cnpj.slice(12, 14)}`;
    return cnpj;
  }

  setDiscount = value => {
    if (value.length < 1) return '';
    let floatValue = utils.removeNonNumericCharacters(value);
    floatValue = parseFloat(
      `${floatValue.slice(0, -2)}.${floatValue.slice(-2)}`
    );
    let floatBalance = utils.removeNonNumericCharacters(
      this.props.resume.contract_credit_balance
    );
    floatBalance = parseFloat(
      `${floatBalance.slice(0, -2)}.${floatBalance.slice(-2)}`
    );

    return floatValue > floatBalance
      ? utils.removeNonNumericCharacters(
          this.props.resume.contract_credit_balance
        )
      : utils.removeNonNumericCharacters(value);
  };

  render() {
    const { tabValue } = this.state;
    return (
      <Grid container spacing={16} justify="center" style={{ marginTop: 16 }}>
        <Grid item xs={12} className={`${this.props.classes.root}`}>
          <Grid container justify="center">
            <Grid item xs={12}>
              <Table padding="dense">
                <TableHead>
                  <TableRow>
                    <TableCell component="th" style={{ width: '40%' }}>
                      <Typography variant="subtitle1">DESCRIÇÃO</Typography>
                    </TableCell>
                    <TableCell
                      component="th"
                      style={{ width: '10%' }}
                      align="right"
                    >
                      <Typography variant="subtitle1">QUANTIDADE</Typography>
                    </TableCell>
                    <TableCell
                      component="th"
                      style={{ width: '50%' }}
                      align="right"
                    >
                      <Typography variant="subtitle1">VALOR</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">Recargas</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="subtitle2">
                        {this.props.resume.totalRecharges}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="subtitle2">
                        R${' '}
                        {utils.formatCurrency(
                          this.props.resume.totalValueRecharges
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow selected>
                    <TableCell>
                      <Typography variant="subtitle2">Total</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography />
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="subtitle2">
                        R${' '}
                        {utils.formatCurrency(
                          this.props.resume.totalValueNewEmployees +
                            this.props.resume.totalValuePlans +
                            this.props.resume.totalValueRecharges
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default UploadSuccessTable;
