import React from 'react';

function AssignmentAddIcon({ className, ...props }) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={32}
        height={32}
      >
        <path fill="#D9D9D9" d="M0 0H32V32H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M6.667 28a2.568 2.568 0 01-1.884-.783A2.568 2.568 0 014 25.333V6.667c0-.734.261-1.361.783-1.884A2.568 2.568 0 016.667 4h5.6c.289-.8.772-1.444 1.45-1.933A3.809 3.809 0 0116 1.333c.844 0 1.606.245 2.283.734A4.004 4.004 0 0119.733 4h5.6c.734 0 1.361.261 1.884.783.522.523.783 1.15.783 1.884v6.7c0 .377-.128.694-.383.95a1.29 1.29 0 01-.95.383 1.29 1.29 0 01-.95-.383 1.29 1.29 0 01-.384-.95v-6.7H6.667v18.666h6.666c.378 0 .695.128.95.384.256.255.384.572.384.95 0 .377-.128.694-.384.95a1.29 1.29 0 01-.95.383H6.667zm0-2.667V6.667v8.1-.1 10.666zm4-2.666H14c.378 0 .694-.128.95-.384a1.29 1.29 0 00.383-.95 1.29 1.29 0 00-.383-.95A1.29 1.29 0 0014 20h-3.333a1.29 1.29 0 00-.95.383 1.29 1.29 0 00-.384.95c0 .378.128.695.384.95.255.256.572.384.95.384zm0-5.334h6.666c.378 0 .695-.127.95-.383a1.29 1.29 0 00.384-.95 1.29 1.29 0 00-.384-.95 1.29 1.29 0 00-.95-.383h-6.666a1.29 1.29 0 00-.95.383 1.29 1.29 0 00-.384.95c0 .378.128.695.384.95.255.256.572.383.95.383zm0-5.333h10.666c.378 0 .695-.128.95-.383a1.29 1.29 0 00.384-.95 1.29 1.29 0 00-.384-.95 1.29 1.29 0 00-.95-.384H10.667a1.29 1.29 0 00-.95.384 1.29 1.29 0 00-.384.95c0 .377.128.694.384.95.255.255.572.383.95.383zM16 5.667a.971.971 0 00.717-.284.971.971 0 00.283-.716.971.971 0 00-.283-.717.971.971 0 00-.717-.283.971.971 0 00-.717.283.971.971 0 00-.283.717c0 .289.094.527.283.716a.971.971 0 00.717.284zm8 25c-1.844 0-3.417-.65-4.717-1.95-1.3-1.3-1.95-2.872-1.95-4.717 0-1.844.65-3.417 1.95-4.717 1.3-1.3 2.873-1.95 4.717-1.95 1.844 0 3.417.65 4.717 1.95 1.3 1.3 1.95 2.873 1.95 4.717 0 1.845-.65 3.417-1.95 4.717-1.3 1.3-2.873 1.95-4.717 1.95zm-.667-6v2.666a.64.64 0 00.2.467.64.64 0 00.467.2.64.64 0 00.467-.2.64.64 0 00.2-.467v-2.666h2.666a.64.64 0 00.467-.2A.64.64 0 0028 24a.64.64 0 00-.2-.467.64.64 0 00-.467-.2h-2.666v-2.666a.64.64 0 00-.2-.467A.64.64 0 0024 20a.64.64 0 00-.467.2.64.64 0 00-.2.467v2.666h-2.666a.64.64 0 00-.467.2.64.64 0 00-.2.467.64.64 0 00.2.467.64.64 0 00.467.2h2.666z"
          className="icon-path"
        />
      </g>
    </svg>
  );
}

export default AssignmentAddIcon;
