
import moment from 'moment';

// Função de formatação de datas
export const formatDate = (value, key) => {
  // Lista de formatos possíveis para datas
  const dateFormats = ['DD/MM/YYYY HH:mm:ss', moment.ISO_8601];

  // Tenta parsear a data usando os formatos possíveis
  const momentDate = moment(value, dateFormats, true);
  
  // Verifica se o valor parece ser uma data válida e formata
  if (momentDate.isValid()) {
    return momentDate.format('DD/MM/YYYY');
  }
  
  return value;
};

export const getCellValue = (row, columnId) => {
  const cellValue = row[columnId];
  if (cellValue === null || cellValue === undefined) {
    return '-';
  }
  return formatDate(cellValue, columnId);
};

export const exportToCSV = (data, columns) => {
  // Obter os cabeçalhos traduzidos
  const headers = columns.map((column) => column.label);

  // Gerar linhas de dados formatadas
  const rows = data.map((row) => {
    return columns.map((column) => getCellValue(row, column.id));
  });

  // Unir cabeçalhos e linhas de dados em uma string CSV
  const csvContent =
    'data:text/csv;charset=utf-8,' +
    [headers.join(',')].concat(rows.map((row) => row.join(','))).join('\n');

  // Criar e clicar no link de download
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'dados.csv');
  document.body.appendChild(link);
  link.click();
};