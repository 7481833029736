import React from 'react';
import {
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody
} from '@material-ui/core';

function getTableWarning(obj, title, type) {
  if (obj.hasOwnProperty(title)) {
    const response = obj[title];
    let content = [];
    let counter = 0;

    let rowBackgroundColor = true;
    for (let line in response) {
      let warningType = response[line];
      if (typeof warningType === 'string') warningType = [warningType];
      let lineCounter = 0;
      for (let property in warningType) {
        counter++;
        content.push(
          <TableRow
            key={line + '-' + property}
            style={{
              backgroundColor: rowBackgroundColor ? '#f7f7f7' : 'white'
            }}
          >
            <TableCell>{lineCounter > 0 ? '' : line}</TableCell>
            <TableCell>{warningType[property]}</TableCell>
          </TableRow>
        );
        lineCounter++;
      }
      rowBackgroundColor = !rowBackgroundColor;
    }
    // totalErrors += counter;
    return [
      <Table key={title} counter={counter}>
        <TableHead>
          <TableRow>
            <TableCell>Causa</TableCell>
            <TableCell>{type}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{content}</TableBody>
      </Table>
    ];
  } else {
    return [];
  }
}

function getTable(obj, title, type) {
  if (obj.hasOwnProperty(title)) {
    const response = obj[title];
    let content = [];
    let counter = 0;

    let rowBackgroundColor = true;
    for (let line in response) {
      let lineCounter = 0;
      for (let property in response[line]) {
        counter++;
        content.push(
          <TableRow
            key={line + '-' + property}
            style={{
              backgroundColor: rowBackgroundColor ? '#f7f7f7' : 'white'
            }}
          >
            <TableCell>
              {lineCounter > 0 ? '' : line.replace('Linha ', '')}
            </TableCell>
            <TableCell>{[property].toString().toUpperCase()}</TableCell>
            <TableCell>{response[line][property]}</TableCell>
          </TableRow>
        );
        lineCounter++;
      }
      rowBackgroundColor = !rowBackgroundColor;
    }
    // totalErrors += counter;
    return [
      <Table key={title} counter={counter}>
        <TableHead>
          <TableRow>
            <TableCell>Linha</TableCell>
            <TableCell>Campo</TableCell>
            <TableCell>{type}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{content}</TableBody>
      </Table>
    ];
  } else {
    return [];
  }
}

function getTotal(array) {
  if (array[0] && array[0].props) return array[0].props.counter;
  return 0;
}

export const contractErrors = errors => {
  let deliveryAreasErrors = [];
  let newEmployeesErrors = [];
  let rechargesErrors = [];
  let terminationErrors = [];
  let genericErrors = [];

  let totalErrors = 0;

  if (errors) {
    rechargesErrors = getTable(errors, 'recharges', 'Erro');
    genericErrors = getTableWarning(errors, 'generic', 'Erro');
    totalErrors += getTotal(rechargesErrors);
    totalErrors += getTotal(genericErrors);
  }

  return {
    rechargesErrors,
    genericErrors,
    totalErrors
  };
};

export const contractWarnings = warnings => {
  let rechargesWarning = [];
  let genericWarning = [];
  let totalWarnings = 0;

  if (warnings) {
    rechargesWarning = getTable(warnings, 'recharges', 'Aviso');
    genericWarning = getTable(warnings, 'generic', 'Aviso');
    genericWarning = getTableWarning(warnings, 'generic', 'Aviso');
    totalWarnings += getTotal(rechargesWarning);
    totalWarnings += getTotal(genericWarning);
  }
  return {
    genericWarning,
    rechargesWarning,
    totalWarnings
  };
};
