import React, { Fragment } from 'react';
import {
  Grid,
  Paper,
  Divider,
  Typography,
  Button,
  LinearProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import IconCloudUpload from '@material-ui/icons/CloudUploadRounded';
import IconCheckCircle from '@material-ui/icons/CheckCircleOutlineRounded';

import { utils } from './../../../../utils';

export const ReviewFile = ({
  file,
  handleBack,
  warnings,
  loading,
  errors,
  resume,
  toggleDetail,
  showDetailStep2,
  order
}) => {
  return (
    <Grid container justify='center'>
      <Paper className='w-75'>
        <Grid
          container
          justify='space-between'
          alignItems='center'
          style={{ padding: 16 }}
        >
          <Grid item xs={8}>
            <Typography variant='h4'>{file.name}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Grid container justify='flex-end' alignItems='center'>
              <Button
                size='small'
                variant='outlined'
                color='primary'
                aria-label='Baixar Planilha de Exemplo'
                onClick={handleBack}
              >
                <IconCloudUpload style={{ marginRight: 16 }} />
                NOVO UPLOAD
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {loading ? <LinearProgress /> : <Divider />}

        {(errors === null || errors === undefined) && (
          <ReviewFileOk
            toggleDetail={toggleDetail}
            showDetailStep2={showDetailStep2}
          />
        )}

        <ReviewFileError errors={errors} warnings={warnings} order={order} />
      </Paper>

      {showDetailStep2 && <ReviewFileOkTable resume={resume} />}
    </Grid>
  );
};

//Uploading file ======//
const ReviewFileError = ({ errors, warnings, order }) => (
  <Grid container direction='column'>
    <Divider />

    {/* ERROR LISt */}
    {errors && Object.keys(errors).length !== 0 && (
      <Fragment>
        <Grid container spacing={16} style={{ padding: 20 }} className='ml-1'>
          <Typography variant='h3' className='red-info'>{`Erro`}</Typography>
        </Grid>

        <Divider />
        {/* RENDER GENERIC ERRORS */}
        {Object.keys(errors).filter(error => error === 'generic').length > 0 ? (
          <Grid container spacing={16} style={{ padding: 20 }} className='ml-1'>
            {Object.keys(errors)
              .filter(error => error === 'generic')
              .map(error =>
                Object.keys(errors[error]).map((line, key) => (
                  <Grid container direction='row' xs={12} key={key}>
                    <Grid item xs={3}>
                      <Typography variant='p'>{`${line}`}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant='p'>
                        {` ${errors[error][line]}`}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              )}
          </Grid>
        ) : (
          <Grid container spacing={16} style={{ padding: 20 }} className='ml-1'>
            <Typography variant='p'>{`Nenhum erro genérico`}</Typography>
          </Grid>
        )}

        <Divider />
        {Object.keys(errors).filter(error => error !== 'generic').length >
          0 && (
          <Grid>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Linha</TableCell>
                  <TableCell> Erros</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* RENDER NORMAL ERRORS */}
                {Object.keys(errors)
                  .filter(error => error !== 'generic')
                  .map(error =>
                    Object.keys(errors[error]).map((line, key) => (
                      <TableRow key={key}>
                        <TableCell>{`${line}`}</TableCell>
                        <TableCell>
                          {Object.keys(errors[error][line]).map(type => (
                            <p>{`${type}: ${errors[error][line][type]}`}</p>
                          ))}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
              </TableBody>
            </Table>
          </Grid>
        )}
      </Fragment>
    )}

    {/* WARNINGS LIST */}
    {warnings && Object.keys(warnings).length !== 0 && (
      <Fragment>
        <Grid container spacing={16} style={{ padding: 20 }} className='ml-1'>
          <Typography
            variant='h3'
            className='orange-info'
          >{`Aviso`}</Typography>
        </Grid>

        {/* RENDER GENERIC WARNING */}
        <Divider />
        {Object.keys(warnings).filter(warnings => warnings === 'generic')
          .length > 0 ? (
          <Grid container spacing={16} style={{ padding: 20 }} className='ml-1'>
            {Object.keys(warnings)
              .filter(warnings => warnings === 'generic')
              .map(warning =>
                Object.keys(warnings[warning]).map(line => (
                  <Grid container direction='row' xs={12}>
                    <Grid item xs={3}>
                      <Typography variant='p'>{`${line}`}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant='p'>
                        {` ${warnings[warning][line]}`}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              )}
          </Grid>
        ) : (
          <Grid container spacing={16} style={{ padding: 20 }} className='ml-1'>
            <Typography variant='p'>{`Nenhum aviso genérico`}</Typography>
          </Grid>
        )}

        <Divider />
        {Object.keys(warnings).filter(warnings => warnings !== 'generic')
          .length > 0 && (
          <Grid>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Linha</TableCell>
                  <TableCell> Avisos</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* RENDER NORMAL WARNING */}
                {Object.keys(warnings)
                  .filter(warnings => warnings !== 'generic')
                  .map(warning =>
                    Object.keys(warnings[warning]).map(line => (
                      <TableRow>
                        <TableCell>{`${line}`}</TableCell>
                        <TableCell>
                          {Object.keys(warnings[warning][line]).map(type => (
                            <p>{`${type}: ${warnings[warning][line][type]}`}</p>
                          ))}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
              </TableBody>
            </Table>
          </Grid>
        )}
      </Fragment>
    )}
  </Grid>
);

//Uploading file ======//
const ReviewFileOk = ({ toggleDetail, showDetailStep2 }) => (
  <Grid
    container
    alignItems='stretch'
    spacing={16}
    style={{ padding: 10, paddingLeft: 20 }}
  >
    <Grid
      container
      alignItems='center'
      justify='space-between'
      spacing={16}
      style={{ padding: 16, marginRight: 16 }}
    >
      <Grid container item xs={10}>
        <IconCheckCircle
          className='green-info'
          style={{ marginLeft: 16, marginRight: 16, fontSize: 36 }}
        />
        <Typography variant='h2' align='center' className='green-info'>
          Arquivo validado
        </Typography>
      </Grid>
    </Grid>
  </Grid>
);

const ReviewFileOkTable = ({ resume }) => (
  <Grid container justify='center' className='mx-5 mt-4'>
    <Grid item xs={10} className='mx-2'>
      <Table>
        <TableHead>
          <TableRow style={{ height: '20px' }}>
            <TableCell style={{ color: 'rgb(0,0,0,1)' }}>
              YALO Corporativo
            </TableCell>
            <TableCell>Valor unitário</TableCell>
            <TableCell>Quantidade</TableCell>
            <TableCell align='right'>Valor</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow style={{ height: '20px' }}>
            <TableCell
              style={{ color: 'rgb(0,0,0,0.54)' }}
            >{`Novos`}</TableCell>
            <TableCell>{``}</TableCell>
            <TableCell>{resume.totalNewEmployees}</TableCell>
            <TableCell align='right'>{``}</TableCell>
          </TableRow>

          <TableRow style={{ height: '20px' }}>
            <TableCell
              style={{ color: 'rgb(0,0,0,0.54)' }}
            >{`Desligamentos`}</TableCell>
            <TableCell>{``}</TableCell>
            <TableCell> {resume.totalTerminations}</TableCell>
            <TableCell align='right'>{``}</TableCell>
          </TableRow>

          <TableRow style={{ height: '20px' }}>
            <TableCell
              style={{ color: 'rgb(0,0,0,0.54)' }}
            >{`Recorrentes`}</TableCell>
            <TableCell>{``}</TableCell>
            <TableCell>{resume.totalRecharges}</TableCell>
            <TableCell align='right'>{``}</TableCell>
          </TableRow>

          <TableRow style={{ height: '20px', backgroundColor: '#F1F2F3' }}>
            <TableCell
              style={{ color: 'rgb(0,0,0,0.54)' }}
            >{`Mensalidade`}</TableCell>
            <TableCell>{`R$ ${utils.formatCurrency(
              resume.contract_plans.find(plan => plan.name === 'YALO Benefício')
                .negotiated_price
            )}`}</TableCell>
            <TableCell>{`${
              resume.contract_plans.find(plan => plan.name === 'YALO Benefício')
                .qtd_plans
            }`}</TableCell>
            <TableCell align='right'>{`R$ ${utils.formatCurrency(
              resume.contract_plans
                .filter(plan => plan.name === 'YALO Benefício')
                .map(plan => plan.qtd_plans * parseInt(plan.negotiated_price))
            )}`}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>

    <Grid item xs={10} className='mx-2 mt-4'>
      <Table>
        <TableHead>
          <TableRow style={{ height: '20px' }}>
            <TableCell style={{ color: 'rgb(0,0,0,1)' }}>TAXAS</TableCell>
            <TableCell>VALOR UNITÁRIO</TableCell>
            <TableCell>QUANTIDADE</TableCell>
            <TableCell align='right'>VALOR</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resume.contract_taxes.map((taxe, index) => (
            <TableRow key={index} style={{ height: '20px' }}>
              <TableCell style={{ color: 'rgb(0,0,0,0.54)' }}>
                {taxe.description}
              </TableCell>
              <TableCell>{`R$ ${utils.formatCurrency(taxe.value)}`}</TableCell>
              <TableCell>{taxe.quantity}</TableCell>
              <TableCell align='right'>{`R$ ${utils.formatCurrency(
                taxe.total_value
              )}`}</TableCell>
            </TableRow>
          ))}

          <TableRow style={{ height: '20px', backgroundColor: '#F1F2F3' }}>
            <TableCell
              style={{ color: 'rgb(0,0,0,0.54)' }}
            >{`Total`}</TableCell>
            <TableCell>{``}</TableCell>
            <TableCell>{``}</TableCell>
            <TableCell align='right'>
              {resume.contract_taxes.length < 2
                ? `R$ ${utils.formatCurrency(
                    resume.contract_taxes[0].total_value
                  )}`
                : `R$ ${utils.formatCurrency(
                    resume.contract_taxes.reduce(
                      (cont, taxe) => cont.total_value + taxe.total_value
                    )
                  )}`}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Grid container justify='flex-end' style={{ marginTop: 32 }}>
        <Table>
          <TableHead>
            <TableRow
              style={{ height: '20px' }}
              style={{ backgroundColor: '#F1F2F3' }}
            >
              <TableCell style={{ color: 'rgb(0,0,0,1)' }}>Total</TableCell>
              <TableCell>
                <Typography align='right'>
                  {`R$ ${utils.formatCurrency(
                    resume.totalValuePlans + resume.totalValueTaxes
                  )}`}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Grid>
    </Grid>
  </Grid>
);
