import moment from 'moment';
moment.locale('pt_BR');

export const utils = {
  validateCpf,
  validateDate,
  removeNonNumericCharacters,
  handleCPF,
  handleCellphone,
  formatCurrency,
  settingNumber,
  sliceToMoneyFloat,
  unMaskMoneyInput,
  editableMaskMoneyInput,
  maskMoneyInput,
  formatCNPJ,
  formatCents,
  formatDate,
  removePonctuation,
  capDate,
  momentFromDate,
  splitStringDate,
  statusColor,
  orderStatusColor,
  orderStatusIcon,
};

function validateCpf(cpf, component) {
  if (!cpf) {
    return 'Por favor, insira um CPF válido.';
  }

  if (cpf.length < 14) {
    return 'Por favor, insira um CPF válido.';
  }

  const cpfSplitPoint = cpf.split('.');
  const cpfSplitHif = cpf.split('-');
  const formattedCpf =
    cpfSplitPoint[0] +
    cpfSplitPoint[1] +
    cpfSplitPoint[2].slice(0, 3) +
    cpfSplitHif[1];
  // Testa se todos os chars sao iguais 000000000000, 111111111111 ....
  if (formattedCpf.split('').every(char => char === formattedCpf[0])) {
    return 'Por favor, insira um CPF válido.';
  }

  let sum;
  let rest;
  for (let i = 1; i <= 9; i++)
    sum = sum + parseInt(formattedCpf.substring(i - 1, i), 10) * (11 - i);
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(formattedCpf.substring(9, 10), 10)) {
  }
  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum = sum + parseInt(formattedCpf.substring(i - 1, i), 10) * (12 - i);
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(formattedCpf.substring(10, 11), 10)) {
    return 'Por favor, insira um CPF válido.';
  } else {
    return '';
  }
}

function validateDate(date) {
  let pastDate = false;
  if (date) {
    const timestamp = new Date(date);
    let newDate = new Date();
    pastDate = timestamp.getTime() < newDate.getTime() ? true : false;
  }

  return pastDate;
}

function removeNonNumericCharacters(text) {
  if (!text) return;
  let newText = '';
  for (let i = 0; i < text.length; i++) {
    const number = Number(text[i]);
    if (!isNaN(number) && text[i] !== ' ') {
      newText += text[i];
    }
  }
  return newText;
}

function handleCPF(cpf, component) {
  if (!cpf) return;

  if (cpf.length > 14) {
    return cpf.slice(0, 14);
  }

  cpf = removeNonNumericCharacters(cpf);
  const lastDigitsList = cpf.match(/..$/);
  if (!lastDigitsList) {
    return cpf;
  }

  const lastDigits = lastDigitsList[0];
  return cpf
    .replace(/..$/, '-' + lastDigits)
    .replace(/(\d)(?=(\d{3})+\-)/g, '$1.');
}

function handleCellphone(v) {
  if (!v) return;

  if (v.length > 15) {
    return v.slice(0, 15);
  }
  v = v.replace(/\D/g, '');
  v = v.replace(/^(\d{2})(\d)/g, '($1) $2');
  v = v.replace(/(\d)(\d{4})$/, '$1-$2');
  return v;
}

function formatCurrency(value) {
  value = parseFloat(value);
  if (value) {
    if (value / 1000 > 1) {
      const thousands = Math.floor(value / 1000);

      const millions = `${thousands}.${(
        '000' + parseFloat(value % 1000).toFixed(2)
      )
        .slice(-6)
        .replace('.', ',')}`;
      if (millions.length >= 11) {
        return millions.slice(0, -10) + '.' + millions.slice(-10);
      }
      return millions;
    }

    return value.toFixed(2).replace('.', ',');
  } else {
    return '0,00';
  }
}

function sliceToMoneyFloat(value) {
  if (!value) return 0;
  return value
    ? parseFloat(
        value.toString().slice(0, -2) + '.' + value.toString().slice(-2)
      )
    : 0;
}

function unMaskMoneyInput(value) {
  if (value === undefined) return 0;
  value = removeNonNumericCharacters(value);

  value =
    '00' +
    value.substring(0, value.length - 2) +
    '.' +
    value.substring(value.length - 2);

  return parseFloat(value);
}

function editableMaskMoneyInput(value) {
  if (!value) return '0,00';
  if (value) {
    if (typeof value !== 'string') value = value.toFixed(2).toString();
    if (value.length < 3) {
      value = `00${value}`.slice(-3);
    }

    value = value.replace('.', '');
    return `${value.slice(0, -2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}${
      value.length > 2 ? ',' : ''
    }${value.slice(-2)}`;
  }
  return value;
}

function maskMoneyInput(value) {
  if (!value) return '0,00';
  if (value) {
    if (typeof value !== 'string') value = value.toFixed(2).toString();
    if (value.length < 3) {
      value = `00${value}`.slice(-3);
    }
    if (value.length > 3) {
      while (value.length > 3 && value[0] === '0') {
        value = value.slice(1);
      }
    }
    value = value.replace('.', '');
    return `${value.slice(0, -2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}${
      value.length > 2 ? ',' : ''
    }${value.slice(-2)}`;
  }
  return;
}

function formatCNPJ(cnpj) {
  if (!cnpj) return;

  cnpj = `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(
    5,
    8
  )}/${cnpj.slice(8, 12)}-${cnpj.slice(12, 14)}`;
  return cnpj;
}

function formatCents(value) {
  if (!value) return;

  if (value % 1 != 0) value = `${value.slice(0, -2)}.${value.slice(-2)}`;
  return parseFloat(value);
}

function formatDate(date) {
  if (!date) return 'N/A';
  if (date === 'Invalid date') {
    return 'N/A';
  } else {
    return date;
  }
}

function settingNumber(e) {
  if (!e) return;

  return e.target.value > 9999
    ? 9999
    : e.target.value < 1
    ? 0
    : parseInt(e.target.value.slice(-4));
}

function removePonctuation(value) {
  if (!value) return;
  return value.replace(/[^\w\s]/g, '');
}

function capDate(e) {
  if (!e) return;
  if (e.target.value.length > 10)
    e.target.value = e.target.value.slice(0, 4) + e.target.value.slice(5);

  return e;
}

// Date is in string format like 'DD-MM-YYYYT00:00:00', so
// that fuction return the date splited
function momentFromDate(date) {
  if (!date || date === null) return '--';
  return moment(this.splitStringDate(date)).format('DD/MM/YYYY');
}

function splitStringDate(date) {
  if (date) return date.split('T')[0];
  else return date;
}

function statusColor(status) {
  switch (status) {
    case 'Processado':
      return 'blue-info';
    case 'Aguardando Processamento':
    case 'Pedido em Processamento':
      return 'green-info';
    case 'Vencido':
    case 'Estornado':
    case 'Parcialmente Estornado':
    case 'Cancelado':
      return 'red-info';
    case 'Pendente de Preenchimento de Informações':
    case 'Pendente de Pagamento':
    case 'Pagamento Parcial':
    case 'Ativo':
    default:
      return 'orange-info';
  }
}

function orderStatusColor(status) {
  switch (status) {
    case 'Processado':
      return 'status-success';
    case 'Aguardando Processamento':
    case 'Pedido em Processamento':
      return 'status-info';
    case 'Vencido':
    case 'Estornado':
    case 'Parcialmente Estornado':
    case 'Cancelado':
      return 'status-error';
    case 'Pendente de Preenchimento de Informações':
    case 'Pendente de Pagamento':
    case 'Pagamento Parcial':
    case 'Ativo':
    default:
      return 'status-warning';
  }
}

function orderStatusIcon(status) {
  switch (status) {
    case 'Processado':
      return 'person_edit';
    case 'Aguardando Processamento':
    case 'Pedido em Processamento':
      return 'info';
    case 'Vencido':
    case 'Estornado':
    case 'Parcialmente Estornado':
    case 'Cancelado':
      return 'dangerous';
    case 'Pendente de Preenchimento de Informações':
    case 'Pendente de Pagamento':
    case 'Pagamento Parcial':
    case 'Ativo':
    default:
      return 'emergency_home';
  }
}