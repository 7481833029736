import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Typography,
  Grid,
  Button,
  Divider,
  ListItem
} from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import {
  setListContractsAsync,
  setContract
} from '../../actions/ContractActions';
import YaloSearchFilter from '../../components/YaloSearchFilter';
import { utils } from './utils';

class PopUpUserInfo extends React.Component {
  state = {
    contractSearch: ''
  };

  componentDidMount = () => {
    const { setListContracts } = this.props;
    setListContracts();
  };

  componentWillReceiveProps = nextProps => {
    const { open, setListContracts } = nextProps;
    if (open !== this.props.open) setListContracts();
  };

  handleContractSearch = e => {
    this.setState({ ...this.state, contractSearch: e.target.value });
  };

  selectContract = contract => {
    this.setState({ ...this.state, contractSearch: '' });

    this.props.setSelectedContract(contract);
    this.props.handleRequestClose();
  };

  getListFiltered() {
    const { contracts } = this.props;
    const { contractSearch } = this.state;

    return _.chain(contracts)
      .filter(
        contract =>
          _.includes(_.upperCase(contract.name), _.upperCase(contractSearch)) ||
          _.includes(contract.contracting_company_cnpj, contractSearch)
      )
      .value();
  }

  render() {
    const { open, handleRequestClose, classes, selectedContract } = this.props;

    const { contractSearch } = this.state;

    let filterContracts = this.getListFiltered();

    return (
      <Modal
        open={open}
        onClose={handleRequestClose}
      >
        <Grid container className={classes.paper}>
          <Grid container item xs={5}>
            <Grid container item xs={1} direction='row'>
              <div
                className={classes.rectangle}
                style={{
                  backgroundColor:
                    selectedContract.id === undefined
                      ? 'rgba(0, 0, 0, 0.12)'
                      : '#3A10E0'
                }}
              />
            </Grid>

            <Grid>
              <Typography variant='h5' id='modal-title' className='mb-0 pb-0'>
              </Typography>
              <Typography variant='h6' className='mb-0 pb-0'>
                {!_.isUndefined(selectedContract.name)
                  ? selectedContract.name.slice(0, 15)
                  : `Selecione um contrato`}
              </Typography>
              <Typography variant='subtitle1'>
                CNPJ:{' '}
                {utils.formatCNPJ(selectedContract.contracting_company_cnpj) ||
                  '-'}
              </Typography>
              <Typography variant='subtitle1' className='mb-4 pb-0'>
                Filiais: {selectedContract.qtd_relations || '-'}
              </Typography>
              <Button
                disabled={selectedContract.id === undefined}
                variant='contained'
                color='primary'
                onClick={() => {
                  this.props.history.push(
                    `/app/contratos/detalhes-contrato/${
                      this.props.selectedContract.id
                    }`
                  );
                  this.props.handleRequestClose();
                }}
              >
                Ver Contrato
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={7}>
            <YaloSearchFilter
              value={contractSearch}
              onChange={this.handleContractSearch}
              className={`${classes.yaloSearchFilter} mb-3`}
              placeholder='Pesquisar'
            />

            <Grid container justify='space-between'>
              <Typography variant='subtitle1' id='modal-title' className='mb-1'>
                CONTRATOS
              </Typography>
              <Typography variant='subtitle1' id='modal-title' className='mb-1'>
                EMPRE. RELACIONADAS
              </Typography>
            </Grid>

            <Divider />
            <Grid className={classes.contractList}>
              {filterContracts &&
                filterContracts.map(contract => (
                  <ContractItem
                    contract={contract}
                    key={contract.id}
                    setSelectedContract={this.selectContract}
                  />
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

const ContractItem = ({ contract, setSelectedContract }) => (
  <Grid>
    <ListItem
      button
      className='p-1'
      onClick={() => setSelectedContract(contract)}
    >
      <Grid container direction='row' className='mr-2'>
        <Grid item xs={8} alignItems='flex-end' container>
          <Grid item xs={12}>
            <Typography variant='subtitle2' style={{ fontWeight: 700 }}>
              {contract.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>
              {utils.formatCNPJ(contract.contracting_company_cnpj)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction='column' item xs={4} alignItems='flex-end'>
          <Typography variant='subtitle1' id='modal-title'>
            Filiais
          </Typography>
          <Typography variant='subtitle1' id='modal-title'>
            <strong>{contract.qtd_relations}</strong>
          </Typography>
        </Grid>
      </Grid>
    </ListItem>

    <Divider variant='inset' className='ml-0' />
  </Grid>
);

const styles = theme => ({
  contractList: {
    height: '300px',
    overflow: 'auto'
  },
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 85,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    paddingLeft: 0,
    outline: 'none',
    top: `${9}%`,
    right: `${2}%`,
    borderRadius: 5
  },
  rectangle: {
    width: 5,
    height: '85px',
    backgroundColor: '#3A10E0',
    borderRadius: '0px 20px 20px 0px'
  },
  yaloSearchFilter: {
    width: '85%'
  }
});

const mapStateToProps = ({ contract }) => {
  const { selectedContract, contracts } = contract;
  return { selectedContract, contracts };
};

const mapDispatchToProps = dispatch => {
  return {
    setListContracts: () => dispatch(setListContractsAsync()),
    setSelectedContract: contract => dispatch(setContract(contract))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(PopUpUserInfo)));
