import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Button,
  Paper,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Divider
} from '@material-ui/core';
import { connect } from 'react-redux';

import SphereIcon from '@material-ui/icons/FiberManualRecordRounded';
import EventIcon from '@material-ui/icons/EventRounded';
import { ordersService } from '../../../../services/ordersService';
import { utils } from '../../utils';

class lastOrders extends Component {
  state = {
    latestOrders: [],
    loading: true,
    error: false
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.selectedContract.id !== nextProps.selectedContract.id)
      this.getAllOrders(nextProps.selectedContract);
  };

  getAllOrders = selectedContract => {
    ordersService.getAllOrders(selectedContract.id).then(response => {
      if (response.success) {
        const lastOrders = response.response.sort(function(a, b) {
          return a.created_at < b.created_at;
        });

        this.setState({
          latestOrders: lastOrders.slice(0, 3),
          loading: false,
          error: false
        });
      } else {
        this.setState({ loading: false, error: false });
      }
    });
  };

  componentDidMount() {
    const token = localStorage.getItem('token');
    if (!token) window.location('#signin');

    this.getAllOrders(this.props.selectedContract);
  }

  LatestOrdersList(order) {
    return (
      <Link
        to={`/app/pedidos-emitidos/consulting/${order.id}`}
        style={{ textDecoration: 'none' }}
      >
        <ListItem button>
          <ListItemText
            primary={<Typography>{order.company_name}</Typography>}
            secondary={
              <Typography variant="subtitle1" component="span">
                <EventIcon
                  fontSize="small"
                  style={{ verticalAlign: 'middle', marginRight: 6 }}
                />
                {`${utils.momentFromDate(order.created_at)}`}
              </Typography>
            }
          />
          <Fragment>
            <Typography variant="subtitle1" align="right">
              {order.order_status}
            </Typography>
            <SphereIcon
              style={{ marginLeft: 16, width: 15 }}
              className={utils.statusColor(order.order_status)}
            />
          </Fragment>
        </ListItem>
        <Divider />
      </Link>
    );
  }

  render() {
    const listItems = this.state.latestOrders.map(order => (
      <this.LatestOrdersList {...order} key={order.id} />
    ));

    return (
      <Paper>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={6} style={{ padding: 16 }}>
            <Typography variant="h1" color="secondary">
              Últimas Recargas
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ padding: 16 }}>
            <Grid container justify="flex-end" alignItems="center">
              <Link
                to="/app/pedidos-emitidos"
                style={{ textDecoration: 'none' }}
              >
                <Button variant="outlined" color="primary">
                  Ver Todos
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            xs={12}
            style={{ minHeight: 214 }}
          >
            <Divider />
            {/* Loading */}
            {this.state.loading && (
              <Grid container justify="center">
                <CircularProgress
                  mode="indeterminate"
                  color="primary"
                  style={{ margin: 50 }}
                />
              </Grid>
            )}

            {/* Error */}
            {!this.state.loading &&
            (this.state.error || this.state.latestOrders.length === 0) ? (
              <Typography
                variant="subtitle1"
                align="center"
                style={{ margin: 'auto' }}
              >{`${
                this.state.error
                  ? 'Ocorreu algum erro, tente novamente.'
                  : 'Nenhum pedido emitido no momento.'
              }`}</Typography>
            ) : (
              <List disablePadding>{listItems}</List>
            )}
          </Grid>
          <Grid item xs={12} style={{ padding: 16 }}>
            <Grid container justify="flex-end" alignItems="center">
              <Link to="/app/pedido-recarga" style={{ textDecoration: 'none' }}>
                <Button variant="contained" color="primary">
                  NOVA RECARGA
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = ({ contract }) => {
  const { selectedContract } = contract;
  return { selectedContract };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(lastOrders));
