import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Grid, Typography, Paper } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import IconCheckCircle from '@material-ui/icons/CheckCircleOutlineRounded';
import { yalo_images } from '../assets/images';
import CircularProgress from '@material-ui/core/CircularProgress';
import { hideMessage, userSignIn, createPwd } from '../actions/Auth';
import { authService } from '../services/authService';

class CreatePassword extends React.Component {
  constructor() {
    super();
    this.state = {
      token: '',
      password: '',
      confirmPassword: '',
      showPassword: false,
      loading: false,
      success: false,
      error: ''
    };
  }

  componentDidMount() {
    this.setState({ token: document.location.href.split('?token=')[1] });
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 150);
    }
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  changePasswordButton = () => {
    authService
      .changePwd(this.state.token, this.state.password)
      .then(response => {
        if (response.success) {
          this.setState({
            loading: false,
            password: '',
            confirmPassword: '',
            success: true
          });
        } else {
          if (response.status === 401) {
            return this.setState({
              loading: false,
              error: 'Token inválido.',
              confirmPassword: '',
              confirmPassword: ''
            });
          }
          response.json().then(response => {
            return this.setState({
              loading: false,
              error: response.message || 'Algum erro ocorreu, tente novamente',
              confirmPassword: '',
              confirmPassword: ''
            });
          });
        }
      });
  };

  render() {
    const { password, confirmPassword } = this.state;
    return (
      <div className='app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3'>
        <Grid container s>
          <Grid item xs={12} style={{ padding: 12 }}>
            <Grid container justify='center'>
              <Link className='logo-lg' to='/' title='Yalo'>
                <img
                  width='160'
                  height='160'
                  src={yalo_images.yalo_flor_grande}
                  alt='YALO'
                  title='YALO'
                />
              </Link>{' '}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper style={{ padding: 12 }}>
              <Grid container justify='center'>
                <Grid item xs={10}>
                  <Typography align='center' variant='h1' paragraph>
                    Nova Senha{' '}
                  </Typography>
                </Grid>
                {!this.state.success ? (
                  <Fragment>
                    <form onSubmit={this.sendEmail}>
                      <Grid xs={12}>
                        <Grid container justify='center'>
                          <Grid item xs={10} style={{ marginTop: 12 }}>
                            <TextField
                              required
                              name='password'
                              type={
                                this.state.showPassword ? 'text' : 'password'
                              }
                              value={this.state.password}
                              label='Senha'
                              onChange={event =>
                                this.setState({
                                  password: event.target.value,
                                  error: ''
                                })
                              }
                              InputLabelProps={{ shrink: true }}
                              variant='outlined'
                              fullWidth
                              margin='normal'
                            />
                          </Grid>

                          <Grid item xs={10} style={{ marginTop: 12 }}>
                            <TextField
                              required
                              name='password'
                              type={
                                this.state.showPassword ? 'text' : 'password'
                              }
                              value={this.state.confirmPassword}
                              label='Confirmar Senha'
                              onChange={event =>
                                this.setState({
                                  confirmPassword: event.target.value,
                                  error: ''
                                })
                              }
                              InputLabelProps={{ shrink: true }}
                              variant='outlined'
                              fullWidth
                              margin='normal'
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton
                                      aria-label='Toggle password visibility'
                                      onClick={this.handleClickShowPassword}
                                    >
                                      {this.state.showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={10}
                            style={
                              this.state.error
                                ? { marginTop: 0, marginBottom: 24 }
                                : { marginTop: 24, marginBottom: 12 }
                            }
                          >
                            {this.state.error && (
                              <Typography
                                variant='subtitle1'
                                align='center'
                                className='red-info'
                                style={{ marginTop: 0, marginBottom: 24 }}
                              >
                                {this.state.error}
                              </Typography>
                            )}
                            <Grid
                              container
                              justify='space-between'
                              alignItems='center'
                            >
                              <Grid item xs={4}>
                                <Link style={{ color: '#4db848' }} to='/'>
                                  <Typography
                                    variant='subtitle1'
                                    color='primary'
                                  >
                                    Voltar ao login
                                  </Typography>
                                </Link>
                              </Grid>
                              <Grid item xs={8}>
                                <Grid container justify='flex-end'>
                                  <Button
                                    variant='contained'
                                    size='large'
                                    color='primary'
                                    align='right'
                                    onClick={() => {
                                      this.setState({ loading: true });
                                      this.changePasswordButton();
                                    }}
                                    disabled={
                                      this.state.password !==
                                        this.state.confirmPassword ||
                                      this.state.password === ''
                                    }
                                  >
                                    Trocar Senha
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                  </Fragment>
                ) : (
                  <Grid container justify='center'>
                    <Grid item xs={10}>
                      <Grid
                        container
                        alignItems='center'
                        justify='center'
                        spacing={16}
                        style={{ padding: 16 }}
                      >
                        <IconCheckCircle
                          className='green-info'
                          style={{
                            marginLeft: 16,
                            marginRight: 16,
                            fontSize: 36
                          }}
                        />
                        <Typography variant='h4' align='left'>
                          Senha cadastrada com sucesso.
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={10} style={{ marginTop: 22 }}>
                      <Grid container justify='center'>
                        <Link to='/'>
                          <Button
                            variant='contained'
                            size='large'
                            color='primary'
                            align='right'
                          >
                            LOGIN
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {this.state.loading && (
          <div className='loader-view'>
            <CircularProgress
              mode='indeterminate'
              style={{ color: '#005DF9' }}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      createPwd,
      userSignIn,
      hideMessage
    }
  )(CreatePassword)
);
