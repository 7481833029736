export const utils = {
  getColor,
  getLabel,
};

function getColor(response, id, effective_date) {
  if (
    //drConsulta
    (_.isUndefined(response) &&
      id === 'c5f363b3-3e35-6d2f-7d73-a335a701a971' &&
      effective_date === '2021-10-01T00:00:00.000Z') ||
    (_.isUndefined(response) &&
      id === '0a532e33-11d2-2fc0-ac09-ffce4900a63a' &&
      effective_date === '2021-11-01T00:00:00.000Z') ||
    (_.isUndefined(response) &&
      id === '42991a84-7b6b-6661-b596-b412847cdc5c' &&
      effective_date === '2021-11-01T00:00:00.000Z') ||
    //EXPERIENCE AGENCIA DE PROMOCOES E EVENTOS LTDA
    (_.isUndefined(response) &&
      id === '2557d672-cc13-ac88-0bf0-01c91ad60164' &&
      effective_date === '2021-11-01T00:00:00.000Z') ||
    (_.isUndefined(response) &&
      id === '4fed6963-04b1-6f3c-407b-7aa05dbbe7ad' &&
      effective_date === '2021-10-01T00:00:00.000Z') ||
    (_.isUndefined(response) &&
      id === '33ea2e5b-16d5-5978-acfd-040f3999d645' &&
      effective_date === '2021-10-01T00:00:00.000Z') ||
    //DIDA
    (_.isUndefined(response) &&
      id === 'cf3ac136-7819-0082-3742-e70ebc94ad1e' &&
      effective_date === '2022-10-01T00:00:00.000Z') ||
    (_.isUndefined(response) &&
      id === '36cc680f-e910-4cde-32a8-003465ab57f1' &&
      effective_date === '2022-08-01T00:00:00.000Z') ||
    (_.isUndefined(response) &&
      id === 'e14f6786-7aaa-9b1c-7e08-eaf8867154bb' &&
      effective_date === '2022-07-01T00:00:00.000Z') ||
    //OLIVER PRESS COMUNICACAO INTEGRADA LTDA
    (_.isUndefined(response) &&
      id === '8ae886b0-d015-4e7a-a003-4b3e17c9c504' &&
      effective_date === '2022-11-01T00:00:00.000Z')   
    )
    return 'green';
  if (_.isUndefined(response)) return '#007bff';
  if (response.success) return 'green';
  else return 'red';
}

function getLabel(response, id, effective_date) {
  if (
    //drConsulta
    (_.isUndefined(response) &&
      id === 'c5f363b3-3e35-6d2f-7d73-a335a701a971' &&
      effective_date === '2021-10-01T00:00:00.000Z') ||
    (_.isUndefined(response) &&
      id === '0a532e33-11d2-2fc0-ac09-ffce4900a63a' &&
      effective_date === '2021-11-01T00:00:00.000Z') ||
    (_.isUndefined(response) &&
      id === '42991a84-7b6b-6661-b596-b412847cdc5c' &&
      effective_date === '2021-11-01T00:00:00.000Z') ||
    //EXPERIENCE AGENCIA DE PROMOCOES E EVENTOS LTDA
    (_.isUndefined(response) &&
      id === '2557d672-cc13-ac88-0bf0-01c91ad60164' &&
      effective_date === '2021-11-01T00:00:00.000Z') ||
    (_.isUndefined(response) &&
      id === '4fed6963-04b1-6f3c-407b-7aa05dbbe7ad' &&
      effective_date === '2021-10-01T00:00:00.000Z') ||
    (_.isUndefined(response) &&
      id === '33ea2e5b-16d5-5978-acfd-040f3999d645' &&
      effective_date === '2021-10-01T00:00:00.000Z') ||
     //DIDA
     (_.isUndefined(response) &&
      id === 'cf3ac136-7819-0082-3742-e70ebc94ad1e' &&
      effective_date === '2022-10-01T00:00:00.000Z') ||
    (_.isUndefined(response) &&
      id === '36cc680f-e910-4cde-32a8-003465ab57f1' &&
      effective_date === '2022-08-01T00:00:00.000Z') ||
    (_.isUndefined(response) &&
      id === 'e14f6786-7aaa-9b1c-7e08-eaf8867154bb' &&
      effective_date === '2022-07-01T00:00:00.000Z') ||
    //OLIVER PRESS COMUNICACAO INTEGRADA LTDA
    (_.isUndefined(response) &&
      id === '8ae886b0-d015-4e7a-a003-4b3e17c9c504' &&
      effective_date === '2022-11-01T00:00:00.000Z')   
    )
    return 'Processado';

  if (_.isUndefined(response)) return 'Agendado';
  if (response.success) return 'Processado';
  else return 'Erro';
}
