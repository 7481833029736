import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { modalEmitAlert, modalAlertShowLoading } from '../../../../../actions';
import { SUCCESS, ERROR } from '../../../../../constants/ActionTypes';
import ContainerHeader from '../../../../../components/ContainerHeader';
import IntlMessages from '../../../../../util/IntlMessages';
import { Divider, CircularProgress, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { withRouter, Link } from 'react-router-dom';
import { utils } from '../../../utils';
import { ROLES } from '../../../../../constants';
import RenderComponentCondition from '../../../../../componentsPortal/RenderComponentCondition';
import Button from './../../../../../components/ui/button/Button';

import { pjService } from '../../../../../services/pjService';
import { ChevronLeftRounded } from '@material-ui/icons';

import { formStyles as styles } from '../cadastroPJ/styles';
import RegistrationForm from '../cadastroPJ/RegistrationForm';
import ViewRegistrationForm from '../cadastroPJ/ViewRegistrationForm';
import Footer from '../components/Footer';
import ButtonUi from '../../../../../components/ui/button/Button'

const steps = [
  'Informações da Empresa',
  'Endereço da Empresa',
  'Informações do Sócio Majoritário',
  'Registros Governamentais',
  'Responsável Financeiro',
  'Responsáveis RH',
  'Usuários',
  'Empresa Principal',
  'Confirmação',
];
class Consulting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {},
      address: {},
      partner: {},
      regs: {},
      financial_resp: {},
      hr_resps: [],
      users: [],
      emp_princ: {},
      hasStakeholder: false,

      editing: false,
      loading: true,
      fetchSuccess: false,
      //Stepper related state
      activeStep: 0,
      completed: [],
      dialog: {
        open: false,
        message: [],
        success: false,
      },
      remount: false,
    };
  }

  componentDidMount() {
    pjService
      .getPJ(this.props.match.params.cnpj)
      .then((response) => this.handleResponse(response));
  }

  componentDidUpdate() {
    if (this.state.remount) {
      pjService
        .getPJ(this.props.match.params.cnpj)
        .then((response) => this.handleResponse(response));
    }
  }

  handleResponse = (response) => {
    if (response.success) {
      const {
        company,
        responsibleCompanies,
        stakeholders,
        users: response_users,
      } = response.response;
      const info = {
        cnpj: company.cnpj || this.props.match.params.cnpj,
        nome_fantasia: company.printed_name || '',
        printed_name_card: company.printed_name_card || '',
        razao_social: company.name || '',
        tipo_propriedade: '',
        ano_fundacao: company.year_was_founded || '',
        numero_funcionarios: company.number_of_employees || '',
        cnae: company.cnae || '',
        ramo_atividade: company.industry || '',
        id: company.id,
        telefone: company.phones[0]
          ? utils.handleCellphone(
            `${company.phones[0].area_code}${company.phones[0].number}`.replace(
              '0',
              '',
            ),
          )
          : '',
      };

      const response_address = company.address;
      const address = response_address
        ? {
          cep: response_address.zip_code || '',
          rua: response_address.street_address || '',
          estado: response_address.state || '',
          numero: response_address.address_number || '',
          complemento: response_address.additional_address || '',
          bairro: response_address.neighborhood || '',
          cidade: response_address.city || '',
          pais: '',
        }
        : {
          cep: '',
          rua: '',
          estado: '',
          numero: '',
          complemento: '',
          bairro: '',
          cidade: '',
          pais: '',
        };

      const partner = stakeholders[0]
        ? {
          cpf: utils.handleCPF(stakeholders[0].cpf) || '',
          nome: stakeholders[0].name || '',
          data_nascimento:
            stakeholders[0] &&
              stakeholders[0].birth_date &&
              stakeholders[0].birth_date !== null
              ? stakeholders[0].birth_date.slice(0, 10)
              : '',
          sexo: stakeholders[0].gender || '',
          telefone:
            stakeholders[0] &&
              stakeholders[0].phones &&
              stakeholders[0].phones[0]
              ? utils.handleCellphone(
                `${stakeholders[0].phones[0].area_code}${stakeholders[0].phones[0].number}`.replace(
                  '0',
                  '',
                ),
              )
              : '',
          socio_desde:
            stakeholders[0] &&
              stakeholders[0].date_since_became_partner &&
              stakeholders[0].date_since_became_partner !== null
              ? stakeholders[0].date_since_became_partner.slice(0, 10)
              : '',
          tbm_contato: stakeholders[0].tbm_contato || '',
          qtd_socios: stakeholders[0]
            ? stakeholders[0].number_of_partners
            : '',
          email: stakeholders[0].email || '',
        }
        : {
          cpf: '',
          nome: '',
          data_nascimento: '',
          sexo: '',
          telefone: '',
          socio_desde: '',
          tbm_contato: '',
          qtd_socios: '',
          email: '',
        };

      const regs = {
        isento_est: company.exempt_from_state_registration
          ? 'sim'
          : company.exempt_from_state_registration === null
            ? ''
            : 'nao',
        insc_est: company.exempt_from_state_registration
          ? 'Isento'
          : company.state_registration || '',
        isento_mun: company.exempt_from_municipal_registration
          ? 'sim'
          : company.exempt_from_municipal_registration === null
            ? ''
            : 'nao',
        insc_mun: company.exempt_from_municipal_registration
          ? 'Isento'
          : company.municipal_registration || '',
        tax_regime: company.tax_regime,
      };

      let financial_resp = responsibleCompanies.filter(
        (responsible) => responsible.responsible_type === 'Financeiro',
      )[0];
      if (financial_resp) {
        financial_resp = {
          nome: financial_resp.name,
          cpf: utils.handleCPF(financial_resp.cpf),
          sexo: financial_resp.gender,
          data_nascimento:
            financial_resp &&
              financial_resp.birth_date &&
              financial_resp.birth_date !== null
              ? financial_resp.birth_date.slice(0, 10)
              : '',
          telefone: financial_resp.phones[0]
            ? utils.handleCellphone(
              `${financial_resp.phones[0].area_code}${financial_resp.phones[0].number}`.replace(
                '0',
                '',
              ),
            )
            : '',
          email: financial_resp.email,
        };
      } else {
        financial_resp = {
          nome: '',
          cpf: '',
          sexo: '',
          data_nascimento: '',
          telefone: '',
          email: '',
        };
      }

      const hr_resps = responsibleCompanies
        .filter((resp) => resp.responsible_type === 'RH')
        .map((resp) => {
          return {
            resp: {
              nome: resp.name,
              cpf: utils.handleCPF(resp.cpf),
              sexo: resp.gender,
              data_nascimento:
                resp && resp.birth_date && resp.birth_date !== null
                  ? resp.birth_date.slice(0, 10)
                  : '',
              telefone: resp.phones[0]
                ? utils.handleCellphone(
                  `${resp.phones[0].area_code}${resp.phones[0].number}`.replace(
                    '0',
                    '',
                  ),
                )
                : '',
              email: resp.email,
            },
            errors: {
              cpf: '',
              data_nascimento: '',
              email: '',
              nome: '',
              sexo: '',
              telefone: '',
            },
            touched: {
              cpf: true,
              data_nascimento: true,
              email: true,
              nome: true,
              sexo: true,
              telefone: true,
            },
          };
        });

      const users = response_users.map((user) => {
        return {
          user: {
            nome: user.name,
            cpf: utils.handleCPF(user.cpf),
            sexo: user.gender,
            data_nascimento:
              user && user.birth_date && user.birth_date !== null
                ? user.birth_date.slice(0, 10)
                : '',
            telefone: user.phones[0]
              ? utils.handleCellphone(
                `${user.phones[0].area_code}${user.phones[0].number}`.replace(
                  '0',
                  '',
                ),
              )
              : '',
            email: user.email,
          },
          errors: {
            cpf: '',
            data_nascimento: '',
            email: '',
            nome: '',
            sexo: '',
            telefone: '',
          },
          touched: {
            cpf: true,
            data_nascimento: true,
            email: true,
            nome: true,
            sexo: true,
            telefone: true,
          },
        };
      });
      pjService
        .getPJbyID(company.principal_company_id)
        .then((response) => {
          let emp_princ = {
            empresa: '',
            cnpj: '',
          };

          if (response.success) {
            emp_princ = {
              cnpj: response.response.cnpj,
              empresa: response.response.name,
            };
          }
          return emp_princ;
        })
        .then((emp_princ) =>
          this.setState({
            info,
            address,
            partner,
            regs,
            financial_resp,
            hr_resps,
            users,
            emp_princ,
            hasStakeholder: partner.nome ? true : false,

            loading: false,
            fetchSuccess: true,
            remount: false,
          }),
        );
    }
  };

  getInputs = (info, data) => {
    this.setState({ ...this.state, [info]: data });
  };

  setActiveComponentStep(component) {
    this.setState({ hasActiveComponentStepError: true });
    this.setState({ activeComponentStep: component });
  }

  onCurrentComponentStepChange(hasError) {
    this.setState({ hasActiveComponentStepError: hasError });
  }

  defaultState = () => {
    return this.setState({
      activeStep: 0,
      completed: [],
      editing: false,
      loading: false,
    });
  };

  //Stepper related functions
  handleNext = () => {
    if (this.state.activeStep < 8) {
      this.state.activeComponentStep.updateCurrentState();
      this.setState({
        activeStep: this.state.activeStep + 1,
        completed: [...this.state.completed, this.state.activeStep],
      });
    } else {
      this.props.modalAlertShowLoading();
      this.setState({ loading: true });

      pjService
        .updatePJ(this.handleSetCompanyObj())
        .then((response) => {
          if (response.json) {
            return response.json();
          }

          return response;
        })
        .then((response) => {

          if (response.success) {
            this.setState({
              dialog: {
                open: true,
                message: ['Empresa editada com sucesso'],
                success: response.success,
                loading: false,
              },
            });
            this.props.modalEmitAlert('Empresa editada com sucesso', SUCCESS);
            this.defaultState();
          } else {
            this.setState({
              loading: false,
              dialog: {
                open: true,
                message: response.message,
                success: response.success,
                loading: false,
              },
            });
            const messages = Array.isArray(response.message)
              ? response.message.join('<br /> ')
              : response.message;
            this.props.modalEmitAlert(messages, ERROR);
          }
        });
    }
  };

  handleBack = () => {
    if (this.state.activeStep > 0) {
      const completed = this.state.completed;
      completed.pop();
      this.setState({
        activeStep: this.state.activeStep - 1,
        completed,
      });
    }
  };

  handleSetCompanyObj() {
    const { info, regs, address, emp_princ, partner, financial_resp } =
      this.state;

    const brokerId = localStorage.getItem('brokerId');

    const company = {
      id: info.id,
      name: info.razao_social,
      cnpj: utils.removeNonNumericCharacters(info.cnpj),
      printed_name_card: info.printed_name_card,
      printed_name: info.nome_fantasia === '' ? null : info.nome_fantasia,
      principal_company_cnpj: emp_princ.cnpj
        ? utils.removeNonNumericCharacters(emp_princ.cnpj)
        : null,
      cnae: info.cnae === '' ? null : info.cnae,
      industry: info.ramo_atividade === '' ? null : info.ramo_atividade,
      year_was_founded:
        info.ano_fundacao === '' ? null : parseInt(info.ano_fundacao),
      number_of_employees:
        info.numero_funcionarios === '' ||
          info.numero_funcionarios === 0 ||
          info.numero_funcionarios === '0'
          ? null
          : parseInt(info.numero_funcionarios),
      exempt_from_state_registration:
        regs.isento_est === ''
          ? null
          : regs.isento_est === 'sim'
            ? true
            : false,
      state_registration: regs.insc_est === null ? 'Isento' : regs.insc_est,
      exempt_from_municipal_registration:
        regs.isento_mun === ''
          ? null
          : regs.isento_mun === 'sim'
            ? true
            : false,
      tax_regime: regs.tax_regime === '' ? null : regs.tax_regime,
      municipal_registration: regs.insc_mun === null ? 'Isento' : regs.insc_mun,
      address_type_id: '769fec6b-3363-4e56-bac3-56d4391616a3',
      zip_code: utils.removeNonNumericCharacters(address.cep),
      street_address: address.rua === '' ? null : address.rua,
      additional_address:
        address.complemento === '' ? null : address.complemento,
      address_number: address.numero,
      neighborhood: address.bairro === '' ? null : address.bairro,
      city: address.cidade === '' ? null : address.cidade,
      state: address.estado === '' ? null : address.estado,
      is_mailing_address: null,
      phones:
        info.telefone === ''
          ? null
          : [
            {
              phone: `0${utils.removeNonNumericCharacters(info.telefone)}`,
              phone_type_name: 'Celular',
            },
          ],
    };

    const phones = {
      phones:
        info.telefone === ''
          ? null
          : [
            {
              phone: `0${utils.removeNonNumericCharacters(info.telefone)}`,
              phone_type_name: 'Celular',
            },
          ],
    };

    const users = this.state.users.map((user) => ({
      username: user.user.email,
      is_admin: true,
      name: user.user.nome,
      gender: user.user.sexo,
      cpf: utils.removeNonNumericCharacters(user.user.cpf),
      email: user.user.email,
      user_type_id: '3f88b9b9-2687-4712-baf3-d5e8326602d3',
      birth_date:
        user.user.data_nascimento === '' ? null : user.user.data_nascimento,
      phones:
        user.user.telefone === ''
          ? null
          : [
            {
              phone: `0${utils.removeNonNumericCharacters(
                user.user.telefone,
              )}`,
              phone_type_name: 'Celular',
            },
          ],
    }));

    const stakeholders =
      partner.nome === ''
        ? []
        : [
          {
            name: partner.nome,
            gender: partner.sexo,
            cpf: utils.removeNonNumericCharacters(partner.cpf),
            email: partner.email,
            date_since_became_partner:
              partner.socio_desde === '' ? null : partner.socio_desde,
            number_of_partners:
              partner.qtd_socios === '' ? null : parseInt(partner.qtd_socios),
            partner_also_is_contact:
              partner.tbm_contato === ''
                ? null
                : partner.tbm_contato === 'sim'
                  ? true
                  : false,
            birth_date:
              partner.data_nascimento === '' ? null : partner.data_nascimento,
            phones:
              partner.telefone === ''
                ? null
                : [
                  {
                    phone: `0${utils.removeNonNumericCharacters(
                      partner.telefone,
                    )}`,
                    phone_type_name: 'Celular',
                  },
                ],
          },
        ];

    const responsible_companies = [
      {
        name: financial_resp.nome,
        gender: financial_resp.sexo,
        cpf: utils.removeNonNumericCharacters(financial_resp.cpf),
        email: financial_resp.email,
        responsible_type: 'Financeiro',
        birth_date:
          financial_resp.data_nascimento === ''
            ? null
            : financial_resp.data_nascimento,
        phones:
          financial_resp.telefone === ''
            ? null
            : [
              {
                phone: `0${utils.removeNonNumericCharacters(
                  financial_resp.telefone,
                )}`,
                phone_type_name: 'Celular',
              },
            ],
      },

      ...this.state.hr_resps.map((resp) => ({
        name: resp.resp.nome,
        gender: resp.resp.sexo,
        cpf: utils.removeNonNumericCharacters(resp.resp.cpf),
        email: resp.resp.email,
        responsible_type: 'RH',
        birth_date:
          resp.resp.data_nascimento === '' ? null : resp.resp.data_nascimento,
        phones:
          resp.resp.telefone === ''
            ? null
            : [
              {
                phone: `0${utils.removeNonNumericCharacters(
                  resp.resp.telefone,
                )}`,
                phone_type_name: 'Celular',
              },
            ],
      })),
    ];
    const pj = {
      company,
      users,
      stakeholders,
      responsible_companies,
      phones,
    };

    return pj;
  }

  onChangeStakeholder = () => {
    this.setState({ hasStakeholer: !this.state.hasStakeholer });
  };

  onChangeReplicate = () => {
    this.setState({ replicate: !this.state.replicate });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <ContainerHeader
          title={
            this.state.editing ? (
              <IntlMessages id='pages.pessoaJuridica.editarPJ' />
            ) : (
              <IntlMessages id='pages.pessoaJuridica.consultaPJ' />
            )
          }
        />
        <Grid
          container
          direction='column'
          justify='flex-start'
          alignItems='stretch'
          spacing={0}
          className={classes.containerWrapper}
        >
          <Grid item xs={12} md={12} className={classes.rowTitle}>
            <Typography className={classes.title} variant='h6' noWrap>
              Dados Cadastrais
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            {!this.state.editing ? (
              <div style={{ display: 'flex' }}>
                {this.props.location.redirect ? (
                  <Link
                    to={{
                      pathname: `/app/contratos/detalhes-contrato/${this.props.location.redirect}`,
                      redirect: true,
                    }}
                  >
                    <ButtonUi className='mr-3'
                      variant='link'
                      startIcon={<ChevronLeftRounded />}>
                      Voltar
                    </ButtonUi>
                  </Link>
                ) : (
                  <ButtonUi
                    style={{ marginRight: '8px' }}
                    className='mr-3'
                    variant='link'
                    startIcon={<ChevronLeftRounded />}
                    onClick={() =>
                      this.props.history.push(
                        '/app/pessoa-juridica/consulta-pj',
                      )
                    }
                  >
                    Voltar
                  </ButtonUi>
                )}
                <RenderComponentCondition
                  current={localStorage.getItem('usertype')}
                  listCondition={[ROLES.BACKOFFICE, ROLES.SALES]}
                >
                  <ButtonUi
                    variant='primary'
                    onClick={() => this.setState({ editing: true })}
                  >
                    Editar
                  </ButtonUi>
                </RenderComponentCondition>
              </div>
            ) : this.props.location.redirect ? (
              <Link
                to={{
                  pathname: `/app/contratos/detalhes-contrato/${this.props.location.redirect}`,
                  redirect: true,
                }}
              >
                <ButtonUi
                  className='mr-3'
                  variant='link'
                  startIcon={<ChevronLeftRounded />}

                >
                  Voltar
                </ButtonUi>
              </Link>
            ) : (
              <ButtonUi
                variant='link'
                startIcon={<ChevronLeftRounded />}
                onClick={() =>
                  this.setState({
                    loading: true,
                    remount: true,
                    editing: false,
                  })
                }
              >
                Voltar
              </ButtonUi>
            )}
          </Grid>
          <Divider />
          {!this.state.loading &&
            this.state.fetchSuccess &&
            this.state.editing && (
              <RegistrationForm
                steps={steps}
                completed={this.state.completed}
                getInputs={this.getInputs.bind(this)}
                setActiveComponentStep={this.setActiveComponentStep.bind(this)}
                onCurrentComponentStepChange={this.onCurrentComponentStepChange.bind(
                  this,
                )}
                activeStep={this.state.activeStep}
                onChangeStakeholder={this.onChangeStakeholder.bind(this)}
                onChangeReplicate={this.onChangeReplicate.bind(this)}
                form={this.state}
              />
            )}
          <ViewRegistrationForm
            steps={steps}
            confirmation={
              this.state.activeStep === 8 ||
              (!this.state.loading &&
                this.state.fetchSuccess &&
                !this.state.editing)
            }
            form={this.state}
          />
          {!this.state.loading &&
            this.state.fetchSuccess &&
            this.state.editing && (
              <Footer
                activeStep={this.state.activeStep}
                handleBack={this.handleBack}
                handleNext={this.handleNext}
                steps={steps}
                hasErrors={this.state.hasActiveComponentStepError}
              />
            )}
        </Grid>

        <div className='row'>
          {this.state.loading && (
            <div className='loader-view'>
              <CircularProgress
                mode='indeterminate'
                style={{ color: '#071060' }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(
  connect(null, { modalAlertShowLoading, modalEmitAlert })(
    withRouter(Consulting),
  ),
);
