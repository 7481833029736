import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Grid,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { modalHiddenAlert } from './../../actions/ModalAlertActions';
import { SUCCESS, WARNING, ERROR } from './../../constants/ActionTypes';

class index extends Component {
  getAlertIcon = typeAlert => {
    switch (typeAlert) {
      case SUCCESS:
        return (
          <DoneIcon className="green-info mr-3" style={{ fontSize: '35px' }} />
        );
      case ERROR:
        return (
          <ErrorOutlineIcon
            className="red-info mr-3"
            style={{ fontSize: '35px' }}
          />
        );
      case WARNING:
        return (
          <WarningIcon
            className="orange-info mr-3"
            style={{ fontSize: '35px' }}
          />
        );
      default:
        return;
    }
  };

  getAlertMsg = typeAlert => {
    switch (typeAlert) {
      case SUCCESS:
        return 'Sucesso';
      case ERROR:
        return 'Erro';
      case WARNING:
        return 'Alerta';
      default:
        return;
    }
  };

  render() {
    const { open, msg, loading, typeAlert } = this.props;
    const typeAlertIcon = this.getAlertIcon(typeAlert);

    return (
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText>
            <Grid
              container
              direction="column"
              justify="space-between"
              alignItems="center"
              style={{ width: '500px', marginTop: '25px' }}
            >
              {loading ? (
                <Grid item xs={12}>
                  <div className="loader-view">
                    <CircularProgress
                      mode="indeterminate"
                      className="m-5"
                      style={{ color: '#3A10E0' }}
                    />
                  </div>
                </Grid>
              ) : (
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid container direction="row" justify="center">
                    <Grid
                      container
                      justify="flex-end"
                      alignItems="flex-start"
                      item
                      xs={2}
                    >
                      {typeAlertIcon}
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="h2">
                        {this.getAlertMsg(typeAlert)}
                      </Typography>
                      <Typography variant="h4">{msg || ''}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </DialogContentText>
          <br />
        </DialogContent>
        <Grid container justify="center" alignItems="center">
          <DialogActions>
            {!loading ? (
              <Button
                variant="contained"
                onClick={() => this.props.modalHiddenAlert()}
                color="primary"
              >
                OK
              </Button>
            ) : (
              <div />
            )}
          </DialogActions>
        </Grid>
      </Dialog>
    );
  }
}

index.propTypes = {
  open: PropTypes.bool,
  msg: PropTypes.string,
  loading: PropTypes.bool,
  typeAlert: PropTypes.oneOf([SUCCESS, ERROR, WARNING])
};

const mapState = ({ modalAlert }) => {
  const { open, msg, loading, typeAlert } = modalAlert;
  return { open, msg, loading, typeAlert };
};

export default connect(
  mapState,
  { modalHiddenAlert }
)(index);
