import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Paper,
  Tab,
  Tabs,
  TableSortLabel
} from '@material-ui/core';
import Pagination from 'material-ui-flat-pagination';
import IconSwapVert from '@material-ui/icons/SwapVert';

import ContainerHeader from '../../../../../components/ContainerHeader';
import { pjService } from '../../../../../services/pjService';
import { utils } from '../../../utils';
import YaloSearchFilter from '../../../../../components/YaloSearchFilter';
import { PJHeader } from '../components/PJHeader';

import moment from 'moment';
moment.locale('pt_BR');

class Employees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: {},
      loading: false,
      employeesOffset: 0,
      status_filter: null,
      status_tabs: 0,
      loadEmployees: false,
      search: '',
      active: 0,
      cancel: 0,
      cnpj: this.props.match.params.cnpj,
      name: '',
      status: '',
      id: '',
      columnToSort: '',
      columnDirection: 'asc'
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    pjService
      .getPJ(this.props.match.params.cnpj)
      .then(response => this.handleResponseCNPJ(response));

    pjService
      .getEmployees(this.props.match.params.cnpj)
      .then(response => this.handleResponseEmployees(response));
  }

  formatDate = date => {
    if (date === 'Invalid date') {
      return 'N/A';
    } else {
      return date;
    }
  };

  handleResponseCNPJ = response => {
    if (response.success)
      this.setState({
        name: response.response.company.name,
        status: response.response.company.status,
        id: response.response.company.id
      });
  };

  handleResponseEmployees = response => {
    if (response.success)
      this.setState({
        employees: response.response,
        loadEmployees: true,
        loading: false
      });

    let activeCount = 0,
      cancelCount = 0;
    response.response.map(el => {
      !el.has_dismissed ? activeCount++ : cancelCount++;

      this.setState({ active: activeCount });
      this.setState({ cancel: cancelCount });
    });
    return;
  };

  handleTabsFilter = (event, status_tabs) => {
    switch (status_tabs) {
      case 2:
        this.setState({
          status_filter: 'Cancelado',
          status_tabs,
          employeesOffset: 0
        });
        break;
      case 1:
        this.setState({
          status_filter: 'Ativo',
          status_tabs,
          employeesOffset: 0
        });
        break;
      case 0:
      default:
        this.setState({ status_filter: null, status_tabs, employeesOffset: 0 });
        break;
    }
  };

  matchAccount = employee => {
    if (!this.state.status_filter) return true;
    if (this.state.status_filter === 'Ativo' && !employee.has_dismissed)
      return true;
    if (this.state.status_filter === 'Cancelado' && employee.has_dismissed)
      return true;
    return false;
  };

  handleSort = column => {
    const { columnToSort, columnDirection } = this.state;
    let direction = columnDirection;
    if (column === columnToSort) {
      direction = columnDirection === 'asc' ? 'desc' : 'asc';
    }
    this.setState({
      columnToSort: column,
      columnDirection: direction
    });
  };

  render() {
    const {
      employees,
      loadEmployees,
      columnToSort,
      columnDirection
    } = this.state;

    const filteredEmployees =
      this.state.employees.length > 0
        ? this.state.employees.filter(employee => {
            let match = false;
            if (employee.registration)
              match =
                match ||
                employee.registration.indexOf(this.state.search) !== -1;

            return (
              (match ||
                employee.person_name
                  .toLowerCase()
                  .indexOf(this.state.search.toLowerCase()) !== -1) &&
              this.matchAccount(employee)
            );
          })
        : [];
    return (
      <div className="app-wrapper">
        <Grid container justify="space-between" spacing={0}>
          <Grid item>
            <ContainerHeader title="Consulta de Funcionários" />
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={() =>
                this.props.history.push('/app/pessoa-juridica/consulta-pj')
              }
            >
              Voltar
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={16} style={{ marginBottom: 24 }}>
          <Grid item xs={12}>
            <PJHeader
              status={this.state.status}
              cnpj={this.state.cnpj}
              name={this.state.name}
              id={this.state.id}
            />
          </Grid>
        </Grid>
        <Grid container spacing={16} style={{ marginBottom: 24 }}>
          <Grid item xs={3}>
            <YaloSearchFilter
              onChange={e =>
                this.setState({ search: e.target.value, employeesOffset: 0 })
              }
              value={this.state.search}
              placeholder="Busca por nome ou matrícula"
            />
          </Grid>
          <Grid item xs={5}>
            <Tabs
              value={this.state.status_tabs}
              onChange={this.handleTabsFilter}
              indicatorColor="primary"
              textColor="primary"
              fullWidth
            >
              <Tab label="Todos" style={{ minWidth: 50 }} />
              <Tab
                label={`Ativos (${this.state.active})`}
                style={{ minWidth: 50 }}
              />
              <Tab
                label={`Desligados (${this.state.cancel})`}
                style={{ minWidth: 50 }}
              />
            </Tabs>
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Paper>
              {this.state.loading && (
                <Grid container justify="center" style={{ padding: 100 }}>
                  <CircularProgress
                    mode="indeterminate"
                    style={{ margin: 50, color: '#005DF9'}}
                  />
                </Grid>
              )}
              {loadEmployees && (
                <Grid container>
                  <Grid item xs={12} style={{margin:'0px 20px'}}>
                    <Table padding='none'>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            onClick={() => this.handleSort('person_name')}
                          >
                            <TableSortLabel>
                              Nome
                              <IconSwapVert style={{ fontSize: 14 }} />
                            </TableSortLabel>
                          </TableCell>
                          <TableCell onClick={() => this.handleSort('cpf')}>
                            <TableSortLabel>
                              CPF
                              <IconSwapVert style={{ fontSize: 14 }} />
                            </TableSortLabel>
                          </TableCell>

                          <TableCell>Conta</TableCell>
                          <TableCell
                            onClick={() => this.handleSort('registration')}
                          >
                            <TableSortLabel>
                              Matrícula
                              <IconSwapVert style={{ fontSize: 14 }} />
                            </TableSortLabel>
                          </TableCell>
                          <TableCell
                            onClick={() => this.handleSort('admission_date')}
                          >
                            <TableSortLabel>
                              Data admissão
                              <IconSwapVert style={{ fontSize: 14 }} />
                            </TableSortLabel>
                          </TableCell>
                          <TableCell
                            onClick={() => this.handleSort('dismissed_date')}
                          >
                            <TableSortLabel>
                              Data desligamento
                              <IconSwapVert style={{ fontSize: 14 }} />
                            </TableSortLabel>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {_.chain(filteredEmployees)
                          .slice(
                            this.state.employeesOffset,
                            this.state.employeesOffset + 20
                          )
                          .orderBy([columnToSort], columnDirection)
                          .map(row => {
                            return (
                              <TableRow key={row.cpf}>
                                <TableCell component="th" scope="row">
                                  {row.person_name}
                                </TableCell>
                                <TableCell>
                                  {utils.handleCPF(row.cpf)}
                                </TableCell>
                                <TableCell>{row.status}</TableCell>
                                <TableCell>
                                  {row.registration ? row.registration : 'N/A'}
                                </TableCell>
                                <TableCell>
                                  {`${this.formatDate(
                                    moment(row.admission_date).format(
                                      'DD/MM/YYYY'
                                    )
                                  )}`}
                                </TableCell>
                                <TableCell>
                                  {`${this.formatDate(
                                    moment(row.dismissed_date, 'YYYY/MM/DD').format(
                                      'DD/MM/YYYY'
                                    )
                                  )}`}
                                </TableCell>
                              </TableRow>
                            );
                          })
                          .value()}
                      </TableBody>
                    </Table>
                    <Grid
                      item
                      xs={12}
                      style={{ marginBottom: 16, marginTop: 16 }}
                    >
                      <Pagination
                        size="small"
                        limit={20}
                        offset={this.state.employeesOffset}
                        total={filteredEmployees.length}
                        onClick={(e, employeesOffset) => {
                          this.setState({ employeesOffset });
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(Employees);
