import React, { Component, Fragment } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { utils } from '../../app/routes/utils';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  header: {
    marginBottom: '12px',
  },
  caption: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    color: 'var(--color-brand-primary-800)',
    fontWeight: 700,
    lineHeight: '24.5px',
  },
  table: {
    width: '100%',
  },
  rootCell: {
    border: 'none',
  },
  cell: {
    border: '1px solid var(--color-neutral-700, #707070)',
  },
  cellCaption: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    color: 'var(--color-neutral-700)',
    fontWeight: 700,
    lineHeight: '18px',
    padding: '8px 0px 8px 16px',
  },
  innerCell: {
    borderBottom: '0.5px solid var(--color-neutral-700, #707070)',
    '&:last-child': {
      borderRight: '1px solid var(--color-neutral-700, #707070)',
    },
  },
  cellValue: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    color: 'var(--color-neutral-700)',
    fontWeight: 400,
    lineHeight: '15.4px',
    padding: '8px 0px 8px 16px',
  },
  cellFooter: {
    backgroundColor: 'var(--color-neutral-200)',
  },
});
class TableEmployeeSubscription extends Component {
  valorBruto = (taxes) => {
    let totalTaxes = _.reduce(
      taxes,
      (acc, tax) => _.toNumber(tax.taxed_value) + acc,
      0,
    );

    return (
      totalTaxes + _.toNumber(this.props.rows[this.props.rows.length - 1].col4)
    );
  };
  formatCurrency = (value) => {
    if (!value) return '--';

    if (isNaN(value)) return value;

    return `R$ ${utils.formatCurrency(value)}`;
  };

  render() {
    const { rows, classes, table_title } = this.props;
    let lastRow = rows.length - 1;

    return (
      <Grid container direction='column' className={classes.root}>
        <Grid item xs={12}  container direction='row' className={classes.header}>
          <Typography variant='h6' className={classes.caption}>
            {table_title}
          </Typography>
        </Grid>
        <Grid item xs={12} container direction='row'>
          <Table border={0} className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell classes={{ root: classes.rootCell }}></TableCell>
                <TableCell
                  align='left'
                  padding='none'
                  variant='head'
                  classes={{ root: classes.cell }}
                >
                  <Typography
                    variant='subtitle1'
                    className={classes.cellCaption}
                  >
                    Valor Unitário
                  </Typography>
                </TableCell>
                <TableCell
                  align='left'
                  padding='none'
                  variant='head'
                  classes={{ root: classes.cell }}
                >
                  <Typography
                    variant='subtitle1'
                    className={classes.cellCaption}
                  >
                    Quantidade
                  </Typography>
                </TableCell>
                <TableCell
                  align='left'
                  padding='none'
                  variant='head'
                  classes={{ root: classes.cell }}
                >
                  <Typography
                    variant='subtitle1'
                    className={classes.cellCaption}
                  >
                    Valor
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow className={classes.row} key={index}>
                  <TableCell
                    component='th'
                    scope='row'
                    padding='none'
                    variant='head'
                    classes={{ root: classes.cell }}
                  >
                    <Typography
                      variant='subtitle1'
                      className={classes.cellCaption}
                    >
                      {row.col1}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align='left'
                    padding='none'
                    variant='body'
                    classes={{ root: classes.innerCell }}
                    className={classNames({
                      [classes.cellFooter]: index === lastRow,
                    })}
                  >
                    <Typography
                      variant='subtitle1'
                      className={classes.cellValue}
                    >
                      {row.col2}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align='left'
                    padding='none'
                    variant='body'
                    classes={{ root: classes.innerCell }}
                    className={classNames({
                      [classes.cellFooter]: index === lastRow,
                    })}
                  >
                    <Typography
                      variant='subtitle1'
                      className={classes.cellValue}
                    >
                      {row.col3}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align='left'
                    padding='none'
                    variant='body'
                    classes={{ root: classes.innerCell }}
                    className={classNames({
                      [classes.cellFooter]: index === lastRow,
                    })}
                  >
                    <Typography
                      variant='subtitle1'
                      className={classes.cellValue}
                    >
                      {this.formatCurrency(row.col4)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  }
}

TableEmployeeSubscription.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  table_title: PropTypes.string.isRequired,
};

export default withStyles(styles)(TableEmployeeSubscription);
