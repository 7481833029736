import React from 'react';

function MenuOpenIcon({ className, ...props }) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={32}
        height={32}
      >
        <path fill="#D9D9D9" d="M0 0H32V32H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M21 15.61l-1.406 1.406L14.578 12l5.016-5.016L21 8.391 17.437 12 21 15.61zM3 6h12.984v2.016H3V6zm0 6.984v-1.968h9.984v1.968H3zM3 18v-2.016h12.984V18H3z"
          className="icon-path"
        />
      </g>
    </svg>
  );
}

export default MenuOpenIcon;
