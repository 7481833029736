import React from 'react';

function AccountBalanceIcon({ className, ...props }) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={32}
        height={32}
      >
        <path fill="#D9D9D9" d="M0 0H32V32H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M6.667 21.333v-6.666c0-.378.128-.695.383-.95a1.29 1.29 0 01.95-.384c.378 0 .695.128.95.384.256.255.383.572.383.95v6.666c0 .378-.127.695-.383.95a1.29 1.29 0 01-.95.384 1.29 1.29 0 01-.95-.384 1.29 1.29 0 01-.383-.95zm8 0v-6.666c0-.378.127-.695.383-.95a1.29 1.29 0 01.95-.384c.378 0 .695.128.95.384.256.255.383.572.383.95v6.666c0 .378-.127.695-.383.95a1.29 1.29 0 01-.95.384 1.29 1.29 0 01-.95-.384 1.29 1.29 0 01-.383-.95zM4 28a1.29 1.29 0 01-.95-.383 1.29 1.29 0 01-.383-.95c0-.378.128-.695.383-.95a1.29 1.29 0 01.95-.384h24c.378 0 .695.128.95.384.256.255.383.572.383.95 0 .377-.127.694-.383.95A1.29 1.29 0 0128 28H4zm18.667-6.667v-6.666c0-.378.127-.695.383-.95a1.29 1.29 0 01.95-.384c.378 0 .695.128.95.384.256.255.383.572.383.95v6.666c0 .378-.127.695-.383.95a1.29 1.29 0 01-.95.384 1.29 1.29 0 01-.95-.384 1.29 1.29 0 01-.383-.95zM28 10.667H3.867c-.334 0-.617-.117-.85-.35a1.157 1.157 0 01-.35-.85v-.734c0-.244.06-.455.183-.633.122-.178.283-.322.483-.433L14.8 1.933a2.784 2.784 0 011.2-.266c.422 0 .822.089 1.2.266l11.4 5.7c.244.111.428.278.55.5.122.223.183.456.183.7v.5c0 .378-.127.695-.383.95a1.29 1.29 0 01-.95.384zM8.6 8h14.8L16 4.333 8.6 8z"
          className="icon-path"
        />
      </g>
    </svg>
  );
}

export default AccountBalanceIcon;
