import React, { Component, Fragment } from 'react';

import { Grid, Typography, withStyles } from '@material-ui/core';
import moment from 'moment';

import { utils } from '../../../../../../app/routes/utils/index';

const styles = (theme) => ({
  textOverFlow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0px 0px 0px 2px',
  },
});

class SummaryDetail extends Component {

  getModifiedDate = () => {
    const { order } = this.props;
    
    if (order === null) return '--';

    if (order.updated_at && order.updated_at !== order.created_at) {
      return _.capitalize(moment(order.updated_at).format('DD/MM/YYYY'));
    }

    return _.capitalize(moment(order.created_at).format('DD/MM/YYYY'));
  }

  render() {
    const { order, contract, classes } = this.props;
    if (order === null) return null;

    return (
      <Fragment>
        <Grid container spacing={0} direction='row'>
          <Grid item xs={4} md={4}>
            <Typography className={classes.textOverFlow}>{order.company}</Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <Typography>Alteração</Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <Typography>Mensalidade</Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <Typography>
            {(order.order_status == 'Processado' || order.order_status == 'Ativo')
                    ? 'Processado em:'
                    : 'Agendado para:'}
            </Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <Typography>Vencimento</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={0} direction='row'>
          <Grid item xs={4} md={4}> </Grid>
          <Grid item xs={2} md={2}>
            <Typography variant='subtitle1'>{this.getModifiedDate()}</Typography>
          </Grid>
          <Grid item xs={2} md={2}>
           <Typography variant='subtitle1'>R$ {utils.formatCurrency(order.value)}</Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <Typography variant='subtitle1'>{utils.momentFromDate(order.updated_at)}</Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <Typography variant='subtitle1'>{utils.momentFromDate(order.charge_due_date)}</Typography>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(SummaryDetail);
