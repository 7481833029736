import React, { Component } from 'react';
import {
  Typography,
  CircularProgress,
  Grid,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Paper,
} from '@material-ui/core';
import { utils } from '../../../../utils';
import { contractService } from '../../../../../../services/contractService';

import moment from 'moment';

moment.locale('pt_BR');

export default class CreditManagement extends Component {
  componentDidMount() {
    if (!this.props.fetched) {
      contractService
        .getCredit(this.props.id)
        .then(response => this.handleResponse(response));
    }
  }

  handleResponse = creditsResponse => {
    this.props.setCredit(creditsResponse.data.response);
  };

  handleValue(value, type, afterTransactionBalance) {
    let statusName = '';
    let color = 'red';

    if (type === 'Crédito') {
      afterTransactionBalance += parseFloat(value);

      color = 'green';
    } else {
      afterTransactionBalance -= parseFloat(value);

      color = 'red';
    }

    return [
      <Typography variant="subtitle1" className={`${color}-info`}>
        R$ {utils.formatCurrency(value)}
      </Typography>,
      afterTransactionBalance
    ];
  }

  styles = {
    tablleCellHeader: {
      fontSize: 16,
      color: '#707070',
      fontFamily: 'Sua dr consulta',
      borderBottom: '1px solid #707070',
      fontWeight: 700
    },
    tableCell: {
      fontFamily: 'Inter',
      fontSize: 14,
      color: '#707070',
      fontWeight: 400
    }
  }

  render() {
    let afterTransactionBalance = 0;
    let lastTransactionBalance = 0;
    let valueTypography = 0;

    return (
      <Grid container spacing={24}>
 




        <Grid item xs={12}>
          {!this.props.fetched ? (
            <Grid container justify="center">
              <CircularProgress
                mode="indeterminate"
                style={{ color: '#3A10E0', margin: 50 }}
              />
            </Grid>
          ) : (
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <Typography variant="body2" color="primary" paragraph>
                  SALDO
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">Total</Typography>
                    <Typography variant="h1" color="textPrimary" paragraph>
                      R$ {utils.formatCurrency(this.props.balance)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">Disponivel</Typography>
                    <Typography variant="h1" color="textPrimary" paragraph>
                      R$ {utils.formatCurrency(this.props.balance)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">Bloqueado</Typography>
                    <Typography variant="h1" color="textPrimary" paragraph>
                      R$ 0,00
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" color="primary">
                  HISTÓRICO
                </Typography>
              </Grid>
              <Grid item xs={12}>
              <Paper>
          <Table>
            <TableHead>
              <TableRow >
                <TableCell style={{ ...this.styles.tablleCellHeader, width: 120 }} >Data </TableCell>
                <TableCell style={{ ...this.styles.tablleCellHeader, width: 110 }} >Hora </TableCell>
                <TableCell style={{ ...this.styles.tablleCellHeader }} >Valor</TableCell>
                <TableCell style={{ ...this.styles.tablleCellHeader }} >Motivo</TableCell>
                <TableCell style={{ ...this.styles.tablleCellHeader }} >Saldo anterior</TableCell>
                <TableCell style={{ ...this.styles.tablleCellHeader }} >Saldo atual</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
               {this.props.logs && this.props.logs.map(row => (
                <TableRow key={row.id}>
                  <TableCell style={this.styles.tableCell}>
                    {moment(row.created_at).format('DD/MM/YYYY')}

                  </TableCell>

                  <TableCell style={this.styles.tableCell}>
                    {moment(row.created_at).format('HH:mm')}

                  </TableCell>

                  <TableCell style={this.styles.tableCell}>
                    {utils.formatCurrency(row.amount)}

                  </TableCell>


                  
                  <TableCell style={this.styles.tableCell}>
                    {row.observation}
                  </TableCell>

                  <TableCell style={this.styles.tableCell}>
                    R$ {utils.formatCurrency(lastTransactionBalance)}
                  </TableCell>
                  <TableCell style={this.styles.tableCell}>
                    R$ {utils.formatCurrency(afterTransactionBalance)}
                  </TableCell>

                </TableRow>
              ))} 
            </TableBody>
          </Table>
        </Paper>


        
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}
