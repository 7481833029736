import React, { Component } from 'react';
import {
  Typography,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { contractService } from '../../../../../../services/contractService';
import { CheckCircleOutlineRounded, CancelOutlined, ErrorOutlineRounded } from '@material-ui/icons'
import moment from 'moment';
import GenericTable from '../../../../../../components/GenericTable/GenericTable'

moment.locale('pt_BR');

export default class StatusLog extends Component {
  componentDidMount() {
    if (!this.props.fetched) {
      contractService
        .getStatusLogs(this.props.id)
        .then(response => this.handleResponse(response))
    }
  }

  getStatusColor(status) {
    let contract_status_color;
    switch (status) {
      case 'Ativo':
        contract_status_color = 'green-info';
        break;
      case 'Aprovado':
        contract_status_color = 'blue-info';
        break;
      case 'Pendente de Aprovação':
      case 'Atraso':
      case 'Benefício Bloqueado':
        contract_status_color = 'orange-info';
        break;
      case 'Cancelado':
      case 'Contrato Vencido':
      default:
        contract_status_color = 'red-info';
        break;
    }
    return contract_status_color
  }


  handleResponse = responseLogs => {
    contractService.getStatus().then(responseStatus => {
      const responses = {
        logs: responseLogs.data.response.map(it => ({
          ...it,
          new_status_id: responseStatus.data.response.find(resp => resp.id == it.new_status_id).status || '',
          created_at: moment(it.created_at).format('DD/MM/YYYY HH:mm')
        })),
        statusList: responseStatus.data.response
      };

      this.props.setStatus(responses);
    });
  };

  styles = {
    tablleCellHeader: {
      fontSize: 16,
      color: '#707070',
      fontFamily: 'Sua dr consulta',
      borderBottom: '1px solid #707070',
      fontWeight: 700
    },
    tableCell: {
      fontFamily: 'Inter',
      fontSize: 14,
      color: '#707070',
      fontWeight: 400
    }
  }

  render() {
    return (
      <Grid container spacing={24} >
        <Grid item xs={12}>
          {!this.props.fetched ? (
            <Grid container justify="center">
              <CircularProgress
                mode="indeterminate"
                style={{ color: '#3A10E0', margin: 50 }}
              />
            </Grid>
          ) : (
            <Grid container spacing={8}>
              <Grid xs={8} style={{ display: 'flex' }}>
                <Grid style={{ width: '200px', marginTop: 8 }}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      color: '#1A1A1A',
                      fontSize: 16,
                      fontFamily: 'Sua dr consulta',

                    }}>
                    Situação da assinatura:
                  </Typography>
                </Grid>

                <Grid item xs={8} style={{
                  display: 'flex',
                  textAlign: 'center',
                  justifyContent: 'start',
                  width: '100%',
                  marginBottom: '20px'
                }}>

                  <Typography style={{
                    display: 'flex',
                    padding: '8px',
                    paddingTop: 10,
                    fontSize: '14px',
                    fontWeight: '400',
                    borderRadius: '6px'
                  }}
                    className={this.getStatusColor(this.props.activeStatus)}>
                    <div>
                      {['Vinculado', 'Ativo', 'Aprovado'].includes(this.props.activeStatus) && <CheckCircleOutlineRounded style={{ marginRight: '8px', }} />}
                      {['Cancelado', 'Contrato Vencido'].includes(this.props.activeStatus) && <CancelOutlined style={{ marginRight: '8px' }} />}
                      {['Pendente de Aprovação', 'Atraso', 'Benefício Bloqueado'].includes(this.props.activeStatus) && <ErrorOutlineRounded style={{ marginRight: '8px' }} />}

                    </div>
                    <div style={{ marginLeft: 2, marginTop: 2 }}>
                      {this.props.activeStatus}
                    </div>
                  </Typography>

                </Grid>


              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" color="primary">
                  Histórico
                </Typography>
              </Grid>

              <Grid item xs={12}>
             

               <Paper>
                  <Table>
                    <TableHead>
                      <TableRow >
                        <TableCell style={this.styles.tablleCellHeader} >Data/Hora</TableCell>
                        <TableCell style={this.styles.tablleCellHeader} >Status</TableCell>
                        <TableCell style={this.styles.tablleCellHeader} >Motivo</TableCell>
                        <TableCell style={this.styles.tablleCellHeader} >Usuário</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.logs && this.props.logs.map(row => (
                        <TableRow key={row.id}>
                          <TableCell style={this.styles.tableCell}> {row.created_at} </TableCell>
                          <TableCell style={this.styles.tableCell}> {row.new_status_id} </TableCell>
                          <TableCell style={this.styles.tableCell}> {row.justification} </TableCell>
                          <TableCell style={this.styles.tableCell}> {row.email} </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>  

              </Grid>

            </Grid>
          )}
        </Grid>
      </Grid >
    );
  }
}
