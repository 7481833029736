import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import Settings from "./Settings";
import Auth from "./Auth";

import Contract from "./Contract";
import ModalAlert from "./ModalAlert";

const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  auth: Auth,

  contract: Contract,
  modalAlert: ModalAlert
});

export default reducers;
