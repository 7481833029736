import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Grid, withStyles } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ChevronRight';

import { withRouter } from 'react-router-dom';
import ContainerHeader from '../../../../../components/ContainerHeader';
import SummaryDetail from '../../../../../app/routes/Employees/routes/subscriptionDetail/subcomponent/SummaryDetail';
import Vencido from '../../../../../app/routes/Employees/routes/subscriptionDetail/subcomponent/status/Vencido';
import EmProcessamento from '../../../../../app/routes/Employees/routes/subscriptionDetail/subcomponent/status/EmProcessamento';
import AguardandoPagamento from '../../../../../app/routes/Employees/routes/subscriptionDetail/subcomponent/status/AguardandoPagamento';
import TableEmployes from '../../../../../app/routes/Employees/routes/subscriptionDetail/subcomponent/TableEmployes';
import LoadingWrapper from '../../../../../componentsPortal/LoadingWrapper';
import { contractService, ordersService } from '../../../../../services';
import { ORDER_STATUS_C } from '../../../../../constants';
import RenderComponentCondition from '../../../../../componentsPortal/RenderComponentCondition';
import {
  modalEmitAlert,
  ERROR,
} from '../../../../../actions/ModalAlertActions';

import Button from './../../../../../components/ui/button/Button';

const styles = (theme) => ({
  root: {
    padding: '0',
    margin: '0',
    width: 'auto',
  },
  rowSpacer: {
    margin: '16px 0px 24px 0px',
    padding: '0',
  },
});

function OrderButtonDownload(props) {
  const { orderDownloadError, downloadOrderUrl, modalEmitAlert } = props;
  const label = 'Baixar Pedido';
  const LinkButton = () => {
    return (
      <a
        className='download-xlsx'
        href={downloadOrderUrl}
        download={`Pedido.xlsx`}
        target='_blank'
      >
        <Button variant='link' size='small' color='primary'>
          {label} <ArrowForward />
        </Button>
      </a>
    );
  };

  const ErrorButton = ({ onClick }) => {
    return (
      <Button variant='link' size='small' color='primary' onClick={onClick}>
        {label} <ArrowForward />
      </Button>
    );
  };

  if (orderDownloadError) {
    return (
      <ErrorButton
        onClick={() =>
          modalEmitAlert('Erro ao fazer download do pedido.', ERROR)
        }
      />
    );
  }

  return <LinkButton />;
}

class index extends Component {
  state = {
    order: null,
    contract: null,
    loading: true,
    downloadOrderUrl: '',
    orderDownloadError: false,
    downloadInvoiceUrl: '',
    invoiceDownloadError: false,
  };
  async componentWillMount() {
    const { params } = this.props.match;

    const response = await ordersService.getOrder(params.id);
    if (response.success) {
      const contract = await this.getContractById(
        response.response.contract_id,
      );
      this.setState({
        order: response.response,
        contract: contract,
        loading: false,
      });
    } else {
      return '';
    }

    this.downloadOrder();
    /*
      Disable this function call because system is missing integration to process invoices
    */
    //this.downloadInvoice();
  }
  getContractById = async (contract_id) => {
    const { contract } = this.state;
    const response = await contractService.getContractById(contract_id);
    if (response.data.success) return response.data.response;
    return '';
  };

  downloadOrder = async () => {
    const { order } = this.state;
    const response = await ordersService.downloadOrder(order.id);

    if (!_.isUndefined(response) && response.success === false)
      this.setState({ orderDownloadError: true });
    else this.setState({ downloadOrderUrl: response.response });
  };

  downloadInvoice = async () => {
    const { order } = this.state;
    const response = await ordersService.getInvoiceByOrderId(order.id);

    if (!_.isUndefined(response) && response.success === false)
      this.setState({ invoiceDownloadError: true });
    else
      this.setState({
        downloadInvoiceUrl: window.URL.createObjectURL(response),
      });
  };

  render() {
    const {
      order,
      contract,
      loading,
      orderDownloadError,
      downloadOrderUrl,
    } = this.state;

    const { modalEmitAlert, classes } = this.props;

    return (
      <div className='app-wrapper'>
        <Grid
          container
          direction='column'
          justify='flex-start'
          alignItems='flex-start'
          spacing={0}
          className={classes.root}
        >
          <Grid
            container
            item
            direction='row'
            justify='space-between'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item>
              <ContainerHeader title='Detalhes da Mensalidade' />
            </Grid>
            <Grid item>
              <Grid
                container
                spacing={0}
                direction='row'
                justify='flex-end'
                alignItems='flex-start'
              >
                <OrderButtonDownload
                  orderDownloadError={orderDownloadError}
                  downloadOrderUrl={downloadOrderUrl}
                  modalEmitAlert={modalEmitAlert}
                />
                {/*
                    Disable this button because system is missing integration to process invoices
                  */}
                {/* {invoiceDownloadError ? (
                  <Button
                    variant='outlined'
                    color='primary'
                    className='p-5'
                    onClick={() =>
                      modalEmitAlert(
                        'Erro ao fazer download da nota de adiantamento.',
                        ERROR
                      )
                    }
                  >
                    <CloudDownload
                      variant='rounded'
                      style={{ marginRight: '10px' }}
                    />
                    Baixar Nota
                  </Button>
                ) : (
                  <a
                    className='download-xlsx'
                    href={downloadInvoiceUrl}
                    download={`NotaAdiantamento.pdf`}
                    target='_blank'
                  >
                    <Button variant='outlined' color='primary' className='mr-2'>
                      <CloudDownload
                        variant='rounded'
                        style={{ marginRight: '10px' }}
                      />
                      Baixar Nota
                    </Button>
                  </a>
                )} */}
              </Grid>
            </Grid>
          </Grid>
          <LoadingWrapper loading={loading}>
            {!_.isNull(contract) && (
              <Grid
                container
                item
                spacing={0}
                direction='row'
                className={classes.rowSpacer}
              >
                <SummaryDetail order={order} contract={contract} />
              </Grid>
            )}
          </LoadingWrapper>
          <Grid
            container
            direction='column'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
          >
            <LoadingWrapper loading={loading}>
              {!_.isNull(contract) && (
                <Grid item container>
                  <TableEmployes order={order} contract={contract} />
                </Grid>
              )}
            </LoadingWrapper>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default connect(null, { modalEmitAlert })(
  withRouter(withStyles(styles)(index)),
);

// sem função no momento
function OrderPop() {
  <Grid
  container
  direction='column'
  justify='flex-start'
  alignItems='flex-start'
  spacing={0}
>
  <LoadingWrapper loading={loading} hiddenLoading>
    {!_.isNull(order) && (
      <Grid item>
        <RenderComponentCondition
          current={order.order_status}
          listCondition={[
            ORDER_STATUS_C.AGUARDANDO_PROCESSAMENTO,
            ORDER_STATUS_C.AGUARDANDO_GERECAO_NOTA,
            ORDER_STATUS_C.PEDIDO_EM_PROCESSAMENTO,
          ]}
        >
          <EmProcessamento order={order} />
        </RenderComponentCondition>

        <RenderComponentCondition
          current={order.order_status}
          listCondition={[
            ORDER_STATUS_C.VENCIDO,
            ORDER_STATUS_C.ESTORNADO,
            ORDER_STATUS_C.PARCIALMENTE_ESTORNADO,
            ORDER_STATUS_C.CANCELADO,
          ]}
        >
          <Vencido order={order} />
        </RenderComponentCondition>

        <RenderComponentCondition
          current={order.order_status}
          listCondition={[
            ORDER_STATUS_C.PENDENTE_PREENCHIMENTO_INFORMACOES,
            ORDER_STATUS_C.PENDENTE_PAGAMENTO,
            ORDER_STATUS_C.PAGAMENTO_PARCIAL,
            ORDER_STATUS_C.ATRASADO,
          ]}
        >
          <AguardandoPagamento order={order} />
        </RenderComponentCondition>
      </Grid>
    )}
  </LoadingWrapper>
</Grid>
}