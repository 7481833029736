import React from 'react';

function UserIcon({ className, ...props }) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={32}
        height={32}
      >
        <path fill="#D9D9D9" d="M0 0H32V32H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M1.333 24a1.29 1.29 0 01-.95-.383 1.29 1.29 0 01-.383-.95V21.9c0-.956.489-1.733 1.467-2.333.977-.6 2.266-.9 3.866-.9.29 0 .567.005.834.016.266.011.522.04.766.084a5.547 5.547 0 00-.7 1.466 5.465 5.465 0 00-.233 1.6V24H1.333zm8 0a1.29 1.29 0 01-.95-.383 1.29 1.29 0 01-.383-.95v-.834c0-.71.194-1.36.583-1.95.39-.589.94-1.105 1.65-1.55.711-.444 1.561-.777 2.55-1A14.664 14.664 0 0116 17c1.178 0 2.261.111 3.25.333.989.223 1.839.556 2.55 1 .711.445 1.256.961 1.633 1.55.378.59.567 1.24.567 1.95v.834c0 .377-.128.694-.383.95a1.29 1.29 0 01-.95.383H9.333zM26 24v-2.167c0-.577-.072-1.122-.217-1.633a5.218 5.218 0 00-.65-1.433c.245-.045.495-.073.75-.084.256-.01.517-.016.784-.016 1.6 0 2.889.294 3.866.883.978.589 1.467 1.372 1.467 2.35v.767c0 .377-.128.694-.383.95a1.29 1.29 0 01-.95.383H26zm-15.167-2.667H21.2c-.222-.444-.839-.833-1.85-1.166-1.011-.334-2.128-.5-3.35-.5s-2.339.166-3.35.5c-1.011.333-1.617.722-1.817 1.166zm-5.5-4a2.568 2.568 0 01-1.883-.783 2.568 2.568 0 01-.783-1.883c0-.756.26-1.39.783-1.9A2.596 2.596 0 015.333 12c.756 0 1.39.256 1.9.767.511.51.767 1.144.767 1.9 0 .733-.256 1.36-.767 1.883-.51.522-1.144.783-1.9.783zm21.334 0a2.568 2.568 0 01-1.884-.783A2.568 2.568 0 0124 14.667c0-.756.261-1.39.783-1.9A2.596 2.596 0 0126.667 12c.755 0 1.389.256 1.9.767.51.51.766 1.144.766 1.9 0 .733-.255 1.36-.766 1.883-.511.522-1.145.783-1.9.783zM16 16c-1.111 0-2.056-.389-2.833-1.167C12.389 14.056 12 13.111 12 12c0-1.133.389-2.083 1.167-2.85C13.944 8.383 14.889 8 16 8c1.133 0 2.083.383 2.85 1.15.767.767 1.15 1.717 1.15 2.85 0 1.111-.383 2.056-1.15 2.833C18.083 15.611 17.133 16 16 16zm0-2.667c.378 0 .694-.127.95-.383a1.29 1.29 0 00.383-.95 1.29 1.29 0 00-.383-.95 1.29 1.29 0 00-.95-.383 1.29 1.29 0 00-.95.383 1.29 1.29 0 00-.383.95c0 .378.127.694.383.95s.572.383.95.383z"
          className="icon-path"
        />
      </g>
    </svg>
  );
}

export default UserIcon;
