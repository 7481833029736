import { SET_CONTRACT, SET_LIST_CONTRACTS, SET_LIST_CONTRACTS_ASYNC } from '../constants/ActionTypes';

export const setContract = contract => {
  return {
    type: SET_CONTRACT,
    payload: contract
  };
};


export const setListContracts = contracts => {
  return {
    type: SET_LIST_CONTRACTS,
    payload: contracts
  };
};

export const setListContractsAsync = () => {
  return {
    type: SET_LIST_CONTRACTS_ASYNC,
    // payload: contract
  };
};
