export const banckSlipStatusEnum = {
  paid: "Pago",
  pending: "Pendente",
  canceled: "Cancelado",
  expired: "Expirado",
  registered: "Registrado",
  liquidated: "Liquidado",
  rejected: "Rejeitado",
  failed: "Falha",
  processing: "Processando",
};