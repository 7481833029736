import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { toggleCollapsedNav } from '../../actions/Setting';
import UserInfo from 'components/UserInfo';
import { Grid } from '@material-ui/core';
import ContainerHeader from '../ContainerHeader';
class Header extends React.Component {
  componentWillMount() {
    localStorage.getItem('username');
  }
  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  render() {
    return (
      <div className='app-main-header-content'>
        {localStorage.getItem('username') && (
            <ContainerHeader
              title={'Olá, ' + localStorage.getItem('username')}
              style={{ marginBottom: 0 }}
            />
        )}

        <UserInfo />
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType } = settings;
  return { drawerType };
};

export default withRouter(
  connect(mapStateToProps, { toggleCollapsedNav })(Header)
);
