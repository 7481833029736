import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

export const styles = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    textAlign: 'left',
  },
  formLabelControl: {
    textTransform: 'none',
    fontFamily: 'Sua dr consulta',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    position: 'relative',
    margin: '0px',
    padding: '0px 0px 4px 0px',
    transformOrigin: 'unset',
    width: 'fit-content',
    transform: 'unset',
    color: 'var(--color-neutral-800)',
    '&$disabled': {
      color: 'var(--color-neutral-800)',
      cursor: 'default',
    },
  },
  disabled: {
    color: 'var(--color-neutral-800) !important',
  },
  focused: {
    color: 'var(--color-neutral-800) !important',
    cursor: 'default',
  },
  error: {
    color: 'var(--color-neutral-800) !important',
    cursor: 'default',
  },
  filled: {
    color: 'var(--color-neutral-800) !important',
    cursor: 'default',
  },
  required: {
    color: 'var(--color-neutral-800) !important',
    cursor: 'default',
  },
  asterisk: {
    color: 'var(--color-feedback-danger-900) !important',
    '&$error': {
      color: 'var(--color-feedback-danger-900) !important',
    },
  },
});

function Label(props) {
  const { classes, htmlFor, children, ...other } = props;

  return (
    <InputLabel
      shrink={false}
      htmlFor={htmlFor}
      classes={{ ...other.LabelClasses }}
      {...other}
      variant='outlined'
      FormLabelClasses={{
        root: classes.formLabelControl,
        focused: classes.focused,
        disabled: classes.disabled,
        error: classes.error,
        filled: classes.filled,
        required: classes.required,
        asterisk: classes.asterisk,
      }}
    >
      {children}
    </InputLabel>
  );
}

Label.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  focused: PropTypes.bool,
  required: PropTypes.bool,
  htmlFor: PropTypes.string,
};

export default withStyles(styles, { name: 'CustomLabel' })(Label);
