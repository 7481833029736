import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import './styles.css';
import { EnhancedTableHeadCell } from '../../../../util/EnhancedTableHeadCell';
import { salesEnum, salesLabelsEnum } from '../utils/SalesEnum';
import YaloSearchFilter from '../../../../components/YaloSearchFilter';
import ContainerHeader from '../../../../components/ContainerHeader';
import Pagination from 'material-ui-flat-pagination';

import {
  sortData,
  fetchBrokerSales,
  filterData,
  renderValues,
  exportToCSV,
} from '../utils/SalesTableLogic';

class SalesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      filteredData: [],
      originalData: [],
      order: 'desc',
      orderBy: 'orderDate',
      offset: 0,
      limit: 10,
      loading: true,
    };
    this.filterTimer = null;
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    try {
      const data = await fetchBrokerSales();
      this.setState({ filteredData: data, originalData: data });
    } catch (error) {
      console.error('Erro ao buscar as vendas:', error);
    } finally {
      this.setState({ loading: false });
    }
  };

  handlePageChange = (offset) => {
    this.setState({ offset });
  };

  handleSearchChange = (e) => {
    const search = e.target.value;
    this.setState({ search });

    clearTimeout(this.filterTimer);
    this.filterTimer = setTimeout(() => {
      const { originalData } = this.state;
      const filteredData = filterData(originalData, search);
      this.setState({ filteredData });
    }, 500);
  };

  handleRequestSort = (property) => {
    const { filteredData, order } = this.state;
    const orderBy = property;
    const sortedData = sortData(
      filteredData,
      property,
      order === 'desc' ? 'asc' : 'desc'
    );
    this.setState({
      filteredData: sortedData,
      order: order === 'desc' ? 'asc' : 'desc',
      orderBy,
    });
  };

  render() {
    const { filteredData, search, order, orderBy, offset, limit, loading } =
      this.state;

    const showNoRecords = filteredData.length === 0;

    const paginatedData = filteredData.slice(offset, offset + limit);

    return (
      <div className='app-wrapper'>
        <Grid container justify='space-between' spacing={0}>
          <Grid item xs={12} md={12}>
            <ContainerHeader title='Vendas B2C' />
          </Grid>
          <Grid item xs={12} md={12} style={{ marginTop: 24 }}>
            <YaloSearchFilter
              onChange={this.handleSearchChange}
              value={search}
              placeholder='Busca por nome ou cpf'
            />
          </Grid>
        </Grid>
        {loading ? (
          <Grid container justify='center' style={{ padding: 100, marginTop: 24 }}>
            <CircularProgress
              mode='indeterminate'
              style={{ margin: 50, color: '#005DF9' }}
            />
          </Grid>
        ) : showNoRecords ? (
          <Grid container justify='center' style={{ marginTop: 24 }}>
            <Typography color='textSecondary' variant='h1' gutterBottom>
              Não há histórico
            </Typography>
          </Grid>
        ) : (
          <Paper style={{ paddingTop: 10, paddingLeft: 8, paddingRight: 8, marginTop: 24, borderRadius: 16 }}>
            <div style={{ height: 'calc(100vh - 325px)', overflowY: 'auto' }}>
              <Grid container>
                <Grid item xs={12} style={{ margin: '0px 20px' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {salesEnum.map((el) => (
                          <EnhancedTableHeadCell
                            alignment='left'
                            key={el}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={() => this.handleRequestSort(el)}
                            labelName={salesLabelsEnum[el]}
                            labelId={el}
                          />
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedData.map((venda, index) => (
                        <TableRow key={index} style={{backgroundColor: ((index+1) % 2) === 0 ? '#F6F6F6' : '#fff'}}>
                          {salesEnum.map((columnName) => (
                            <TableCell
                              component='th'
                              scope='row'
                              key={columnName}
                              style={{
                                paddingLeft: '.2rem',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                              }}
                            >
                              {renderValues(venda[columnName], columnName) ||
                                '-'}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </div>
            <Grid
              item
              container
              direction='row'
              justify='space-between'
              xs={12}
              style={{ marginBottom: 30, marginTop: 16 }}
            >
              <Grid item style={{ margin: '0px 0px 20px 0px' }}>
                <Pagination
                  size='small'
                  limit={limit}
                  offset={offset}
                  total={filteredData.length}
                  onClick={(e, offset) => {
                    this.handlePageChange(offset);
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => exportToCSV(filteredData)}
                >
                  Exportar
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}
      </div>
    );
  }
}

export default SalesTable;
