import React, { Component } from 'react';
import _ from 'lodash';
import ContainerHeader from '../../../../../components/ContainerHeader';
import Pagination from 'material-ui-flat-pagination';
import {
  TextField,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  Tabs,
  Tab,
  Button,
  IconButton,
  Tooltip,
  Typography,
  Checkbox,
  CircularProgress
} from '@material-ui/core';
import { connect } from 'react-redux';
import { utils } from '../../../utils';
import OrderHeader from '../components/OrderHeader';
import { Link, withRouter } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { UserActions } from '../../../../../actions';
import './styles.css';
import YaloSearchFilter from '../../../../../components/YaloSearchFilter';
import TableEmployeeSubscription from '../../../../../componentsPortal/Tables/TableEmployeeSubscription';
import OrderStatusSelect from '../../../../../componentsPortal/Selects/OrderStatusSelect.js';

moment.locale('pt_BR');

class Pedidos extends Component {
  state = {
    companies: [],
    filter: '',
    loading: true,
    offset: 0,
    rechargesFilter: false,
    payment_tabs: 0,
    status_filter: 'Todos',
    payment_filter: null,
    from: '',
    to: ''
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.selectedContract.id !== nextProps.selectedContract.id)
      this.getAllOrders(nextProps.selectedContract);
  };

  componentDidMount() {
    if (this.props.location.filter && this.props.location.filter.hasFilter) {
      this.setState({ ...this.state, ...this.props.location.filter.func() });
    }
    this.getAllOrders(this.props.selectedContract);
  }

  getAllOrders = selectedContract => {
    UserActions.getAllRecharges(selectedContract.id).then(response => {
      if (response.status == 500) this.setState({ loading: false });

      this.setState({
        companies: response.response.map(order => {
          const order_status_color = utils.statusColor(order.order_status);

          const order_resume_table_data = _.pick(
            order,
            `total_recharges`,
            `total_value_recharges`,
            `total_plans`,
            `total_value_plans`,
            `total_terminations`,
            `total_value_taxes`,
            `value`
          );
         
          let tableData = {
            table_title: 'Pedidos',
            inset: true,
            rows: [
              {
                col1: 'Assinaturas',
                col2: '',
                col3: order.total_recharges || '--',
                col4: order.total_value_recharges || '--'
              },
              {
                col1: 'Total',
                col2: '',
                col3: '',
                col4: order.value
              }
            ]
          };

          return (
            <ExpansionPanel
              company_name={order.company_name}
              identifier={order.identifier || ''}
              created_at={order.created_at}
              paymentstatus={order.is_payment_done ? 'true' : 'false'}
              order_type={order.order_type}
              key={order.id}
              className={order_status_color}
              order_status_name={order.order_status_name}
            >
              <ExpansionPanelSummary
                className="panel-summary-format"
                style={{ paddingLeft: 0, paddingRight: 70 }}
                expandIcon={<ExpandMoreIcon />}
              >
                <OrderHeader order={order} />
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container>
                  <Grid item xs={12}>
                    <TableEmployeeSubscription
                      {...tableData}
                      taxes={order.taxes_json}
                    />
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                    >
                      {/* Se um pedido tem as informações */}
                      {order.order_status !==
                        'Pendente de Preenchimento de Informações' && (
                        <Link
                          to={{
                            pathname: `/app/pedidos-emitidos/consulting/${
                              order.id
                            }`,
                            filter: this.filterToProps
                          }}
                        >
                          <Button
                            className="mt-3"
                            variant="contained"
                            color="primary"
                          >
                            Detalhes do pedido
                          </Button>
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        }),
        loading: false
      });
    });
  };

  handleStatusFilter = event => {
    this.setState({
      status_filter: event.target.value,
      offset: 0
    });
  };

  handleClick = offset => {
    this.setState({ offset });
  };

  filterToProps = () => {
    return {
      from: this.state.from,
      to: this.state.to,
      filter: this.state.filter,
      status_filter: this.state.status_filter,
      payment_filter: this.state.payment_filter,
      payment_tabs: this.state.payment_tabs
    };
  };

  render() {
    this.state.companies.filter(el => {
      if (!el.props.identifier);
    });

    const filteredOrders = _.filter(
      this.state.companies,
      function(o) {
        return (
          (_.includes(
            o.props.identifier.toLowerCase(),
            this.filter.toLowerCase()
          ) &&
            this.status_filter === 'Todos') ||
          o.props.order_status_name === this.status_filter
        );
      }.bind(this.state)
    );

    return (
      <div className="app-wrapper">
        <ContainerHeader title="Pedidos" />
        <Grid container spacing={16}>
          {/* Busca */}
          <Grid item xs={3}>
            <YaloSearchFilter
              onChange={e =>
                this.setState({ filter: e.target.value, offset: 0 })
              }
              value={this.state.filter}
              placeholder="Busca por nº do pedido"
            />
          </Grid>
          {/* Filtros */}
          <Grid item xs={3}>
            <OrderStatusSelect
              value={this.state.status_filter}
              onChange={this.handleStatusFilter}
            />
          </Grid>
          {/* Data e Limpar */}
          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >

              <Grid item>
                <Tooltip title="Limpar Filtros">
                  <IconButton
                    aria-label="Limpar Filtros"
                    size="small"
                    onClick={() =>
                      this.setState({
                        to: '',
                        from: '',
                        filter: '',
                        status_filter: 'Todos',
                        payment_filter: null,
                        payment_tabs: 0,
                        offset: 0
                      })
                    }
                  >
                    <CloseIcon variant="round" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {this.state.loading && (
          <div className="loader-view mt-5">
            <CircularProgress
              mode="indeterminate"
              style={{ color: '#005DF9' }}
            />
          </div>
        )}
        {!this.state.loading && this.state.companies.length < 1 && (
          <Typography variant="subtitle1" align="center" style={{ margin: 40 }}>
            Nenhum pedido emitido no momento.
          </Typography>
        )}
        {filteredOrders.slice(this.state.offset, this.state.offset + 10)}
        <div className="mt-4">
          <Pagination
            size="large"
            limit={10}
            offset={this.state.offset}
            total={filteredOrders.length}
            onClick={(e, offset) => this.handleClick(offset)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ contract }) => {
  const { selectedContract } = contract;
  return { selectedContract };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(Pedidos));
