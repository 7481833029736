import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Typography,
  CircularProgress,
  Grid,
  Paper,
  FormControl,
  Input,
  ExpansionPanel,
  ExpansionPanelSummary,
  IconButton,
  Tabs,
  Tab,
  Tooltip,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputAdornment,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import OrderHeader from '../../../Orders/routes/components/OrderHeader';
import Pagination from 'material-ui-flat-pagination';
import YaloSearchFilter from '../../../../../components/YaloSearchFilter';
import SelectUi from '../../../../../components/ui/select/index';
import InputUi from '../../../../../components/ui/TextField/index';
import ButtonUi from '../../../../../components/ui/button/Button';
import CustomDialog from '../../../../../components/ui/Dialog/customDialog.js'
import CustomExpansionPanel from '../../../../../components/ui/ExpansionPanel/index.js'


import moment from 'moment';

import { contractService } from '../../../../../services/contractService';
import { ordersService } from '../../../../../services/ordersService';

import { utils } from '../../../utils';
import { AttachMoneyRounded, ChevronLeftOutlined } from '@material-ui/icons';

moment.locale('pt_BR');



class Credit extends Component {
  state = DEFAULT_CONTRACT_STATE;

  componentDidMount() {
    contractService
      .getContractById(this.props.match.params.id)
      .then(response => this.handleResponse(response));
  }

  handleResponse = contractResponse => {
    if (contractResponse.data.success) {
      ordersService
        .getContractOrders(this.props.match.params.id)
        .then(ordersResponse => {
          this.setState({
            loading: false,
            contract: contractResponse.data.response,
            pedidos: ordersResponse.response.map(pedido =>
              this.renderPedido(pedido)
            )
          });
        });
    }
  };

  formatDate = date => {
    if (date === 'Invalid date') {
      return 'N/A';
    } else {
      return date;
    }
  };

  handleClick(offset) {
    this.setState({ offset });
  }

  handleTabsFilter = (event, payment_tabs) => {
    switch (payment_tabs) {
      case 2:
        this.setState({ payment_filter: 'false', payment_tabs, offset: 0 });
        break;
      case 1:
        this.setState({ payment_filter: 'true', payment_tabs, offset: 0 });
        break;
      case 0:
      default:
        this.setState({ payment_filter: null, payment_tabs, offset: 0 });
        break;
    }
  };

  handleSubmit = () => {
    this.setState({ dialog: true, submitLoading: true });
    const body = {
      contract_id: this.props.match.params.id,
      amount: parseFloat(
        this.state.amount.slice(0, -2) + '.' + this.state.amount.slice(-2)
      ),
      operation_type: 'Crédito',
      user_id: localStorage.getItem('userid'),
      order_id: this.state.pedido.id,
      observation: this.state.observation,
      status: this.state.status
    };

    contractService.setCredit(body).then(response => {
      if (response.data.success) {
        this.setState({ submitLoading: false });
      } else {
        this.setState({ submitLoading: false, message: response.data.message });
      }
    });
  };

  renderPedido(el) {
    return (
      <Grid
        item
        xs={12}
        key={el.id}
        companyname={el.company_name}
        created_at={el.created_at}
        paymentstatus={el.is_payment_done ? 'true' : 'false'}
      >
        <CustomExpansionPanel
          onClick={() => this.setState({ pedido: el })}
          //className={order_status_color}
          style={{ borderRadius: 8 }}

          expanded={false}
        >
          <ExpansionPanelSummary
            className="panel-summary-format"
            expandIcon={<Fragment />}
          >
            <OrderHeader order={el} />
          </ExpansionPanelSummary>
        </CustomExpansionPanel>
      </Grid>
    );
  }

  filterDate = date => {
    let filter = true;
    const dateUnix = new Date(moment(date).format('YYYY-MM-DD')).getTime();
    if (this.state.to) {
      const toUnix = new Date(this.state.to).getTime();
      if (dateUnix > toUnix) filter = false;
    }

    if (this.state.from) {
      const fromUnix = new Date(this.state.from).getTime();
      if (dateUnix < fromUnix) filter = false;
    }
    return filter;
  };

  render() {
    const { loading, contract } = this.state;
    const filteredOrders =
      this.state.pedidos.length > 0
        ? this.state.pedidos.filter(
          el =>
            el.props.companyname
              .toLowerCase()
              .includes(this.state.filter.toLowerCase()) &&
            (this.state.payment_filter === null
              ? true
              : el.props.paymentstatus === this.state.payment_filter) &&
            this.filterDate(el.props.created_at)
        )
        : [];

    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={16}
        style={{ padding: 16 }}
      >
        <Grid container>
          <Grid item xs={10} style={{ marginLeft: 8 }}>
            <Typography
              style={{
                fontFamily: 'Sua dr consulta',
                fontSize: 24,
                fontWeight: 700,
                color: '#707070'
              }}
            >
              Conta
            </Typography>
          </Grid>

          <Grid item xs={1} style={{ marginRight: 8 }}>
            {!this.state.pedido && (
              <Link
                style={{ all: 'unset' }}
                to={this.props.match.url.replace(
                  'adicionar-credito',
                  'detalhes-contrato'
                )}
              >
                <ButtonUi
                  startIcon={<ChevronLeftOutlined />}
                  variant="link"
                  color="primary">
                  Voltar
                </ButtonUi>
              </Link>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Paper
            style={{ paddingTop: 8, marginBottom: this.state.pedido ? 48 : 16 }}
          >

            <Grid item xs={12}>
              {loading ? (
                <Grid container justify="center">
                  <CircularProgress
                    mode="indeterminate"
                    style={{ color: '#3A10E0', margin: 50 }}
                  />
                </Grid>
              ) : (
                <Grid container justify="center">
                  <Grid item xs={12} style={{ padding: 16 }}>
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <Grid container spacing={8}>
                          {/* INICIO CONTRATO */}
                          <Grid item xs={12}>
                            <Typography style={{
                              fontFamily: 'Inter',
                              fontSize: 16,
                              fontWeight: 700
                            }}
                              color="primary">
                              CONTRATO
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={16}>
                              <Grid item>
                                <Typography style={{
                                  fontFamily: 'Inter',
                                  fontSize: 16,
                                  fontWeight: 700
                                }}>
                                  {contract.name}{' '}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}>

                            <Grid xs={3}>
                              <Grid item xs={4}>
                                <Typography
                                  variant="body1" >
                                  Contratante:
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <Typography variant="body2">
                                  {contract.contracting_company_name}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid xs={3}>
                              <Grid >
                                <Typography align='left'
                                  variant="body1">
                                  CNPJ:
                                </Typography>
                              </Grid>
                              <Grid >
                                <Typography variant="body2" align='left'>
                                  {`${utils.formatCNPJ(
                                    contract.contracting_company_cnpj
                                  )}`}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid xs={3}>
                              <Grid item xs={6}>
                                <Typography align='left'
                                  variant="body1">
                                  Data de assinatura:

                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <Typography variant="body2" align='left'>
                                  {`${utils.momentFromDate(
                                    contract.signature_date
                                  )}`}
                                </Typography>
                              </Grid>



                              {/*  <Grid container spacing={16}>
                                  <Grid item xs={4}>
                                    <Typography
                                      variant="subtitle1"
                                      align="right"
                                    >
                                      Contratante:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={8}>
                                    <Typography variant="subtitle2">
                                      {contract.contracting_company_name}
                                    </Typography>
                                  </Grid>
                                </Grid> */}

                            </Grid>

                            <Grid xs={3}>
                              <Grid item xs={6}>
                                <Typography align='left'
                                  variant="body1">
                                  Data de vigência:
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <Typography variant="body2" align='left'>

                                  {`${utils.momentFromDate(
                                    contract.closure_date
                                  )}`}
                                </Typography>
                              </Grid>
                            </Grid>

                          </Grid>

                        </Grid>
                        {/* FIM DO CONTRATO */}
                        {!this.state.pedido && (
                          <Grid container spacing={8} style={{ marginTop: 52 }}>
                            <Grid item xs={12}>
                              <Typography
                                style={{
                                  fontFamily: 'Inter',
                                  fontSize: 16,
                                  fontWeight: 700,
                                }}
                                color="primary">
                                PEDIDO
                              </Typography>
                            </Grid>
                            <Grid container spacing={16}>
                              {/* Busca */}
                              <Grid item xs={3}>
                                <YaloSearchFilter
                                  onChange={e =>
                                    this.setState({
                                      filter: e.target.value,
                                      offset: 0
                                    })
                                  }
                                  value={this.state.filter}
                                  placeholder="Busca por nome"
                                />
                              </Grid>
                              {/* Filtros */}
                              <Grid item xs={4}>
                                <Tabs
                                  value={this.state.payment_tabs}
                                  onChange={this.handleTabsFilter}
                                  indicatorColor="primary"
                                  textColor="primary"
                                  fullWidth
                                >
                                  <Tab label="Todos" style={{ minWidth: 50 }} />
                                  <Tab label="Pagos" style={{ minWidth: 50 }} />
                                  <Tab
                                    label="Não Pagos"
                                    style={{ minWidth: 50 }}
                                  />
                                </Tabs>
                              </Grid>
                              {/* Data e Limpar */}
                              <Grid item xs={5}>
                                <Grid
                                  container
                                  direction="row"
                                  justify="space-between"
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Grid
                                      container
                                      direction="row"
                                      justify="flex-start"
                                      alignItems="center"
                                      spacing={16}
                                    >
                                      <Grid item>
                                        <InputUi
                                          small={true}

                                          name="from"
                                          label="De:"
                                          value={this.state.from}
                                          onChange={e =>
                                            this.setState({
                                              from: e.target.value,
                                              offset: 0
                                            })
                                          }
                                          type="date"
                                          InputLabelProps={{ shrink: true }}
                                          style={{ width: 150 }}
                                        />


                                      </Grid>
                                      <Grid item>
                                        <InputUi
                                          small={true}

                                          name="to"
                                          label="Até:"
                                          value={this.state.to}
                                          onChange={e =>
                                            this.setState({
                                              to: e.target.value,
                                              offset: 0
                                            })
                                          }
                                          type="date"
                                          InputLabelProps={{ shrink: true }}
                                          style={{ width: 150 }}
                                        />


                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item>
                                    <Tooltip title="Limpar Filtros">
                                      <IconButton
                                        aria-label="Limpar Filtros"
                                        size="small"
                                        onClick={() =>
                                          this.setState({
                                            to: '',
                                            from: '',
                                            filter: '',
                                            payment_filter: null,
                                            payment_tabs: 0,
                                            offset: 0
                                          })
                                        }
                                      >
                                        <CloseIcon variant="round" />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        <Grid container>
                          {this.state.pedido && (
                            <Grid item xs={12}>
                              <Grid container spacing={16}>

                                <Grid item xs={12} style={{ marginTop: 52 }}>
                                  <Typography
                                    style={{
                                      fontFamily: 'Inter',
                                      fontSize: 16,
                                      fontWeight: 700,
                                    }} color="primary">
                                    PEDIDO
                                  </Typography>
                                </Grid>


                                <Grid item xs={12}>
                                  <OrderHeader
                                    order={this.state.pedido}
                                    credit={true}
                                    onClick={() =>
                                      this.setState({ pedido: '' })
                                    }
                                  />
                                </Grid>
                              </Grid>
                              {/* FIM PEDIDO */}
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography style={{
                                    marginTop: 24,
                                    fontFamily: 'Inter',
                                    fontSize: 16,
                                    fontWeight: 700,
                                  }}
                                    color="primary">
                                    INFORMAÇÕES
                                  </Typography>
                                </Grid>
                                <Grid container xs={12}>
                                  <Grid item xs={2}>
                                    <FormControl margin="normal">
                                      <InputUi
                                        small={true}
                                        style={{ width: '90%' }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              R$
                                            </InputAdornment>
                                          )
                                        }}
                                        variant="outlined"
                                        label="Valor"
                                        name="value"
                                        value={utils.maskMoneyInput(
                                          this.state.amount
                                        )}
                                        onChange={e =>
                                          this.setState({
                                            amount: utils.removeNonNumericCharacters(
                                              e.target.value
                                            )
                                          })
                                        }
                                        type="text"
                                      />

                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={4} >
                                    <FormControl fullWidth margin="normal" style={{  marginLeft: 24,  width: '90%'}}>
                                      <SelectUi
                                        style={{ width: '90%' }}
                                        value={this.state.status}
                                        label="Status"

                                        onChange={e =>
                                          this.setState({
                                            status: e.target.value
                                          })
                                        }

                                        options={
                                          [
                                            {
                                              value: 'Pagamento a Mais',
                                              name: 'Pagamento a Mais'
                                            },
                                            {
                                              value: 'Cliente Cancelado antes do beneficio',
                                              name: 'Cliente Cancelado antes do beneficio'
                                            },
                                          ]
                                        }>

                                      </SelectUi>
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={6}>
                                  <FormControl margin="normal" style={{  width: '100%' }}>
                                      <InputUi
                                        style={{ width: '90%' }}

                                        small={true}
                                        type="text"
                                        value={this.state.observation}
                                        onChange={e =>
                                          this.setState({
                                            observation: e.target.value
                                          })
                                        }
                                        input={<Input name="observation" />}
                                        label="Descrição"
                                        variant="outlined"

                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>

                              </Grid>


                              <Grid item xs={12} style={{ paddingTop: 8 }}>
                                <Grid container style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}>
                                  <Link
                                    style={{ all: 'unset' }}
                                    to={this.props.match.url.replace(
                                      'adicionar-credito',
                                      'detalhes-contrato'
                                    )}
                                  >
                                    <ButtonUi
                                      variant="link"
                                      startIcon={<ChevronLeftOutlined />}
                                    >
                                      Cancelar
                                    </ButtonUi>
                                  </Link>
                                  <ButtonUi
                                    variant="primary"
                                    style={{ marginLeft: 8 }}
                                    onClick={this.handleSubmit}
                                    endIcon={<AttachMoneyRounded />}
                                    size='md'
                                    isDisabled={
                                      !this.state.observation ||
                                      !this.state.status
                                    }
                                  >
                                    Adicionar crédito
                                  </ButtonUi>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Paper>
          {
            !this.state.pedido && (
              <Grid container style={{ marginBottom: 48 }}>
                <Grid item xs={12}>
                  {filteredOrders.slice(
                    this.state.offset,
                    this.state.offset + 10
                  )}
                </Grid>
                <Pagination
                  size="large"
                  limit={10}
                  offset={this.state.offset}
                  total={this.state.pedidos.length}
                  onClick={(e, offset) => this.handleClick(offset)}
                />
              </Grid>
            )
          }
        </Grid >

        <CustomDialog open={this.state.dialog}>

          <DialogContent>
            <DialogContentText>
              {this.state.submitLoading ? (
                <Grid container justify="center">
                  <CircularProgress
                    mode="indeterminate"
                    style={{ color: '#3A10E0', margin: 50 }}
                  />
                </Grid>
              ) : this.state.message.length > 0 ? (
                this.state.message
              ) : (
                'Crédito adicionado com sucesso'
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonUi
              variant="primary"
              onClick={() =>
                this.state.message.length > 0
                  ? this.setState({
                    dialog: false,
                    message: []
                  })
                  : this.setState({
                    pedido: '',
                    offset: 0,
                    filter: '',
                    payment_tabs: 0,
                    payment_filter: null,
                    from: '',
                    to: '',
                    observation: '',
                    amount: '',
                    status: '',
                    dialog: false,
                    message: []
                  })
              }
              color="primary"
            >
              OK
            </ButtonUi>
          </DialogActions>
        </CustomDialog>
      </Grid >
    );
  }
}
const DEFAULT_CONTRACT_STATE = {
  loading: true,
  contract: {},
  pedido: '',
  pedidos: [],
  offset: 0,
  filter: '',
  payment_tabs: 0,
  payment_filter: null,
  from: '',
  to: '',
  observation: '',
  amount: '',
  status: '',
  dialog: false,
  submitLoading: false,
  message: []
};

export default withRouter(Credit);
