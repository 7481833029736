import { brokerService } from '../../../../services/brokerService';
import moment from 'moment';
import { salesEnum, date, salesLabelsEnum } from './SalesEnum';

export async function fetchBrokerSales() {
  try {
    const res = await brokerService.getBrokerSales();
    if (res.status === 200) {
      return res.data.response;
    } else {
      console.error('Erro ao buscar as vendas');
      return [];
    }
  } catch (error) {
    console.error('Erro ao buscar as vendas:', error);
    throw error;
  }
}

export function filterData(originalData, search) {
  const formattedSearch = search.trim();
  const isCPF = /^\d/.test(formattedSearch); // Verifica se os primeiros caracteres são numéricos

  return originalData.filter((venda) => {
    const vendaName = (venda.name || '').toLowerCase();
    const vendaCPF = (venda.cpf || '').replace(/[^\d]/g, ''); // Remove qualquer formatação do CPF da venda

    if (isCPF) {
      // Se parece ser um CPF, compara diretamente
      const formattedSearchWithoutSymbols = formattedSearch.replace(
        /[^\d]/g,
        ''
      );
      const vendaCPFWithoutSymbols = vendaCPF.replace(/[^\d]/g, '');
      return vendaCPFWithoutSymbols.includes(formattedSearchWithoutSymbols);
    } else {
      // Se não parece ser um CPF, compara com o nome da venda
      return vendaName.includes(formattedSearch.toLowerCase());
    }
  });
}

export function sortData(filteredData, property, order) {
  const isDesc = order === 'desc';
  return filteredData.sort((a, b) => {
    const aValue = a[property] || '';
    const bValue = b[property] || '';

    if (aValue < bValue) {
      return isDesc ? -1 : 1;
    }
    if (aValue > bValue) {
      return isDesc ? 1 : -1;
    }
    return 0;
  });
}

export const formatDate = (object, key) => {
  if (date.includes(key)) {
    
    const momentDate = moment(
      object,
      ['DD/MM/YYYY HH:mm:ss', moment.ISO_8601],
      true
    );
  
    return momentDate.isValid()
      ? momentDate.format('DD/MM/YYYY HH:mm')
      : object;
  }
  
  return object;
};

export const renderValues = (object, key) => {
  object = formatDate(object, key); 

  if (key === 'gender') {
    return object === 'Male' ? 'Masculino' : 'Feminino';
  }
  return object;
};

export const exportToCSV = (filteredData) => {
  // Obter os cabeçalhos traduzidos
  const headers = salesEnum.map((key) => salesLabelsEnum[key]);

  // Gerar linhas de dados formatadas
  const rows = filteredData.map((row) => {
    return salesEnum.map((key) => renderValues(row[key], key));
  });

  // Unir cabeçalhos e linhas de dados em uma string CSV
  const csvContent =
    'data:text/csv;charset=utf-8,' +
    [headers.join(',')].concat(rows.map((row) => row.join(','))).join('\n');

  // Criar e clicar no link de download
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'vendas.csv');
  document.body.appendChild(link);
  link.click();
};
