import { UserActions } from '../../src/actions/UserActions';
import { BASE_URL } from '../config/env';

export const contractService = {
  createContract,
  getContract,
  getPlans,
  getContractById,
  updateContract,
  getAccountManagers,
  delContract,
  getStatus,
  getStatusLogs,
  updateContractStatus,
  getCredit,
  setCredit,
  totalStatus,
  getFinancialByCompanyId,
  getTaxes,
  getFichaProposta,
  getBanckSlip,
};

function createContract(contract) {
  const token = localStorage.getItem('token');
  const body = JSON.stringify(contract);

  return fetch(`${BASE_URL}/contracts/set`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body,
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}

function getAccountManagers() {
  const token = localStorage.getItem('token');

  return fetch(`${BASE_URL}/people/accountManagers`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}

function getTaxes() {
  const token = localStorage.getItem('token');

  return fetch(`${BASE_URL}/contracts/taxes`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}

function getStatus() {
  const token = localStorage.getItem('token');

  return fetch(`${BASE_URL}/contracts/status/all`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}

function updateContract(contract) {
  const token = localStorage.getItem('token');
  const body = JSON.stringify(contract);

  return fetch(`${BASE_URL}/contracts/update`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body,
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}

function updateContractStatus(contract) {
  const token = localStorage.getItem('token');
  const body = JSON.stringify(contract);

  return fetch(`${BASE_URL}/contracts/updateStatus`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body,
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}

function getContract() {
  const token = localStorage.getItem('token');

  return fetch(`${BASE_URL}/contracts/all`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  }).then((response) => {
    // If unauthorized, logout
    if (response.status === 401) {
      UserActions.logout();
      location.reload(true);
    }

    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}

function getContractById(id) {
  const token = localStorage.getItem('token');
  const body = JSON.stringify({ id });

  return fetch(`${BASE_URL}/contracts/get`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body,
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}

function getFinancialByCompanyId(id) {
  const token = localStorage.getItem('token');
  const body = JSON.stringify({ id });

  return fetch(`${BASE_URL}/companies/getFinancialByCompanyId`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body,
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}

function delContract(id) {
  const token = localStorage.getItem('token');
  const body = JSON.stringify({ id });
  return fetch(`${BASE_URL}/contracts/remove`, {
    method: 'DELETE',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body,
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}

function getStatusLogs(id) {
  const token = localStorage.getItem('token');
  const body = JSON.stringify({ id });

  return fetch(`${BASE_URL}/contracts/status/logs`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body,
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}

function getPlans() {
  const token = localStorage.getItem('token');

  return fetch(`${BASE_URL}/plans/all?type=PJ`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}

function getFichaProposta(id) {
  const token = localStorage.getItem('token');

  return fetch(`${BASE_URL}/contracts/proposal/${id}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  })
    .then((response) => {
      if (!response.ok) throw response;
      else return response;
    })
    .then((response) => response.blob())
    .then((blob) => {
      return window.URL.createObjectURL(blob);
    })
    .catch((error) => {
      throw error;
    });
}

function getCredit(contract_id) {
  const token = localStorage.getItem('token');
  const body = JSON.stringify({ contract_id });

  return fetch(`${BASE_URL}/contracts/credit/get`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body,
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}

function setCredit(data) {
  const token = localStorage.getItem('token');
  const body = JSON.stringify(data);
  return fetch(`${BASE_URL}/contracts/credit/set`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body,
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}

async function totalStatus(selectedContract, periodSelectionFilter) {
  const token = localStorage.getItem('token');
  const contractId = selectedContract.id;

  try {
    const response = await fetch(`${BASE_URL}/contracts/totalStatus?contractId=${contractId}&period=${periodSelectionFilter}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
    });

    if (!response.ok) {
      throw new Error('Falha na requisição');
    }

    const data = await response.json();
    return {
      data: data.response,
      status: response.status,
    };
  } catch (error) {
    console.error('Erro:', error);
    throw error;
  }
}


async function getBanckSlip(contractId) {
  const token = localStorage.getItem('token');

  try {
    const response = await fetch(`${BASE_URL}/contracts/${contractId}/listBanckSlip`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
    });

    if (!response.ok) {
      throw new Error('Falha na requisição');
    }

    const data = await response.json();
    return {
      data: data.response,
      status: response.status,
    };
  } catch (error) {
    console.error('Erro:', error);
    throw error;
  }
}
