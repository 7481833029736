import React, { Component } from 'react';
import {
  Typography,
  TableRow,
  TableCell,
  TableHead,
  Table,
  TableBody,
  TableSortLabel
} from '@material-ui/core';
import IconSwapVert from '@material-ui/icons/SwapVert';

import { utils } from '../../../utils';

const rechargeHeader = [
  { label: 'CPF', columnName: 'cpf' },
  { label: 'BENEFÍCIO', columnName: 'yalo_benefit' },
  { label: 'STATUS', columnName: '' }
];

const newEmployeesHeader = [
  { label: 'MATRÍCULA', columnName: 'registration' },
  { label: 'NOME', columnName: 'name' },
  { label: 'CPF', columnName: 'cpf' },
  { label: 'CONTA', columnName: 'yalo_account' },
  { label: 'STATUS', columnName: '' }
];

export class OrderTableRechargesHead extends Component {
  render() {
    const { handleSort, columnDirection } = this.props;
    return (
      <TableHead>
        <TableRow>
          {_.map(rechargeHeader, (head, i) => (
            <TableCell onClick={event => handleSort(event, head.columnName)}>
              <TableSortLabel>
                {head.label} <IconSwapVert style={{ fontSize: 14 }} />
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

export class OrderTableNewEmployeesHead extends Component {
  render() {
    return (
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '10%' }}>
            <Typography variant='subtitle1' color='secondary'>
              MATRÍCULA
            </Typography>
          </TableCell>
          <TableCell style={{ width: '40%' }}>
            <Typography variant='subtitle1' color='secondary'>
              NOME
            </Typography>
          </TableCell>
          <TableCell style={{ width: '30%' }}>
            <Typography variant='subtitle1' color='secondary'>
              CPF
            </Typography>
          </TableCell>
          <TableCell style={{ width: '10%' }}>
            <Typography variant='subtitle1' color='secondary'>
              BENEFÍCIO
            </Typography>
          </TableCell>
          <TableCell style={{ width: '15%' }}>
            <Typography variant='subtitle1' color='secondary'>
              STATUS
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

export class OrderTableTerminationsHead extends Component {
  render() {
    return (
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '10%' }}>
            <Typography variant='subtitle1' color='secondary'>
              MATRÍCULA
            </Typography>
          </TableCell>
          <TableCell style={{ width: '40%' }}>
            <Typography variant='subtitle1' color='secondary'>
              NOME
            </Typography>
          </TableCell>
          <TableCell style={{ width: '30%' }}>
            <Typography variant='subtitle1' color='secondary'>
              CPF
            </Typography>
          </TableCell>
          <TableCell style={{ width: '10%' }}>
            <Typography variant='subtitle1' color='secondary'>
              DESLIGAMENTO
            </Typography>
          </TableCell>
          <TableCell style={{ width: '10%' }}>
            <Typography variant='subtitle1' color='secondary'>
              STATUS
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

export class OrderTableResume extends Component {
  valorBruto = taxes => {
    let totalTaxes = _.reduce(
      taxes,
      (acc, tax) => _.toNumber(tax.taxed_value) + acc,
      0
    );

    return totalTaxes + _.toNumber(this.props.value);
  };
  render() {
    return (
      <Table padding='dense'>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant='subtitle1'>DESCRIÇÃO</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle1'>QUANTIDADE</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle1'>VALOR</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant='subtitle1'>Novos Empregados</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle2'>
                {this.props.total_new_employees}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle2'>--</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant='subtitle1'>Recargas</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle2'>
                {this.props.total_recharges}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle2'>
                R$ {utils.formatCurrency(this.props.total_value_recharges)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant='subtitle1'>Assinaturas</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle2'>
                {this.props.total_plans}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle2'>
                R$ {utils.formatCurrency(this.props.total_value_plans)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant='subtitle1'>Desligamentos</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle2'>
                {this.props.total_terminations}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle2'>--</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant='subtitle1'>Taxas</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle2'>--</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle2'>
                R$ {utils.formatCurrency(this.props.total_value_taxes)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow selected>
            <TableCell>
              <Typography variant='subtitle2'>Total</Typography>
            </TableCell>
            <TableCell>
              <Typography />
            </TableCell>
            <TableCell>
              <Typography variant='subtitle2'>
                R$ {utils.formatCurrency(this.props.value)}
              </Typography>
            </TableCell>
          </TableRow>
          {_.map(this.props.taxes_json, tax => (
            <TableRow>
              <TableCell>
                <Typography variant='subtitle1'>{tax.name}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='subtitle2'>
                  {_.toNumber(tax.value * 100)}%
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='subtitle2'>
                  R$ {utils.formatCurrency(tax.taxed_value)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
          {this.props.taxes_json && this.props.taxes_json.length > 0 && (
            <TableRow selected>
              <TableCell>
                <Typography variant='subtitle2'>Valor Bruto</Typography>
              </TableCell>
              <TableCell style={{ borderBottom: 'none' }} rowSpan={3} />
              <TableCell>
                <Typography variant='body2'>
                  R$ {this.valorBruto(this.props.taxes_json)}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  }
}
