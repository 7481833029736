import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Grid, Typography, Button } from '@material-ui/core';

import { modalEmitAlert, ERROR } from '../../../../../../../actions/ModalAlertActions';
import { ordersService } from '../../../../../../../services';

class Vencido extends Component {
  state = {
    downloadFile: '',
    pdfDownloadError: false
  };

  componentDidMount() {
    this.downloadBoleto();
  }

  downloadBoleto = async () => {
    const { order } = this.props;
    const resp = await ordersService.getBoletoByOrderId(order.id);

    if (!_.isUndefined(resp) && resp.success === false)
      this.setState({ pdfDownloadError: true });
    else this.setState({ downloadFile: window.URL.createObjectURL(resp) });
  };

  render() {
    const { downloadFile, pdfDownloadError } = this.state;
    const { order, modalEmitAlert } = this.props;
    return (
      <Grid
        container
        direction='row'
        alignItems='center'
        justify='space-around'
        className='mt-3 mb-3 py-3 bg-danger'
      >
        <Grid container item xs={9} direction='column'>
          <Typography variant='h2' className='text-white'>
            <ins>{order.order_status}</ins>
          </Typography>
          <Typography className='text-white'>
            A assinatura de seus funcioários foi desativada, regularize a situação
            realizando o pagamento da mensalidade.
          </Typography>
        </Grid>

        {pdfDownloadError ? (
          <Button
            className='px-2'
            variant='outlined'
            color='primary'
            size='small'
            style={{ backgroundColor: '#ffffff' }}
            onClick={() =>
              modalEmitAlert('Erro ao fazer download do boleto', ERROR)
            }
          >
            PAGAR BOLETO
          </Button>
        ) : (
          <a download={`Boleto.pdf`} href={downloadFile} target='_blank'>
            <Button
              className='px-2'
              variant='outlined'
              color='primary'
              size='small'
              style={{ backgroundColor: '#ffffff' }}
            >
              PAGAR BOLETO
            </Button>
          </a>
        )}
      </Grid>
    );
  }
}

export default connect(
  null,
  { modalEmitAlert }
)(Vencido);
