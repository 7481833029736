import React from 'react';

function UserCircleIcon({ className, ...props }) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={32}
        height={32}
      >
        <path fill="#D9D9D9" d="M0 0H32V32H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M8.62 22.12c1.02-.78 2.16-1.395 3.42-1.845 1.26-.45 2.58-.675 3.96-.675s2.7.225 3.96.675c1.26.45 2.4 1.065 3.42 1.845.7-.82 1.245-1.75 1.635-2.79.39-1.04.585-2.15.585-3.33 0-2.66-.935-4.925-2.805-6.795S18.66 6.4 16 6.4s-4.925.935-6.795 2.805S6.4 13.34 6.4 16c0 1.18.195 2.29.585 3.33.39 1.04.935 1.97 1.635 2.79zM16 17.2c-1.18 0-2.175-.405-2.985-1.215S11.8 14.18 11.8 13s.405-2.175 1.215-2.985S14.82 8.8 16 8.8s2.175.405 2.985 1.215S20.2 11.82 20.2 13s-.405 2.175-1.215 2.985S17.18 17.2 16 17.2zM16 28c-1.66 0-3.22-.315-4.68-.945a12.118 12.118 0 01-3.81-2.565 12.118 12.118 0 01-2.565-3.81C4.315 19.22 4 17.66 4 16c0-1.66.315-3.22.945-4.68.63-1.46 1.485-2.73 2.565-3.81a12.118 12.118 0 013.81-2.565C12.78 4.315 14.34 4 16 4c1.66 0 3.22.315 4.68.945 1.46.63 2.73 1.485 3.81 2.565a12.118 12.118 0 012.565 3.81c.63 1.46.945 3.02.945 4.68 0 1.66-.315 3.22-.945 4.68a12.118 12.118 0 01-2.565 3.81 12.118 12.118 0 01-3.81 2.565c-1.46.63-3.02.945-4.68.945zm0-2.4c1.06 0 2.06-.155 3-.465.94-.31 1.8-.755 2.58-1.335A9.036 9.036 0 0019 22.465 9.519 9.519 0 0016 22c-1.06 0-2.06.155-3 .465-.94.31-1.8.755-2.58 1.335.78.58 1.64 1.025 2.58 1.335.94.31 1.94.465 3 .465zm0-10.8c.52 0 .95-.17 1.29-.51.34-.34.51-.77.51-1.29s-.17-.95-.51-1.29c-.34-.34-.77-.51-1.29-.51s-.95.17-1.29.51c-.34.34-.51.77-.51 1.29s.17.95.51 1.29c.34.34.77.51 1.29.51z"
          className="icon-path"
        />
      </g>
    </svg>
  );
}

export default UserCircleIcon;
