import React, { Component, Fragment } from 'react';

import { Tabs, Tab, Typography, Grid } from '@material-ui/core';

function TabContainer(props) {
  return (
    <Typography component='div' style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

export default class AfterUploadTabs extends Component {
  state = {
    tabValue: 0
  };

  handleTab = (event, tabValue) => {
    this.setState({ tabValue });
  };

  render() {
    const { tabValue } = this.state;
    const {
      genericErrors,
      rechargesErrors,
      rechargesWarning,
      genericWarning
    } = this.props;

    return (
      <Grid container>
        <Grid item xs={12}>
          <Tabs
            value={tabValue}
            onChange={this.handleTab}
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
          >
            <Tab
              label={`Geral${
                genericErrors.length > 0
                  ? ` (${genericErrors[0].props.counter})`
                  : ''
              }`}
            />
            <Tab
              label={`Recarga${
                rechargesErrors.length > 0
                  ? ` (${rechargesErrors[0].props.counter})`
                  : ''
              }`}
            />
          </Tabs>

          <TabContainer>
            {/* Erros Gerais  */}
            {genericErrors.length == 0 &&
              genericWarning.length == 0 &&
              tabValue === 0 && (
                <Typography
                  variant='subtitle1'
                  align='center'
                  style={{ margin: 40 }}
                >
                  Sem erros Gerais
                </Typography>
              )}
            {genericErrors.length !== 0 && tabValue === 0 && (
              <Fragment>
                <Typography
                  variant='h4'
                  className='red-info'
                  style={{ fontWeight: 600 }}
                >
                  Erros
                </Typography>
                {genericErrors}
              </Fragment>
            )}
            {/* fim erros gerais */}

            {genericErrors.length !== 0 &&
              genericWarning.length !== 0 &&
              tabValue === 0 && <Fragment style={{ marginTop: 16 }} />}

            {/* avisos gerais */}
            {rechargesErrors.length == 0 &&
              tabValue === 1 &&
              rechargesWarning.length == 0 && (
                <Typography
                  variant='subtitle1'
                  align='center'
                  style={{ margin: 40 }}
                >
                  Sem erros em Recargas
                </Typography>
              )}
            {genericWarning.length !== 0 && tabValue === 0 && (
              <Fragment>
                <Typography
                  variant='h4'
                  className='orange-info'
                  style={{ fontWeight: 600 }}
                >
                  Avisoss
                </Typography>
                {genericWarning}
              </Fragment>
            )}

            {rechargesErrors.length !== 0 && tabValue === 1 && (
              <Fragment>
                <Typography
                  variant='h4'
                  className='red-info'
                  style={{ fontWeight: 600 }}
                >
                  Erros
                </Typography>
                {rechargesErrors}
              </Fragment>
            )}
            {rechargesWarning.length !== 0 && tabValue === 1 && (
              <Fragment>
                <Typography
                  variant='h4'
                  className='orange-info'
                  style={{ fontWeight: 600, marginTop: 16 }}
                >
                  Avisos
                </Typography>
                {rechargesWarning}
              </Fragment>
            )}
          </TabContainer>
        </Grid>
      </Grid>
    );
  }
}
