import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import SalesTable from './subComponents/SalesTable';

const Sales = ({ match }) => (
  <div>
    {!localStorage.getItem('token') && (
      <Redirect
        to={{
          pathname: '/signin'
        }}
        push
      />
    )}
    <Switch>
      <Route
        path={`${match.url}`}
        component={SalesTable}
      />
    </Switch>
  </div>
);

export default Sales;
