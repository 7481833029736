/// CONTRACT
export const ORDER_STATUS = [
  //  BLUE INFO
  {
    VALUE: 'processed',
    COLOR: 'blue-info',
    DESCRIPTION: 'Processado'
  },
  // GREEN INFO
  {
    VALUE: 'awaiting_processing',
    COLOR: 'green-info',
    DESCRIPTION: 'Aguardando Processamento'
  },
  {
    VALUE: 'processing_order',
    COLOR: 'green-info',
    DESCRIPTION: 'Pedido em Processamento'
  },
  // RED INFO
  {
    VALUE: 'expired',
    COLOR: 'red-info',
    DESCRIPTION: 'Vencido'
  },
  {
    VALUE: 'reversed',
    COLOR: 'red-info',
    DESCRIPTION: 'Estornado'
  },
  {
    VALUE: 'partial_reversed',
    COLOR: 'red-info',
    DESCRIPTION: 'Parcialmente Estornado'
  },
  {
    VALUE: 'canceled',
    COLOR: 'red-info',
    DESCRIPTION: 'Cancelado'
  },
  //ORANGE INFO
  {
    VALUE: 'pending_information_filling',
    COLOR: 'orange-info',
    DESCRIPTION: 'Pendente de Preenchimento de Informações'
  },
  {
    VALUE: 'pending_payment',
    COLOR: 'orange-info',
    DESCRIPTION: 'Pendente de Pagamento'
  },
  {
    VALUE: 'partial_payment',
    COLOR: 'orange-info',
    DESCRIPTION: 'Pagamento Parcial'
  },
  {
    VALUE: 'waiting_nf',
    COLOR: 'orange-info',
    DESCRIPTION: 'Aguardando Geraçao da Nota Fiscal'
  },
  {
    VALUE: 'partial_processing',
    COLOR: 'orange-info',
    DESCRIPTION: 'Processamento Parcial'
  },
  {
    VALUE: 'active',
    COLOR: 'orange-info',
    DESCRIPTION: 'Ativo'
  }
];

export const PJ_STATUS = [
  {
    VALUE: 'Vinculado',
    COLOR: 'green-info'
  },
  {
    VALUE: 'Não Vinculado',
    COLOR: 'red-info'
  }
];

export const CONTRACT_STATUS = [
  // GREEN INFO
  {
    VALUE: 'Ativo',
    COLOR: 'green-info'
  },
  // BLUE INFO
  {
    VALUE: 'Aprovado',
    COLOR: 'blue-info'
  },
  // ORANGE INFO
  {
    VALUE: 'Pendente de Aprovação',
    COLOR: 'orange-info'
  },
  {
    VALUE: 'Atraso',
    COLOR: 'orange-info'
  },
  {
    VALUE: 'Benefício Bloqueado',
    COLOR: 'orange-info'
  },
  // RED INFO
  {
    VALUE: 'Cancelado',
    COLOR: 'red-info'
  },
  {
    VALUE: 'Contrato Vencido',
    COLOR: 'red-info'
  }
];
