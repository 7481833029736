import { BASE_URL } from "../config/env";

export const cardService = {
  upload,
  finishOrder,
  finishRecharge,
  getByCnpj,
  updateOrder,
  uploadRechargeSheet
};

export function getCompanies(contractId) {
  const token = localStorage.getItem('token');

  return fetch(`${BASE_URL}/companies/all`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      contractId
    })
  }).then(response => {
    return response.json().then(json => {
      return {
        data: json,
        status: response.status
      };
    });
  });
}

function uploadRechargeSheet(file, contractId, orderId) {
  const token = localStorage.getItem('token');

  let formData = new FormData();
  formData.append('orderFile', file, file.name);
  formData.append('contractId', contractId);
  if (orderId) formData.append('orderId', orderId);

  return fetch(`${BASE_URL}/orders/upload-recharge`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`
    }),
    body: formData
  })
    .then(response => {
      return response.json().then(json => {
        return {
          data: json,
          status: response.status
        };
      });
    })
    .catch(function(error) {
      console.error(error);
    });
}

function upload(file, contractId, orderId) {
  const token = localStorage.getItem('token');

  let formData = new FormData();
  formData.append('orderFile', file, file.name);
  formData.append('contractId', contractId);
  if (orderId) formData.append('orderId', orderId);

  return fetch(`${BASE_URL}/orders/upload`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`
    }),
    body: formData
  })
    .then(response => {
      return response.json().then(json => {
        return {
          data: json,
          status: response.status
        };
      });
    })
    .catch(function(error) {
      console.error(error);
    });
}

function finishRecharge(order) {
  const token = localStorage.getItem('token');

  return fetch(`${BASE_URL}/orders/recharge`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(order)
  })
    .then(response => {
      return response.json().then(json => {
        return {
          data: json,
          status: response.status
        };
      });
    })
    .catch(function(error) {
      console.error(error);
    });
}

function finishOrder(order) {
  const token = localStorage.getItem('token');

  return fetch(`${BASE_URL}/orders/finish`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(order)
  })
    .then(response => {
      return response.json().then(json => {
        return {
          data: json,
          status: response.status
        };
      });
    })
    .catch(function(error) {
      console.error(error);
    });
}

function updateOrder(order) {
  const token = localStorage.getItem('token');

  return fetch(`${BASE_URL}/orders/implantation`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(order)
  })
    .then(response => {
      return response.json().then(json => {
        return {
          data: json,
          status: response.status
        };
      });
    })
    .catch(function(error) {
      console.error(error);
    });
}

function getByCnpj(cnpjId) {
  const token = localStorage.getItem('token');

  return fetch(`${BASE_URL}/companies/getByCnpj`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      cnpj: cnpjId
    })
  }).then(response => {
    return response.json().then(json => {
      return {
        data: json,
        status: response.status
      };
    });
  });
}
