import React from 'react';

function DashboardIcon({ className, ...props }) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={32}
        height={32}
      >
        <path fill="#D9D9D9" d="M0 0H32V32H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M17.333 10.667V5.333c0-.377.128-.694.384-.95a1.29 1.29 0 01.95-.383h8c.377 0 .694.128.95.383.255.256.383.573.383.95v5.334c0 .377-.128.694-.383.95a1.29 1.29 0 01-.95.383h-8a1.29 1.29 0 01-.95-.383 1.29 1.29 0 01-.384-.95zM4 16V5.333c0-.377.128-.694.383-.95A1.29 1.29 0 015.333 4h8c.378 0 .695.128.95.383.256.256.384.573.384.95V16c0 .378-.128.694-.384.95a1.29 1.29 0 01-.95.383h-8a1.29 1.29 0 01-.95-.383A1.29 1.29 0 014 16zm13.333 10.667V16c0-.378.128-.694.384-.95a1.29 1.29 0 01.95-.383h8c.377 0 .694.127.95.383.255.256.383.572.383.95v10.667c0 .377-.128.694-.383.95a1.29 1.29 0 01-.95.383h-8a1.29 1.29 0 01-.95-.383 1.29 1.29 0 01-.384-.95zM4 26.667v-5.334c0-.377.128-.694.383-.95a1.29 1.29 0 01.95-.383h8c.378 0 .695.128.95.383.256.256.384.573.384.95v5.334c0 .377-.128.694-.384.95a1.29 1.29 0 01-.95.383h-8a1.29 1.29 0 01-.95-.383 1.29 1.29 0 01-.383-.95zm2.667-12H12v-8H6.667v8zM20 25.333h5.333v-8H20v8zm0-16h5.333V6.667H20v2.666zm-13.333 16H12v-2.666H6.667v2.666z"
          className="icon-path"
        />
      </g>
    </svg>
  );
}

export default DashboardIcon;
