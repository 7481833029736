import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, Button } from '@material-ui/core';
import { Component } from 'react';

export default class WarningOrderDue extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { order } = this.props;

    return (
      <Grid
        container
        style={{
          background: 'rgba(242, 153, 74, 0.87)',
          padding: 16
        }}
        alignItems='center'
      >
        {/*Primeira Coluna*/}
        <Grid item xs={10}>
          <Grid style={{ marginLeft: 30 }}>
            <Grid container>
              <Grid item>
                <Typography variant='h6' className='white-info'>
                  {order.order_status}
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item>
                <Typography variant='h4' className='white-info'>
                  Este pedido está vencido, para manter seu benefício ativo gere
                  um novo boleto no botão ao lado, evitando assim o trancamento
                  do mesmo
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container justify='center' alignItems='center'>
            <Button
              variant='contained'
              color='primary'
              style={{
                backgroundColor: 'white',
                color: '#3A10E0'
              }}
            >
              Gerar novo boleto
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
