import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { userSignOut } from '../../../../actions';
import './style.css';
import ContainerHeader from '../../../../components/ContainerHeader';
import useBankSlips from '../utils/useBanksSlips';
import { BankSlipCardList } from '../subComponents/BanckSlipCard';

const BilletItem = ({ selectedContract }) => {
  const { bankSlips, loading } = useBankSlips(
    selectedContract ? selectedContract.id : null
  );

  if (!selectedContract || !selectedContract.id) {
    return (
      <div className='app-wrapper'>
        <ContainerHeader title='Boletos' />
        <Typography variant='body1'>
          Selecione um contrato para visualizar os boletos.
        </Typography>
      </div>
    );
  }

  return (
    <div className='app-wrapper'>
      <ContainerHeader title='Boletos' />
      {loading && (
        <div className='loading-overlay'>
          <CircularProgress size={40} />
        </div>
      )}
      {!loading && <BankSlipCardList bankSlips={bankSlips} />}
    </div>
  );
};

const mapStateToProps = ({ contract }) => ({
  selectedContract: contract.selectedContract,
});

export default connect(mapStateToProps, { userSignOut })(BilletItem);
