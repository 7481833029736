import React, { Component } from 'react';
import { connect } from 'react-redux';
import ContainerHeader from '../../../../../components/ContainerHeader';
import { Link, withRouter } from 'react-router-dom';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  Typography,
  CircularProgress,
  FormControl
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded.js';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { getCompanies } from '../../../../../services/cardService';
import { utils } from '../../../utils';
import YaloSearchFilter from '../../../../../components/YaloSearchFilter';
import { PJHeader } from '../components/PJHeader';
import Pagination from 'material-ui-flat-pagination';
import { PJ_STATUS } from '../../../../../constants';
import SelectUi from '../../../../../components/ui/select/index.js'
import ButtonUi from '../../../../../components/ui/button/Button.js'
import ChevronRight from '@material-ui/icons/ChevronRightRounded.js';

moment.locale('pt_BR');

class ConsultaPJ extends Component {
  state = {
    companies: [],
    filter: '',
    loading: true,
    offset: 0,
    status_tabs: 0,
    status_filter: 'Todos'
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.selectedContract.id !== nextProps.selectedContract.id)
      this.createCompanieList(nextProps.selectedContract);
  };

  componentDidMount() {
    this.createCompanieList(this.props.selectedContract);
  }

  createCompanieList = selectedContract => {
    getCompanies(selectedContract.id).then(response => {
      if (response.data.success) {
        this.setState({
          companies: response.data.response.map(el => {

            return (
              <ExpansionPanel
                companyname={el.name}
                cnpj={el.cnpj}
                status={el.status}
                key={el.id}
                style={{ borderRadius: '8px' }}
              >
                <ExpansionPanelSummary
                  className='panel-summary-format'
                  expandIcon={<ExpandMoreIcon />}
                  style={{ paddingLeft: 8 }}
                >

                  <PJHeader
                    status={el.status}
                    cnpj={el.cnpj}
                    name={el.name}
                    id={el.id}
                  />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid
                    container
                    direction='row'
                    justify='flex-end'
                    alignItems='center'
                  >
                    <Link
                      className='link-consulting ml-4'
                      to={`/app/pessoa-juridica/employees/${el.cnpj}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <ButtonUi
                        variant='secondary'
                        className='btn-consulting'
                        endIcon={<ChevronRight colorPrimary color="#3A10E0" />}
                        style={{ marginRight: 16 }}

                      >
                        Consultar Funcionários
                      </ButtonUi>

                    </Link>
                    <Link
                      className='link-consulting'
                      to={`/app/pessoa-juridica/consulting/${el.cnpj}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <ButtonUi
                        variant='primary'
                        className='btn-consulting'
                        endIcon={<ChevronRight colorPrimary />}
                      >
                        Consultar Empresa
                      </ButtonUi>
                    </Link>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          }),
          loading: false
        });
      } else {
        this.setState({ fetchError: true, loading: false });
      }
    });
  };

  handleClick(offset) {
    this.setState({ offset });
  }

  handleStatusFilter = event => {
    this.setState({
      status_filter: this.state.status_filter,
      offset: 0
    });
  };

  handleStatusChange = event => {
    this.setState({
      status_filter: event.target.value,
    });
  };

  render() {  
    const filteredCompanies =
      this.state.companies.length > 0
        ? this.state.companies.filter(
          el =>
            (el.props.companyname
              .toLowerCase()
              .includes(this.state.filter.toLowerCase()) ||
              el.props.cnpj.includes(
                utils.removePonctuation(this.state.filter)
              )) &&
            (this.state.status_filter === 'Todos'
              ? true
              : el.props.status === this.state.status_filter)
        )
        : [];
    return (
      <div className='app-wrapper'>
        <ContainerHeader title='Consulta PJ' />
        <Grid container spacing={16}>
          {/* Busca */}
          <Grid item xs={12}>
            <YaloSearchFilter
              onChange={e =>
                this.setState({ filter: e.target.value, offset: 0 })
              }
              value={this.state.filter}
              placeholder='Busca por nome ou CNPJ'
            />
          </Grid>
          {/* Filtros */}
          <Grid item xs={12}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>

            <FormControl>
              <SelectUi
                variant="outlined"
                value={this.state.status_filter}
                onChange={this.handleStatusChange}
                label="Status"
                margin="dense"
                name="status_filter"
                options={[
                  {
                    value: 'Todos',
                    name: 'Todos'
                  },
                  ...PJ_STATUS.map(el => (
                    {
                      value: el.VALUE,
                      name: el.VALUE
                    })
                  )
                ]
                }
              />
            </FormControl>

     {/*        
            Utilizar quando comerçamos a utilizar paginação (Segue o layout original criado pelo time de UI)
            <FormControl >
              <ButtonUi style={{marginTop: '28px'}} variant='secondary' onClick={this.handleStatusFilter} endIcon={<ChevronRight />}>
                Filtrar
              </ButtonUi>
            </FormControl> */}
         </div>

          </Grid>
          {/*  <Grid item >
            <Tooltip title="Limpar Filtros">
              <IconButton
                aria-label="Limpar Filtros"
                size="small"
                onClick={() =>
                  this.setState({
                    to: '',
                    from: '',
                    filter: '',
                    status_filter: 'Todos',
                    payment_filter: null,
                    payment_tabs: 0,
                    offset: 0
                  })
                }
              >
                <CloseIcon variant="round" />
              </IconButton>
            </Tooltip>
          </Grid> */}
        </Grid>

        {this.state.loading && (
          <div className='loader-view mt-5'>
            <CircularProgress
              mode='indeterminate'
              style={{ color: '#005DF9' }}
            />
          </div>
        )}
        {!this.state.loading && filteredCompanies.length < 1 && (
          <div className='text-center col-12 my-5'>
            <h3>Nenhum cliente encontrado.</h3>
          </div>
        )}
        {this.state.error && (
          <Grid item xs={12}>
            <Typography align='center' variant='body1'>
              Erro ao Carregar Informações.
            </Typography>
          </Grid>
        )}
        {filteredCompanies.slice(this.state.offset, this.state.offset + 14)}
        <div className='mt-4'>
          <Pagination
            size='large'
            limit={14}
            offset={this.state.offset}
            total={filteredCompanies.length}
            onClick={(e, offset) => this.handleClick(offset)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ contract }) => {
  const { selectedContract } = contract;
  return { selectedContract };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(ConsultaPJ));
