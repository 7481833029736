import React, { Component } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { utils } from '../../../utils';
import CheckCircleOutlineRounded from '@material-ui/icons/CheckCircleOutlineRounded'
import Cancel from '@material-ui/icons/CancelOutlined'
export class PJHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const styleTag = {
        boder: '2px solid green',
        marginRight: '8px',
        padding: '8px',
        borderRadius: '4px',
        fontSize: '14px',
        fontWeight: '400',
        maxHeight: '40px',
        borderRadius: '8px'
      };
    switch (this.props.status) {
      case 'Ativo':
      case 'Vinculado':
        styleTag.color = '#143E47'
        styleTag.backgroundColor = '#E5FAF4'
        break;
      case null:
      default:
        styleTag.color = "#89024C";
        styleTag.backgroundColor = "#FFE0F1";
        break;
    }
    
    return (
      <Grid direction='row' container>
        {/*Primeira Coluna*/}
        <Grid item xs={11} md={10} container direction='row'
          style={{
            display: 'flex',
            flex: 'auto',
            flexDirection: 'row',
            textAlign: 'center',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <div style={styleTag}>
            <Typography
              style={{
                display: 'flex',
                textAlign: 'center',
                fontSize: '14px',
                fontWeight: '400',
              }}
            >
              {['Vinculado', 'Ativo'].includes(this.props.status) && (
                <CheckCircleOutlineRounded style={{ marginRight: '8px' }} />
              )}
              {this.props.status == 'Não Vinculado' && (
                <Cancel style={{ marginRight: '8px' }} />
              )}

              {this.props.status === null ? 'Desligado' : this.props.status}
            </Typography>
          </div>

          <Typography
            style={{
              fontFamily: 'inter',
              fontSize: '16px',
              color: '#1A1A1A',
              fontWeight: '700',
            }}
          >
            {this.props.name}
          </Typography>
        </Grid>

        {/* Segunda Coluna */}
        <Grid item md={2} xs={1}>
          <Grid container spacing={0} direction='column'>
            {/* Metade esquerda */}
            <Grid item xs={12} md={12}>
              <Typography
                style={{ fontWeight: '400', fontSize: '14px' }}
                align='left'
              >
                CNPJ:
              </Typography>
            </Grid>
            {/* Metade direita */}
            <Grid item xs={12} md={12}>
              <Typography
                style={{
                  fontWeight: '700',
                  fontSize: '14px',
                  color: '#1A1A1A',
                }}
                align='left'
              >
                {`${utils.formatCNPJ(this.props.cnpj)}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
