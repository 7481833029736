export const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  iconSmall: {
    fontSize: '16px !important',
    margin: '0px',
  },
  rowSpacer: {
    margin: 'var(--spacing-stack-xs) 0px 0px 0px',
  },
  rowSpacer2xs: {
    margin: 'var(--spacing-stack-2xs) 0px 0px 0px',
  },
  columnSpacer: {
    margin: '0px',
    padding: '0px var(--spacing-stack-sm) 0px 0px',
  },
  lineSeparator: {
    height: '4px',
    width: '100%',
    color: 'var(--color-neutral-300)',
    backgroundColor: 'var(--color-neutral-300)',
    border: 'none',
  },
  linkCNAE: {
    color: 'var(--color-brand-primary-800)',
    '& a': {
      textDecoration: 'none',
      color: 'var(--color-brand-primary-800)',
      fontSize: '16px',
      fontFamily: theme.typography.fontFamily,
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '16px',
      textAlign: 'left',
    },
    '& a:link': {
      color: 'var(--color-brand-primary-800)',
    },
    '& a:visited': {
      color: 'var(--color-brand-primary-800)',
    },
    '& a:hover': {
      color: 'var(--color-brand-primary-800)',
      textDecoration: 'underline',
    },
    '& a:active': {
      color: 'var(--color-brand-primary-800)',
      textDecoration: 'underline',
    },
    '& a div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },
  inputTextCharLimit: {
    display: 'flex',
    gap: '4px',
    color: 'var(--color-neutral-800)',
    fontSize: '16px',
    fontFamily: theme.typography.fontFamily,
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'left',
    '& .title': {
      fontWeight: 700,
    },
    '& .value': {
      fontWeight: 400,
    },
  },
});

export const formStyles = (theme) => ({
  root: {
    padding: '0px 0px 0px 0px',
    margin: 'var(--spacing-inline-2xs)',
    width: 'auto',
    backgroundColor: theme.palette.background.default,
  },
  containerWrapper: {
    backgroundColor: theme.palette.background.paper,
    margin: 'var(--spacing-inset-2xs) 0px 0px',
    padding: 'var(--spacing-xs) var(--spacing-3xs) 32px var(--spacing-3xs)',
    width: 'auto',
    minWidth: 'auto',
    border: '1px solid transparent',
    borderRadius: 'var(--spacing-3xs)',
  },
  rowTitle: {
    alignSelf: 'center',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '26.4px',
    textAlign: 'center',
    textTransform: 'none',
    color: 'var(--color-neutral-900)',
    margin: '0px',
  },
  subTitle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '17.6px',
    textAlign: 'left',
    textTransform: 'none',
    color: 'var(--color-brand-primary-800)',
    margin: 'var(--spacing-2xs) 0px',
    padding: '0px',
  },
  checkboxSpacer: {
    padding: '0px 0px var(--spacing-xs) 0px',
  },
  iconSmall: {
    fontSize: '16px !important',
    margin: '0px',
  },
  rowSpacer: {
    margin: 'var(--spacing-stack-xs) 0px 0px 0px',
  },
  rowSpacer2xs: {
    margin: 'var(--spacing-stack-2xs) 0px 0px 0px',
  },
  columnSpacer: {
    margin: '0px',
    padding: '0px var(--spacing-stack-sm) 0px 0px',
  },
});
