import React from 'react';

function ArrowUpIcon({ className, ...props }) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={48}
        height={48}
      >
        <path
          transform="rotate(180 48 48)"
          fill="#D9D9D9"
          d="M48 48H96V96H48z"
        />
      </mask>
      <g mask="url(#a)">
        <path
          d="M24 26.4l7.8-7.8c.367-.367.834-.55 1.4-.55.567 0 1.034.183 1.4.55.367.367.55.833.55 1.4s-.183 1.033-.55 1.4l-9.2 9.2c-.4.4-.866.6-1.4.6-.533 0-1-.2-1.4-.6l-9.2-9.2c-.366-.367-.55-.833-.55-1.4s.184-1.033.55-1.4c.367-.367.834-.55 1.4-.55.567 0 1.034.183 1.4.55l7.8 7.8z"
          fill="#404040"
        />
      </g>
    </svg>
  )
}

export default ArrowUpIcon;
