import React from 'react';
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/PhoneRounded';
import MailIcon from '@material-ui/icons/EmailRounded';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    background: 'white',
    width: 'calc(100% + 26px)',
    borderRadius: '4px',
    padding: theme.spacing.unit * 2,
  },
  title: {
    fontFamily: 'Sua dr consulta',
    color: 'var(--color-brand-primary-900)',
    textAlign: 'left',
    marginBottom: theme.spacing.unit * 2,
  },
  listItem: {
    marginBottom: theme.spacing.unit,
  },
  listItemIcon: {
    color: 'var(--color-brand-primary-900)',
  },
  itemContainer: {
    padding: theme.spacing.unit * 2,
  },
});

const Contact = ({ classes }) => (
  <Grid container className={classes.container}>
    <Grid item xs={8} >
      <Typography variant='h1' className={classes.title}>
        Contato
      </Typography>
      <List disablePadding>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.listItemIcon}>
            <MailIcon />
          </ListItemIcon>
          <ListItemText primary={'empresas@drconsulta.com'} />
        </ListItem>
      </List>
    </Grid>
    <Grid item xs={4} className={classes.itemContainer} />
  </Grid>
);

export default withStyles(styles)(Contact);
