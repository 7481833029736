import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { modalEmitAlert, modalAlertShowLoading } from '../../../../../actions';
import { SUCCESS, ERROR } from '../../../../../constants/ActionTypes';
import { withRouter } from 'react-router-dom';
import CustomDialog from '../../../../../components/ui/Dialog/customDialog.js'

import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Tabs,
  Tab,
  Button,
  Typography,
  CircularProgress,
  Grid,
  Paper,
  FormControl,
  Input,
  MenuItem,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';
import adminIds from '../../../../../helpers/admins.enums';

import { utils } from '../../../utils';

import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { contractService } from '../../../../../services/contractService';
import ContractData from './subcomponents/ContractData';
import CompanyRelationsList from './subcomponents/CompanyRelationsList';
import StatusLog from './subcomponents/StatusLog';
import CreditManagement from './subcomponents/CreditManagement';
import { ChevronLeftRounded, ErrorOutlineOutlined, AttachMoneyRounded, ChevronRightRounded, CloseRounded } from '@material-ui/icons';
import ButtonUi from '../../../../../components/ui/button/Button'
import SelectUi from '../../../../../components/ui/select/index.js'
import InputUi from '../../../../../components/ui/TextField/index.js'


moment.locale('pt_BR');
var ContractIcon = () => <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 -960 960 960" width="24px" fill="#FFF"><path d="M240-80q-50 0-85-35t-35-85v-40q0-33 23.5-56.5T200-320h40v-480q0-33 23.5-56.5T320-880h440q33 0 56.5 23.5T840-800v600q0 50-35 85t-85 35H240Zm480-80q17 0 28.5-11.5T760-200v-600H320v480h280q33 0 56.5 23.5T680-240v40q0 17 11.5 28.5T720-160ZM400-600q-17 0-28.5-11.5T360-640q0-17 11.5-28.5T400-680h280q17 0 28.5 11.5T720-640q0 17-11.5 28.5T680-600H400Zm0 120q-17 0-28.5-11.5T360-520q0-17 11.5-28.5T400-560h280q17 0 28.5 11.5T720-520q0 17-11.5 28.5T680-480H400ZM240-160h360v-80H200v40q0 17 11.5 28.5T240-160Zm0 0h-40 400-360Z" /></svg>
var PersonEditRounded = () => <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#3A10E0"><path d="M480-240Zm-320 40v-72q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q37 0 73 4.5t72 14.5l-67 68q-20-3-39-5t-39-2q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32h240v80H200q-17 0-28.5-11.5T160-200Zm400 40v-50q0-16 6.5-30.5T584-266l197-197q9-9 20-13t22-4q12 0 23 4.5t20 13.5l37 37q8 9 12.5 20t4.5 22q0 11-4 22.5T903-340L706-143q-11 11-25.5 17t-30.5 6h-50q-17 0-28.5-11.5T560-160Zm300-223-37-37 37 37ZM620-180h38l121-122-18-19-19-18-122 121v38Zm141-141-19-18 37 37-18-19ZM480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-80q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Z" /></svg>



class DetalhesContrato extends Component {
  state = DEFAULT_CONTRACT_STATE;

  componentDidMount() {
    this.refreshDetalhesContrato(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.refreshDetalhesContrato(nextProps);
  }

  refreshDetalhesContrato(props) {
    contractService
      .getContractById(props.match.params.id)
      .then(response => this.handleResponse(response));

    if (props.location.redirect) {
      this.setState({ tabIndex: 1 });
    }

    this.handleFichaProposta(props);
  }

  handleFichaProposta = props => {
    this.setState({ loadingProposalFile: true });

    contractService
      .getFichaProposta(props.match.params.id)
      .then(response => {
        this.handleFicha(response);
        this.setState({ loadingProposalFile: false });
      })
      .catch(error => {
        error.json().then(data => {
          this.handleFicha(data.success);
          console.error(data.message);
        });
        this.setState({ loadingProposalFile: false });
      });
  };

  handleFicha = response => {
    this.setState({ downloadUrl: response });
  };

  handleResponse = response => {
    if (response.data.success) {
      this.setState({
        loading: false,
        contract: response.data.response,
        contract_active_status_id: response.data.response.contract_status_id
      });
    }
  };

  totalValue = contract => {
    let qtdPlans = _.reduce(
      contract.contract_plans,
      (acc, plan) => plan.qtd_plans + acc,
      0
    );
    let totalPlans = _.reduce(
      contract.contract_plans,
      (acc, plan) => plan.negotiated_price * plan.qtd_plans + acc,
      0
    );
    let totalTaxes = _.reduce(
      contract.contract_taxes,
      (acc, tax) => _.toNumber(tax.value) * qtdPlans + acc,
      0
    );

    return totalPlans + totalTaxes;
  };

  totalTax = contract => {
    let qtdPlans = _.reduce(
      contract.contract_plans,
      (acc, plan) => plan.qtd_plans + acc,
      0
    );

    let totalTaxes = _.reduce(
      contract.contract_taxes,
      (acc, tax) => _.toNumber(tax.value) * qtdPlans + acc,
      0
    );

    return totalTaxes;
  };

  setDefaultDate = () => {
    let date = new Date();
    date = new Date(date.getTime());

    const _formattedDate =
      `${date.getFullYear()}-` +
      `0${date.getMonth() + 1}-`.slice(-3) +
      `0${date.getDate()}`.slice(-2);

    return _formattedDate;
  };

  handleTab = (event, tabIndex) => {
    if (localStorage.getItem('usertype') === 'sales' && tabIndex === 2)
      tabIndex = 3;
    this.setState({ tabIndex });
  };

  setStatus = responses => {
    responses.statusList.forEach(status => {
      if (status.status === 'Aprovado') {
        this.setState({ aproved_id: status.id });
      }
    });
    this.setState({
      statusFetched: true,
      statusLogs: responses.logs,
      statusList: responses.statusList
    });
  };

  setCredit = response => {
    this.setState({
      creditFetched: true,
      creditLogs: response
    });
  };

  filterStatusList = () => {
    const userId = localStorage.getItem('userid');
    /*
      Only this users in the adminIds constant can approve a contract
    */
    const isUserAllowedToApprove = adminIds.includes(userId);
    return this.state.statusList.filter(status => {
      const isDifferentOfContractCurrentlyStatus = status.id !== this.state.contract_active_status_id;
      const isActiveStatusButContractStillNotApproved = status.status === 'Ativo' && !this.state.statusLogs.some(log => log.new_status_id === this.state.aproved_id);
      let onlyShowApprovedToCertainUsersIfContractIsPending;

      if (isActiveStatusButContractStillNotApproved) {
        return false;
      }
      else if (
        this.state.contract.contract_status_name === 'Pendente de Aprovação'
      ) {
        onlyShowApprovedToCertainUsersIfContractIsPending =
          status.id === this.state.aproved_id ? isUserAllowedToApprove : true;
      } else {
        onlyShowApprovedToCertainUsersIfContractIsPending =
          status.id !== this.state.aproved_id;
      }

      return (
        isDifferentOfContractCurrentlyStatus && onlyShowApprovedToCertainUsersIfContractIsPending
      );
    });
  };

  handleStatusChange = () => {
    this.setState({ dialog: { ...this.state.dialog, loading: true } });
    const date = new Date();
    const contract = {
      contract_status_id: this.state.contract.contract_status_id,
      is_payment_done: this.state.isPaymentDone,
      id: this.props.match.params.id,
      justification: this.state.justification,
      effective_date: moment(date).format('YYYY-MM-DD')
    };
    if (this.state.contract.contract_status_id === this.state.aproved_id) {
      contract.expiration_date = this.state.contract.expiration_date;
    }

    this.props.modalAlertShowLoading();
    this.setState({
      dialog: {
        ...this.state.dialog,
        loading: false,
        completed: true,
        open: false
      }
    });
    contractService.updateContractStatus(contract).then(response => {
      if (response.status === 200) {
        this.setState({
          dialog: {
            ...this.state.dialog,
            loading: false,
            completed: true,
            open: false
          }
        });
        this.props.modalEmitAlert('Status atualizado com sucesso.', SUCCESS);
      } else {
        let msgApi =
          'Erro ao atualizar status. Motivo: ' + response.data.message[0];
        this.setState({
          dialog: {
            ...this.state.dialog,
            loading: false,
            completed: true,
            open: false
          }
        });
        this.props.modalEmitAlert(msgApi, ERROR);
      }
    });
  };

  onClose = () => {
    this.setState({
      justification: '',
      dialog: {
        ...this.state.dialog,
        completed: false,
        open: false
      }
    });
    this.refreshLogs();
  };

  refreshLogs = () => {
    this.setState({ loading: true });
    contractService.getStatusLogs(this.props.match.params.id).then(response => {
      if (response.status === 200) {
        this.setState({ statusLogs: response.data.response, loading: false });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  handlePaymentDone = () => {
    const { isPaymentDone } = this.state;
    this.setState({ isPaymentDone: !isPaymentDone });
  };

  styles = {
    tablleCellHeader: {
      fontSize: 16,
      color: '#707070',
      fontFamily: 'Sua dr consulta',
      borderBottom: '1px solid #707070',
      fontWeight: 700
    },
    tableCell: {
      fontFamily: 'Inter',
      fontSize: 14,
      color: '#707070',
      fontWeight: 400
    },
  }

  render() {
    const { contract, loading, tabIndex, total, isPaymentDone } = this.state;
    const stylesTab = { fontFamily: 'Sua dr consulta', fontWeight: 400, fontSize: 16, color: '#1A1A1A' }
    return (
      <div className='app-wrapper'>
        <Grid container justify='center' alignItems='center'>
          <Grid container justify='space-between' spacing={0}>
            <Grid item>
              <Typography variant='h1' paragraph>
                Contratos
              </Typography>
            </Grid>
            <Grid item>
              <Link
                to={{
                  pathname: '/app/contratos/consulta-contrato',
                  filter: {
                    func: this.props.location.filter,
                    hasFilter: this.props.location.filter ? true : false
                  }
                }}
                style={{ textDecoration: 'none' }}
              >
                <ButtonUi variant='link' startIcon={<ChevronLeftRounded />}>
                  Voltar
                </ButtonUi>
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper
              style={{ paddingTop: 8, paddingBottom: 8, marginBottom: 48 }}
            >
              {loading ? (
                <Grid container justify='center'>
                  <CircularProgress
                    mode='indeterminate'
                    style={{ color: '#3A10E0', margin: 50 }}
                  />
                </Grid>
              ) : (
                <Grid item style={{ marginBottom: 20, marginLeft: '10px' }}>

                  <Grid justify='start' style={{ marginLeft: '36px' }}>
                    <Typography
                      align='start'
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '24px',
                        fontWeight: 700,
                        color: '#384459'
                      }}
                      variant='h1'
                      color='secondary'
                    >
                      {contract.name}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontWeight: '400',
                        color: 'rgba(56, 68, 89, 0.87)',
                      }}>
                      Empresa: {contract.contracting_company_name}
                    </Typography>
                  </Grid>


                  <Grid container justify='center' item xs={12}>
                    <Tabs
                      value={tabIndex}
                      onChange={this.handleTab}
                      variant='fullWidth'
                      style={{ borderBottom: '1px solid #1A1A1A', width: '100%', marginTop: 26, marginRight: 26 }}
                    >
                      <Tab
                        label='Dados do contrato'
                        style={{
                          ...stylesTab,
                          backgroundColor: tabIndex === 0 ? '#3A10E0' : 'transparent',
                          color: tabIndex === 0 ? '#FFF' : 'inherit',
                        }}
                      />
                      <Tab
                        label='Empresas relacionadas'
                        style={{
                          ...stylesTab,
                          backgroundColor: tabIndex === 1 ? '#3A10E0' : 'transparent',
                          color: tabIndex === 1 ? '#FFF' : 'inherit',
                        }}
                      />
                      {localStorage.getItem('usertype') !== 'sales' && (
                        <Tab
                          label='Status'
                          style={{
                            ...stylesTab,
                            backgroundColor: tabIndex === 2 ? '#3A10E0' : 'transparent',
                            color: tabIndex === 2 ? '#FFF' : 'inherit',
                          }}
                        />
                      )}
                      <Tab
                        label='Conta'
                        style={{
                          ...stylesTab,
                          backgroundColor: tabIndex === 3 ? '#3A10E0' : 'transparent',
                          color: tabIndex === 3 ? '#FFF' : 'inherit',
                        }}
                      />
                    </Tabs>
                    <Divider />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ padding: 16, paddingTop: 36, paddingLeft: 36 }}
                  >
                    {tabIndex === 0 && <ContractData contract={contract} />}
                    {tabIndex === 1 && (
                      <CompanyRelationsList
                        relations={contract.company_relations}
                        id={this.props.match.params.id}
                      />
                    )}

                    {/* sales dont see status tab */}
                    {tabIndex === 2 &&
                      localStorage.getItem('usertype') !== 'sales' && (
                        <StatusLog
                          fetched={this.state.statusFetched}
                          logs={this.state.statusLogs}
                          setStatus={this.setStatus}
                          id={this.props.match.params.id}
                          statusList={this.state.statusList}
                          activeStatus={
                            this.state.contract.contract_status_name
                          }
                        />
                      )}

                    {tabIndex === 3 && (
                      <CreditManagement
                        fetched={this.state.creditFetched}
                        logs={this.state.creditLogs}
                        setCredit={this.setCredit}
                        id={this.props.match.params.id}
                        balance={contract.credit_balance}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                spacing={0}
                direction='row'
                justify='center'
                alignItems='center'
                style={{ marginTop: 16 }}
              >
                <Grid item>
                  <Link
                    to={`/app/contratos/consulta-contrato/`}
                    style={{ textDecoration: 'none', margin: 16 }}
                  >
                    <ButtonUi variant='link' startIcon={<ChevronLeftRounded />} style={{ marginRight: '16px' }}>
                      Voltar
                    </ButtonUi>


                  </Link>
                </Grid>

                <ButtonUi
                  variant='primary'
                  size='md'
                  isDisabled={
                    this.state.loadingProposalFile || !this.state.downloadUrl
                  }
                  download={
                    this.state.downloadUrl &&
                    `FichaProposta-AnexoIII-${contract.contracting_company_name}`
                  }
                  href={this.state.downloadUrl && this.state.downloadUrl}
                >
                  {this.state.loadingProposalFile ? (
                    <Fragment>
                      BUSCANDO FICHA PROPOSTA
                      <CircularProgress size={25} style={{ marginLeft: 8 }} />
                    </Fragment>
                  ) : this.state.downloadUrl ? (
                    <Fragment>
                      FICHA PROPOSTA
                      <ContractIcon style={{ marginLeft: 8 }} />
                    </Fragment>
                  ) : (
                    <Fragment>
                      FICHA PROPOSTA INDISPONÍVEL
                      <ErrorOutlineOutlined style={{ marginLeft: 8 }} />
                    </Fragment>
                  )}
                </ButtonUi>
                {this.state.tabIndex === 2 && (
                  <ButtonUi variant='secondary' endIcon={<PersonEditRounded />}
                    style={{ marginLeft: 8 }}
                    onClick={() => {
                      this.setState({
                        dialog: { ...this.state.dialog, open: true }
                      });
                    }}>
                    Editar
                  </ButtonUi>

                )}

                {this.state.tabIndex === 3 && (
                  <Link
                    to={`/app/contratos/adicionar-credito/${this.props.match.params.id}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <ButtonUi
                      color='primary'
                      variant='secondary'
                      style={{ margin: 16 }}
                      endIcon={<AttachMoneyRounded />}
                    >
                      Adicionar Crédito
                    </ButtonUi>
                  </Link>
                )}

                {this.state.tabIndex === 4 && (
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => {
                      this.setState({
                        dialog: { ...this.state.dialog, open: true }
                      });
                    }}
                    style={{ margin: 16 }}
                  >
                    Editar
                  </Button>
                )}

                {this.state.tabIndex !== 2 &&
                  this.state.tabIndex !== 3 &&
                  this.state.tabIndex !== 4 && (
                    <Link
                      to={{
                        pathname: `/app/contratos/edicao-contrato/${this.props.match.params.id}`,
                        details: true,
                        filter: this.props.location.filter
                      }}
                      style={{ textDecoration: 'none' }}
                    >

                      <ButtonUi variant='secondary' endIcon={<PersonEditRounded />} style={{ marginLeft: '16px' }}>
                        Editar
                      </ButtonUi>

                    </Link>
                  )}

                {/* </Link> */}
              </Grid>
            </Paper>
          </Grid>

          <CustomDialog
            maxWidth={this.state.showDetail ? 'xl' : 'sm'}
            open={this.state.dialog.open}
            onClose={() =>
              !this.state.dialog.completed
                ? this.setState({
                  dialog: { ...this.state.dialog, open: false }
                })
                : this.onClose()
            }
          >
            <DialogContent style={{
              width: this.state.showDetail ? 1180 : 370,
              padding: 32,
              paddingBottom: 24,
            }} >
              <Grid container style={{
                display: 'flex',
                justifyContent: 'space-between',
              }} >
                <Grid container   >
                  <Grid item xs={this.state.showDetail ? 4 : 12}>
                    <Typography style={{
                      fontFamily: 'Sua dr consulta',
                      fontWeight: '700',
                      fontSize: '32px',
                      color: '#000',
                    }}>
                      ATUALIZAR STATUS
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  xs={this.state.showDetail ? 4 : 12}
                  container

                >
                  {this.state.dialog.loading ? (
                    <Grid container justify='center'>
                      <CircularProgress
                        mode='indeterminate'
                        style={{ color: '#3A10E0', margin: 50 }}
                      />
                    </Grid>
                  ) : this.state.dialog.completed ? (
                    <Grid item xs={12}>
                      <Typography variant='subtitle1'>
                        {this.state.dialog.message}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      justify='center'
                      alignItems='center'
                    >

                      <Grid item xs={12}>
                        <FormControl fullWidth margin='normal'>
                          <SelectUi
                            value={contract.contract_status_id}
                            onChange={e => {
                              if (e.target.value === this.state.aproved_id) {
                                this.setState({
                                  ...this.state,
                                  justification:
                                    this.state.statusList.filter(
                                      status => status.id === e.target.value
                                    )[0].standard_justification || '',
                                  contract: {
                                    ...this.state.contract,
                                    contract_status_id: e.target.value,
                                    expiration_date: this.setDefaultDate()
                                  }
                                });
                              } else {
                                this.setState({
                                  ...this.state,
                                  justification:
                                    this.state.statusList.filter(
                                      status => status.id === e.target.value
                                    )[0].standard_justification || '',
                                  contract: {
                                    ...this.state.contract,
                                    contract_status_id: e.target.value
                                  }
                                });
                              }
                            }}
                            label="Status"
                            options={this.filterStatusList().map(el => ({
                              value: el.id,
                              name: el.status,
                            }))}
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        justify='left'
                        className='mt-2'
                      >
                        <ButtonUi
                          variant='link'
                          style={{ marginBottom: 24, marginTop: 24, color: '#1A1A1A', borderColor: '#1A1A1A' }}
                          onClick={() =>
                            this.setState({
                              showDetail: !this.state.showDetail
                            })
                          }
                        >
                          Detalhes <IconKeyboardArrowRight />
                        </ButtonUi>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth margin='normal'>
                          <InputUi
                            small={true}
                            label='Motivo'
                            name='justification'
                            value={this.state.justification}
                            onChange={e =>
                              this.setState({
                                justification: e.target.value
                              })
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                {this.state.showDetail && (
                  <Grid
                    style={{ 
                      width: '705px' 
                    }}
                  >
                    <Grid >
                      {/* Mensalidades */}

                      <Table padding='none'>
                        <TableHead>
                          <TableRow>
                            <TableCell style={this.styles.tablleCellHeader} />
                            <TableCell style={this.styles.tablleCellHeader} >Nome</TableCell>
                            <TableCell style={this.styles.tablleCellHeader}  >Valor unitário</TableCell>
                            <TableCell style={this.styles.tablleCellHeader}  >Quatidade</TableCell>
                            <TableCell style={this.styles.tablleCellHeader}  >Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {_.map(contract.contract_plans, (plan, index) => (
                            <TableRow key={index}>
                              <TableCell
                                style={{
                                  ...this.styles.tablleCellHeader,
                                  borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                  paddingLeft: 10
                                }}
                                rowSpan={plan.length}
                              >
                                {' '}
                                Mensalidades{' '}
                              </TableCell>
                              <TableCell style={this.styles.tableCell} >{plan.name_description}</TableCell>
                              <TableCell style={this.styles.tableCell} >{`R$ ${plan.negotiated_price}`}</TableCell>
                              <TableCell style={this.styles.tableCell} >{plan.qtd_plans}</TableCell>
                              <TableCell style={this.styles.tableCell} >{`R$ ${utils.formatCurrency(
                                _.toNumber(plan.negotiated_price) *
                                plan.qtd_plans
                              )}`}</TableCell>
                            </TableRow>
                          ))}
                          {_.map(contract.contract_taxes, (tax, index) => (
                            <TableRow key={index}>
                              <TableCell
                                style={{
                                  ...this.styles.tableCell,
                                  borderBottom: 'none',
                                  paddingLeft: 10
                                }}
                                rowSpan={tax.length}
                              >
                                {' '}
                                Taxas{' '}
                              </TableCell>
                              <TableCell style={{ ...this.styles.tableCell }} >{tax.description}</TableCell>
                              <TableCell style={this.styles.tableCell} >{`R$ ${utils.formatCurrency(
                                tax.value
                              )}`}</TableCell>
                              <TableCell></TableCell>
                              <TableCell style={this.styles.tableCell} >{`R$ ${utils.formatCurrency(
                                this.totalTax(contract)
                              )}`}</TableCell>
                            </TableRow>
                          ))}
                          <TableRow selected>
                            <TableCell rowSpan='1' style={{ paddingLeft: 10 }}>
                              {' '}
                              <Typography style={{ ...this.styles.tablleCellHeader, border: 'none' }} >Valor total</Typography>{' '}
                            </TableCell>
                            <TableCell colSpan='3' />
                            <TableCell >
                              <Typography style={{ ...this.styles.tablleCellHeader, border: 'none' }} >
                                {`R$ ${utils.formatCurrency(
                                  this.totalValue(contract)
                                )}`}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <DialogActions style={{ paddingRight: this.state.showDetail ? 0 : 16, paddingBottom: 67 }}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignContent: 'center',
                  alignItems: 'center'
                }}>
                  <ButtonUi
                    variant='link'
                    style={{ marginRight: '16px' }}
                    onClick={() =>
                      this.setState({
                        dialog: { ...this.state.dialog, open: false }
                      })
                    }
                    startIcon={<CloseRounded />}
                  >
                    FECHAR
                  </ButtonUi>
                  <ButtonUi
                    size='md'
                    disabled={this.state.justification === ''}
                    variant='primary'
                    onClick={this.handleStatusChange}
                    endIcon={<ChevronRightRounded />}
                  >
                    OK
                  </ButtonUi>
                </div>
              </DialogActions>
            </DialogContent>

          </CustomDialog>
        </Grid>
      </div>
    );
  }
}

const DEFAULT_CONTRACT_STATE = {
  loading: true,
  loadingProposalFile: true,
  contract: {},
  isPaymentDone: true,
  tabIndex: 0,
  statusLogs: [],
  statusFetched: false,
  statusList: [],
  downloadUrl: '',
  justification: '',
  dialog: { open: false, message: '', loading: false, completed: false },
  creditLogs: [],
  creditFetched: false,
  backoffice: false,
  aproved_id: '',
  showDetail: false,
  total: 0,
  qtdPlans: 0
};

export default connect(null, { modalAlertShowLoading, modalEmitAlert })(
  withRouter(DetalhesContrato)
);
