import React, { Component } from 'react';
import ContainerHeader from '../../../../../components/ContainerHeader';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  CircularProgress,
  withStyles,
  Divider,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import { utils } from '../../../../../app/routes/utils';

import moment from 'moment';
import Pagination from 'material-ui-flat-pagination';
import YaloSearchFilter from '../../../../../components/YaloSearchFilter';
import { ordersService } from '../../../../../services/ordersService';
import ExpansionPanelSummaryYaloSubscription from '../../../../../componentsPortal/ExpansionPanelYalo/ExpansionPanelSummaryYaloSubscription';
import TableEmployeeSubscription from '../../../../../componentsPortal/Tables/TableEmployeeSubscription';
import OrderStatusSelect from '../../../../../componentsPortal/Selects/OrderStatusSelect';

import ArrowForward from '@material-ui/icons/ChevronRight';
import Button from '../../../../../components/ui/button/Button';

moment.locale('pt_BR');

const styles = (theme) => ({
  panel: {
    border: 'none',
    margin: 0,
    padding: 0,
  },
  content: {
    margin: '0px !important',
    padding: 0,
  },
  container: {
    backgroundColor: '#FFFFFF',
    border: 'none',
    margin: '24px 0px',
    padding: '12px 16px',
    borderRadius: 16,
  },
  customExpandIcon: {
    top: '12%',
    padding: 8,
    right: 0,
  },
  customDivider: {
    height: 4,
    backgroundColor: 'var(--color-neutral-300)',
    marginBottom: 24,
  }
});

function CustomExpansionPanel({ classes, children, summaryData, ...props }) {
  return (
    <ExpansionPanel {...props} className={classes.container}>
      {summaryData.col3_row2_data !== 'R$ 0,00' && (
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ root: classes.panel, content: classes.content, expandIcon: classes.customExpandIcon }}
        >
          <ExpansionPanelSummaryYaloSubscription
            {...summaryData}
            style={{ margin: 0, padding: 0, backgroundColor: 'blue' }}
          />
        </ExpansionPanelSummary>
      )}
      <Divider variant="fullWidth" className={classes.customDivider} />
      {children}
    </ExpansionPanel>
  );
}

const StyledExpansionPanel = withStyles(styles)(CustomExpansionPanel);

class EmployeesSubscriptions extends Component {
  state = {
    monthlyResumed: [],
    filter: '',
    dialog: { open: false, message: '', loading: false, confirmation: true },
    loading: true,
    offset: 0,
    status_filter: 'Todos',
    from: '',
    to: '',
  };
  text = {
    info: '',
    label: '',
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.selectedContract.id !== nextProps.selectedContract.id)
      this.getMonthlyResumed(nextProps.selectedContract);
  };

  getMonthlyResumed = (selectedContract) => {
    if (!selectedContract.id) {
      this.setState({ loading: false });
      return;
    }

    this.setState({ loading: true });
    ordersService.getAllMonthlyResumed(selectedContract.id).then((response) => {
      if (response && !response.response) {
        this.setState({ loading: false });
        return;
      }

      this.setState({
        monthlyResumed: response.response.map((el) => {
          let item_status_color = utils.orderStatusColor(el.order_status);
          let item_status_icon = utils.orderStatusIcon(el.order_status);

          let summaryData = {
            item_color: item_status_color,
            item_icon: item_status_icon,
            is_payment_done: el.is_payment_done,
            col1_row1_data: _.capitalize(moment(el.created_at).format('MMMM')),
            col1_row2_data: el.order_status,
            col1_row3_data:
              el.benefits_started_immediately === true ? 'Sim' : 'Não',
            col1_row4_data: utils.momentFromDate(el.payment_date),

            col2_row1_data: utils.momentFromDate(el.created_at),
            col2_row2_data: el.total_plans,
            col2_row3_data: 'R$ ' + utils.formatCurrency(el.value),

            col3_row1_data: el.id_pre_nf || '--',
            col3_row2_data: el.id_nf_sap || '--',
            col3_row3_data: el.is_payment_done === true ? 'Pago' : 'Pendente',
            col3_row4_data:
              el.benefits_started_immediately === true
                ? utils.momentFromDate(el.created_at)
                : utils.momentFromDate(el.payment_date),
          };

          let tableData = {
            table_title: 'Mensalidades',
            rows: [
              {
                col1: 'Novos',
                col2: '--',
                col3: el.total_new_employees || '--',
                col4:
                  el.total_new_employees &&
                  el.total_value_plans / el.total_plans,
              },
              {
                col1: 'Desligamentos',
                col2: '--',
                col3: el.total_terminations || '--',
                col4: '--',
              },
              {
                col1: 'Recorrentes',
                col2: '--',
                col3: el.total_recharges || '--',
                col4: '--',
              },
              {
                col1: 'Mensalidade',
                col2: '--',
                col3: el.total_plans,
                col4: el.total_value_plans,
              },
            ],
          };

          let taxes = el.contract_taxes.map((tax) => {
            return {
              col1: tax.description,
              col2: 'R$ ' + utils.formatCurrency(tax.value),
              col3: tax.quantity,
              col4: tax.total_value,
            };
          });

          taxes.push({
            col1: 'Mensalidade',
            col2: '--',
            col3: '--',
            col4: 'R$ ' + utils.formatCurrency(el.total_value_taxes),
          });

          let tableTaxes = {
            table_title: 'Taxas',
            rows: taxes,
          };

          return (
            <StyledExpansionPanel
              contractname={el.name}
              contract_company_name={el.contract_company_name}
              cnpj={el.company_cnpj}
              status={el.order_status}
              status_name={el.order_status_name}
              created_at={el.created_at}
              charge_due_date={el.charge_due_date}
              key={el.id}
              className={item_status_color}
              summaryData={summaryData}
            >
              <ExpansionPanelDetails style={{ margin: 0, padding: 0 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <TableEmployeeSubscription {...tableData} />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 16 }}>
                    {el.contract_taxes.length > 0 && (
                      <TableEmployeeSubscription {...tableTaxes} />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={0}
                      justify='flex-end'
                      alignItems='center'
                      style={{ marginTop: 16 }}
                    >
                      <Link
                        to={{
                          pathname: `/app/mensalidade/${el.id}`,
                          // filter: this.filterToProps
                        }}
                        style={{ textDecoration: 'none' }}
                      >
                        <Button variant='primary' size='md' color='primary'>
                          Detalhes
                          <ArrowForward />
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </StyledExpansionPanel>
          );
        }),
        loading: false,
      });
    });
  };

  componentDidMount() {
    if (this.props.location.filter && this.props.location.filter.hasFilter) {
      this.setState({ ...this.state, ...this.props.location.filter.func() });
    }

    this.getMonthlyResumed(this.props.selectedContract);
  }

  filterToProps = () => {
    return {
      from: this.state.from,
      to: this.state.to,
      filter: this.state.filter,
      status_filter: this.state.status_filter,
      // status_tabs: this.state.status_tabs
    };
  };

  formatDate = (date) => {
    if (date === 'Invalid date') {
      return 'N/A';
    } else {
      return date;
    }
  };

  handleClick(offset) {
    this.setState({ offset });
  }

  filterDate = (date) => {
    let filter = true;
    const dateUnix = new Date(moment(date).format('YYYY-MM-DD')).getTime();
    if (this.state.to) {
      const toUnix = new Date(this.state.to).getTime();
      if (dateUnix > toUnix) filter = false;
    }

    if (this.state.from) {
      const fromUnix = new Date(this.state.from).getTime();
      if (dateUnix < fromUnix) filter = false;
    }
    return filter;
  };

  handleStatusFilter = (event) => {
    this.setState({
      status_filter: event.target.value,
      offset: 0,
    });
  };

  render() {
    const { selectedContract, selectedContractLoading } = this.props;
    const isLoading = this.state.loading || selectedContractLoading;
    const filteredMonthlyResumed =
      this.state.monthlyResumed.length > 0
        ? this.state.monthlyResumed.filter((el) => {
            return (
              (el.props.contract_company_name
                .toLowerCase()
                .includes(this.state.filter.toLowerCase()) ||
                el.props.cnpj.includes(
                  this.state.filter.replace(/[./-]/g, ''),
                )) &&
              (this.state.status_filter === 'Todos'
                ? true
                : this.state.status_filter.includes(el.props.status_name)) &&
              this.filterDate(el.props.signature_date)
            );
          })
        : [];

    return (
      <div className='app-wrapper'>
        <ContainerHeader title='Mensalidades' style={{ lineHeight: 42 }} />
        <Grid container alignItems='center'>
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            style={{ margin: '12px 0px' }}
          >
            {/* Busca */}
            <Grid item xs={12} md={12}>
              <YaloSearchFilter
                onChange={(e) =>
                  this.setState({ filter: e.target.value, offset: 0 })
                }
                value={this.state.filter}
                placeholder='Busca por nome ou CNPJ'
              />
            </Grid>
          </Grid>
          {/* Filtros */}
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
          >
            <Grid item xs={10} md={10} style={{ margin: 0 }}>
              <OrderStatusSelect
                value={this.state.status_filter}
                onChange={this.handleStatusFilter}
                label='Status'
              />
              {/* Limpar */}
              <Tooltip
                title='Limpar Filtros'
                style={{ marginTop: 12, marginLeft: 5 }}
              >
                <IconButton
                  aria-label='Limpar Filtros'
                  size='small'
                  onClick={() =>
                    this.setState({
                      to: '',
                      from: '',
                      filter: '',
                      status_filter: 'Todos',
                      // status_tabs: 0,
                      offset: 0,
                    })
                  }
                >
                  <CloseIcon variant='round' />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {/* loading */}
        {selectedContract.id && isLoading && (
          <Grid item xs={12} style={{ marginTop: 32 }}>
            <Grid container justify='center'>
              <CircularProgress
                mode='indeterminate'
                style={{ color: '#071060' }}
              />
            </Grid>
          </Grid>
        )}

          {(!selectedContract.id && !isLoading) && this.state.monthlyResumed.length == 0 && (
          <Grid item xs={12} style={{ padding: 32 }}>
            <Typography variant='h3' align='center'>
              Selecione um contrato para visualizar as mensalidades.
            </Typography>
          </Grid>
        )}
        {(selectedContract.id && !isLoading && this.state.monthlyResumed.length == 0) && (
          <Grid item xs={12} style={{ padding: 32 }}>
            <Typography variant='h3' align='center'>
              Nenhuma mensalidade encontrada.
            </Typography>
          </Grid>
        )}

        {!isLoading && filteredMonthlyResumed.slice(
          this.state.offset,
          this.state.offset + 14,
        )}
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <Pagination
            size='large'
            limit={14}
            offset={this.state.offset}
            total={filteredMonthlyResumed.length}
            onClick={(e, offset) => this.handleClick(offset)}
          />
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ contract }) => {
  const { selectedContract, loading: selectedContractLoading } = contract;
  return { selectedContract };
};

export default connect(mapStateToProps, {})(withRouter(EmployeesSubscriptions));
