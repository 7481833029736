import React, { Fragment } from 'react';
import { Grid, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowForward from '@material-ui/icons/ChevronRight';
import ArrowBack from '@material-ui/icons/ChevronLeft';

import Button from '../../../../../components/ui/button/Button';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: 'var(--spacing-stack-2xs) 0px var(--spacing-stack-xl) 0px',
    margin: '0px',
  },
  row: {
    margin: '0px',
    padding: '0px',
    gap: 'var(--spacing-stack-2xs)',
  },
});
function Footer({
  activeStep,
  handleBack,
  handleNext,
  steps,
  classes,
  hasErrors,
}) {
  const buttonBack = 'Voltar';
  const buttonNext =
    activeStep === steps.length - 1 ? 'Finalizar' : 'Continuar';

  return (
    <Grid
      container
      direction='column'
      justify='flex-start'
      alignItems='flex-start'
      spacing={0}
      className={classes.root}
    >
      <Grid
        container
        item
        direction='row'
        justify='center'
        alignItems='center'
        spacing={0}
        className={classes.row}
      >
        <Button
          isDisabled={activeStep === 0}
          onClick={() => handleBack()}
          color='primary'
          variant='link'
          size='sm'
        >
          <ArrowBack />
          {buttonBack}
        </Button>
        <Button
          variant='primary'
          size='md'
          color='primary'
          onClick={() => handleNext()}
          isDisabled={hasErrors}
        >
          {buttonNext}
          <ArrowForward />
        </Button>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(Footer);
