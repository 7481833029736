import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  textField: {
    width: '100%',
    height: '66px',
    borderRadius: '35px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    '&:hover': {
      backgroundColor: '#FFFFFF', 
    },
    '& .MuiInputBase-root': {
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
    },
  },
  input: {
    padding: '0px',
    textAlign: 'start',
    marginLeft: '20px',
    '&::placeholder': {
      color: '#071060',
      fontSize: '14px',
      fontWeight: 700,
      fontFamily: 'Sua dr consulta',
      opacity: 1,
    },

    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      border: 'none',
    },
  },
  inputAdornment: {
    marginRight: '20px',
    backgroundColor: '#EBEBEB',
    width: '40px',
    height: '40px',
    paddingLeft: '5px',
    borderRadius: '8px',

    '&:hover': {
      backgroundColor: '#EBEBEB',
    },
  },
  searchIcon: {
    color: '#071060',

    '&:hover': {
      color: '#071060',
    },
  },
});

class YaloSearchFilter extends React.Component {
  render() {
    const { classes, onChange, value, placeholder } = this.props;

    return (
      <TextField
        type='search'
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        fullWidth
        variant='filled'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end' className={classes.inputAdornment}>
              <SearchIcon className={classes.searchIcon} />
            </InputAdornment>
          ),
          disableUnderline: true,
          classes: { root: classes.textField, input: classes.input },
        }}
      />
    );
  }
}

export default withStyles(styles)(YaloSearchFilter);
