import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../components/Header';
import SideNav from '../containers/SideNav/index';

import asyncComponent from '../util/asyncComponent';
import PessoaJuridica from './routes/PessoaJuridica';
import Perfil from './routes/Perfil/Perfil';
import NewRechargeOrder from './routes/NewRechargeOrder';
import Pedidos from './routes/Orders';
import Contratos from './routes/Contratos';
import Home from './routes/Home';
import Employees from './routes/Employees';
import RegisterDisconnect from './routes/Employees/routes/register';
import EmployeesSubscriptions from './routes/Employees/routes/subscription';
import SubscriptionDetail from './routes/Employees/routes/subscriptionDetail';
import Sales from './routes/Sales';
import BanckSlip from './routes/BanckSlip';
import ProtectedRoute from '../componentsPortal/ProtectedRoute';
import { ROLES } from '../constants';

class App extends React.Component {
  state = {
    drawerCollapsed: true
  };

  handleDrawer = () => {
    this.state.drawerCollapsed
      ? this.setState({ drawerCollapsed: false })
      : this.setState({ drawerCollapsed: true });
  };

  componentDidUpdate(prevProps) {
    // On route change, collapse sidebar
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ drawerCollapsed: true });
    }
  }

  render() {
    const { match } = this.props;
    const drawerStyle = this.state.drawerCollapsed
      ? 'mini-drawer'
      : 'fixed-drawer';

    return (
      <div className={`app-container ${drawerStyle}`}>
        {!localStorage.getItem('token') && (
          <Redirect
            to={{
              pathname: '/signin'
            }}
            push
          />
        )}

        <SideNav handleDrawer={this.handleDrawer} collapsed={this.state.drawerCollapsed} style={drawerStyle} />
        <div
          className="app-main-container"
          onClick={() => {
            if (!this.state.drawerCollapsed)
              this.setState({ drawerCollapsed: true });
          }}
        >
          <div className={`app-header app-header-horizontal`}>
            <Header />
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route path={`${match.url}/home`} component={Home} />
                {/* <ProtectedRoute
                  path={`${match.url}/pedido-recarga`}
                  component={NewRechargeOrder}
                  roles={[ROLES.BACKOFFICE, ROLES.PROMOTER]}
                /> */}

                <ProtectedRoute
                  path={`${match.url}/pedidos-emitidos`}
                  component={Pedidos}
                  roles={[ROLES.BACKOFFICE, ROLES.PROMOTER, ROLES.BROKER]}
                />

                <ProtectedRoute
                  path={`${match.url}/funcionarios`}
                  component={Employees}
                  roles={[ROLES.BACKOFFICE, ROLES.PROMOTER, ROLES.BROKER]}
                />

                <ProtectedRoute
                  path={`${match.url}/cadastrar-desligar`}
                  component={RegisterDisconnect}
                  roles={[ROLES.BACKOFFICE, ROLES.PROMOTER, ROLES.BROKER]}
                />
                <ProtectedRoute
                  path={`${match.url}/mensalidades`}
                  component={EmployeesSubscriptions}
                  roles={[ROLES.BACKOFFICE, ROLES.PROMOTER, ROLES.BROKER]}
                />

                <ProtectedRoute
                  exact
                  path={`${match.url}/mensalidade/:id`}
                  component={SubscriptionDetail}
                  roles={[ROLES.BACKOFFICE, ROLES.PROMOTER, ROLES.BROKER]}
                />

                <Route
                  path={`${match.url}/pessoa-juridica`}
                  component={PessoaJuridica}
                />
                <Route path={`${match.url}/profile`} component={Perfil} />
                <ProtectedRoute
                  path={`${match.url}/contratos`}
                  component={Contratos}
                  roles={[ROLES.BACKOFFICE, ROLES.SALES, ROLES.PROMOTER, ROLES.BROKER]}
                />
                <ProtectedRoute
                  path={`${match.url}/vendas`}
                  component={Sales}
                  roles={[ROLES.BROKER]}
                />
                <ProtectedRoute
                  path={`${match.url}/boleto`}
                  component={BanckSlip}
                  roles={[ROLES.BACKOFFICE, ROLES.SALES, ROLES.PROMOTER]}
                />
                <Route
                  component={asyncComponent(() =>
                    import('components/Error404')
                  )}
                />
              </Switch>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType } = settings;
  return { drawerType };
};
export default withRouter(connect(mapStateToProps)(App));
