import React, { Component, Fragment } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

const index = ({ loading, children, hiddenLoading }) => {
  return (
    <Fragment>
      {loading && !hiddenLoading && (
        <Grid container className="w-100" justify="center">
          <Grid item xs={12} container justify="center">
            <CircularProgress
              mode="indeterminate"
              style={{ color: '#3A10E0' }}
            />
          </Grid>
        </Grid>
      )}
      {!loading && children}
    </Fragment>
  );
};

export default index;
