import enLang from './entries/en-US';
import brLang from './entries/br_BR';
import { addLocaleData } from 'react-intl';

const AppLocale = {
  br: brLang
};
addLocaleData(AppLocale.br.data);

export default AppLocale;
