import React, { Component } from 'react';

import {
  Paper,
  Grid,
  Tabs,
  Tab,
  withStyles,
} from '@material-ui/core';
import Pagination from 'material-ui-flat-pagination';

import YaloSearchFilter from '../../../../../../components/YaloSearchFilter';
import ListEmployees from '../../../../../../app/routes/Employees/routes/subscriptionDetail/subcomponent/tabs/ListEmployees';

const TAB = {
  NEW_EMPLOYEES: 0,
  TERMINATION_EMPLOYEES: 1,
  RECHARGES_EMPLOYEES: 2
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  panel: {
  margin:'24px 0px',
  padding: '0px 24px',
  backgroundColor: theme.palette.background.paper,
  border: 'none',
  borderRadius: '8px',
  },
  rowSpacer: {
    margin: '16px 0px 24px 0px',
    padding: '0',
  },
});

class TableEmployes extends Component {
  state = {
    tabIndex: 0,
    filter: { name: '', cpf: '' },
    page: {
      limit: 30,
      offset: 0
    }
  };

  handleTabIndex = (e, newTab) => {
    this.setState({
      tabIndex: newTab
    });
  };

  getEmployeesListFilter = () => {
    const { tabIndex, filter } = this.state;
    const { order } = this.props;

    //get list by indexTab
    let empList = [];
    switch (tabIndex) {
      case TAB.NEW_EMPLOYEES:
        empList = order.new_employees;
        break;
      case TAB.TERMINATION_EMPLOYEES:
        empList = order.terminations;
        break;
      case TAB.RECHARGES_EMPLOYEES:
        empList = order.recharges;
        break;
    }

    //filter list
    empList = _.filter(
      empList,
      filteredEmp =>
        _.includes(_.upperCase(filteredEmp.name), _.upperCase(filter.name)) ||
        _.includes(filteredEmp.cpf, filter.cpf)
    );

    return empList;
  };

  render() {
    const { tabIndex, page, filter } = this.state;
    const { order, toggleModal, classes } = this.props;

    const employeesList = this.getEmployeesListFilter();
    return (
      <Grid container direction='column' className={classes.root}>
        <Grid container direction='row' alignItems="center">
          <Grid item xs={12} md={12}>
            <YaloSearchFilter
              onChange={e =>
                this.setState({
                  filter: { name: e.target.value, cpf: e.target.value }
                })
              }
              value={filter.name}
              className=""
              placeholder="Buscar por nome ou cpf"
            />
          </Grid>
        </Grid>

        <Grid container direction='row' className={classes.panel}>
          <Tabs
            value={tabIndex}
            onChange={this.handleTabIndex}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label={`Novos (${order.new_employees.length || 0})`} />
            <Tab label={`Desligados (${order.terminations.length || 0})`} />
            <Tab label={`Mensalidades (${order.recharges.length || 0})`} />
          </Tabs>

          <ListEmployees
            key={this.state.tabIndex}
            employeesList={employeesList}
            order={order}
            page={page}
            toggleModal={toggleModal}
            classes={classes}
          />

          <Grid item>
            <Pagination
              size="small"
              limit={page.limit}
              offset={page.offset}
              total={employeesList.length}
              onClick={(e, offset) => {
                this.setState({ page: { ...page, offset: offset } });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(TableEmployes);