import appLocaleData from 'react-intl/locale-data/br';
import enMessages from '../locales/br_BR.json';

const BrLang = {
  messages: {
    ...enMessages
  },
  locale: 'br-BR',
  data: appLocaleData
};
export default BrLang;
