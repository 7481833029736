import React, { useState, useEffect, useRef } from 'react';
import { Input } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDownRounded';
import './styles.css';
import * as uuid from 'uuid';

/**
 * Custom select component.
 * 
 * @param {Object} props - The props object.
 * @param {boolean} [props.required=true] - Whether the select is required.
 * @param {string} props.errorMessage - The error message to display.
 * @param {boolean} [props.isDisabled=false] - Whether the select is disabled.
 * @param {string} props.name - The name attribute of the select.
 * @param {string} props.label - The label for the select.
 * @param {string} props.value - The current value of the select.
 * @param {function} props.onChange - The function to call when the value changes.
 * @param {Array.<{key: string, value: string, name: string}>} props.menuItens - The items to display in the select.
 * 
 * @returns {JSX.Element} The rendered select component.
 */

const CustomSelect = ({
    errorMessage,
    isDisabled = false,
    name,
    label,
    value,
    onChange,
    onFocus,
    onKeyDown,
    onKeyUp,
    onBlur,
    onClick,
    options,
    style,
    isNotRequried = false
}) => {
    let message = errorMessage;
    const inputEvents = { onFocus, onKeyDown, onKeyUp, onClick };

    const menuItens = options.map(opt => ({
        name: opt.name,
        value: opt.value,
        key: uuid.v6(),
        ...opt
    }));

    const [menu, setMenu] = useState('none');
    const [selectedItem, setSelectedItem] = useState({
        key: '',
        value: value,
        name: 'Selecione'
    });
    const [selectId] = useState(uuid.v6());
    const [categoryId] = useState(uuid.v6());
    const [topMenuPos, setTopMenuPos] = useState(67);

    const wrapperRef = useRef(null);

    useEffect(() => {
        setItemByValue();
    }, [value]);

    useEffect(() => {
        value = selectedItem.value;
    }, [selectedItem]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setMenu('none');
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    function setItemByValue() {
        const selectedValue = menuItens.find(menuItem => menuItem.value === value);
        if (selectedValue) {
            setSelectedItem(selectedValue);
        }
    }

    function selectOnclick() {
        toggleMenu();
    }

    function customOnChange(event) {
        toggleMenu();
        const select = document.getElementById(selectId);
        if (select) {
            if (typeof onChange === 'function') {
                setSelectedItem(event);
                const fakeEvent = { target: { value: event.value, name: name } };
                select.value = event.value;
                const changeEvent = new Event('change', { bubbles: true });
                select.dispatchEvent(changeEvent);
                onChange(fakeEvent);
            }
        }
    }

    const setListOptions = () => {
        const select = document.getElementById(categoryId);
        if (select) {
            const pos = select.getBoundingClientRect();
            const marginTop = 2;
            const nolabelMargin = label ? 0 : 8;
            setTopMenuPos(pos.height + marginTop + nolabelMargin);
        }
    };

    function selectOnBlur(e) {
        if (isDisabled) {
            return;
        }

        if (typeof onBlur === 'function') {
            onBlur(e);
        }

        setMenu('none');
    }

    function toggleMenu() {
        if (isDisabled) {
            setMenu('none');
            return;
        }

        setMenu(menu === 'none' ? 'block' : 'none');
    }

    function selectOnChange(event) {
        setItemByValue();
        onChange(event);
    }

    const colors = {
        primary: '#404040',
        fill: '#FFF'
    };

    let liClass = 'liDefault';
    if (errorMessage) {
        colors.primary = "#89024C";
        colors.fill = "#FFE0F1";
        liClass = 'liError';
    }
    if (isDisabled) {
        colors.primary = "#404040";
        colors.fill = "#EBEBEB";
        message = 'Campo desabilitado';
    }

    return (
        <div ref={wrapperRef}>
            <div key={value} className="select">
                <div className="category-select" onClick={setListOptions} id={categoryId}>
                    <p className="label">
                        {label} {!isNotRequried && <span style={{ color: '#89024C' }}>*</span>}
                    </p>
                    <div id="select-button" style={{ border: `1px solid ${colors.primary}`, backgroundColor: colors.fill }}>
                        <div id='selected-value' style={{ width: '100%' }}>
                            <div onClick={() => setMenu('none')}>
                                <Input
                                    name={name}
                                    fullWidth
                                    margin={'none'}
                                    disableUnderline
                                    type='text'
                                    style={{ width: '98%' }}
                                    value={selectedItem.name}
                                    readOnly
                                    {...inputEvents}
                                    onBlur={selectOnBlur}
                                />
                            </div>
                        </div>
                        <KeyboardArrowDown onClick={selectOnclick}
                            style={{ color: colors.primary }} />
                    </div>
                </div>
            </div>
            <select id={selectId} value={value} hidden={true} name={name} onChange={selectOnChange}>
                {menuItens && menuItens.map((menuItem, index) => (
                    <option key={menuItem.key} value={menuItem.value}>{menuItem.name}</option>
                ))}
            </select>
            {message && <p style={{ marginTop: '5px', color: '#404040', fontSize: '12px' }}>
                <span style={{ fontWeight: '700', color: colors.primary }}>Erro: </span>{message}
            </p>}
            <ul role="listbox" id="options" style={{
                display: menu,
                backgroundColor: colors.fill,
                border: `1px solid ${colors.primary}`,
                top: topMenuPos,
                cursor: 'pointer'
            }}>
                {menuItens && menuItens.map((menuItem, index) => (
                    <li role="option" onClick={() => customOnChange(menuItem)} key={menuItem.key}
                        style={{
                            borderBottom: index === menuItens.length - 1 ? 'none' : `1px solid ${colors.primary}`,
                            cursor: 'pointer'
                        }} className={`option ${liClass}`}>
                        <label>{menuItem.name}</label>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CustomSelect;
