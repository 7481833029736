import React, { Component, Fragment } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { utils } from '../../../utils';
import moment from 'moment';
import ButtonUi from '../../../../../components/ui/button/Button'
import { CancelOutlined, CheckCircleOutlineRounded, ErrorOutlineRounded } from '@material-ui/icons'
moment.locale('pt_BR');

export default class OrderHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let order = { ...this.props.order };

    if (!this.props.order.id) return null;

    let order_status_color = utils.statusColor(order.order_status);

    return (
      <Grid container style={{ paddingLeft: 8  }}>
        {/* Primeira Coluna */}
        <Grid style={{
          display: 'flex',
          textAlign: 'center',
          justifyContent: 'center',
        }}>

          <Typography style={{
            marginRight: '8px',
            padding: '8px',
            borderRadius: '4px',
            fontSize: '14px',
            fontWeight: '400',
            maxHeight: '40px',
            borderRadius: '8px'
          }}
            className={order_status_color}>
        {/*     {['Vinculado', 'Ativo', 'Aprovado'].includes(order.order_status) && <CheckCircleOutlineRounded fontSize='5' />}
            {['Cancelado', 'Contrato Vencido'].includes(order.order_status) && <CancelOutlined fontSize='5' />}
            {['Pendente de Aprovação', 'Atraso', 'Benefício Bloqueado'].includes(order.order_status) && <ErrorOutlineRounded fontSize='5' />}
 */}            {order.order_status}
          </Typography>


          <Typography style={{
            fontFamily: 'Inter',
            fontWeight: 700,
            fontSize: 16,
            color: '#1A1A1A',
            marginTop: 8,
            maxWidth: '750px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }} >
            {order.company_name.toUpperCase()}
          </Typography>


        </Grid>
        {/* Segunda Coluna */}

        <Grid container style={{ marginTop: 12 }}>

          <Grid xs={2}>
            <Grid item xs={12}>
              <Typography
                variant="body1" >
                Pedido:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {`${order.identifier}`}
              </Typography>
            </Grid>
          </Grid>

          <Grid xs={2}>
            <Grid item xs={12}>
              <Typography
                variant="body1" >
                Emitido:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {`${utils.momentFromDate(order.created_at)}`}
              </Typography>
            </Grid>
          </Grid>

          <Grid xs={2}>
            <Grid item xs={12}>
              <Typography
                variant="body1" >
                Vencimento:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {`${utils.momentFromDate(order.charge_due_date)}`}
              </Typography>
            </Grid>
          </Grid>


          <Grid xs={2}>
            <Grid item xs={12}>
              <Typography
                variant="body1" >
                Pagamento:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {`${utils.momentFromDate(order.payment_date)}`}
              </Typography>
            </Grid>
          </Grid>

          <Grid xs={2}>
            <Grid item xs={12}>
              <Typography
                variant="body1" >
                Desconto aplicado
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {`R$` + utils.formatCurrency(order.discount)}
              </Typography>
            </Grid>
          </Grid>

          <Grid xs={2}>
            <Grid item xs={12}>
              <Typography
                variant="body1" >
                Total:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {`R$` + utils.formatCurrency(order.value)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
