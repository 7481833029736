import React from 'react';
import Dropzone from 'react-dropzone';
import { Grid, Paper, Divider, Typography, Button } from '@material-ui/core';
import IconNewFile from '@material-ui/icons/DescriptionOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconCloudUpload from '@material-ui/icons/CloudUploadRounded';
import IconCheckCircle from '@material-ui/icons/CheckCircleOutlineRounded';
import { utils } from '../../utils';

const AfterSelectionForm = ({
  afterUpload,
  loading,
  error,
  file,
  onSelectFile,
  cleanFile,
  resume,
  success,
  renderErrorTabs,
  renderSuccessTable,
  totalErrors,
  warnings,
  contract
}) => (
  <Grid container spacing={16} justify="center">
    {!success && !afterUpload && !file && (
      <Grid item xs={12}>
        <Grid container justify="center" className="mb-3">
          <Grid container />
          <Grid container justify="center">
            <Typography variant="subtitle2" className="mr-2">
              <strong>{contract.contracting_company_name + ':'}</strong>
            </Typography>

            <Typography variant="subtitle1">
              {utils.formatCNPJ(contract.contracting_company_cnpj)}
            </Typography>
          </Grid>
        </Grid>

        <Dropzone
          onDrop={file => onSelectFile(file[0])}
          disabled={file !== ''}
          className="form-upload"
        >
          {!afterUpload && (
            <Grid
              container
              justify="center"
              direction="column"
              alignItems="center"
              style={{ padding: 24 }}
            >
              <IconNewFile color="disabled" style={{ fontSize: 100 }} />
              <Typography variant="h3">Arraste o arquivo aqui</Typography>
              <Typography variant="h3" paragraph>
                ou
              </Typography>
              <Button
                color="primary"
                variant="contained"
                disabled={file !== ''}
              >
                SELECIONE
              </Button>
              <input
                id="files"
                style={{ display: 'none' }}
                type="file"
                onChange={e => onSelectFile(e.target.files[0])}
              />
            </Grid>
          )}

          {/* Erro upload */}
          {error && (
            <Grid container justify="center" style={{ padding: 24 }}>
              <Typography color="error" variant="h4" align="center">
                {error}
              </Typography>
            </Grid>
          )}
        </Dropzone>
      </Grid>
    )}
    {file && (
      <Grid item xs={10}>
        <Paper>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{ padding: 16 }}
          >
            <Grid item xs={8}>
              <Typography variant="h4">{file.name}</Typography>
              {loading && (
                <Typography variant="h4">Analisando o arquivo...</Typography>
              )}
              {!loading && resume && (
                <Grid container spacing={32}>
                  <Grid item>
                    <Typography variant="h4">
                      {resume.totalNewEmployees} <strong>Novos</strong>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">
                      {resume.totalRecharges} <strong>Recorrentes</strong>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">
                      {resume.totalTerminations} <strong>Cancelados</strong>
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={4}>
              <Grid container justify="flex-end" alignItems="center">
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  aria-label="Baixar Planilha de Exemplo"
                  onClick={cleanFile}
                  disabled={loading}
                >
                  <IconCloudUpload style={{ marginRight: 16 }} />
                  NOVO UPLOAD
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {loading ? <LinearProgress /> : <Divider />}
          {!loading && !resume && (
            <Grid container spacing={16} style={{ padding: 20 }}>
              <Grid item>
                <Typography
                  variant="body2"
                  className="red-info"
                >{`${totalErrors} Erro(s)`}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" className="orange-info">{`${
                  warnings.totalWarnings
                } Aviso(s)`}</Typography>
              </Grid>
            </Grid>
          )}
          {!loading &&
            (success ? (
              <Grid
                container
                alignItems="stretch"
                spacing={16}
                style={{ padding: 10, paddingLeft: 20 }}
              >
                <Grid item xs={10}>
                  <Grid
                    container
                    alignItems="center"
                    spacing={16}
                    style={{ padding: 16 }}
                  >
                    <IconCheckCircle
                      className="green-info"
                      style={{ marginLeft: 16, marginRight: 16, fontSize: 36 }}
                    />
                    <Typography
                      variant="h2"
                      align="center"
                      className="green-info"
                    >
                      Arquivo validado
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              renderErrorTabs()
            ))}
        </Paper>
        {success && renderSuccessTable()}
      </Grid>
    )}
  </Grid>
);

export default AfterSelectionForm;
