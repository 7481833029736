import React from 'react';

function ExitIcon({ className, ...props }) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={32}
        height={32}
      >
        <path fill="#D9D9D9" d="M0 0H32V32H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M6.667 28a2.568 2.568 0 01-1.884-.783A2.568 2.568 0 014 25.333V6.667c0-.734.261-1.361.783-1.884A2.568 2.568 0 016.667 4h8c.377 0 .694.128.95.383.255.256.383.573.383.95 0 .378-.128.695-.383.95a1.29 1.29 0 01-.95.384h-8v18.666h8c.377 0 .694.128.95.384.255.255.383.572.383.95 0 .377-.128.694-.383.95a1.29 1.29 0 01-.95.383h-8zM22.9 17.333h-9.567a1.29 1.29 0 01-.95-.383A1.29 1.29 0 0112 16c0-.378.128-.694.383-.95a1.29 1.29 0 01.95-.383H22.9l-2.5-2.5a1.224 1.224 0 01-.367-.9c0-.356.123-.667.367-.934.244-.266.556-.405.933-.416.378-.011.7.116.967.383l4.767 4.767c.266.266.4.577.4.933 0 .356-.134.667-.4.933L22.3 21.7a1.24 1.24 0 01-.95.383 1.36 1.36 0 01-.95-.416 1.297 1.297 0 01-.35-.95c.011-.367.139-.673.383-.917l2.467-2.467z"
          className="icon-path"
        />
      </g>
    </svg>
  );
}

export default ExitIcon;
