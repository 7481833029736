import {
  authService,
  cardService,
  contractService,
  ordersService
} from '../services';

export const UserActions = {
  login,
  logout,
  resetPwd,
  getLoginData,
  updatePwd,
  uploadFile,
  uploadRechargeSheet,
  getContract,
  getByCNPJ,
  finishOrder,
  updateOrder,
  createContract,
  getAllOrders,
  getOrder,
  finishRecharge,
  getAllRecharges
};

function login(username, password) {
  return authService.login(username, password);
}

function getLoginData(token, username) {
  return authService.getLoginData(token, username);
}

function logout() {
  return authService.logout();
}

function resetPwd(username) {
  return authService.resetPwd(username);
}

function updatePwd(id, oldPwd, newPwd) {
  return authService.updatePwd(id, oldPwd, newPwd);
}

function uploadFile(file, contractId, orderId) {
  return cardService.upload(file, contractId, orderId);
}

function uploadRechargeSheet(file, contractId, orderId) {
  return cardService.uploadRechargeSheet(file, contractId, orderId);
}

function getContract(token) {
  return contractService.getContract(token);
}

function getByCNPJ(cnpj) {
  return cardService.getByCnpj(cnpj);
}

function finishOrder(order) {
  return cardService.finishOrder(order);
}

function finishRecharge(order) {
  return cardService.finishRecharge(order);
}

function updateOrder(order) {
  return cardService.updateOrder(order);
}

function createContract(contract) {
  return contractService.createContract(contract);
}

function getAllOrders(contractId) {
  return ordersService.getAllOrders(contractId);
}

function getAllRecharges(contractId) {
  return ordersService.getAllRecharges(contractId);
}

function getOrder(id) {
  return ordersService.getOrder(id);
}
