import React, { Component } from 'react';
import ContainerHeader from '../../../../../components/ContainerHeader';
import { withRouter } from 'react-router-dom';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  Tabs,
  Tab,
  Button,
  IconButton,
  Tooltip,
  Typography,
  CircularProgress,
  Divider,
  MenuItem,
  TextField,
  FormControl
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import { utils } from '../../../utils';

import moment from 'moment';
import Pagination from 'material-ui-flat-pagination';
import YaloSearchFilter from '../../../../../components/YaloSearchFilter';
import { contractService } from '../../../../../services/contractService';
import { CONTRACT_STATUS } from '../../../../../constants';

import ContractStatusSelect from '../../../../../componentsPortal/Selects/ContractStatusSelect.js';
import SelectUi from '../../../../../components/ui/select/index.js'
import ButtonUi from '../../../../../components/ui/button/Button.js'

import { CheckCircleOutlineRounded, CancelOutlined, ErrorOutlineRounded } from '@material-ui/icons'
import { ChevronRightRounded } from '@material-ui/icons';

moment.locale('pt_BR');

class ConsultaContrato extends Component {
  state = {
    contracts: [],
    filter: '',
    dialog: { open: false, message: '', loading: false, confirmation: true },
    loading: true,
    offset: 0,
    filterStatus: '',
    status_filter: 'Todos',
    statusList: [],
    from: '',
    to: ''
  };
  text = {
    info: '',
    label: ''
  };



  getContract = selectedContract => {
    contractService.getContract().then(response => {
      this.setState({
        contracts: response.data.response.map(el => {
          let contract_status_color;
          switch (el.contract_status_name) {
            case 'Ativo':
              contract_status_color = 'green-info';
              break;
            case 'Aprovado':
              contract_status_color = 'blue-info';
              break;
            case 'Pendente de Aprovação':
            case 'Atraso':
            case 'Benefício Bloqueado':
              contract_status_color = 'orange-info';
              break;
            case 'Cancelado':
            case 'Contrato Vencido':
            default:
              contract_status_color = 'red-info';
              break;
          }

          const styles = {
            companyName: {
              fontSize: '16px',
              fontFamily: 'Inter',
              color: '#1A1A1A',
              fontWeight: '700',
              marginTop: '8px',
              lineHeight: '24.5px'
            }
          }

          return (
            <ExpansionPanel
              contractname={el.name}
              companyname={el.contracting_company_name}
              cnpj={el.contracting_company_cnpj}
              status={el.contract_status_name}
              signature_date={el.signature_date}
              closure_date={el.closure_date}
              key={el.id}

              className={contract_status_color}

              style={{ borderRadius: '12px', border: 'none' }}
            >
              <ExpansionPanelSummary
                style={{ paddingLeft: 0 }}
                className='panel-summary-format'
                expandIcon={<ExpandMoreIcon />}
              >
                <Grid xs={16} style={{ display: 'flex', flexDirection: 'column', width: '100%', marginLeft: '4px' }}>
                  {/*Primeira Coluna*/}
                  <Grid item container direction='row' xs={16} style={{
                    flexWrap: 'nowrap',
                    textAlign: 'center',
                    justifyContent: 'start',
                    alignItems: 'center',
                    width: '100%',
                    marginBottom: '20px',
                  }}>
                    <div style={{
                      display: 'flex',
                      marginRight: '8px',
                      maxHeight: '40px',
                      borderRadius: '25px',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}>
                      <Typography style={{
                        display: 'flex',
                        padding: '8px',
                        fontSize: '14px',
                        fontWeight: '400',
                        borderRadius: '6px'
                      }} className={contract_status_color}>
                        <div>
                          {['Vinculado', 'Ativo', 'Aprovado'].includes(el.contract_status_name) && <CheckCircleOutlineRounded fontSize='5' style={{}} />}
                          {['Cancelado', 'Contrato Vencido'].includes(el.contract_status_name) && <CancelOutlined fontSize='5' style={{}} />}
                          {['Pendente de Aprovação', 'Atraso', 'Benefício Bloqueado'].includes(el.contract_status_name) && <ErrorOutlineRounded fontSize='5' style={{}} />}

                        </div>
                        <div style={{ marginLeft: 5, marginTop: 2 }}>
                          {el.contract_status_name === null ? 'Desligado' : el.contract_status_name}

                        </div>

                      </Typography>
                    </div>
                    <Grid item xs={13}>
                      <Typography style={styles.companyName}>
                        {el.contracting_company_name}
                      </Typography>

                    </Grid>

                  </Grid>
                  <Grid container item xs={16} style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }} >

                    <Grid item xs={6} style={{ display: 'flex' }}>

                      <Grid xs={6}>
                        <Grid item xs={5}>
                          <Typography align='left'
                            variant="body1" >
                            Contratante:
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2" align='left'>
                            {el.contracting_company_name}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid xs={6} >
                        <Grid item xs={4}>
                          <Typography align='left'
                            variant="body1">
                            CNPJ:
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2" align='left'>
                            {`${utils.formatCNPJ(
                              el.contracting_company_cnpj
                            )}`}
                          </Typography>
                        </Grid>
                      </Grid>

                    </Grid>

                    <Grid item xs={6} style={{ display: 'flex' }}>
                      <Grid xs={6}>
                        <Grid item xs={6}>
                          <Typography align='left'
                            variant="body1" >
                            Data de assinatura:
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2" align='left'>
                            {`${utils.momentFromDate(el.signature_date)}`}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid xs={6} >
                        <Grid item xs={6}>
                          <Typography align='left'
                            variant="body1">
                            Data de vigência:
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2" align='left'>
                            {`${utils.momentFromDate(el.closure_date)}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>



                  </Grid>

                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container style={{ marginLeft: '-20px', paddingLeft: '0px' }}>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={8}>
                      <Grid item xs={12}>
                        <Typography variant='h4' color='primary'>
                          EMPRESA
                        </Typography>
                      </Grid>
                      {/* Primeira Coluna */}

                      <Grid item xs={12} style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}>
                        <Grid xs={4}>
                          <Grid item xs={4}>
                            <Typography
                              variant="body1" >
                              Nome da empresa:
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="body2">
                              {el.contracting_company_name}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid xs={4} >
                          <Grid >
                            <Typography align='left'
                              variant="body1">
                              CNPJ:
                            </Typography>
                          </Grid>
                          <Grid >
                            <Typography variant="body2" align='left'>
                              {utils.formatCNPJ(el.contracting_company_cnpj)}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid xs={4} >
                          <Grid item xs={6}>
                            <Typography align='left'
                              variant="body1">
                              Empresas relacionadas:
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="body2" align='left'>
                              {el.qtd_relations}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>

                    <Grid item xs={12} style={{ marginTop: '16px', marginBottom: '16px' }}>
                      <Typography variant='h4' color='primary'>
                        DADOS DO PLANO
                      </Typography>
                    </Grid>


                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Grid xs={4}>
                        <Grid item xs={6}>
                          <Typography align='left'
                            variant="body1" >
                            Gerente da conta:
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2" align='left'>
                            {el.account_manager_yalo_name}
                          </Typography>
                        </Grid>
                      </Grid>


                      <Grid xs={4} >
                        <Grid >
                          <Typography align='left'
                            variant="body1">
                            Recarga dos cartões:
                          </Typography>
                        </Grid>
                        <Grid >
                          <Typography variant="body2" align='left'>
                            {el.should_renew_recharges ? 'SIM' : 'NÃO'}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid xs={4} >
                        <Grid item xs={6}>
                          <Typography align='left'
                            variant="body1">
                            Data da assinatura:
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2" align='left'>
                            {utils.momentFromDate(el.signature_date)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>


                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>

                      <Grid xs={4}>
                        <Grid item xs={6}>
                          <Typography align='left'
                            variant="body1" >
                            Gerador do lead:
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2" align='left'>
                            {el.sales_promotor_name == null
                              ? 'N/A'
                              : el.sales_promotor_name}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid xs={4} >
                        <Grid >
                          <Typography align='left'
                            variant="body1">
                            Recarrega ao renovar:
                          </Typography >
                        </Grid>
                        <Grid >
                          <Typography variant="body2" align='left'>
                            {el.should_renew_recharges ? 'SIM' : 'NÃO'}
                          </Typography >
                        </Grid>
                      </Grid>

                      <Grid xs={4} >
                        <Grid item xs={6}>
                          <Typography align='left'
                            variant="body1">
                            Data de vigência
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2" align='left'>
                            {utils.momentFromDate(el.closure_date)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>

                  <Grid
                    container
                    spacing={0}
                    direction='row'
                    justify='flex-end'
                    alignItems='center'
                    style={{ marginTop: 16 }}
                  >
                    <Link
                      to={{
                        pathname: `${this.props.match.path.replace(
                          'consulta',
                          'detalhes'
                        )}/${el.id}`,
                        filter: this.filterToProps
                      }}
                      style={{ textDecoration: 'none' }}
                    >
                      <ButtonUi
                        variant='secondary'
                        className='btn-consulting'
                        endIcon={<ChevronRightRounded />}
                      >
                        Detalhes do contrato
                      </ButtonUi>
                    </Link>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        }),
        loading: false
      });
    });
  };

  componentDidMount() {
    if (this.props.location.filter && this.props.location.filter.hasFilter) {
      this.setState({ ...this.state, ...this.props.location.filter.func() });
    }
    this.getContract(this.props.selectedContract);
    contractService
      .getStatus()
      .then(resp => this.setState({ statusList: resp.data.response }));
  }

  filterToProps = () => {
    return {
      from: this.state.from,
      to: this.state.to,
      filter: this.state.filter,
      status_filter: this.state.status_filter,
      status_tabs: this.state.status_tabs
    };
  };

  FormLine(label, info) {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Typography variant='subtitle1'>{label}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle2'>{info}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  FormAssinatura(name, info) {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Typography variant='subtitle2'>{name}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {this.FormLine('Número de assinaturas:', info)}
      </React.Fragment>
    );
  }

  formatDate = date => {
    if (date === 'Invalid date') {
      return 'N/A';
    } else {
      return date;
    }
  };

  verifyNullDate = date => {
    if (date) return date.split('T')[0];
    else return date;
  };

  handleClick(offset) {
    this.setState({ offset });
  }

  filterDate = date => {
    let filter = true;
    const dateUnix = new Date(moment(date).format('YYYY-MM-DD')).getTime();
    if (this.state.to) {
      const toUnix = new Date(this.state.to).getTime();
      if (dateUnix > toUnix) filter = false;
    }

    if (this.state.from) {
      const fromUnix = new Date(this.state.from).getTime();
      if (dateUnix < fromUnix) filter = false;
    }
    return filter;
  };

  handleSelectFilter = e => {
    this.setState({
      ...this.state,
      filterStatus: e.target.value
    });
  };

  getListFiltered = () => {
    // FilteredContracts is returning an array filtered through
    // ( contract.props.contractname or contract.props.cnpj) and contract.props.status
    const { contracts, filter, status_filter } = this.state;

    return _.filter(
      contracts,
      contract =>
        (_.includes(
          contract.props.contractname.toLowerCase(),
          filter.toLowerCase()
        ) ||
          _.includes(
            contract.props.cnpj,
            this.state.filter.replace(/[./-]/g, '')
          )) &&
        (status_filter === 'Todos' || contract.props.status === status_filter)
    );
  };

  render() {
    const filteredContracts = this.getListFiltered();
    /*     <div style={styleTag}>
        <Typography style={{display: 'flex', textAlign: 'center' }}>
        {['Vinculado', 'Ativo'].includes(this.props.status) && <CheckCircleOutlineRounded style={{ marginRight: '8px'}}/>}
        {this.props.status == 'Não Vinculado' && <Cancel style={{ marginRight: '8px'}}/>}
    
          {this.props.status === null ? 'Desligado' : this.props.status} 
        </Typography>
      </div>  */

    return (
      <div className='app-wrapper'>
        <ContainerHeader title='Consulta Contratos' />
        <Grid container item xs={12} alignItems='center' style={{ margin: '12px 0px' }}>
          <YaloSearchFilter
            placeholder='Busca por nome ou CNPJ'
            value={this.state.filter}
            onChange={e =>
              this.setState({ filter: e.target.value, offset: 0 })
            }
          />
          {/* Filtros */}
          <Grid item xs={12} style={{ marginTop: '12px' }}>
            <Grid item xs={6} justify='center'>
              <FormControl>
                <SelectUi
                  value={this.state.status_filter}
                  onChange={this.handleStatusFilter}
                  label="Status"
                  options={CONTRACT_STATUS.map(el => ({
                    value: el.VALUE,
                    name: el.VALUE,
                  }
                  ))}
                />
              </FormControl>

              <Tooltip style={{ marginTop: 12, marginLeft: 5 }} title='Limpar Filtros'>
                <IconButton
                  aria-label='Limpar Filtros'
                  size='small'
                  onClick={() =>
                    this.setState({
                      to: '',
                      from: '',
                      filter: '',
                      status_filter: 'Todos',
                      filterStatus: '',
                      status_tabs: 0,
                      offset: 0
                    })
                  }
                >
                  <CloseIcon variant='round' />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid item xs={1}>
            <Grid container direction='row' alignItems='center'>

            </Grid>
          </Grid>
        </Grid>
        {this.state.loading && (
          <Grid item xs={12} style={{ marginTop: 32 }}>
            <Grid container justify='center'>
              <CircularProgress
                mode='indeterminate'
                style={{ color: '#3A10E0' }}
              />
            </Grid>
          </Grid>
        )}
        {!this.state.loading && this.state.contracts.length < 1 && (
          <Grid item xs={12} style={{ padding: 32 }}>
            <Typography variant='h3' align='center'>
              Nenhum contrato encontrado.
            </Typography>
          </Grid>
        )}
        {filteredContracts.slice(this.state.offset, this.state.offset + 14)}
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <Pagination
            size='large'
            limit={14}
            offset={this.state.offset}
            total={filteredContracts.length}
            onClick={(e, offset) => this.handleClick(offset)}
          />
        </Grid>
      </div>
    );
  }

  handleStatusFilter = event => {
    this.setState({
      status_filter: event.target.value,
      offset: 0
    });
  };
}

export default withRouter(ConsultaContrato);
