import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  Grid,
  Typography,
  Checkbox,
} from '@material-ui/core';

import Stepper from './../components/Stepper';

import CompanyInfo from './subcomponents/CompanyInfo';
import CompanyAddress from './subcomponents/CompanyAddress';
import PartnerInfo from './subcomponents/PartnerInfo';
import GovReg from './subcomponents/GovReg';
import FinancialResponsible from './subcomponents/FinancialResponsible';
import HR from './subcomponents/HR';
import Users from './subcomponents/Users';
import MainCompany from './subcomponents/MainCompany';

import { formStyles as styles } from './styles';

class RegistrationForm extends React.Component {
  render() {
    const { 
      classes,
      steps,
      completed,
      getInputs,
      setActiveComponentStep,
      onCurrentComponentStepChange,
      activeStep,
      onChangeStakeholder,
      onChangeReplicate,
      form,
    } = this.props;

    if (activeStep > 7) {
      return null;
    }

    return (
      <Grid
        container
        item
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
        spacing={0}
        className={classes.rowSpacer2xs}
      >
        <Grid
          container
          direction='column'
          justify='flex-start'
          alignItems='stretch'
          spacing={0}
        >
          <Grid item xs={12} md={12}>
            <Stepper
              steps={steps}
              activeStep={activeStep}
              completed={completed}
            />

            {/* INFORMACOES EMPRESA */}
            {activeStep === 0 && (
              <CompanyInfo
                info={form.info}
                setInputs={(data) => getInputs('info', data)}
                setActiveComponentStep={(component) =>
                  setActiveComponentStep(component)
                }
                currentComponentStepChange={(hasError) =>
                  onCurrentComponentStepChange(hasError)
                }
              />
            )}

            {/* ENDERECO EMPRESA */}
            {activeStep === 1 && (
              <CompanyAddress
                address={form.address}
                setInputs={(data) => getInputs('address', data)}
                setActiveComponentStep={(component) =>
                  setActiveComponentStep(component)
                }
                currentComponentStepChange={(hasError) =>
                  onCurrentComponentStepChange(hasError)
                }
              />
            )}

            {/* INFORMACOES SOCIO */}
            {activeStep === 2 && (
              <Fragment>
                <Grid container className={classes.checkboxSpacer}>
                  <Grid item xs={12}>
                    <Typography
                      variant='subtitle1'
                      style={{ marginLeft: -14, marginBottom: -10 }}
                    >
                      <Checkbox
                        checked={form.hasStakeholer}
                        onChange={() => onChangeStakeholder()}
                        color='primary'
                      />
                      Adicionar informações de sócio majoritário
                    </Typography>
                  </Grid>
                </Grid>
                <PartnerInfo
                  partner={form.partner}
                  hasStakeholder={form.hasStakeholer}
                  setInputs={(data) => getInputs('partner', data)}
                  setActiveComponentStep={(component) =>
                    setActiveComponentStep(component)
                  }
                  currentComponentStepChange={(hasError) =>
                    onCurrentComponentStepChange(hasError)
                  }
                />
              </Fragment>
            )}

            {/* REGISTROS GOV */}
            {activeStep === 3 && (
              <GovReg
                regs={form.regs}
                setInputs={(data) => getInputs('regs', data)}
                setActiveComponentStep={(component) =>
                  setActiveComponentStep(component)
                }
                currentComponentStepChange={(hasError) =>
                  onCurrentComponentStepChange(hasError)
                }
              />
            )}

            {/* Responsável Financeiro */}
            {activeStep === 4 && (
              <Fragment>
                <Grid item xs={12} md={12} className={classes.checkboxSpacer}>
                  <Checkbox
                    checked={form.replicate}
                    onChange={() => onChangeReplicate()}
                    color='primary'
                  />
                  Replicar dados.
                </Grid>
                <FinancialResponsible
                  financial_resp={form.financial_resp}
                  setInputs={(data) => getInputs('financial_resp', data)}
                  setActiveComponentStep={(component) =>
                    setActiveComponentStep(component)
                  }
                  currentComponentStepChange={(hasError) =>
                    onCurrentComponentStepChange(hasError)
                  }
                />
              </Fragment>
            )}

            {/* Responsável RH */}
            {activeStep === 5 && (
              <HR
                hr_resps={form.hr_resps}
                financialResp={form.financial_resp}
                replicate={form.replicate}
                setInputs={(data) => getInputs('hr_resps', data)}
                setActiveComponentStep={(component) =>
                  setActiveComponentStep(component)
                }
                currentComponentStepChange={(hasError) =>
                  onCurrentComponentStepChange(hasError)
                }
              />
            )}
            {/* Users */}
            {activeStep === 6 && (
              <Users
                cadastro={true}
                users={form.users}
                financialResp={form.financial_resp}
                replicate={form.replicate}
                setInputs={(data) => getInputs('users', data)}
                setActiveComponentStep={(component) =>
                  setActiveComponentStep(component)
                }
                currentComponentStepChange={(hasError) =>
                  onCurrentComponentStepChange(hasError)
                }
              />
            )}

            {/* Empresa Principal */}
            {activeStep === 7 && (
              <MainCompany
                empPrinc={form.emp_princ}
                setInputs={(data) => getInputs('emp_princ', data)}
                setActiveComponentStep={(component) =>
                  setActiveComponentStep(component)
                }
                currentComponentStepChange={(hasError) =>
                  onCurrentComponentStepChange(hasError)
                }
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(RegistrationForm);
