import React from 'react';
import { connect } from 'react-redux';
import { modalEmitAlert, modalAlertShowLoading, UserActions } from '../../../actions';
import { SUCCESS, ERROR } from '../../../constants/ActionTypes';
import { withRouter, Link } from 'react-router-dom';
import ContainerHeader from '../../../components/ContainerHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from './style';
import {
  Button,
  StepButton,
  Paper,
  Grid,
  Step,
  Stepper,
  Typography
} from '@material-ui/core';
import IconCloudDownload from '@material-ui/icons/CloudDownloadRounded';

import { utils } from '../utils';
import { contractErrors } from './utils';
import { contractWarnings } from './utils';
import AfterUploadTabs from './subcomponents/AfterUploadTabs';
import UploadSuccessTable from './subcomponents/UploadSuccessTable';
import AfterSelectionForm from './subcomponents/AfterSelectionForm';
import PaymentSettings from './subcomponents/PaymentSettings';
import OrderConfirmation from './subcomponents/OrderConfirmation';

function TabContainer(props) {
  return (
    <Typography component='div' style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class NewOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      file: '',
      add: false,
      loading: false,
      error: '',
      hasContract: false,
      selectNewContract: false,
      contracts: [],
      contractId: '',
      contractName: '',
      value: 0,
      afterUpload: false,
      deliveryAreas: '',
      successUpload: false,
      resume: '',
      path: '',
      cnpj: '',
      cnpjData: '',
      errorGetByCnpj: '',
      errorFinishOrder: '',
      isPaymentDone: false,
      dialog: {
        open: false,
        message: ''
      },
      responsable: '',
      download: false,
      implantationErrors: {},

      paymentSettings: {},

      //stepper related
      activeStep: 0,
      completed: []
    };
  }

  componentDidMount = () => {
    if (this.props.location.order_info) {
      const { order_info } = this.props.location;
      this.setState({
        ...this.state,
        order_id: order_info.order_id,
        editing: true,
        contractId: {
          value: order_info.contract_id,
          label: order_info.contract_name
        },
        contractName: order_info.contract_name
      });
    }
  };

  componentWillReceiveProps = nextProps => {
    this.setState({
      ...this.state,
      contractId: nextProps.selectedContract.id
    });
  };

  componentDidUpdate() {
    this.centerTabs();
  }

  byCnpj = e => {
    this.setState({ cnpj: e.target.value });
  };

  changeContract = e => {
    this.setState({
      hasContract: false,
      file: '',
      error: '',
      afterUpload: false,
      successUpload: false,
      selectNewContract: false,
      download: false,
      activeStep: 0,
      completed: []
    });
  };

  choseContract = e => {
    this.setState({ hasContract: true, selectNewContract: true });
  };

  handleTab = (event, value) => {
    this.setState({ value });
  };

  handleSelectClick = () => {
    if (this.state.contracts.length < 1) {
      const token = localStorage.getItem('token');
      let initialContracts = [];

      UserActions.getContract(token).then(response => {
        let data = response.data.response;

        initialContracts = data.map(contract => {
          return contract;
        });

        this.setState({ contracts: initialContracts });
      });
    }
  };

  onFormSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true });
    this.setState({ error: '' });

    UserActions.uploadRechargeSheet(
      this.state.file,
      this.state.contractId.value
    ).then(response => {
      const success = response.data.success,
        data = response.data.response,
        message = response.data.message;
      // Arquivo com erros e precisa de ajustes
      if (
        response.data &&
        response.data.response &&
        !response.data.response.success &&
        response.status !== 500
      ) {
        return this.setState({
          errors: response.data.response.errors,
          warnings: response.data.response.warnings,
          afterUpload: true,
          download: true,
          loading: false,
          implantationErrors: data.errors.generic
            ? this.getGenerics(data.errors.generic)
            : {}
        });
      }
      if (
        response.data &&
        response.data.response &&
        response.data.response.success &&
        response.data.response.errors
      ) {
        return this.setState({
          errors: response.data.response.errors,
          implantationErrors: data.errors.generic
            ? this.getGenerics(data.errors.generic)
            : {},
          warnings: response.data.response.warnings,
          afterUpload: true,
          loading: false
        });
      }

      if (
        response.data &&
        response.data.response &&
        response.data.response.success &&
        response.data.response.warnings
      ) {
        return this.setState({
          warnings: response.data.response.warnings,
          afterUpload: true,
          loading: false,
          resume: response.data.response.resume,
          path: response.data.response.path,
          successUpload: true
        });
      }
      if (!response.data.success && response.status === 500)
        // Tipo de arquivo incorreto
        return this.setState({
          error: response.data.message,
          afterUpload: false,
          loading: false,
          file: ''
        });

      // Arquivo correto
      if (response.data.success) {
        return this.setState({
          resume: response.data.response.resume,
          path: response.data.response.path,
          successUpload: true,
          afterUpload: false,
          loading: false,
          hasContract: false
        });
      }
    });
  };

  cleanFile = () => {
    this.setState({
      afterUpload: false,
      file: '',
      successUpload: false,
      resume: '',
      implantationErrors: {}
    });
  };

  getByCnpj = e => {
    e.preventDefault();
    this.setState({ loading: true });

    UserActions.getByCNPJ(
      utils.removeNonNumericCharacters(this.state.cnpj)
    ).then(response => {
      this.setState({ loading: false });

      if (!response.data.success)
        this.setState({
          errorGetByCnpj: response.data.message
        });

      if (response.data.success) {
        this.setState({
          cnpjData: response.data,
          errorGetByCnpj: '',
          responsable: response.data
        });
        this.handleNext();
      }
    });
  };

  centerTabs = () => {
    const tabs = document.querySelector('.outer-tabs-container');
    if (tabs && tabs.firstChild && tabs.firstChild.firstChild) {
      tabs.firstChild.firstChild.firstChild.style.justifyContent = 'center';
    }
  };

  defaultState = () => {
    return this.setState({
      file: '',
      add: false,
      error: '',
      hasContract: false,
      selectNewContract: false,
      contractId: '',
      contractName: '',
      value: 0,
      afterUpload: false,
      deliveryAreas: '',
      successUpload: false,
      isPaymentDone: false,
      resume: '',
      path: '',
      cnpj: '',
      cnpjData: '',
      errorGetByCnpj: '',
      responsableRH: '',
      activeStep: 0,
      completed: []
    });
  };

  updateOrder = () => {
    this.setState({
      loading: true,
      dialog: { ...this.state.dialog, open: true }
    });

    this.props.modalAlertShowLoading();

    const id = this.props.location.order_info.order_id;
    const path = this.state.path;

    let order = {
      id,
      path
    };

    UserActions.updateOrder(order).then(response => {
      this.setState({ loading: false });

      if (response.status === 200) {
        this.setState({
          dialog: { open: true, message: 'Pedido editado com sucesso.' }
        });
        this.props.modalEmitAlert('Pedido editado com sucesso.', SUCCESS);
        this.defaultState();
      } else {
        this.setState({
          dialog: {
            open: true,
            message: ['Erro ao editar pedido. ', ...response.message]
          },
          errorFinishOrder: response.data.message
        });
        this.props.modalEmitAlert('Erro ao editar pedido.', ERROR);
      }
    });
  };

  getMinDate = () => {
    let date = new Date(
      parseInt(this.state.paymentSettings.expiration_date.slice(0, 4)),
      parseInt(this.state.paymentSettings.expiration_date.slice(5, 7)) - 1,
      parseInt(this.state.paymentSettings.expiration_date.slice(8, 10))
    );
    date = new Date(date.getTime() + 86400000 * 3);
    if (date.getDay() === 0) {
      date = new Date(date.getTime() + 86400000);
    } else if (date.getDay() === 6) {
      date = new Date(date.getTime() + 86400000 * 2);
    }
    return (
      `${date.getFullYear()}-` +
      `0${date.getMonth() + 1}-`.slice(-3) +
      `0${date.getDate()}`.slice(-2)
    );
  };

  finishOrder = () => {
    this.setState({
      loading: true,
      dialog: { ...this.state.dialog, open: true }
    });
    this.props.modalAlertShowLoading();

    const userId = localStorage.getItem('userid');
    const contractId = this.props.selectedContract.id;
    const companieIdBilling = this.state.paymentSettings.responsable
      .contracting_company_id;
    const recharge_date = this.state.paymentSettings.recharge_when_payment_done
      ? this.getMinDate()
      : this.state.paymentSettings.recharge_date;
    const recharge_when_payment_done = this.state.paymentSettings
      .rechargeOnPayment;
    const isPaymentDone = this.state.isPaymentDone;
    const valueInt =
      this.state.resume.totalValuePlans +
      this.state.resume.totalValueRecharges +
      this.state.resume.totalValueNewEmployees;
    const value = valueInt.toString();
    const path = this.state.path;

    let order = {
      user_id: userId,
      contract_id: contractId,
      companie_id_billing: companieIdBilling,
      is_payment_done: isPaymentDone,
      value: value,
      path: path,
      recharge_date: recharge_date,
      recharge_when_payment_done: recharge_when_payment_done,
      expiration_date: this.state.paymentSettings.expiration_date,
      discount: this.state.paymentSettings.useAccountBalance
        ? utils.sliceToMoneyFloat(this.state.paymentSettings.discount)
        : 0
    };

    UserActions.finishRecharge(order).then(response => {
      this.setState({ loading: false });
      if (response.status === 200) {
        this.setState({
          dialog: { open: true, message: 'Pedido finalizado com sucesso.' }
        });
        this.props.modalEmitAlert('Pedido finalizado com sucesso.', SUCCESS);
        this.defaultState();
      } else {
        this.setState({
          dialog: {
            open: true,
            message: `Erro ao finalizar pedido. ${response.data.message[0]}`
          },
          errorFinishOrder: response.data.message
        });
        this.props.modalEmitAlert('Erro ao finalizar pedido.', ERROR);
      }
    });
  };

  getGenerics = generics => {
    const generic = [];
    Object.keys(generics).forEach(error => generic.push(...generics[error]));
    return generic;
  };

  disableButton = () => {
    if (
      this.state.activeStep === 0 &&
      this.state.hasContract &&
      !this.state.editing
    )
      return false;
    if (
      this.state.activeStep === 1 &&
      this.state.successUpload &&
      !this.state.editing
    )
      return false;
    if (
      this.state.activeStep === 2 &&
      // this.state.cnpjData &&
      !this.state.editing
    )
      return false;
    if (
      this.state.activeStep === 3 &&
      this.state.successUpload &&
      !this.state.editing
    )
      return false;
    if (
      this.state.activeStep === 0 &&
      this.state.successUpload &&
      this.state.editing
    )
      return false;

    return true;
  };

  onSelectFile = file => {
    if (this.state.file) {
      this.setState({
        file: '',
        add: true,
        successUpload: false,
        afterUpload: false,
        error: ''
      });
    } else {
      this.setState({
        file,
        add: true,
        successUpload: false,
        afterUpload: false,
        error: '',
        loading: true
      });
      UserActions.uploadRechargeSheet(
        file,
        this.props.selectedContract.id,
        this.state.editing ? this.state.order_id : false
      ).then(response => {
        const success = response.data.success,
          data = response.data.response,
          message = response.data.message;
        // Arquivo com erros e precisa de ajustes
        if (
          response.data &&
          response.data.response &&
          !response.data.response.success &&
          response.status !== 500
        ) {
          return this.setState({
            errors: response.data.response.errors,
            warnings: response.data.response.warnings,
            afterUpload: true,
            download: true,
            loading: false,
            completed: [...this.state.completed, this.state.activeStep],
            activeStep: this.state.activeStep + 1,
            implantationErrors: data.errors.generic
              ? this.getGenerics(data.errors.generic)
              : {}
          });
        }
        if (
          response.data &&
          response.data.response &&
          response.data.response.success &&
          response.data.response.errors
        ) {
          return this.setState({
            errors: response.data.response.errors,
            implantationErrors: data.errors.generic
              ? this.getGenerics(data.errors.generic)
              : {},
            warnings: response.data.response.warnings,
            afterUpload: true,
            loading: false
          });
        }

        if (
          response.data &&
          response.data.response &&
          response.data.response.success &&
          response.data.response.warnings
        ) {
          return this.setState({
            completed: [...this.state.completed, this.state.activeStep],
            activeStep: this.state.activeStep + 1,
            warnings: response.data.response.warnings,
            afterUpload: true,
            loading: false,
            resume: response.data.response.resume,
            path: response.data.response.path,
            successUpload: true
          });
        }
        if (!response.data.success && response.status === 500)
          // Tipo de arquivo incorreto
          return this.setState({
            error: response.data.message,
            afterUpload: false,
            loading: false,
            file: ''
          });

        // Arquivo correto
        if (response.data.success) {
          return this.setState({
            completed: [...this.state.completed, this.state.activeStep],
            activeStep: this.state.activeStep + 1,
            resume: response.data.response.resume,
            path: response.data.response.path,
            successUpload: true,
            afterUpload: false,
            loading: false,
            hasContract: false
          });
        }
      });
    }
  };

  handleNext = () => {
    if (this.state.activeStep < 5) {
      this.setState({
        activeStep: this.state.activeStep + 1,
        completed: [...this.state.completed, this.state.activeStep]
      });
    }
  };

  handleBack = () => {
    if (this.state.activeStep === 1) {
      const completed = this.state.completed;
      completed.pop();
      this.setState({
        activeStep: this.state.activeStep - 1,
        completed,
        errorFinishOrder: '',
        file: '',
        add: true,
        successUpload: false,
        afterUpload: false,
        error: ''
      });
    } else if (this.state.activeStep > 0) {
      const completed = this.state.completed;
      completed.pop();
      this.setState({
        activeStep: this.state.activeStep - 1,
        completed,
        errorFinishOrder: ''
      });
    }
  };

  handleDiscountNext = obj => {
    this.setState({
      paymentSettings: obj
    });

    this.handleNext();
  };

  handlePaymentDone = () => {
    const { isPaymentDone } = this.state;
    this.setState({ isPaymentDone: !isPaymentDone });
  };

  render() {
    const errors = this.state.errors;
    const warnings = this.state.warnings;

    const { rechargesErrors, totalErrors, genericErrors } = contractErrors(
      errors
    );
    const {
      rechargesWarning,
      totalWarnings,
      genericWarning
    } = contractWarnings(warnings);

    const { classes } = this.props;

    const steps = !this.state.editing
      ? [
          'Selecione um arquivo',
          'Revisar Arquivo',
          'Pagamento',
          'Revisar Pedido'
        ]
      : ['Selecione um arquivo', 'Pagamento', 'Revisar Pedido'];
    return (
      <div className='app-wrapper'>
        <Grid container justify='space-between'>
          <ContainerHeader title='Pedidos' />
          <a
            download
            href='assets/files/template_planilha_recarga_novo_06_2020.xlsx'
            target='_blank'
            style={{ all: 'unset' }}
          >
            <Button
              size='small'
              variant='outlined'
              color='primary'
              aria-label='Baixar Planilha de Exemplo'
              style={{ marginBottom: 16 }}
            >
              <IconCloudDownload style={{ marginRight: 16 }} />
              BAIXAR PLANILHA DE EXEMPLO
            </Button>
          </a>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Paper>
              {/* Header */}
              <Grid container style={{ padding: 16 }} justify='center'>
                <Typography variant='h2' align='center' color='secondary'>
                  Nova Recarga
                </Typography>
              </Grid>

              {/* Stepper */}
              <Grid container justify='center'>
                <Grid item xs={10}>
                  <Stepper
                    alternativeLabel
                    activeStep={this.state.activeStep}
                    style={{ marginBottom: 24 }}
                  >
                    {steps.map((label, index) => {
                      return (
                        <Step key={label}>
                          <StepButton
                            disabled
                            completed={this.state.completed.includes(index)}
                          >
                            {label}
                          </StepButton>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Grid>
              </Grid>

              {/* DEPOIS DE SELECIONAR O CONTRATO */}
              {((this.state.activeStep === 0 && !this.state.editing) ||
                (this.state.activeStep === 1 && !this.state.editing) ||
                (this.state.editing && this.state.activeStep === 0)) && (
                <AfterSelectionForm
                  contract={this.props.selectedContract}
                  rechargesOnly={true}
                  contractId={this.props.selectedContract.id}
                  afterUpload={this.state.afterUpload}
                  loading={this.state.loading}
                  error={this.state.error}
                  file={this.state.file}
                  onFormSubmit={this.onFormSubmit}
                  onSelectFile={this.onSelectFile}
                  cleanFile={this.handleBack}
                  success={this.state.successUpload}
                  resume={this.state.resume}
                  totalErrors={totalErrors}
                  warnings={{
                    rechargesWarning,
                    totalWarnings
                  }}
                  implantationErrors={this.state.implantationErrors}
                  renderErrorTabs={() => (
                    <AfterUploadTabs
                      rechargesOnly={true}
                      classes={classes}
                      rechargesErrors={rechargesErrors}
                      rechargesWarning={rechargesWarning}
                      genericErrors={genericErrors}
                      genericWarning={genericWarning}
                    />
                  )}
                  renderSuccessTable={() => (
                    <UploadSuccessTable
                      rechargesOnly={true}
                      classes={classes}
                      resume={this.state.resume}
                      loading={this.state.loading}
                      cnpjData={this.props.selectedContract}
                      responsable={this.state.responsable}
                      handleBack={this.handleBack}
                      finishOrder={
                        !this.state.editing
                          ? this.finishOrder
                          : this.updateOrder
                      }
                      editing={this.state.editing}
                      errorFinish={this.state.errorFinishOrder}
                      contractName={this.state.contractId.label}
                      file={this.state.file}
                    />
                  )}
                />
              )}

              {((this.state.successUpload &&
                this.state.activeStep === 2 &&
                !this.state.editing) ||
                (this.state.successUpload &&
                  this.state.editing &&
                  this.state.activeStep === 1)) && (
                <PaymentSettings
                  resume={this.state.resume}
                  cnpjData={this.props.selectedContract}
                  responsable={this.props.selectedContract}
                  contractName={this.state.contractId.label}
                  contractId={this.props.selectedContract.id}
                  file={this.state.file}
                  back={this.handleBack}
                  next={this.handleDiscountNext}
                  paymentSettings={this.state.paymentSettings}
                />
              )}

              {((this.state.successUpload &&
                this.state.activeStep === 3 &&
                !this.state.editing) ||
                (this.state.successUpload &&
                  this.state.editing &&
                  this.state.activeStep === 2)) && (
                <OrderConfirmation
                  resume={this.state.resume}
                  cnpjData={this.props.selectedContract}
                  responsable={this.props.selectedContract}
                  contractName={this.state.contractId.label}
                  file={this.state.file}
                  payment={this.state.paymentSettings}
                  handlePaymentDone={this.handlePaymentDone}
                  isPaymentDone={this.state.isPaymentDone}
                  back={this.handleBack}
                />
              )}

              <Grid
                id='UMIDLOCO'
                container
                justify='flex-end'
                style={{ paddingTop: 32, paddingBottom: 16, paddingRight: 16 }}
              >
                {this.props.location.filter && this.state.activeStep === 0 ? (
                  <Link
                    to={{
                      pathname: '/app/pedidos-emitidos',
                      filter: {
                        hasFilter: this.props.location.filter ? true : false,
                        func: this.props.location.filter
                      }
                    }}
                  >
                    <Button
                      color='primary'
                      variant='outlined'
                      style={{ marginRight: 16 }}
                    >
                      Voltar
                    </Button>
                  </Link>
                ) : (
                  this.state.activeStep !== 2 && (
                    <Button
                      variant='outlined'
                      onClick={this.handleBack}
                      color='primary'
                      style={{ marginRight: 16 }}
                    >
                      Voltar
                    </Button>
                  )
                )}
                {((this.state.activeStep !== 2 && !this.state.editing) ||
                  (this.state.activeStep !== 1 && this.state.editing)) &&
                  ((this.state.activeStep !== 3 && !this.state.editing) ||
                    (this.state.activeStep !== 2 && this.state.editing)) && (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={this.handleNext}
                      disabled={this.disableButton()}
                    >
                      Continuar
                    </Button>
                  )}
                {((this.state.activeStep === 3 && !this.state.editing) ||
                  (this.state.activeStep === 2 && this.state.editing)) && (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={this.finishOrder}
                  >
                    Finalizar Pedido
                  </Button>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

NewOrder.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ contract }) => {
  const { selectedContract } = contract;
  return { selectedContract };
};

export default connect(mapStateToProps, {
  modalAlertShowLoading,
  modalEmitAlert
})(withStyles(styles)(withRouter(NewOrder)));
