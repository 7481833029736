import React, { Component } from 'react';
import './index.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ContainerHeader from '../../../../../components/ContainerHeader/index';
import { Grid, FormControl, Typography } from '@material-ui/core';
import { employeeService } from '../../../../../services/employeeService';
import YaloSearchFilter from '../../../../../components/YaloSearchFilter';
import GenericTable from '../../../../../components/GenericTable/GenericTable';

import SelectUi from '../../../../../components/ui/select/index.js';

const STATUS = [
  { VALUE: '', LABEL: 'Todos' },
  { VALUE: 'active', LABEL: 'Ativos' },
  { VALUE: 'inactive', LABEL: 'Desligados' },
];
const dataFields = [
  'has_dismissed',
  'cpf',
  'person_name',
  'dismissed_date',
];

const labels = ['Status', 'CPF', 'Nome', 'Atualizado em'];

const labelsEnum = Object.fromEntries(
  dataFields.map((key, index) => [key, labels[index]])
);

class EmployeesConsulting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: [],
      filteredEmployees: [],
      loading: false,
      search: '',
      statusFilter: '',
    };
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedContract.id !== this.props.selectedContract.id) {
      this.update();
    }
  }

  update = () => {
    if (!this.props.selectedContract.id) {
      return;
    }
    this.setState({ loading: true });
    this.getAccounts(this.props.selectedContract);
  };

  getAccounts = (selectedContract) => {
    employeeService
      .getAccounts(selectedContract.id)
      .then((response) => {
        this.handleResponseEmployees(response);
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  handleResponseEmployees = (response) => {
    const employeesData = response.data.response;
    if (response.data.success) {
      this.setState({
        employees: employeesData,
        filteredEmployees: this.applyFilters(employeesData),
        loading: false,
      });
    } else {
      this.setState({ loading: false });
    }
  };

  applyFilters = (employees) => {
    const { search, statusFilter } = this.state;

    return employees
      .filter(
        (employee) =>
          (employee.person_name.toLowerCase().includes(search.toLowerCase()) ||
            employee.cpf.includes(search)) &&
          (statusFilter === '' ||
            (statusFilter === 'active' && !employee.has_dismissed) ||
            (statusFilter === 'inactive' && employee.has_dismissed))
      )
      .map((employee) => ({
        ...employee,
        has_dismissed: employee.has_dismissed ? 'Desligado' : 'Ativo', // Transformação
      }));
  };

  handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();

    this.setState(
      { search: searchTerm },
      () => {
        this.setState({
          filteredEmployees: this.applyFilters(this.state.employees),
        });
      }
    );
  };

  handleStatusChange = (event) => {
    const status = event.target.value;

    this.setState(
      { statusFilter: status },
      () => {
        this.setState({
          filteredEmployees: this.applyFilters(this.state.employees),
        });
      }
    );
  };

  render() {
    const { filteredEmployees, loading, search, statusFilter, employees } = this.state;

    return (
      <div className='app-wrapper'>
        <Grid container direction="column" spacing={3} style={{ gap: '24px' }}>
          <Grid item xs={12}>
            <ContainerHeader title='Funcionários' />
          </Grid>
          
          {!employees.length ? (
            <Grid item xs={12}>
              <Typography variant="h6" color="textSecondary" style={{ margin: '20px 0' }}>
                Selecione um contrato
              </Typography>
            </Grid>
          ) : (
            <React.Fragment>
              <Grid item xs={12}>
                <YaloSearchFilter
                  onChange={this.handleSearch}
                  value={search}
                  placeholder='Busca por nome ou cpf'
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl>
                  <SelectUi
                    value={statusFilter}
                    onChange={this.handleStatusChange}
                    label="Status"
                    options={STATUS.map(el => ({
                      value: el.VALUE,
                      name: el.LABEL,
                    }))}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <GenericTable
                  data={filteredEmployees}
                  dataFields={dataFields}
                  labelsEnum={labelsEnum}
                  loading={loading}
                  pageSize={20}
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ contract }) => {
  const { selectedContract } = contract;
  return { selectedContract };
};

export default connect(mapStateToProps, {})(withRouter(EmployeesConsulting));
