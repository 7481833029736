export const style = {
  header: { color:"#707070" ,fontWeight: "700", fontSize:"16px", fontFamily:"Sua dr consulta", padding: ".2rem", whiteSpace: "nowrap" },
  body: { fontWeight: "300", padding: ".2rem", whiteSpace: "nowrap" },
  divider: { margin: ".50rem auto" },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
};
