import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import TextField from '../../../../../../components/ui/TextField';
import CircularProgress from '../../../../../../components/ui/progress/CustomizedCircularProgress';
import { styles } from '../styles';

class CompanyAddress extends Component {
  streetNumberInput = React.createRef();

  state = {
    address: {
      cep: '',
      rua: '',
      estado: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      pais: '',
    },
    errors: {
      cep: 'Por Favor, insira um CEP.',
      rua: '',
      estado: '',
      numero: 'Por Favor, insira um número.',
      complemento: '',
      bairro: '',
      cidade: '',
      pais: '',
    },
    touched: {
      cep: false,
      numero: false,
    },
    loading: false,
  };

  componentDidMount() {
    this.props.setActiveComponentStep(this);
    if (this.props.address.cep !== undefined) {
      const errors = {
        cep:
          this.props.address.cep === null || this.props.address.cep === ''
            ? 'Por favor, insira um CEP.'
            : '',
        rua: '',
        estado: '',
        numero:
          this.props.address.numero === null ||
          this.props.address.numero === ''
            ? 'Por favor, insira um numero.'
            : '',
        complemento: '',
        bairro: '',
        cidade: '',
        pais: '',
      };

      this.setState({
        address: this.props.address,
        errors,
      });

      this.props.currentComponentStepChange(this.inValidateForm(errors));
    }
  }

  updateCurrentState() {
    this.props.setInputs(this.state.address);
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({ address: { ...this.state.address, [name]: value } });
  }
  handleInputCepChange(event) {
    const target = event.target;
    const cep = target.value.replace(/[^0-9]/g, '');
    this.setState({ address: { ...this.state.address, [target.name]: target.value } });

    if (!/^[0-9]{8}$/.test(cep)) {
      return;
    }

    this.setState({ loading: true });
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .catch((_error) => {
        this.setState({
          errors: {
            ...this.state.errors,
            cep: 'O CEP informado é inválido ou inexistente!',
          },
          touched: {
            ...this.state.touched,
            cep: true,
          },
          fetched: false,
          loading: false,
        });
        target.focus();
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.erro) {
          return {
            logradouro: '',
            bairro: '',
            localidade: '',
            uf: '',
            error: 'Verifique se digitou o CEP corretamente!',
          };
        }
        return data;
      })
      .then((data) => {
        this.setState({
          address: {
            ...this.state.address,
            rua: data.logradouro,
            bairro: data.bairro,
            cidade: data.localidade,
            estado: data.uf,
            pais: 'Brasil',
          },
          errors: {
            ...this.state.errors,
            cep: data.error || '',
            bairro: '',
            cidade: '',
            rua: '',
            estado: '',
            pais: '',
          },
          touched: {
            ...this.state.touched,
            cep: true,
            bairro: true,
            cidade: true,
            rua: true,
            estado: true,
            pais: true,
          },
          fetched: true,
          loading: false,
        });
        data.error ? target.focus() : this.streetNumberInput.current.focus();
      });
  }

  validate = (e) => {
    let error = e.target.value ? '' : 'Por Favor, preencha este campo.';
    if (e.target.name === 'cep' && !e.target.value) {
      error = 'Por Favor, preencha somente com números.';
    } else if (
      e.target.name === 'cep' &&
      !/^[0-9]{5}-[0-9]{3}$/.test(e.target.value)
    ) {
      error = 'CEP deve ser composto apenas por 8 números';
    }

    if (e.target.name === 'numero') {
      error =
        e.target.value &&
        parseInt(e.target.value) &&
        parseInt(e.target.value) > 0
          ? ''
          : 'Por Favor, preencha somente com números.';
    }

    if (e.target.name === 'complemento') {
      error = '';
    }

    const errors = { ...this.state.errors, [e.target.name]: error };
    this.setState({
      errors,
      touched: { ...this.state.touched, [e.target.name]: true },
    });

    this.props.currentComponentStepChange(this.inValidateForm(errors));
  };

  inValidateForm(errors) {
    const {
      cep,
      numero,
    } = errors;
    if (!cep && !numero) {
      return false;
    }

    return true;
  };

  render() {
    const { address, errors, touched } = this.state;
    const { classes } = this.props;
    return (
      <Fragment>
        <Grid
          container
          direction='column'
          justify='flex-start'
          alignItems='flex-start'
          spacing={0}
          className={classes.root}
        >
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                id='cep-input'
                label='CEP'
                name='cep'
                type='text'
                mask='99999-999'
                value={address.cep}
                onChange={(e) => this.handleInputCepChange(e)}
                onBlur={(e) => this.validate(e)}
                error={!!(this.state.touched.cep && !!this.state.errors.cep)}
                disabled={this.props.confirmation || this.state.loading}
                endAdornment={ <CircularProgress hidden={!this.state.loading} /> }
                formInputHelper={{
                  title: 'CEP',
                  text: errors.cep,
                  showIcon: true,
                  isShow: !!(this.state.touched.cep && !!this.state.errors.cep),
                }}
              />
            </Grid>
            <Grid item xs={8} md={8} className={classes.columnSpacer}>
              <TextField
                small={true}
                id='rua-input'
                label='Rua'
                name='rua'
                type='text'
                value={address.rua}
                onChange={(e) => this.handleInputChange(e)}
                error={!!(touched.rua && !!errors.rua)}
                disabled={this.props.confirmation || this.state.loading}
                formInputHelper={{
                  title: 'Rua',
                  text: errors.rua,
                  showIcon: true,
                  isShow: !!(touched.rua && !!errors.rua),
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                label='Número'
                small={true}
                id='numero-input'
                name='numero'
                inputRef={this.streetNumberInput}
                value={address.numero}
                onChange={(e) => this.handleInputChange(e)}
                onBlur={(e) => this.validate(e)}
                error={touched.numero && errors.numero !== ''}
                type='text'
                disabled={this.props.confirmation || this.state.loading}
                formInputHelper={{
                  title: 'Número',
                  text: errors.numero,
                  showIcon: true,
                  isShow: touched.numero && errors.numero !== '',
                }}
              />
            </Grid>
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='Complemento'
                type='text'
                name='complemento'
                id='complemento-input'
                value={address.complemento}
                onChange={(e) => this.handleInputChange(e)}
                disabled={this.props.confirmation || this.state.loading}
                formInputHelper={{
                  title: 'Complemento',
                  text: '',
                  showIcon: false,
                  isShow: false,
                }}
              />
            </Grid>
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='Bairro'
                name='bairro'
                id='bairro-input'
                type='text'
                value={address.bairro}
                onChange={(e) => this.handleInputChange(e)}
                disabled={this.props.confirmation || this.state.loading}
                formInputHelper={{
                  title: 'Bairro',
                  text: '',
                  showIcon: false,
                  isShow: false,
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='Cidade'
                id='cidade-input'
                name='cidade'
                type='text'
                value={address.cidade}
                onChange={(e) => this.handleInputChange(e)}
                fullWidth
                disabled={
                  this.state.fetched ||
                  this.props.confirmation ||
                  this.state.loading
                }
                formInputHelper={{
                  title: 'Cidade',
                  text: '',
                  showIcon: false,
                  isShow: false,
                }}
              />
            </Grid>
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='Estado'
                id='estado-input'
                name='estado'
                type='text'
                value={address.estado}
                onChange={(e) => this.handleInputChange(e)}
                disabled={
                  this.state.fetched ||
                  this.props.confirmation ||
                  this.state.loading
                }
                formInputHelper={{
                  title: 'Estado',
                  text: '',
                  showIcon: false,
                  isShow: false,
                }}
              />
            </Grid>
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='País'
                id='pais-input'
                name='pais'
                type='text'
                value={address.pais}
                onChange={(e) => this.handleInputChange(e)}
                disabled={
                  this.state.fetched ||
                  this.props.confirmation ||
                  this.state.loading
                }
                formInputHelper={{
                  title: 'País',
                  text: '',
                  showIcon: false,
                  isShow: false,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(CompanyAddress);
