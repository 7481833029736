import React, { Component, Fragment } from 'react';
import { Grid, FormControl, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { utils } from '../../../../utils';

import TextField from '../../../../../../components/ui/TextField';
import Select from '../../../../../../components/ui/select';
import { styles } from '../styles';

const patternCellphone = `^((\\(0?[1-9][0-9]\\))|(0?[1-9][0-9]))[ -.]?([1-9][0-9]{3,4})[ -.]?([0-9]{4})$`;
class CompanyInfo extends Component {
  state = {
    info: {
      cnpj: '',
      nome_fantasia: '',
      razao_social: '',
      tipo_propriedade: '',
      ano_fundacao: '',
      numero_funcionarios: 0,
      cnae: '',
      ramo_atividade: '',
      telefone: '',
      printed_name_card: '',
    },
    errors: {
      cnpj: 'Por Favor, insira um CNPJ',
      nome_fantasia: '',
      razao_social: 'Por Favor, insira uma razão social',
      ano_fundacao: '',
      numero_funcionarios: '',
      cnae: '',
      ramo_atividade: '',
      telefone: '',
      printed_name_card: '',
    },
    touched: {
      cnpj: false,
      razao_social: false,
      telefone: false,
      printed_name_card: false,
    },
    character_counter: 30,
    disabled_ramo: true,
    loading: false,
  };

  componentDidMount() {
    this.props.setActiveComponentStep(this);
    if (this.props.info.cnpj !== undefined) {
      const errors = {
        cnpj:
          this.props.info.cnpj === null || this.props.info.cnpj === ''
            ? 'Por favor, insira o CNPJ.'
            : '',
        nome_fantasia: '',
        razao_social:
          this.props.info.razao_social === null ||
          this.props.info.razao_social === ''
            ? 'Por favor, insira a razao social.'
            : '',
        tipo_propriedade: '',
        ano_fundacao: '',
        numero_funcionarios: '',
        cnae: '',
        ramo_atividade: '',
        telefone: '',
      }
      this.setState({
        info: this.props.info,
        errors,
      });

      this.props.currentComponentStepChange(this.InValidateForm(errors));
    } else {
      this.props.currentComponentStepChange(this.InValidateForm(this.state.errors));
    }

  }

  updateCurrentState() {
    this.props.setInputs(this.state.info);
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    this.setState({ info: { ...this.state.info, [name]: value } });
  }

  handlePrintedNameCard(event) {
    const target = event.target;
    let value = target.value;

    this.setState({ character_counter: 30 - value.length });

    this.setState({ info: { ...this.state.info, printed_name_card: value } });
  }

  handleCellphone(event) {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    value = utils.handleCellphone(value);

    this.setState({ info: { ...this.state.info, telefone: value } });
  }

  handleCNAE = async (e) => {
    const { info } = this.state;
    const value = e.target.value;
    if (!e.target.value) {
      this.validate(e);
    } else {
      this.setState({ loading: true });
      try {
        const results = await $.getJSON(
          'https://servicodados.ibge.gov.br/api/v2/cnae/classes/' + info.cnae,
        );

        if (results.descricao) {
          this.setState({
            info: { ...info, ramo_atividade: results.descricao },
            touched: { ...this.state.touched, cnae: false },
            errors: { ...this.state.errors, cnae: '', ramo_atividade: '' },
          });
        } else {
          // Adicionar erro de CNAE nao encontrado
          this.setState({
            info: { ...info, ramo_atividade: '' },
            touched: { ...this.state.touched, cnae: true },
            errors: { ...this.state.errors, cnae: 'CNAE inexistente' },
          });
          document.getElementById('cnae-input').focus();
        }
      } catch (e) {
        this.setState({
          info: { ...info, ramo_atividade: '' },
          touched: { ...this.state.touched, cnae: true },
          errors: {
            ...this.state.errors,
            cnae: '',
            ramo_atividade: '',
          },
          disabled_ramo: false,
        });
      }
      this.setState({ loading: false });
    }
    if (value !== '') {
      if (value.length <= 4 || value < 0) {
        this.setState({
          touched: { ...this.state.touched, cnae: true },
          errors: {
            ...this.state.errors,
            cnae: 'Por favor, insira um CNAE válido',
          },
        });
      }
    }
  };

  validate = (e) => {
    let error = e.target.value ? '' : 'Por Favor, preencha este campo.';
    const name = e.target.name;
    const value = e.target.value;
    const digitRegex = /^[0-9\b]+$/;
    const celPhoneRegex = new RegExp(patternCellphone);

    if (name === 'ano_fundacao' && value !== '') {
      const year = new Date().getFullYear();
      error =
        digitRegex.test(value) && value >= 1500 && value <= year
          ? ''
          : 'Por Favor, insira um ano válido.';
    } else if (name === 'ano_fundacao' && value === '') {
      error = 'Por Favor, insira um ano válido.';
    }

    if (name === 'cnae') {
      error = '';
    }
    if (name === 'numero_funcionarios' && value !== '') {
      error = !digitRegex.test(value)
        ? 'Por Favor, insira o número de funcionários válido.'
        : '';
    } else if (name === 'numero_funcionarios' && value === '') {
      error = 'Por favor, insira o número de funcionários.';
    }

    if (name === 'telefone' && value !== '') {
      const b = celPhoneRegex.test(value);
      error = b ? '' : 'Por favor, insira um telefone válido.';
    } else if (name === 'telefone' && value === '') {
      error = 'Por favor, insira um telefone.';
    }

    if (
      name === 'nome_fantasia' &&
      value.length <= 30 &&
      !this.state.touched.printed_name_card
    ) {
      this.setState({
        character_counter: 30 - value.length,
        errors: { ...this.state.errors, printed_name_card: '' },
        info: { ...this.state.info, printed_name_card: e.target.value }
      });
    }

    const errors = { ...this.state.errors, [name]: error };

    this.setState({
      errors,
      touched: { ...this.state.touched, [name]: true },
    });

    this.props.currentComponentStepChange(this.InValidateForm(errors));
  };

  InValidateForm(errors) {
    const {
      cnpj,
      nome_fantasia,
      cnae,
      telefone,
      ano_fundacao,
      razao_social,
      ramo_atividade,
      printed_name_card,
      numero_funcionarios,
    } = errors;

    if (
      !cnpj &&
      !nome_fantasia &&
      !cnae &&
      !telefone &&
      !ano_fundacao &&
      !razao_social &&
      !ramo_atividade &&
      !printed_name_card &&
      !numero_funcionarios
    ) {
      return false;
    }

    return true;
  };

  render() {
    const { info, touched, errors, character_counter } = this.state;
    const { classes } = this.props;

    return (
      <Fragment>
        <Grid
          container
          direction='column'
          justify='flex-start'
          alignItems='flex-start'
          spacing={0}
          className={classes.root}
        >
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='CNPJ'
                mask='99.999.999/9999-99'
                id='cnpj'
                name='cnpj'
                type='text'
                required={true}
                value={info.cnpj}
                onChange={(e) => this.handleInputChange(e)}
                onBlur={(e) => this.validate(e)}
                error={touched.cnpj && errors.cnpj !== ''}
                disabled={
                  this.props.editing ||
                  this.props.confirmation ||
                  this.state.loading
                }
                formInputHelper={{
                  title: 'CNPJ',
                  text: errors.cnpj,
                  showIcon: true,
                  isShow: touched.cnpj && errors.cnpj !== '',
                }}
              />
            </Grid>
            <Grid item xs={8} md={8} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='Nome Fantasia'
                id='nome_fantasia'
                name='nome_fantasia'
                type='text'
                required={true}
                disabled={this.props.confirmation || this.state.loading}
                value={info.nome_fantasia}
                onChange={(e) => this.handleInputChange(e)}
                onBlur={(e) => this.validate(e)}
                error={touched.nome_fantasia && errors.nome_fantasia !== ''}
                formInputHelper={{
                  title: 'Nome Fantasia',
                  text: errors.nome_fantasia,
                  showIcon: true,
                  isShow: touched.nome_fantasia && errors.nome_fantasia !== '',
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='Razão Social'
                id='razao_social'
                name='razao_social'
                type='text'
                required={true}
                disabled={this.props.confirmation || this.state.loading}
                value={info.razao_social}
                onChange={(e) => this.handleInputChange(e)}
                onBlur={(e) => this.validate(e)}
                error={touched.razao_social && errors.razao_social !== ''}
                formInputHelper={{
                  title: 'Razão Social',
                  text: errors.razao_social,
                  showIcon: true,
                  isShow: touched.razao_social && errors.razao_social !== '',
                }}
              />
            </Grid>
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <FormControl fullWidth margin='none'>
                <Select
                  name='tipo_propriedade'
                  label='Tipo Propriedade'
                  value={info.tipo_propriedade}
                  defaultValue='Selecione'
                  isDisabled={this.props.confirmation || this.state.loading}
                  onChange={(e) => {
                    this.handleInputChange(e);
                    this.validate(e);
                  }}
                  onBlur={(e) => this.validate(e)}
                  error={false}
                  options={[
                    { name: 'Ltda.', value: 'Ltda.' },
                    { name: 'S.A.', value: 'S.A.' },
                    { name: 'M.E.', value: 'M.E.' },
                    { name: 'Outro', value: 'Outro' },
                  ]}
                ></Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='Ano Fundação'
                id='ano_fundacao'
                name='ano_fundacao'
                type='number'
                inputProps={{
                  maxLength: 4,
                  min: 1500,
                  max: 2099,
                  step: 1,
                  pattern: '[0-9]{4}',
                  inputMode: 'numeric',
                }}
                required={true}
                disabled={this.props.confirmation || this.state.loading}
                value={info.ano_fundacao}
                onChange={(e) => this.handleInputChange(e)}
                onBlur={(e) => this.validate(e)}
                error={touched.ano_fundacao && errors.ano_fundacao !== ''}
                formInputHelper={{
                  title: 'Ano Fundação',
                  text: errors.ano_fundacao,
                  showIcon: true,
                  isShow: touched.ano_fundacao && errors.ano_fundacao !== '',
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='Número de Funcionários'
                id='numero_funcionarios'
                name='numero_funcionarios'
                inputProps={{
                  maxLength: 4,
                  min: 0,
                  max: 9999,
                  step: 1,
                  pattern: '[0-9]{4}',
                  inputMode: 'numeric',
                }}
                type='number'
                required={true}
                disabled={this.props.confirmation || this.state.loading}
                value={info.numero_funcionarios}
                onChange={(e) => this.handleInputChange(e)}
                onBlur={(e) => this.validate(e)}
                error={
                  touched.numero_funcionarios &&
                  errors.numero_funcionarios !== ''
                }
                formInputHelper={{
                  title: 'Número de Funcionários',
                  text: errors.numero_funcionarios,
                  showIcon: true,
                  isShow:
                    touched.numero_funcionarios &&
                    errors.numero_funcionarios !== '',
                }}
              />
            </Grid>
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='CNAE'
                id='cnae-input'
                name='cnae'
                type='number'
                inputProps={{
                  maxLength: 6,
                  min: 0,
                  max: 999999,
                  step: 1,
                  pattern: '[0-9]',
                  inputMode: 'numeric',
                }}
                required={false}
                disabled={this.props.confirmation || this.state.loading}
                value={info.cnae}
                onChange={(e) => this.handleInputChange(e)}
                onBlur={(e) => this.handleCNAE(e)}
                error={touched.cnae && errors.cnae !== ''}
                formInputHelper={{
                  title: 'CNAE',
                  text: errors.cnae,
                  showIcon: true,
                  isShow: touched.cnae && errors.cnae !== '',
                }}
              />
              <small className={classes.linkCNAE}>
                <a
                  href='https://solucoes.receita.fazenda.gov.br/Servicos/cnpjreva/Cnpjreva_Solicitacao.asp'
                  target='_blank'
                >
                  <div>
                    <Icon className={classes.iconSmall}>error</Icon>
                    <span>Consulte o seu CNAE </span>
                    <Icon className={classes.iconSmall}>chevron_right</Icon>
                  </div>
                </a>
              </small>
            </Grid>
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='Ramo de Atividade'
                id='ramo_atividade'
                name='ramo_atividade'
                type='text'
                required={false}
                disabled={this.props.confirmation || this.state.disabled_ramo}
                value={info.ramo_atividade}
                onChange={(e) => this.handleInputChange(e)}
                onBlur={(e) => this.validate(e)}
                error={touched.ramo_atividade && errors.ramo_atividade !== ''}
                formInputHelper={{
                  title: 'Ramo de Atividade',
                  text: errors.ramo_atividade,
                  showIcon: true,
                  isShow:
                    touched.ramo_atividade && errors.ramo_atividade !== '',
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='Telefone'
                id='telefone'
                name='telefone'
                type='tel'
                inputProps={{
                  maxLength: 16,
                  pattern: patternCellphone,
                }}
                required={true}
                disabled={this.props.confirmation || this.state.loading}
                value={info.telefone}
                onChange={(e) => this.handleCellphone(e)}
                onBlur={(e) => this.validate(e)}
                error={touched.telefone && errors.telefone !== ''}
                formInputHelper={{
                  title: 'Telefone',
                  text: errors.telefone,
                  showIcon: true,
                  isShow: touched.telefone && errors.telefone !== '',
                }}
              />
            </Grid>
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='Nome Impresso no Cartão'
                id='printed_name_card'
                name='printed_name_card'
                type='text'
                inputProps={{
                  maxLength: 30,
                }}
                required={true}
                disabled={this.props.confirmation || this.state.loading}
                value={info.printed_name_card}
                onChange={(e) => this.handlePrintedNameCard(e)}
                onBlur={(e) => this.validate(e)}
                error={
                  touched.printed_name_card && errors.printed_name_card !== ''
                }
                formInputHelper={{
                  title: 'Nome Impresso no Cartão',
                  text: errors.printed_name_card,
                  showIcon: true,
                  isShow:
                    touched.printed_name_card &&
                    errors.printed_name_card !== '',
                }}
              />
              <small className={classes.inputTextCharLimit}>
                <Icon className={classes.iconSmall}>person_edit</Icon>
                <span className='title'>Caracteres disponíveis: </span>
                <span className='value'>{character_counter}</span>
              </small>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(CompanyInfo);
