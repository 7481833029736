import React, { Component } from 'react';

import { Grid, Typography, Button } from '@material-ui/core';

import { utils } from '../../../../../../../app/routes/utils';

class EmProcessamento extends Component {
  render() {
    const { order } = this.props;
    return (
      <div className="app-wrapper">
        <Grid container direction="row" justify="space-between">
          <Typography
            variant="subtitle2"
            className={utils.statusColor(order.order_status)}
          >
            <ins>{order.order_status}</ins>
          </Typography>
          <Grid container item xs={4} justify="flex-end" className="mr-4">
            <Typography variant="subtitle1" className="mr-1">
              Pagamento identificado em:
            </Typography>
            <Typography> {utils.momentFromDate(order.payment_date)}</Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default EmProcessamento;
