import React, { Fragment } from 'react';
import { utils } from '../../../../utils';
import { Typography, Grid, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CallMadeRounded } from "@material-ui/icons";



export default ({ relations, id }) => (
  <Grid container spacing={24}>
    {renderRelations(relations, id)}
  </Grid>
);

function renderRelations(relations, id) {
  const evenRelations = [];
  const oddRelations = [];

  const data = relations.map(item => ({ ...item, cnpj: utils.formatCNPJ(item.cnpj) }))

  relations.forEach((element, index) => {
    let name = (
      <Link
        to={{
          pathname: `/app/pessoa-juridica/consulting/${element.cnpj}`,
          redirect: id
        }}
      >
        <Typography
          variant="subtitle2"
          color="primary"
          key={element.name}
          style={{ textDecoration: 'underline' }}
        >
          {element.name}
        </Typography>
      </Link>
    );
    let cnpj = (
      <Typography variant="subtitle1" key={element.cnpj}>
        {utils.formatCNPJ(element.cnpj)}
      </Typography>
    );
    if (index % 2 === 0) {
      evenRelations.push(name, cnpj);
    } else {
      oddRelations.push(name, cnpj);
    }
  });
  const styles = {
    tablleCellHeader: {
      fontSize: 16,
      color: '#707070',
      fontFamily: 'Sua dr consulta',
      borderBottom: '1px solid #707070',
      fontWeight: 700
    },
    tableCell: {
      fontFamily: 'Inter',
      fontSize: 14,
      color: '#707070',
      fontWeight: 400
    }
  }


  return (
    <Fragment>
      <Grid xs={12} style={{ marginRight: 26 }}>

        <Paper>
          <Table>
            <TableHead>
              <TableRow style={styles.tablleCellHeader}>
                <TableCell style={styles.tablleCellHeader} >CNPJ</TableCell>
                <TableCell style={styles.tablleCellHeader} >Nome Fantasia</TableCell>
                <TableCell style={styles.tablleCellHeader} >Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(row => (
                <TableRow key={row.id}>
                  <TableCell> {row.cnpj}</TableCell>
                  <TableCell style={styles.tableCell} component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>
                    <Link style={{ all: 'unset' }}
                      to={{
                        pathname: `/app/pessoa-juridica/consulting/${row.cnpj.match(/\d+/g).join('')}`,
                        redirect: id
                      }}
                    >
                      <CallMadeRounded />
                    </Link>

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>



      </Grid>


      {/*   <Grid item xs={6}>
        <Grid container>
          <Grid item xs={6}>
            {evenRelations.filter((el, ind) => ind % 2 === 0)}
          </Grid>
          <Grid item xs={6}>
            {evenRelations.filter((el, ind) => ind % 2 === 1)}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={6}>
            {oddRelations.filter((el, ind) => ind % 2 === 0)}
          </Grid>
          <Grid item xs={6}>
            {oddRelations.filter((el, ind) => ind % 2 === 1)}
          </Grid>
        </Grid>
      </Grid> */}
    </Fragment>
  );
}
