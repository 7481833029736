import React from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import configureStore, { history } from './store';
import App from './containers/App';
import { FeatureFlagsProvider } from '~/contexts/FeatureFlagsContext';

export const store = configureStore();

const MainApp = () => (
  <Provider store={store}>
    <FeatureFlagsProvider>
      <Router history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </Router>
    </FeatureFlagsProvider>
  </Provider>
);

export default MainApp;
