export const salesEnum = [
  "orderDate",
  "name",
  "paymentDate",
  "orderId",
  "paymentMethod",
  "store",
  "planName",
  "gender",
  "clientType",
  "paymentStatus",
];

export const labels = [
  "Data do Pedido",
  "Nome do Cliente",
  "Data do Pagamento",
  "ID do Pedido",
  "Método de Pagamento",
  "Loja",
  "Nome do Plano",
  "Gênero",
  "Tipo de Cliente",
  "Status do Pagamento",
];

export const date = [
  "orderDate",
  "paymentDate",
  "paymentDateSlip"
];

export const salesLabelsEnum = Object.fromEntries(
  salesEnum.map((key, index) => [key, labels[index]])
);