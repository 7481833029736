// @inheritedComponent InputBase

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MaterialInputBase from '@material-ui/core/InputBase';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactInputMask from 'react-input-mask';

export const styles = (theme) => {
  return {
    root: {
      position: 'relative',
      borderRadius: '8px',
      border: '1px solid var(--color-neutral-800)',
      padding: 'var(--spacing-3xs) var(--spacing-3xs)',
      margin: '0px',
      color: 'var(--color-neutral-800)',
    },
    input: {
      textTransform: 'none',
      fontFamily: 'Sua dr consulta',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '20px',
      color: 'var(--color-neutral-800)',
      padding: '2px 0px',
      margin: '0px',
    },
    /* Styles applied to the root element if the component is focused. */
    focused: {
      border: '2px solid var(--color-neutral-800)',
    },
    /* Styles applied to the root element if `disabled={true}`. */
    disabled: {
      backgroundColor: 'var(--color-neutral-300)',
    },
    /* Styles applied to the root element if `error={true}`. */
    error: {
      border: '2px solid var(--color-feedback-danger-900)',
      backgroundColor: 'var(--color-feedback-danger-100)',
    },
    warning: {
      border: '2px solid var(--color-feedback-warning-900)',
      backgroundColor: 'var(--color-feedback-warning-100)',
    },
    /* Styles applied to the `input` element if `startAdornment` is provided. */
    inputAdornedStart: {
      margin: 'auto',
      padding: '0px',
      maxHeight: '24px',
    },
    /* Styles applied to the `input` element if `endAdornment` is provided. */
    inputAdornedEnd: {
      margin: '0px',
      maxHeight: '24px',
    },
     /* Styles applied to the root element if `small={true}`. */
    small: {
      padding: 'var(--spacing-5xs) var(--spacing-5xs)',
    },
  };
};

const InputBaseMask = (props) => {
  const {
    classes,
    success,
    warning,
    small,
    mask,
    inputRef,
    maskOptions,
    ...other
  } = props;

  return (
    <ReactInputMask mask={mask} {...maskOptions} fullWidth={true} {...other}>
      {(inputProps) => (
        <MaterialInputBase
          {...inputProps}
          className={classNames({
            [classes.warning]: warning,
            [classes.small]: small,
          })}
          classes={{
            root: classes.root,
            input: classes.input,
            focused: classes.focused,
            disabled: classes.disabled,
            error: classes.error,
            inputAdornedStart: classes.inputAdornedStart,
            inputAdornedEnd: classes.inputAdornedEnd,
            notchedOutline: null,
          }}
        />
      )}
    </ReactInputMask>
  );
};

const InputBase = (props) => {
  const { classes, success, warning, small, maskOptions, ...other } = props;
  return (
    <MaterialInputBase
      className={classNames({
        [classes.warning]: warning,
        [classes.small]: small,
      })}
      classes={{
        root: classes.root,
        input: classes.input,
        focused: classes.focused,
        disabled: classes.disabled,
        error: classes.error,
        inputAdornedStart: classes.inputAdornedStart,
        inputAdornedEnd: classes.inputAdornedEnd,
        notchedOutline: null,
      }}
      {...other}
    />
  );
};

function OutlinedInput(props) {
  if (props.mask && props.mask.length) {
    return <InputBaseMask {...props} />;
  }
  return <InputBase {...props} />;
}

MaterialInputBase.defaultProps = {
  fullWidth: true,
  inputComponent: 'input',
  multiline: false,
  type: 'text',
};

OutlinedInput.propTypes = {
  classes: PropTypes.object.isRequired,
  success: PropTypes.bool,
  warning: PropTypes.bool,
  error: PropTypes.bool,
};

OutlinedInput.muiName = 'CustomOutlinedInput';

export default withStyles(styles, { name: 'CustomOutlinedInput' })(
  OutlinedInput,
);
