import React from 'react';
import ContainerHeader from '../../../components/ContainerHeader';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { UserActions } from '../../../actions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Icon } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ChevronRight';

import TextField from '../../../components/ui/TextField';
import Button from '../../../components/ui/button/Button';

const styles = (theme) => ({
  root: {
    padding: '0px var(--spacing-inset-3xs)',
    margin: 'var(--spacing-inline-2xs)',
    width: 'auto',
  },
  containerWrapper: {
    backgroundColor: 'var(--background-color-white)',
    margin: 'var(--spacing-inset-2xs) 0px 0px',
    padding:
      'var(--spacing-xs) var(--spacing-3xs) var(--spacing-xs) var(--spacing-3xs)',
    width: 'auto',
    minWidth: '852px',
    border: '1px solid transparent',
    borderRadius: 'var(--spacing-3xs)',
    gap: 48,
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '17.6px',
    textAlign: 'left',
    textTransform: 'uppercase',
    color: 'var(--color-brand-primary-800)',
    margin: '0px',
  },
  caption: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: 'var(--color-neutral-800)',
    margin: '0px',
  },
  rowSpacer: {
    margin: 'var(--spacing-stack-3xs) 0px 0px 0px',
  },
  panelSpacer: {
    margin: 'var(--spacing-stack-md) 0px 0px 0px',
  },
  columnSpacer: {
    margin: '0px var(--spacing-stack-xl) 0px 0px',
  },
  showIconPassword: {
    cursor: 'pointer',
  },
});

class Perfil extends React.Component {
  state = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    isPasswordVisible: false,
    hasCurrentPasswordError: false,
    hasNewPasswordError: false,
    hasConfirmPasswordError: false,
    loading: false,
    dialog: {
      open: false,
      message: '',
    },
  };

  isUpdatePasswordDisabled = () => {
    const {
      currentPassword,
      newPassword,
      confirmPassword,
      hasCurrentPasswordError,
      hasNewPasswordError,
      hasConfirmPasswordError,
    } = this.state;
    return (
      !currentPassword ||
      !newPassword ||
      !confirmPassword ||
      hasCurrentPasswordError ||
      hasNewPasswordError ||
      hasConfirmPasswordError ||
      currentPassword === newPassword
    );
  }
  updatePwd = () => {
    this.setState({
      loading: true,
    });

    UserActions.updatePwd(
      localStorage.getItem('userid'),
      this.state.currentPassword,
      this.state.newPassword,
    ).then((response) => {
      if (response.success) {
        this.setState({
          loading: false,
          dialog: { open: true, message: 'Senha alterada com sucesso.' },
          atual: '',
          newPwd: '',
          confirmPwd: '',
        });
      } else {
        this.setState({
          loading: false,
          dialog: {
            open: true,
            message: response.message || 'Ocorreu algum erro, tente novamente.',
          },
          atual: '',
          newPwd: '',
          confirmPwd: '',
        });
      }
    });
  };

  getUsrName = () => {
    return (
      (this.props.user && this.props.user.name) ||
      localStorage.getItem('username') ||
      ''
    );
  };

  getUsrEmail = () => {
    return (
      (this.props.user && this.props.user.email) ||
      localStorage.getItem('useremail') ||
      ''
    );
  };

  isInputPasswordInvalid = (password) => {
    if (!password) return true;

    const regex = /^(?=.*[0-9])(?=.*[a-z]|)(?=.*[A-Z])(?=.*\W|)(?!.*\s).{6,}$/;

    return !regex.test(password);
  };

  setVisibilityPassword = () => {
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
  };

  showIconPassword = () => {
    const { isPasswordVisible } = this.state;
    const icon = isPasswordVisible ? 'visibility' : 'visibility_off';
    return (
      <Icon
        className={this.props.classes.showIconPassword}
        onClick={this.setVisibilityPassword}
      >
        {icon}
      </Icon>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {!localStorage.getItem('token') && (
          <Redirect
            to={{
              pathname: '/signin',
            }}
            push
          />
        )}
        <ContainerHeader title='Perfil' />

        <Grid
          container
          direction='column'
          justify='flex-start'
          alignItems='flex-start'
          spacing={0}
          className={classes.containerWrapper}
        >
          <Grid item xs={12} md={12}>
            <Typography className={classes.title} variant='h6' noWrap>
              Dados do Usuário
            </Typography>
          </Grid>
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={3} md={3} className={classes.columnSpacer}>
              <TextField
                label='Nome'
                id='user-name'
                name='user-name'
                type='input'
                disabled={true}
                required={true}
                placeholder='Nome do Usuário'
                defaultValue={this.getUsrName()}
                endAdornment={<Icon>lock</Icon>}
                small={true}
              />
            </Grid>
            <Grid item xs={3} md={3} className={classes.columnSpacer}>
              <TextField
                label='E-mail'
                id='user-email'
                name='user-email'
                disabled={true}
                required={true}
                small={true}
                type='input'
                placeholder='e-mail-user@email.com'
                defaultValue={this.getUsrEmail()}
                endAdornment={<Icon>lock</Icon>}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} className={classes.panelSpacer}>
            <Typography className={classes.title} variant='h6' noWrap>
              Trocar de Senha
            </Typography>
            <Typography variant='caption' className={classes.caption} noWrap>
              A senha deve conter no mínimo 6 caracteres, com pelo menos um
              número e uma letra maiúscula.
            </Typography>
          </Grid>
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={3} md={3} className={classes.columnSpacer}>
              <TextField
                label='Senha atual'
                id='user-current-password'
                name='user-current-password'
                autoFocus={true}
                required={true}
                small={true}
                warning={this.state.hasCurrentPasswordError}
                placeholder='Digite sua senha atual'
                type={this.state.isPasswordVisible ? 'text' : 'password'}
                value={this.state.currentPassword}
                endAdornment={this.showIconPassword()}
                onBlur={() => {
                  this.setState({
                    hasCurrentPasswordError: this.isInputPasswordInvalid(
                      this.state.currentPassword,
                    ),
                  });
                }}
                onChange={(e) => {
                  e.preventDefault();
                  this.setState({
                    currentPassword: e.target.value,
                    hasCurrentPasswordError: this.isInputPasswordInvalid(
                      e.target.value,
                    ),
                  });
                }}
                formInputHelper={{
                  title: 'Advertência',
                  text: this.state.hasCurrentPasswordError
                    ? 'Senha em formato inválido'
                    : '',
                  showIcon: true,
                  isShow: this.state.hasCurrentPasswordError,
                }}
              />
            </Grid>
            <Grid item xs={3} md={3} className={classes.columnSpacer}>
              <TextField
                label='Nova Senha'
                id='user-new-password'
                name='user-new-password'
                small={true}
                required={true}
                error={this.state.hasNewPasswordError}
                success={!this.state.hasNewPasswordError}
                placeholder='Digite sua nova senha'
                type={this.state.isPasswordVisible ? 'text' : 'password'}
                value={this.state.newPassword}
                endAdornment={this.showIconPassword()}
                onBlur={() => {
                  this.setState({
                    hasNewPasswordError: this.isInputPasswordInvalid(
                      this.state.newPassword,
                    ),
                  });
                }}
                onChange={(e) => {
                  e.preventDefault();
                  this.setState({
                    newPassword: e.target.value,
                    hasNewPasswordError: e.target.value === this.state.currentPassword || this.isInputPasswordInvalid(
                      e.target.value,
                    ),
                    hasConfirmPasswordError: (!this.state.hasConfirmPasswordError && e.target.value !== this.state.confirmPassword),
                  });
                }}
                formInputHelper={{
                  title: 'Erro',
                  text: this.state.hasNewPasswordError ? 'Senhas invalida' : '',
                  showIcon: true,
                  isShow: this.state.hasNewPasswordError,
                }}
              />
            </Grid>
            <Grid item xs={3} md={3} className={classes.columnSpacer}>
              <TextField
                label='Confirmar Senha'
                id='user-confirm-password'
                name='user-confirm-password'
                small={true}
                required={true}
                error={this.state.hasConfirmPasswordError}
                success={!this.state.hasConfirmPasswordError}
                placeholder='Confirme sua nova senha'
                value={this.state.confirmPassword}
                type={this.state.isPasswordVisible ? 'text' : 'password'}
                onBlur={(e) => {
                  this.setState({
                    hasConfirmPasswordError:
                      this.isInputPasswordInvalid(e.target.value) ||
                      e.target.value !== this.state.newPassword,
                  });
                }}
                onChange={(e) =>
                  this.setState({
                    confirmPassword: e.target.value,
                    hasConfirmPasswordError:
                      this.isInputPasswordInvalid(e.target.value) ||
                      e.target.value !== this.state.newPassword,
                  })
                }
                endAdornment={this.showIconPassword()}
                formInputHelper={{
                  title: 'Erro',
                  text: this.state.hasConfirmPasswordError
                    ? 'Senhas devem ser iguais'
                    : '',
                  showIcon: true,
                  isShow: this.state.hasConfirmPasswordError,
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={3} md={3} className={classes.rowSpacer}>
              <Button
                variant='primary'
                size='md'
                color='secondary'
                onClick={this.updatePwd}
                isLoading={this.state.loading}
                isDisabled={ this.isUpdatePasswordDisabled() }
              >
                Trocar Senha
                <ArrowForward fontSize='small' />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Dialog open={this.state.dialog.open}>
          <DialogContent>
            <DialogContentText>{this.state.dialog.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant='primary'
              color='secondary'
              onClick={() =>
                this.setState({
                  dialog: { ...this.state.dialog, open: false },
                })
              }
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale } = settings;
  const { user } = auth;
  return { locale, user: user.response };
};
export default withStyles(styles)(connect(mapStateToProps)(Perfil));
