import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '../components/ui/button/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import { Grid, Typography, Paper, Icon } from '@material-ui/core';
import IconCheckCircle from '@material-ui/icons/CheckCircleOutlineRounded';
import { hideMessage, showAuthLoader } from '../actions/Auth';
import { UserActions } from '../actions';
import { FormErrors } from './FormErrors';
import { yalo_images, drc_logos } from '../assets/images';
import TextField from '../components/ui/TextField'; // Importando o TextField personalizado
import ArrowForward from '@material-ui/icons/ArrowForward';
const styles = {
  container: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    position: 'relative',
  },
  paper: {
    padding: 24,
    textAlign: 'center',
    position: 'relative',
    zIndex: 1,
  },
  logo: {
    width: '260px',
    height: 'auto',
    marginBottom: 16,
  },
  form: {
    marginTop: 16,
  },
  submitButton: {
    marginTop: 16,
  },
  loader: {
    color: '#005DF9',
  },
  loaderOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 2,
  },
  errorText: {
    color: 'red',
    marginBottom: 16,
  },
  successMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
  },
  icon: {
    marginRight: 8,
    fontSize: 36,
    color: 'var(--color-feedback-success-500)',
  },

};

class ForgotPwd extends React.Component {
  constructor() {
    super();
    this.state = {
      username: '',
      loading: false,
      formErrors: { username: '' },
      emailValid: false,
      formValid: false,
      error: false,
      success: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
    this.setState({ success: false, error: false });
  }

  sendEmail(e) {
    e.preventDefault();
    this.setState({ loading: true });
    const { username } = this.state;

    UserActions.resetPwd(username).then((response) => {
      if (response.status === 400)
        return this.setState({ loading: false, error: true });

      return this.setState({ loading: false, success: true });
    });
  }

  validateField(fieldName, value) {
    if (!fieldName || !value) return;

    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;

    if (fieldName === 'username') {
      emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
      fieldValidationErrors.username = emailValid ? '' : 'E-mail inválido.';
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({ formValid: this.state.emailValid });
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 3000);
    }
  }

  render() {
    const { username, loading, error, success, formErrors, formValid } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <Grid container justify='center'>
            <Grid item xs={12}>
              <Link className='logo-lg' to='/' title='Dr Consulta'>
                <img
                  src={drc_logos.drc_logo_salmon}
                  alt='Dr Consulta'
                  className={classes.logo}
                />
              </Link>
              <Typography align='center' variant='h1' paragraph>
                Recuperar Senha
              </Typography>
              {!success ? (
                <Fragment>
                  <Typography variant='subtitle1' align='center' paragraph>
                    Não se preocupe! Informe o seu e-mail cadastrado que iremos te enviar uma nova senha.
                  </Typography>
                  <form className={classes.form} onSubmit={this.sendEmail}>
                    <Grid container justify='center'>
                      <Grid item xs={10} >
                        <TextField
                          required
                          name='username'
                          type='text'
                          value={username}
                          label='E-mail'
                          onChange={this.handleChange}
                          InputLabelProps={{ shrink: true }}
                          
                          fullWidth
                          startAdornment={<Icon>account_circle</Icon>}
                          formInputHelper={{
                            title: 'E-mail',
                            text: formErrors.username,
                            showIcon: !!formErrors.username,
                            isShow: !!formErrors.username,
                          }}
                        />
                      </Grid>
                    </Grid>
                    {error && (
                      <Typography
                        variant='subtitle1'
                        align='center'
                        className={classes.errorText}
                      >
                        Usuário não existe em nosso sistema.
                      </Typography>
                    )}
                    <Grid container justify='flex-end' style={{marginTop:20}}>
                      
                      <Grid item xs={4} style={{paddingLeft:15}}>
                        <Button
                          type='submit'
                          variant='primary'
                          size='md'
                          color='secondary'
                          isDisabled={!formValid}
                          //className={classes.submitButton}
                        >
                          Enviar email
                          <ArrowForward fontSize='small' />
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container justify='space-between' alignItems='center' style={{ marginTop: 20 }}>
                      <Grid item xs={12} >
                        <Typography variant='subtitle1' align='center' paragraph>
                          <Link to='/signin'>
                            Voltar ao login
                          </Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  </form>
                </Fragment>
              ) : (
                <Fragment>
                  <div className={classes.successMessage}>
                    <IconCheckCircle className={classes.icon} />
                    <Typography variant='h4'>
                      Pronto! Acesse o seu e-mail para ver a sua nova senha.
                    </Typography>
                  </div>
                  <Grid container justify='center' style={{ marginTop: 22 }}>
                    <Grid item>
                      <Link to='/signin'>
                        <Button  
                          variant='primary'
                          size='md'
                          color='secondary'
                        >
                          Login
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Grid>
        </Paper>
        {loading && (
          <div className={classes.loaderOverlay}>
            <CircularProgress className={classes.loader} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(
  mapStateToProps,
  {
    hideMessage,
    showAuthLoader,
  }
)(withStyles(styles)(ForgotPwd));
