import React, {
  createContext, useContext, useEffect, useState
} from 'react';
import { featureFlagsService } from '~/services/featureFlagsService';

export const FeatureFlagsContext = createContext();

export const FeatureFlagsProvider = ({ children }) => {
  const [featureFlags, setFeaturesFlags] = useState([]);

  useEffect(() => {
    async function loadFeatureFlags() {
      const flags = await featureFlagsService.loadFeatureFlags();
      setFeaturesFlags(flags);
    }

    loadFeatureFlags();
  }, []);

  return (
    <FeatureFlagsContext.Provider value={{ featureFlags }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
