import { SET_CONTRACT, SET_LIST_CONTRACTS } from '../constants/ActionTypes';

// That contract should be removed in production
const INIT_STATE = {
  loading: false,
  selectedContract: {
    // account_manager_yalo: '5a420cc0-757d-11e8-b69d-a544e7cc76cc',
    // account_manager_yalo_name: 'Arlete Garcia',
    // closure_date: '2019-05-30T00:00:00.000Z',
    // contract_status_id: '7a46fd15-e953-f4ea-bbbd-1de1b248d6a3',
    // contract_status_name: 'Ativo',
    // contracting_company_cnpj: '14245016000179',
    // contracting_company_id: '4c651d59-6739-396a-50ce-85366e15e9dd',
    // contracting_company_name_printed: 'DR CONSULTA',
    // contracting_company_name: 'DR CONSULTA',
    // expiration_day: 0,
    // hired_company_cnpj: '28757895000122',
    // hired_company_id: 'd6da3612-e433-d10a-174e-2238fbaa0b89',
    // hired_company_name: 'YALO',
    // id: '6c2b064f-28fb-60d1-2ed1-be58c7554a01',
    // lead_generator_document_number: null,
    // monthly_payment_responsible: null,
    // name: 'Contrato Getmore',
    // qtd_default_recharges: null,
    // qtd_relations: '7',
    // recharge_day: 0,
    // sale_issue_rate: 0.1,
    // sales_flow: 'Implantação',
    // sales_promotor_name: 'Arlete Garcia',
    // should_renew_plan: true,
    // should_renew_recharges: false,
    // signature_date: '2018-03-30T00:00:00.000Z'
  },
  contracts: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CONTRACT:
      return {
        ...state,
        loading: true,
        selectedContract: action.payload
      };
    case SET_LIST_CONTRACTS:
      return {
        ...state,
        loading: false,
        contracts: action.payload
      };
    default:
      return state;
  }
};
