import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import CadastroContrato from './routes/cadastroContrato';
import EdicaoContrato from './routes/edicaoContrato';
import ConsultaContrato from './routes/consultaContrato';
import DetalhesContrato from './routes/detalhesContratos';
import Credit from './routes/credito';

const Contratos = ({ match }) => (
  <div>
    {!localStorage.getItem('token') && (
      <Redirect
        to={{
          pathname: '/signin'
        }}
        push
      />
    )}
    <Switch>
      <Route
        path={`${match.url}/cadastro-contrato`}
        component={CadastroContrato}
      />

      <Route
        path={`${match.url}/edicao-contrato/:id`}
        component={EdicaoContrato}
      />

      <Route
        path={`${match.url}/consulta-contrato`}
        component={ConsultaContrato}
      />

      <Route
        path={`${match.url}/detalhes-contrato/:id`}
        component={DetalhesContrato}
      />

      <Route path={`${match.url}/adicionar-credito/:id`} component={Credit} />
    </Switch>
  </div>
);

export default Contratos;
