import React from 'react';
import { Dialog } from '@material-ui/core';

function CustomDialog(props) {
    const { open, onClose, children, ...otherProps } = props;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    borderRadius: 16, 
                },
            }}
            {...otherProps} 
        >
            {children}
        </Dialog>
    );
}

export default CustomDialog;