import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classNames from 'classnames';
import SidenavContent from './SidenavContent';
import UserInfoPopup from '../../components/UserInfo/UserInfoPopup';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from '../../constants/ActionTypes';
import { MenuOpenIcon, MenuCloseIcon, DrcIcon, DrconsultaIcon } from '../../assets/svgIcons';

const drawerWidth = 287;
const drawerWidthClosed = 115;

const styles = (theme) => {
  const drawerBase = {
    width: drawerWidth,
    flexShrink: 0,
    background: 'white',
    whiteSpace: 'nowrap',
    borderRadius: '0px 24px 24px 0px',
    borderRight: 'none',
    maxHeight: '830px', // Altura padrão

    '@media (min-height: 601px) and (max-height: 800px)': {
      maxHeight: '650px', 
    },
    
    
  };

  return {
    root: {
      display: 'flex',
    },
    menuButton: {
      marginLeft: 3,
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: theme.zIndex.drawer + 1,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    hide: {
      display: 'none',
    },
    drawer: {
      ...drawerBase,
    },
    drawerOpen: {
      ...drawerBase,
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      ...drawerBase,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: drawerWidthClosed,
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing.unit * 3,
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px', // Espaçamento entre as divs
    },
    iconWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    iconMenuClose:{
      display: 'flex',
      alignItems: 'center',
      marginTop:10,
    },
    iconButtonOpen: {
      marginTop: 2,
      marginLeft: 45,
      marginBotton:10, 
      '&:hover': {
        backgroundColor: 'transparent', // Cor de fundo no hover quando aberto
      },
    },
  };
};

class MiniDrawer extends React.Component {
  state = {
    open: false,
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, theme, drawerType, handleDrawer } = this.props;
    let drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'd-xl-flex'
      : drawerType.includes(COLLAPSED_DRAWER)
      ? ''
      : 'd-flex';

    return (
      <div className={classes.root}>
        <CssBaseline />

        <IconButton
          color='inherit'
          aria-label='Open drawer'
          onClick={
            this.state.open ? this.handleDrawerClose : this.handleDrawerOpen
          }
          className={classNames(classes.menuButton, {
            [classes.hide]: this.state.open,
          })}
        >
          {!this.state.open && (
            <div className={classes.iconContainer}>
              <div className={classes.iconWrapper}>
                <DrcIcon />
              </div>
              <div className={classes.iconMenuClose}>
                <MenuCloseIcon width={24}  height={24} fill='#3A10E0' />
              </div>
            </div>
          )}
        </IconButton>
        <Drawer
          variant='permanent'
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
            }),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbar}>
            <IconButton
            className={classNames(classes.menuButton, {
              [classes.hide]: !this.state.open,
              [classes.iconButtonOpen]: this.state.open,
            })}
             onClick={this.handleDrawerClose}>
              {this.state.open && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                <div>
                  <DrconsultaIcon />
                </div>
                <div style={{marginTop:10}}>
                  <MenuOpenIcon width={24}  height={24} fill='#3A10E0' /> 
                </div>
              </div>
                )}
            </IconButton>
          </div>
          <SidenavContent
            open={this.state.open}
            handleDrawer={handleDrawer}
            type={drawerStyle}
            handleDrawerOpen={this.handleDrawerOpen}
            handleDrawerClose={this.handleDrawerClose}
          />
          <UserInfoPopup handleDrawerClose={this.handleDrawerClose} handleDrawerOpen={this.handleDrawerOpen} open={this.state.open}/>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType } = settings;
  return { drawerType };
};

const mapDispatchToProps = (dispatch) => ({
  handleDrawer: () => dispatch({ type: 'TOGGLE_DRAWER' }), // Ajustar ação conforme necessário
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(MiniDrawer))
);
