import { BASE_URL } from '~/config/env';

class FeatureFlagsService {
  constructor() {
    this.featureFlags = {};
  }

  async loadFeatureFlags() {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${BASE_URL}/feature-flags`, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });
      const data = await response.json();

      if (data.success) {
        data.response.forEach((featureFlag) => {
          const [key, value] = Object.entries(featureFlag)[0];
          this.featureFlags[key] = value;
        });
      }

      return this.featureFlags;
    } catch (error) {
      console.error('Error ao buscar dados:', error);
      throw error;
    }
  }
}

export const featureFlagsService = new FeatureFlagsService();
