import React, { Component } from 'react';
import ClockIcon from '@material-ui/icons/AddCircleOutlineRounded';

const IconWarning = <svg xmlns="http://www.w3.org/2000/svg" height="35px" viewBox="0 -960 960 960" width="35px" fill="#93370D"><path d="M480-79q-16 0-30.5-6T423-102L102-423q-11-12-17-26.5T79-480q0-16 6-31t17-26l321-321q12-12 26.5-17.5T480-881q16 0 31 5.5t26 17.5l321 321q12 11 17.5 26t5.5 31q0 16-5.5 30.5T858-423L537-102q-11 11-26 17t-31 6Zm0-80 321-321-321-321-321 321 321 321Zm-40-281h80v-240h-80v240Zm40 120q17 0 28.5-11.5T520-360q0-17-11.5-28.5T480-400q-17 0-28.5 11.5T440-360q0 17 11.5 28.5T480-320Zm0-160Z"/></svg>
import {
  Typography,
  Grid,
  withStyles,
  List,
  ListItem
} from '@material-ui/core';

const styles = () => ({
  grad: {
    padding: 24,
    border: 'dashed #aaa 2px',
    borderRadius: '10px',
    width: '80%',
    margin: '0 auto'
  },
  clockIcon: {
    fontSize: 100,
    color: '#AFAFAF'
  },
  textHelp: {
    color: '#AFAFAF', 
    textAlign: 'center',
    fontSize: '24px',
  },
  listItem: {
    display: 'flex',
    justifyContent : 'center',
    textAlign: 'center',
    backgroundColor:'#FEF0C7',
    border: '1px solid #93370D',
    borderRadius: '8px',
  }
});

class ThereIsNoOrder extends Component {
  render() {
    const { classes, errors } = this.props;
    return (
      <div>
        <Grid
          container
          justify='center'
          direction='column'
          alignItems='center'
          className={classes.grad}
        >
          <ClockIcon color='disabled' className={classes.clockIcon} />
          <Typography className={classes.textHelp}> Para atualizar a base de vidas, clique aqui ou arraste o arquivo da planilha atualizado</Typography>
          <List className={classes.listItem}>
           <div style={{margin: '8px'}}> {IconWarning} </div>
            {errors.map(error => (
              <ListItem>
                <Typography  style={{ color: '#93370D' }} align='center'>
                  {error}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ThereIsNoOrder);
