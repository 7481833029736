import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Pedidos from './routes/orders';
import ConsultingOrder from './routes/consulting';

const Orders = ({ match }) => (
  <div>
    {!localStorage.getItem('token') && (
      <Redirect
        to={{
          pathname: '/signin'
        }}
        push
      />
    )}
    <Switch>
      <Route path={`${match.url}`} exact component={Pedidos} />

      <Route path={`${match.url}/consulting/:id`} component={ConsultingOrder} />
    </Switch>
  </div>
);

export default Orders;
