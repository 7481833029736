import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Button, withStyles } from '@material-ui/core';
import CustomScrollbars from '../../util/CustomScrollbars';
import { ROLES } from '../../constants';
import {
  UserIcon,
  AccountBalanceIcon,
  ContractIcon,
  AssignmentAddIcon,
  ContractEdit,
  DashboardIcon,
  MonitoringIcon
} from '../../assets/svgIcons';
import { FeatureFlagsContext } from '~/contexts/FeatureFlagsContext';
import styles from './sidenavStyles';

class SidenavContent extends Component {
  static contextType = FeatureFlagsContext;

  state = {
    openMenus: {
      home: false,
      employees: false,
      juridicalPerson: false,
      contracts: false,
      contract: false,
      contractEdit: false,
      boleto: false,
      sales: false,
      health: false,
    },
  };

  componentDidMount() {
    this.setInitialOpenMenus();
  }

  setInitialOpenMenus = () => {
    const currentPath = this.props.location.pathname;

    const initialOpenMenus = {
      home: currentPath === '/app/home',
      employees: currentPath.startsWith('/app/funcionarios'),
      juridicalPerson: currentPath.startsWith('/app/pessoa-juridica'),
      contracts: currentPath.startsWith('/app/contratos'),
      contract: currentPath.startsWith('/app/pessoa-juridica/cadastro-pj'),
      contractEdit: currentPath.startsWith('/app/contratos/cadastro-contrato'),
      boleto: currentPath.startsWith('/app/boleto'),
      sales: currentPath.startsWith('/app/vendas/cadastro-venda'),
      health: currentPath.startsWith('/app/saude'),
    };

    this.setState({ openMenus: initialOpenMenus });
  };

  handleMenuClick = (menuId, path) => {
    const redirectMenuIds = [
      'home',
      'contract',
      'contractEdit',
      'boleto',
      'sales',
      'health'
    ];

    const isRedirectMenu = redirectMenuIds.includes(menuId);

    if (isRedirectMenu) {
      this.props.history.push(path);
      this.props.handleDrawerClose();
    }

    this.toggleMenu(menuId, isRedirectMenu);
  };

  toggleMenu = (menuId, isRedirectMenu) => {
    this.setState((prevState) => {
      const newOpenMenus = {
        ...prevState.openMenus,
        [menuId]: !prevState.openMenus[menuId],
      };

      Object.keys(newOpenMenus).forEach((key) => {
        if (key !== menuId) {
          newOpenMenus[key] = false;
        }
      });

      if (!prevState.openMenus[menuId] && !isRedirectMenu) {
        this.props.handleDrawerOpen();
      }

      return {
        openMenus: newOpenMenus,
      };
    });
  };

  hiddenLink = (roles) => {
    return !roles.includes(localStorage.getItem('usertype'));
  };

  isActive = (path) => this.props.location.pathname === path;

  renderIcon = (IconComponent, path) => {
    const { classes } = this.props;
    const isActive = this.isActive(path);
    return (
      <IconComponent
        className={`${classes.icon} ${
           classes.iconInactive
        }`}
      />
    );
  };

  canShowFeature(featureFlags, id) {
    const flags = {
      health: (featureFlags && featureFlags.MENU_HEALTH_PANEL) || false
    };

    if (flags[id] === undefined) {
      return true;
    }

    return flags[id];
  }

  render() {
    const { classes, open } = this.props;
    const { openMenus } = this.state;
    const { featureFlags } = this.context;

    return (
      <CustomScrollbars className={classes.scrollbar}>
        <ul className={classes.navMenu}>
          {[
            {
              id: 'home',
              to: '/app/home',
              icon: DashboardIcon,
              text: 'Home',
              roles: [
                ROLES.BACKOFFICE,
                ROLES.PROMOTER,
                ROLES.SALES,
                ROLES.BROKER,
              ],
            },
            {
              id: 'employees',
              to: '/app/funcionarios',
              icon: UserIcon,
              text: 'Funcionários',
              roles: [ROLES.BACKOFFICE, ROLES.PROMOTER, ROLES.BROKER],
            },
            {
              id: 'juridicalPerson',
              to: '/app/pessoa-juridica/consulta-pj',
              icon: AccountBalanceIcon,
              text: 'Pessoa Jurídica',
              roles: [
                ROLES.BACKOFFICE,
                ROLES.PROMOTER,
                ROLES.SALES,
                ROLES.BROKER,
              ],
            },
            {
              id: 'contracts',
              to: '/app/contratos/consulta-contrato',
              icon: ContractIcon,
              text: 'Contratos',
              roles: [
                ROLES.BACKOFFICE,
                ROLES.PROMOTER,
                ROLES.SALES,
                ROLES.BROKER,
              ],
            },
            {
              id: 'contract',
              to: '/app/pessoa-juridica/cadastro-pj',
              icon: AssignmentAddIcon,
              text: 'Cadastro PJ',
              roles: [ROLES.BACKOFFICE, ROLES.BROKER, ROLES.SALES],
            },
            {
              id: 'contractEdit',
              to: '/app/contratos/cadastro-contrato',
              icon: ContractEdit,
              text: 'Cadastro Contrato',
              roles: [ROLES.BACKOFFICE, ROLES.BROKER, ROLES.SALES],
            },
            {
              id: 'boleto',
              to: '/app/boleto',
              icon: DashboardIcon,
              text: 'Boletos',
              roles: [ROLES.BACKOFFICE, ROLES.PROMOTER, ROLES.SALES],
            },
            {
              id: 'sales',
              to: '/app/vendas/cadastro-venda',
              icon: DashboardIcon,
              text: 'Vendas',
              roles: [ROLES.BROKER],
            },
            {
              id: 'health',
              to: '/app/saude',
              icon: MonitoringIcon,
              text: 'Painel de Saúde',
              roles: [
                ROLES.BACKOFFICE,
                ROLES.PROMOTER,
                ROLES.SALES,
                ROLES.BROKER,
              ],
            },
          ].map(
              ({ id, to, icon, text, roles }) =>
                !this.hiddenLink(roles) && this.canShowFeature(featureFlags, id) && this.canShowFeature() && (
                  <li
                    key={id}
                    id={id}
                    className={`menu ${openMenus[id] ? 'open' : ''} ${ !open ? 'icon-only' : '' }`} >
                    <Button
                      onClick={() => this.handleMenuClick(id, to)}
                      className={openMenus[id] ? 'open' : ''}
                    >
                      {this.renderIcon(icon, to)}
                      {open && <span className={classes.navText}>{text}</span>}
                    </Button>
                    {id === 'employees' && openMenus[id] && (
                      <ul className='sub-menu'>
                        <li>
                          <NavLink
                            to='/app/funcionarios'
                            onClick={this.props.handleDrawerClose}
                          >
                            <span className={classes.navSubText}>
                              Funcionários
                            </span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to='/app/cadastrar-desligar'
                            onClick={this.props.handleDrawerClose}
                          >
                            <span className={classes.navSubText}>
                              Cadastrar / Desligar
                            </span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to='/app/mensalidades'
                            onClick={this.props.handleDrawerClose}
                          >
                            <span className={classes.navSubText}>
                              Mensalidades
                            </span>
                          </NavLink>
                        </li>
                      </ul>
                    )}
                    {id === 'juridicalPerson' && openMenus[id] && (
                      <ul className='sub-menu'>
                        <li>
                          <NavLink
                            to='/app/pessoa-juridica/consulta-pj'
                            onClick={this.props.handleDrawerClose}
                          >
                            <span className={classes.navSubText}>
                              Consulta PJ
                            </span>
                          </NavLink>
                        </li>
                      </ul>
                    )}
                    {id === 'contracts' && openMenus[id] && (
                      <ul className='sub-menu'>
                        <li>
                          <NavLink
                            to='/app/contratos/consulta-contrato'
                            onClick={this.props.handleDrawerClose}
                          >
                            <span className={classes.navSubText}>
                              Consulta Contrato
                            </span>
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </li>
                )
            )}
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(withStyles(styles)(SidenavContent));
