import React from 'react';
import {
  Stepper,
  Step,
  AppBar,
  Tabs,
  StepButton,
  StepLabel,
  withStyles,
} from '@material-ui/core';
import classNames from 'classnames';

// import StepperUI from '../../../../../componentsPortal/Steps';

const styles = (theme) => ({
  root: {
    // border: '1px solid red',
    backgroundColor: 'white',
  },
  horizontal: {
    // border: '1px solid red',
  },
  vertical: {
    // border: '1px solid red',
  },
  stepButton: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontWeight: '700',
    color: '#3A10E0',
    padding: '16px 16px 16px 16px',
    '& ::after': {
      top: '10px',
      width: '100%',
      height: '6px',
      content: '""',
      position: 'relative',
      backgroundColor: '#EBEBEB',
      borderRadius: '152px',
    }
  },
  stepButtonActive: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontWeight: '700',
    color: '#3A10E0',
    padding: '16px 16px 16px 16px',
    '& ::after': {
      top: '10px',
      width: '100%',
      height: '6px',
      content: '""',
      position: 'relative',
      backgroundColor: '#3A10E0',
      borderRadius: '152px',
    }
  },
  stepLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontWeight: '700',
    margin: '0px 0px 12px 0px !important',
    padding: '2px',
    color: '#707070', //theme.palette.text.secondary,
  },
  active: {
    color: 'gray', //theme.palette.text.primary,
    fontWeight: 700,
  },
  completed: {
    color: '#3A10E0', //theme.palette.text.primary,
    fontWeight: 700,
  },
  alternativeLabel: {
    textAlign: 'left',
    marginTop: 0,
  },
  error: {
    color: theme.palette.error.main,
  },
});

const labelStyles = (theme) => ({
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontWeight: '700',
    color: '#707070',
    padding: '16px 16px 16px 16px',
    '& ::after': {
      top: '10px',
      width: '100%',
      height: '6px',
      content: '""',
      position: 'relative',
      backgroundColor: '#EBEBEB',
      borderRadius: '152px',
    }
  },
  active: {
    color: '#1A1A1A !important', //theme.palette.text.primary,
    fontWeight: 700,
  },
  completed: {
    color: '#3A10E0 !important', //theme.palette.text.primary,
    fontWeight: 700,
  },
  disabled: {
    color: '#EBEBEB !important',
    fontWeight: 700,
  },
  alternativeLabel: {
    textAlign: 'left',
    marginTop: '0 !important',
  },
  error: {
    color: theme.palette.error.main,
  },
});

const CustomStepLabel = withStyles(labelStyles)(StepLabel);

function BuilderStepper({ steps, activeStep, completed, classes, ...props }) {
  return (
    <div style={{ width: 'auto', marginBottom: 10, }}>
      <Stepper
        activeStep={activeStep}
        connector={null}
        completed={completed}
        classes={{
          root: classes.root,
          horizontal: classes.horizontal,
          vertical: classes.vertical,
        }}
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            alternativeLabel={false}
          >
            <StepButton
              alternativeLabel={true}
              icon={null}
              className={classNames({
                [classes.stepButton]: activeStep !== index,
                [classes.stepButtonActive]: activeStep === index,
              })}
              completed={completed.includes(index)}
            >
              <CustomStepLabel 
              alternativeLabel={false}>
              {`${index + 1} - ${label}`}
              </CustomStepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

const CustomStepper = withStyles(styles)(BuilderStepper);

export default function StepperBar({ steps, activeStep, completed }) {
  return (
    <AppBar position='static' color='default' style={{ boxShadow: 'none' }}>
      <Tabs variant='scrollable' scrollButtons='auto' value={null} style={{ backgroundColor: 'white' }}>
        <CustomStepper
          steps={steps}
          activeStep={activeStep}
          completed={completed}
        />
      </Tabs>
    </AppBar>
  );
}
// export default withStyles(styles)(StepperBar);
