import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Grid,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@material-ui/core';
import Pagination from 'material-ui-flat-pagination';
import { EnhancedTableHeadCell } from '../../util/EnhancedTableHeadCell';
import _ from 'lodash';
import { exportToCSV,getCellValue} from './utils';


const GenericTable = ({
  data,
  dataFields,
  labelsEnum,
  loading,
  pageSize,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSort = (column) => {
    const direction = column === sortColumn ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
    setSortColumn(column);
    setSortDirection(direction);
  };


  const columns = dataFields.map(key => ({
    id: key,
    label: labelsEnum[key],
  }));

  return (
    <Paper style={{ paddingTop: 8, paddingLeft: 8, paddingRight: 8, border:'1px solid #AFAFAF'}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {loading && (
            <Grid container justify='center' style={{ padding: 100 }}>
              <CircularProgress style={{ margin: 50, color: '#005DF9' }} />
            </Grid>
          )}
          {!loading && (
            <Grid container>
              <Grid item xs={12} style={{ margin: '0px 20px' }}>
                <Table padding='none'>
                  <TableHead >
                    <TableRow>
                      {columns.map((column) => (
                        <EnhancedTableHeadCell
                          key={column.id}
                          order={sortDirection}
                          orderBy={sortColumn}
                          onRequestSort={() => handleSort(column.id)}
                          labelId={column.id}
                          labelName={column.label}
                          alignment='left' 
                        />
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.chain(data)
                      .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                      .orderBy([sortColumn], [sortDirection])
                      .map((row, index) => (
                        <TableRow
                          key={index}
                          style={{
                            backgroundColor: index % 2 === 0 ? '#ffffff': '#F6F6F6' ,
                          }}
                        >
                          {columns.map((column) => (
                            <TableCell style={{color: '#707070',fontFamily:'Sua dr consulta', fontWeight:400, fontSize:'14px'}} key={column.id}>
                              {getCellValue(row, column.id)}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                      .value()}
                  </TableBody>
                </Table>
                <Grid container direction='row' justify='space-between' xs={12} style={{ marginBottom: 16, marginTop: 16 }}>
                  <Grid item>
                    <Pagination
                      size='small'
                      limit={pageSize}
                      offset={currentPage * pageSize}
                      total={data.length}
                      onClick={(e, offset) => handlePageChange(offset / pageSize)}
                    />
                  </Grid>
                  <Grid item>
                    {data.length > 0 && (
                      <Button
                        onClick={() => exportToCSV(data, columns)}
                        style={{ width: 100, borderColor: 'blue', color: 'blue' }}
                        variant='outlined'
                      >
                        Exportar
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

GenericTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  labelsEnum: PropTypes.objectOf(PropTypes.string).isRequired,
  loading: PropTypes.bool,
  pageSize: PropTypes.number,
};

GenericTable.defaultProps = {
  loading: false,
  pageSize: 10,
};

export default GenericTable;
