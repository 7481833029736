import { BASE_URL } from "../config/env";

export const authService = {
  login,
  logout,
  resetPwd,
  getLoginData,
  updatePwd,
  changePwd
};


function login(username, password) {
  return fetch(`${BASE_URL}/token`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username: username,
      password: password
    })
  })
    .then(response => {
      if (response.status === 200) return response.json();

      return response;
    })
    .catch(function(error) {
      return error;
    });
}

function getLoginData(token, username) {
  return fetch(`${BASE_URL}/users/getLoginData`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username: username
    })
  }).then(response => {
    if (response.status === 200) return response.json();

    return response;
  });
}

function logout() {
  return (function() {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('useremail');
  })();
}

function resetPwd(username) {
  return fetch(`${BASE_URL}/password/recovery`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username: username
    })
  }).then(response => {
    if (response.status === 200) return response.json();

    return response;
  });
}

function updatePwd(id, oldPwd, newPwd) {
  return fetch(`${BASE_URL}/users/updatePassword`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id: id,
      old_password: oldPwd,
      password: newPwd
    })
  }).then(response => {
    if (response.status === 200) return response.json();

    return response.json();
  });
}

function changePwd(token, pwd) {
  return fetch(`${BASE_URL}/users/setPassword/${token}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      password: pwd
    })
  }).then(response => {
    if (response.status === 200) return response.json();

    return response;
  });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
