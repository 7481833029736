const drawerWidth = 240;
const miniDrawerWidth = 80;

const styles = (theme) => ({
  sideNav: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRight: 'none',
    zIndex: 1250,
    transition: 'width 0.3s',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: miniDrawerWidth,
    },
  },
  sideLogo: {
    height: 48,
    width: 'auto',
    margin: '0 auto',
  },
  navMenu: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    fontSize: 16,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& li': {
      position: 'relative',
      width: '100%',
    },
    '& li.menu': {
      '& .sub-menu': {
        display: 'none',
      },
      '&.open .sub-menu': {
        display: 'block !important',
      },
      '&.icon-only .sub-menu': {
        display: 'none !important',
      },
      '& a': {
        color: theme.palette.text.primary,
        display: 'flex',
        alignItems: 'center',
        padding: '12px 20px',
        fontWeight: 400,
        textTransform: 'capitalize',
        textDecoration: 'none',
        width: '100%',
        boxSizing: 'border-box',
        textAlign: 'left',
      },
    },
    '& li.menu a:hover': {
      color: '#3A10E0',
      backgroundColor: '#E2DFFA',
    },
    '& li.menu.open > a': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.primary.main,
    },
    '& li.menu a.active': {
      borderLeft: `5px solid var(--color-brand-primary-900)`,
      backgroundColor: '#3A10E0',
      color: '#FFFF',
    },
    '& li.menu .sub-menu': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      backgroundColor: theme.palette.background.paper,
      overflow: 'hidden',
      display: 'none',
    },
    '& li.menu.open .sub-menu': {
      display: 'block',
    },
    '& li.menu.icon-only .sub-menu': {
      display: 'none !important',
    },
    '& li.menu.open .sub-menu li > a.active': {
      backgroundColor: '#3A10E0',
      color: '#FFFF',
    },
    '& li.menu .sub-menu li > a:hover': {
      backgroundColor: '#E2DFFA',
      color: '#3A10E0',
    },
    '& li button': {
      width: '100%',
      height: 60,
      textAlign: 'left',
      border: 'none',
      padding: '10px 20px',
      fontSize: 16,
      fontWeight: 400,
      fontFamily: 'Sua dr consulta',
      cursor: 'pointer',
      textTransform: 'capitalize',
      borderRadius: 0,
      transition: 'background-color 0.3s, color 0.3s',
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    '& li button:hover': {
      backgroundColor: '#E2DFFA',
      color: '#3A10E0',
    },
    '& li button.open': {
      borderLeft: `5px solid var(--color-brand-primary-900)`,
      backgroundColor: '#3A10E0',
      color: '#FFFF',
    },
    '& li.icon-only': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& li.icon-only a': {
      justifyContent: 'center',
    },
    '& li.icon-only .sub-menu': {
      display: 'none !important',
    },
  },
  customizer: {
    padding: '20px 35px',
    '& img': {
      padding: 5,
      cursor: 'pointer',
    },
  },
  userInfo: {
    '& ul': {
      '& li': {
        fontSize: 15,
      },
    },
  },
  navLink: {
    height: 60,
  },
  icon: {
    marginRight: 16,
    fontSize: 24,
  },
  navText: {
    fontSize: 20,
    fontWeight: 400,
    fontFamily: 'Sua dr consulta',
    flex: 1,
    textAlign: 'left',
  },
  navSubText: {
    paddingLeft: 44,
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'Sua dr consulta',
    textAlign: 'left',
  },
  iconActive: {
    fill: 'white',
  },
  iconInactive: {
    fill: 'currentColor',
  },
});

export default styles;
