import React from 'react';

function DrcIcon({ className, ...props }) {
  return (
    <svg
      width={51}
      height={24}
      viewBox="0 0 51 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5640_7152)">
        <path
          d="M29.738 19.584a3.28 3.28 0 016.558 0 3.286 3.286 0 01-3.279 3.28 3.273 3.273 0 01-3.279-3.28z"
          fill="#F0BA09"
        />
        <path
          d="M16.556.538v22.354h-5.18v-1.673c-1.102 1.188-2.69 2.233-5.018 2.233-3.915 0-6.32-3.288-6.32-7.47 0-5.92 3.764-9.304 8.687-9.304.855 0 1.701.133 2.528.294V.538h5.303zM8.278 18.852c1.074 0 2.082-.522 2.965-1.378V11.42a7.474 7.474 0 00-1.796-.2c-2.623 0-4.077 1.578-4.077 4.116 0 2.233 1.169 3.516 2.908 3.516zM31.573 6.982v4.932a8.732 8.732 0 00-1.987-.228c-1.549 0-3.003.457-4.362 1.416v9.8h-5.303V7.142h5.208v1.673c1.388-1.15 3.032-2.043 4.743-2.043.75.01 1.13.076 1.7.209zM37.836 15.298c0-5 3.288-8.649 8.43-8.649 1.644 0 2.908.295 4.391 1.017v4.438c-.95-.456-2.12-.722-3.383-.722-2.623 0-3.983 1.445-3.983 3.745 0 2.271 1.388 3.554 3.821 3.554 1.36 0 2.623-.294 3.669-.789v4.306c-1.607.855-3.127 1.245-5.399 1.245-4.828.019-7.546-3.308-7.546-8.145z"
          fill="#3A10E0"
        />
      </g>
      <defs>
        <clipPath id="clip0_5640_7152">
          <path
            fill="#fff"
            transform="translate(0 .5)"
            d="M0 0H50.8471V23H0z"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DrcIcon;
