import React, { Component } from 'react';
import {
  Grid,
  Typography,
  Divider,
} from '@material-ui/core';
import { yalo_images } from '../../../../assets/images';
import { Fragment } from 'react';
import { utils } from '../../utils';
import { contractService } from '../../../../services';

export default class OrderConfirmation extends Component {
  state = { loading: true, email: '', success: false };

  componentDidMount() {
    contractService
      .getFinancialByCompanyId(this.props.payment.responsable.id)
      .then(response => {
        if (response.data.success) {
          this.setState({
            loading: false,
            success: true,
            email: response.data.response[0].email
          });
        } else {
          this.setState({ loading: false });
        }
      });
  }

  render() {
    return (
      <Grid container spacing={16} justify='center'>
        <Grid
          item
          xs={5}
          style={{
            borderRadius: 6,
            backgroundColor: '#f0f0f0',
            marginRight: 5
          }}
        >
          <Grid container spacing={8} justify='center'>
            <Grid item xs={11}>
              <Typography
                variant='h4'
                color='primary'
                style={{ fontWeight: 700 }}
              >
                RESUMO
              </Typography>
            </Grid>

            <Grid item xs={11}>
              <Typography variant='h3'>
                <img
                  src={yalo_images.xml_file}
                  width={36}
                  style={{ marginRight: 16 }}
                />
                {this.props.file.name.length > 26
                  ? `${this.props.file.name.slice(0, 24)}...`
                  : this.props.file.name}
              </Typography>
            </Grid>

            <Grid
              container
              direction='row'
              xs={12}
              style={{ padding: 20, height: 150 }}
            >
              <Grid container direction='row' xs={12} justify='space-between'>
                <Typography variant='subtitle1'>DESCRIÇÃO</Typography>
                <Typography variant='subtitle1'>QUANTIDADE</Typography>
                <Typography variant='subtitle1'>VALOR</Typography>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid container direction='row' xs={12} justify='space-between'>
                <Typography variant='subtitle1'>Recargas</Typography>
                <Typography variant='subtitle2'>
                  {this.props.resume.totalRecharges}
                </Typography>
                <Typography variant='subtitle2'>
                  R${' '}
                  {utils.formatCurrency(this.props.resume.totalValueRecharges)}
                </Typography>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid container direction='row' xs={12} justify='space-between'>
                <Typography variant='subtitle1'>Total</Typography>
                <Typography variant='subtitle2'>
                  R${' '}
                  {utils.formatCurrency(
                    this.props.resume.totalValuePlans +
                      this.props.resume.totalValueRecharges
                  )}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={11} style={{ marginBottom: 10 }}>
              <Typography
                variant='h4'
                color='primary'
                style={{ fontWeight: 700 }}
              >
                RESPONSÁVEL PELO PAGAMENTO
              </Typography>

              <Typography variant='body1'>
                {this.props.payment.responsable.contracting_company_name}
              </Typography>
              <Typography variant='subtitle1'>
                CNPJ:{' '}
                {utils.formatCNPJ(
                  this.props.payment.responsable.contracting_company_cnpj
                )}
              </Typography>
              {this.state.success && (
                <Typography variant='subtitle1' paragraph>
                  {this.state.email}
                </Typography>
              )}
              {this.props.payment.sendEmailCopy && (
                <Fragment>
                  <Typography variant='body1'>
                    Cópia do e-mail enviado para:
                  </Typography>
                  <Typography variant='subtitle1'>
                    {this.props.payment.email}
                  </Typography>
                </Fragment>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={5}
          style={{
            borderRadius: 6,
            borderWidth: 1,
            borderColor: '#dfdfdf',
            borderStyle: 'solid'
          }}
        >
          <Grid container spacing={8} justify='center'>
            <Grid item xs={12}>
              <Grid container spacing={16} justify='center'>
                <Grid item xs={11}>
                  <Typography
                    variant='h4'
                    color='primary'
                    style={{ fontWeight: 700 }}
                  >
                    PAGAMENTO
                  </Typography>
                </Grid>

                <Grid item xs={11}>
                  <Grid container spacing={0} justify='space-between'>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={16}
                        justify='space-between'
                        alignItems='center'
                      >
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>
                            Total a pagar
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography variant='subtitle2' align='right'>
                            R${' '}
                            {utils.formatCurrency(
                              this.props.resume.totalValuePlans +
                                this.props.resume.totalValueRecharges
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={16}
                        justify='space-between'
                        alignItems='center'
                      >
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>
                            Recargas pagas com saldo em conta
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant='subtitle2'
                            className={`${
                              this.props.payment.useAccountBalance === 0
                                ? ''
                                : 'red-info'
                            }`}
                            align='right'
                          >
                            R${' '}
                            {this.props.payment.useAccountBalance
                              ? utils
                                  .sliceToMoneyFloat(
                                    this.props.payment.discount
                                  )
                                  .toFixed(2)
                              : utils.formatCurrency(0)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={16}
                        justify='space-between'
                        alignItems='center'
                      >
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>
                            Recargas pagas com boleto
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2' align='right'>
                            R${' '}
                            {utils.formatCurrency(
                              this.props.resume.totalValueRecharges -
                                utils.sliceToMoneyFloat(
                                  this.props.payment.discount
                                )
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={11}>
                  <Grid
                    container
                    spacing={16}
                    justify='space-between'
                    style={{ backgroundColor: '#f0f0f0' }}
                  >
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={16}
                        justify='space-between'
                        alignItems='center'
                      >
                        <Grid item xs={6}>
                          <Typography variant='body1'>
                            <strong>Pagamento com boleto</strong>
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography
                            variant='h2'
                            color='primary'
                            align='right'
                          >
                            R${' '}
                            {utils.formatCurrency(
                              this.props.resume.totalValueRecharges +
                                this.props.resume.totalValuePlans -
                                utils.sliceToMoneyFloat(
                                  this.props.payment.discount
                                )
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={16}
                        justify='space-between'
                        alignItems='center'
                      >
                        <Grid item xs={6}>
                          <Typography variant='body1'>
                            Vencimento do boleto
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography variant='body2' align='right'>
                            {`${utils.momentFromDate(
                              this.props.payment.expiration_date
                            )}`}
                            {/* {moment(this.props.payment.expiration_date).format(
                              'DD/MM/YYYY'
                            )} */}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={11} style={{ marginTop: 15 }}>
                  <Typography
                    variant='h4'
                    color='primary'
                    style={{ fontWeight: 700 }}
                  >
                    SALDO RESTANTE
                  </Typography>
                </Grid>

                <Grid item xs={11}>
                  <Grid container spacing={16} justify='space-between'>
                    <Grid item xs={6}>
                      <Typography variant='subtitle1'>
                        Saldo em conta disponível:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2' align='right'>
                        R${' '}
                        {utils.formatCurrency(
                          this.props.resume.contract_credit_balance -
                            utils.sliceToMoneyFloat(this.props.payment.discount)
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={11}>
                  <Grid
                    container
                    spacing={16}
                    justify='space-between'
                    style={{ backgroundColor: '#f0f0f0' }}
                  >
                    <Grid item xs={6}>
                      <Typography variant='subtitle1'>
                        Data da recarga:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2' align='right'>
                        {this.props.payment.rechargeOnPayment
                          ? 'Na aprovação do pagamento'
                          : utils.momentFromDate(
                              this.props.payment.recharge_date
                            )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
