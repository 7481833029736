import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  progress: {
    flexGrow: 1,
    margin: '0px',
    padding: '0px',
  },
  /* Styles applied to the root element if `color="primary"`. */
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  /* Styles applied to the root element if `color="secondary"`. */
  colorSecondary: {
    color: theme.palette.secondary.main,
  },
});

function CustomizedCircularProgress(props) {
  const { classes, size, thickness, color, hidden, variant } = props;
  if (hidden) {
    return null;
  }

  return (
    <CircularProgress
      className={classes.progress}
      size={size || 16}
      thickness={thickness || 3.6}
      color={color || 'primary'}
      variant={variant || 'indeterminate'}
      classes={{
        colorPrimary: classes.colorPrimary,
        colorSecondary: classes.colorSecondary,
      }}
    />
  );
}

CustomizedCircularProgress.propTypes = {
  classes: PropTypes.object.isRequired,
  /**
   * The size of the circle.
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The thickness of the circle.
   */
  thickness: PropTypes.number,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
  /**
   * If `true`, the component is not shown.
   */
  hidden: PropTypes.bool,
  /**
   * The variant to use.
   * Use indeterminate when there is no progress value.
   */
  variant: PropTypes.oneOf(['determinate', 'indeterminate', 'static']),
};

CustomizedCircularProgress.defaultProps = {
  color: 'primary',
  disableShrink: false,
  size: 16,
  thickness: 3.6,
  value: 0,
  variant: 'indeterminate',
};

export default withStyles(styles)(CustomizedCircularProgress);
