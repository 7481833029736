import React, { Component } from 'react';
import ExpansionPanelSummaryYaloBase from './ExpansionPanelSummaryYaloBase';

export default class ExpansionPanelSummaryYaloSubscription extends Component {
  render() {
    let variables = {
      item_color: this.props.item_color,
      item_icon: this.props.item_icon,
      is_payment_done: this.props.is_payment_done,

      //coluna 1
      col1_row1_label: 'Mês:',
      col1_row1_data: this.props.col1_row1_data,
      col1_row2_label: 'Status:',
      col1_row2_data: this.props.col1_row2_data,
      col1_row3_label: 'Integração Imediata:',
      col1_row3_data: this.props.col1_row3_data,
      col1_row4_label: 'Data da Integração:',
      col1_row4_data: this.props.col1_row4_data,

      //coluna 2
      col2_row1_label: 'Data de Criação:',
      col2_row1_data: this.props.col2_row1_data.split(' ')[0],
      col2_row2_label: 'Total de Assinantes:',
      col2_row2_data: this.props.col2_row2_data,
      col2_row3_label: 'Valor:',
      col2_row3_data: this.props.col2_row3_data,

      //coluna 3
      col3_row1_label: 'Nota Adiantamento:',
      col3_row1_data: this.props.col3_row1_data,
      col3_row2_label: 'Nota Fiscal:',
      col3_row2_data: this.props.col3_row2_data,
      col3_row3_label: 'Status Pagamento:',
      col3_row3_data: this.props.col3_row3_data,
      col3_row4_label: 'Data do Pagamento:',
      col3_row4_data: this.props.col3_row4_data,
    };

    return <ExpansionPanelSummaryYaloBase {...variables} />;
  }
}
