import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from './OutlinedInput';
import Label from './Label';
import TextFieldHelper from './TextFieldHelper';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    border: 'none',
  },
  formControl: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
  },
  formInputHelper: {
    root: {
      display: 'flex',
      flexDirection: 'row',
      flex: 'auto',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      alignContent: 'center',
    },
  },
});

const InputBox = (props) => {
  const {
    classes,
    autoComplete,
    autoFocus,
    defaultValue,
    disabled,
    endAdornment,
    error,
    fullWidth,
    id,
    inputComponent,
    inputProps,
    inputRef,
    multiline,
    name,
    placeholder,
    readOnly,
    required,
    renderPrefix,
    filled,
    focused,
    margin,
    startAdornment,
    rows,
    rowsMax,
    type,
    value,
    onFilled,
    onChange,
    onKeyDown,
    onKeyUp,
    onBlur,
    success,
    warning,
    small,
    mask,
    maskOptions,
  } = props;

  return (
    <div className={classes.container}>
      <OutlinedInput
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        disabled={disabled}
        endAdornment={endAdornment}
        error={error}
        fullWidth={fullWidth}
        id={id}
        inputComponent={inputComponent}
        inputProps={inputProps}
        inputRef={inputRef}
        margin={margin}
        multiline={multiline}
        name={name}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        renderPrefix={renderPrefix}
        filled={filled}
        focused={focused}
        startAdornment={startAdornment}
        rows={rows}
        rowsMax={rowsMax}
        type={type}
        value={value}
        onFilled={onFilled}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onBlur={onBlur}
        aria-describedby={id}
        success={success}
        warning={warning}
        small={small}
        mask={mask}
        maskOptions={maskOptions}
        variant='outlined'
      />
    </div>
  );
};

class TextField extends React.Component {
  constructor(props) {
    super(props);
    this.labelRef = React.createRef();
    this.inputRef = props.inputRef || React.createRef();
  }
  componentDidMount() {
    this.forceUpdate();
  }

  render() {
    return (
      <FormControl
        className={this.props.classes.formControl}
        variant='outlined'
        error={this.props.error}
        required={this.props.required}
        disabled={this.props.disabled}
      >
        <Label
          required={this.props.required}
          disabled={this.props.disabled}
          error={this.props.error}
          htmlFor={this.props.id}
          ref={this.labelRef}
        >
          {this.props.label}
        </Label>
        <InputBox {...this.props} inputRef={this.inputRef} />
        {this.props.formInputHelper && this.props.formInputHelper.isShow && (
          <TextFieldHelper
            required={this.props.required}
            disabled={this.props.disabled}
            error={this.props.error}
            success={this.props.success}
            warning={this.props.warning}
            title={this.props.formInputHelper.title}
            showIcon={this.props.formInputHelper.showIcon}
          >
            {this.props.formInputHelper.text}
          </TextFieldHelper>
        )}
      </FormControl>
    );
  }
}

TextField.propTypes = {
  autoFocus: PropTypes.bool,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The CSS class name of the wrapper element.
   */
  className: PropTypes.string,
  /**
   * The default input value, useful when not controlling the component.
   */
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object,
      ]),
    ),
  ]),
  /**
   * If `true`, the input will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * End `InputAdornment` for this component.
   */
  endAdornment: PropTypes.node,
  /**
   * If `true`, the input will indicate an error. This is normally obtained via context from
   * FormControl.
   */
  error: PropTypes.bool,
  /**
   * The id of the `input` element.
   */
  id: PropTypes.string,
  /**
   * If `true`, a textarea element will be rendered.
   */
  multiline: PropTypes.bool,
  /**
   * Name attribute of the `input` element.
   */
  name: PropTypes.string,
  /**
   * If `true`, the outline is notched to accommodate the label.
   */
  onChange: PropTypes.func,
  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder: PropTypes.string,
  /**
   * It prevents the user from changing the value of the field
   * (not from interacting with the field).
   */
  readOnly: PropTypes.bool,
  /**
   * If `true`, the input will be required.
   */
  required: PropTypes.bool,
  /**
   * Number of rows to display when multiline option is set to true.
   */
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Maximum number of rows to display when multiline option is set to true.
   */
  rowsMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Start `InputAdornment` for this component.
   */
  startAdornment: PropTypes.node,
  /**
   * Type of the input element. It should be a valid HTML5 input type.
   */
  type: PropTypes.string,
  /**
   * The input value, required for a controlled component.
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object,
      ]),
    ),
  ]),
  /**
   * The input value, is success.
   */
  success: PropTypes.bool,
  /**
   * The input value, is warning.
   */
  warning: PropTypes.bool,
  /**
   * The form input helper values.
   */
  formInputHelper: PropTypes.shape({
    title: PropTypes.string,
    showIcon: PropTypes.bool,
    text: PropTypes.string,
    isShow: PropTypes.bool,
  }),
  /**
   * The mask of the input.
   */
  mask: PropTypes.string,
  /**
   * The mask options of the input.
   */
  maskOptions: PropTypes.shape({
    maskChar: PropTypes.string,
    alwaysShowMask: PropTypes.bool,
    formatChars: PropTypes.object,
    permanents: PropTypes.arrayOf(PropTypes.number),
  }),
  /**
   * Use that property to pass a ref callback to the native input component.
   */
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default withStyles(styles, { name: 'CustomTextField' })(TextField);
