import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PainelSaude from './routes/painel-saude';

const Health = ({ match }) => {
    if (!localStorage.getItem('token')) {
        return <Redirect to="/signin" push/>;
    }

    return (
        <div>
            <Switch>
                <Route path={`${match.url}`} component={PainelSaude}/>
            </Switch>
        </div>
    );
};

export default Health;
