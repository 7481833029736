import { BASE_URL } from "../config/env";

export const pjService = {
  getPJ,
  updatePJ,
  setPJ,
  getByCpf,
  getRelationCompanies,
  getEmployees,
  getPJbyID,
  getLoginData
};

function getLoginData(username) {
  const body = JSON.stringify({ username });

  return fetch(`${BASE_URL}/users/getlogindata`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json"
    },
    body
  }).then(response => {
    if (response.status === 200) return response.json();

    return response;
  });
}

function setPJ(object) {
  return fetch(`${BASE_URL}/companies/setPJ`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(object)
  }).then(response => {
    if (response.status === 200) return response.json();

    return response;
  });
}

function getPJ(cnpj) {
  const body = JSON.stringify({ cnpj });
  return fetch(`${BASE_URL}/companies/getPJ`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json"
    },
    body
  }).then(response => {
    if (response.status === 200) return response.json();

    return response;
  });
}

function getPJbyID(id) {
  const body = JSON.stringify({ id });
  return fetch(`${BASE_URL}/companies/get`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json"
    },
    body
  }).then(response => {
    if (response.status === 200) return response.json();

    return response;
  });
}

function updatePJ(object) {
  return fetch(`${BASE_URL}/companies/updatePJ`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(object)
  }).then(response => {
    if (response.status === 200) return response.json();

    return response;
  });
}

function getByCpf(cpf) {
  return fetch(`${BASE_URL}/people/getbycpf`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      cpf: cpf
    })
  }).then(response => {
    if (response.status === 200) return response.json();

    return response;
  });
}

function getRelationCompanies(cnpj) {
  const body = JSON.stringify({ cnpj });
  return fetch(`${BASE_URL}/relationcompanies/getByCnpj`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    body
  }).then(response => {
    if (response.status === 200) return response.json();

    return response;
  });
}
function getEmployees(cnpj) {
  const body = JSON.stringify({ cnpj });
  return fetch(`${BASE_URL}/accounts/getAccountsByCNPJ`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    body
  }).then(response => {
    if (response.status === 200) return response.json();

    return response;
  });
}
