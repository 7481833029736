import React from 'react';

function ContractEdit({ className, ...props }) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={32}
        height={32}
      >
        <path fill="#D9D9D9" d="M0 0H32V32H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M13.333 9.333h9.334c.377 0 .694.128.95.384.255.255.383.572.383.95 0 .377-.128.694-.383.95a1.29 1.29 0 01-.95.383h-9.334a1.29 1.29 0 01-.95-.383 1.29 1.29 0 01-.383-.95c0-.378.128-.695.383-.95a1.29 1.29 0 01.95-.384zm0 4h9.334c.377 0 .694.128.95.384.255.255.383.572.383.95 0 .377-.128.694-.383.95a1.29 1.29 0 01-.95.383h-9.334a1.29 1.29 0 01-.95-.383 1.29 1.29 0 01-.383-.95c0-.378.128-.695.383-.95a1.29 1.29 0 01.95-.384zM8 29.333c-1.111 0-2.056-.389-2.833-1.166C4.389 27.389 4 26.444 4 25.333v-2.666c0-.378.128-.695.383-.95a1.29 1.29 0 01.95-.384H8v-16c0-.733.261-1.36.783-1.883a2.568 2.568 0 011.884-.783h14.666c.734 0 1.361.26 1.884.783.522.522.783 1.15.783 1.883v8c0 .378-.128.695-.383.95a1.29 1.29 0 01-.95.384 1.29 1.29 0 01-.95-.384 1.29 1.29 0 01-.384-.95v-8H10.667v16h4c.377 0 .694.128.95.384.255.255.383.572.383.95 0 .377-.128.694-.383.95a1.29 1.29 0 01-.95.383h-8v1.333c0 .378.127.695.383.95.256.256.572.384.95.384h6.667c.377 0 .694.127.95.383.255.255.383.572.383.95s-.128.694-.383.95a1.29 1.29 0 01-.95.383H8zM18.667 28v-2.2c0-.178.033-.35.1-.517.066-.166.166-.316.3-.45l6.966-6.933c.2-.2.423-.345.667-.433.244-.09.489-.134.733-.134a2.006 2.006 0 011.434.6l1.233 1.234c.178.2.317.422.417.666.1.245.15.49.15.734s-.045.494-.134.75A1.79 1.79 0 0130.1 22l-6.933 6.933a1.331 1.331 0 01-.45.3c-.167.067-.34.1-.517.1H20a1.29 1.29 0 01-.95-.383 1.29 1.29 0 01-.383-.95zm2-.667h1.266l4.034-4.066-.6-.634-.634-.6-4.066 4.034v1.266zm4.7-4.7l-.634-.6 1.234 1.234-.6-.634z"
          className="icon-path"
        />
      </g>
    </svg>
  );
}

export default ContractEdit;
