import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { setListContracts } from '../actions/ContractActions';
import { SET_LIST_CONTRACTS_ASYNC } from '../constants/ActionTypes';
//you have to import setBaseUrl toghether your service, for some reason saga reset var windons
import { contractService } from '../services/contractService';

const getContract = async () =>
  await contractService
    .getContract()
    .then(contracts => contracts.data.response)
    .catch(error => {
      return error;
    });

function* setListContractsAsync({ payload }) {
  try {
    const contracts = yield call(getContract);
    yield put(setListContracts(contracts));
  } catch (error) {
    console.error(error);
  }
}

export function* setContracts() {
  yield takeEvery(SET_LIST_CONTRACTS_ASYNC, setListContractsAsync);
}

export default function* rootSaga() {
  yield all([fork(setContracts)]);
}
