import React, { Component } from 'react';
import { Grid, Typography, withStyles, Icon } from '@material-ui/core';
import classNames from 'classnames';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  container: {
    margin: '0px 0px 24px',
  },
  table: {
    margin: '0px 0px 24px',
  },
  header: {
    margin: '0px 16px 0px 0px',
  },
  headerStatus: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    border: '1px solid',
    width: 'fit-content',
    borderRadius: '4px',
    padding: '2px 8px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
    gap: '4px',
  },
  headerContent: {
    color: 'var(--color-neutral-900) !important',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '22px',
  },
  cellHeader: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16.94px',
    textAlign: 'left',
    color: 'var(--color-neutral-900)',
  },
  cellValue: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16.94px',
    textAlign: 'left',
    color: 'var(--color-neutral-900)',
  },
  paymentStatus: {
    padding:
      'var(--spacing-6xs) var(--spacing-5xs) var(--spacing-6xs) var(--spacing-5xs)',
    border: 'none',
    borderRadius: 'var(--spacing-6xs)',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
    gap: '4px',
  },
  pending: {
    color: ' var(--color-feedback-warning-900, #93370D)',
    background: 'var(--color-feedback-warning-100, #FEF0C7)',
  },
  paid: {
    color: ' var(--color-feedback-success-900, #143E47)',
    background: 'var(--color-feedback-success-100, #FEF0C7)',
  },
  icon: {
    fontSize: '16px',
    margin: '0px 4px 0px 0px',
  },
});

function PaymentIcon(props) {
  const { paymentDone, classes } = props;
  return (
    <Icon
      className={classNames(classes.icon, {
        [classes.paid]: paymentDone,
        [classes.pending]: !paymentDone,
      })}
    >
      {paymentDone ? 'check_circle' : 'schedule'}
    </Icon>
  );
}

class ExpansionPanelSummaryYaloBase extends Component {
  render() {
    // É necessário colocar o componente ExpansionPanelSummary logo em seguida do Expansion Panel,
    // Sendo assim ele não pode ficar neste componente =(
    const { classes, is_payment_done: paymentDone } = this.props;

    return (
      <Grid container direction='column' className={classes.root}>
        {/*Primeira Linha */}
        <Grid container item direction='column' className={classes.container}>
          <Grid container direction='row'>
            <Grid container item>
              <Grid item className={classes.header}>
                <Typography
                  variant='subtitle1'
                  className={classNames(
                    this.props.item_color,
                    classes.headerStatus,
                  )}
                >
                  <Icon
                    className={classNames(this.props.item_color, classes.icon)}
                  >
                    {this.props.item_icon}
                  </Icon>
                  {this.props.col1_row2_data}
                </Typography>
              </Grid>
              <Grid item className={classes.header}>
                <Typography variant='h2' className={classes.headerContent}>
                  {this.props.col1_row1_data}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Segunda linha */}
        <Grid container direction='column' className={classes.table}>
          <Grid container item direction='row'>
            <Grid item xs={3} md={3}>
              <Typography variant='subtitle1' className={classes.cellHeader}>
                {/* Integração Imediata */}
                {this.props.col1_row3_label}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <Typography variant='subtitle1' className={classes.cellHeader}>
                {/* Data de Criação */}
                {this.props.col2_row1_label}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <Typography variant='subtitle1' className={classes.cellHeader}>
                {/* Total de Assinantes */}
                {this.props.col2_row2_label}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <Typography variant='subtitle1' className={classes.cellHeader}>
                {/* Valor */}
                {this.props.col2_row3_label}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item direction='row'>
            <Grid item xs={3} md={3}>
              <Typography variant='subtitle2' className={classes.cellValue}>
                {/* data -> Integração Imediata */}
                {this.props.col1_row3_data}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <Typography variant='subtitle2' className={classes.cellValue}>
                {/* data -> Data de Criação */}
                {this.props.col2_row1_data}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <Typography variant='subtitle2' className={classes.cellValue}>
                {/* data -> Total de Assinantes */}
                {this.props.col2_row2_data}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <Typography variant='subtitle2' className={classes.cellValue}>
                {/* data -> Valor */}
                {this.props.col2_row3_data}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* Terceira linha */}
        <Grid container direction='column' className={classes.table}>
          <Grid container item direction='row'>
            <Grid item xs={3} md={3}>
              <Typography variant='subtitle1' className={classes.cellHeader}>
                {/* Nota Adiantamento */}
                {this.props.col3_row1_label}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <Typography variant='subtitle1' className={classes.cellHeader}>
                {/* Nota Fiscal */}
                {this.props.col3_row2_label}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <Typography variant='subtitle1' className={classes.cellHeader}>
                {/* Status Pagamento */}
                {this.props.col3_row3_label}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <Typography variant='subtitle1' className={classes.cellHeader}>
                {/* Data do Pagamento */}
                {this.props.col3_row4_label}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item direction='row'>
            <Grid item xs={3} md={3}>
              <Typography variant='subtitle2' className={classes.cellValue}>
                {/* data -> Nota Adiantamento */}
                {this.props.col3_row1_data}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <Typography variant='subtitle2' className={classes.cellValue}>
                {/* data -> Nota Fiscal */}
                {this.props.col3_row2_data}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <Typography variant='subtitle2' className={classes.cellValue}>
                {/* data -> Status Pagamento */}
                <div
                  className={classNames(classes.paymentStatus, {
                    [classes.pending]: !paymentDone,
                    [classes.paid]: paymentDone,
                  })}
                >
                  <PaymentIcon paymentDone={paymentDone} classes={classes} />
                  {this.props.col3_row3_data}
                </div>
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <Typography variant='subtitle2' className={classes.cellValue}>
                {/* data -> Data do Pagamento */}
                {this.props.col3_row4_data !== 'N/A'
                  ? this.props.col3_row4_data
                  : '--'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ExpansionPanelSummaryYaloBase);
