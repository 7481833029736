import React, { Component } from 'react';
import { MenuItem, Typography, TextField, Input } from '@material-ui/core';
import { CONTRACT_STATUS } from '../../constants';

export default class ContractStatusSelect extends Component {
  render() {
    return (
      <TextField
        select
        variant="outlined"
        value={this.props.value}
        onChange={this.props.onChange}
        label="Status"
        fullWidth
        margin="dense"
        input={<Input name="status_filter" />}
        InputLabelProps={{ shrink: true }}
        style={{ marginTop: 0 }}
      >
        <MenuItem value="Todos" selected>
          Todos
        </MenuItem>
        {CONTRACT_STATUS.map(el => (
          <MenuItem value={el.VALUE}>
            <Typography className={el.COLOR}>{el.VALUE}</Typography>
          </MenuItem>
        ))}
      </TextField>
    );
  }
}
