import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Fab, withStyles } from '@material-ui/core';
import { ArrowUpIcon } from '../../assets/svgIcons';
import PopUpUserInfo from './PopUpUserInfo';
import { userSignOut } from '../../actions/Auth';
import { utils } from './utils';

const styles = theme => ({
  userProfile: {
    display: 'flex',
    flexDirection: 'row',
    padding: '18.5px 22px 18.5px 22px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    borderRadius:'40px',
    minWidth: '265px',
    width: 'auto', 
    maxWidth: '100%',
    fontFamily: 'Sua dr consulta',
    fontSize: '14px', 
    fontWeight: 700, 
    lineHeight: '22.4px', 
    textAlign: 'left', 
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      height: 'auto',
    },
  },
  userName: {
    color: 'var(--color-brand-primary-900)', 
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  fabButton: {
    backgroundColor: 'var(--color-neutral-200)',
    boxShadow: 'none',
    marginLeft: theme.spacing.unit * 2,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
});

class UserInfo extends Component {
  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  logout = () => {
    localStorage.removeItem('token');
    window.location = '#/signin';
  };

  render() {
    const { selectedContract, classes } = this.props;
    const { open } = this.state;

    return (
      <Grid className={classes.userProfile}>
        <Grid
          container
          direction="column"
          alignItems="center"
          className="user-detail"
          onClick={this.handleClick}
        >
          <Typography
            id="simple-modal-description"
            className={`mb-0 pb-0 ${classes.userName}`}
          >
            <strong>
              {selectedContract.name !== undefined
                ? selectedContract.name
                : `Selecione um contrato`}
            </strong>
          </Typography>

          <Typography variant="subtitle1" className={classes.userName}>
            {utils.formatCNPJ(selectedContract.contracting_company_cnpj)}
          </Typography>
        </Grid>
        <Grid>
          <Fab onClick={this.handleClick} className={classes.fabButton} size="small">
            <ArrowUpIcon height={24} width={24}/>
          </Fab>
        </Grid>

        <PopUpUserInfo
          open={open}
          handleRequestClose={this.handleRequestClose}
        />
      </Grid>
    );
  }
}

const mapStateToProps = ({ settings, auth, contract }) => {
  const { locale } = settings;
  const { user } = auth;
  const { selectedContract } = contract;
  return { locale, user: user.response, selectedContract };
};

export default connect(
  mapStateToProps,
  { userSignOut }
)(withStyles(styles)(UserInfo));
