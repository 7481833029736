import React from 'react';

function MenuCloseIcon({ className, ...props }) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={32}
        height={32}
      >
        <path fill="#D9D9D9" d="M0 0H32V32H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M3.847 15.61l1.406 1.406L10.27 12 5.253 6.984 3.847 8.391 7.41 12l-3.563 3.61zm18-9.61H8.863v2.016h12.984V6zm0 6.984v-1.968h-9.984v1.968h9.984zm0 5.016v-2.016H8.863V18h12.984z"
          className="icon-path"
        />
      </g>
    </svg>
  );
}

export default MenuCloseIcon;
