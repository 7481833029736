export const privacyCardStyles = theme => ({
  root: {
    borderRadius: '0.625rem',
    backgroundColor: '#F6F6F6',
    position: 'relative'
  },
  content: {
    padding: '1.25rem 1.25rem 1.25rem 4rem',
    maxWidth: '28rem',
    rowGap: '1rem',
    display: 'grid'
  },
  materialIcons: {
    position: 'absolute',
    top: '1.25rem',
    left: '1.25rem'
  },
  title: {
    fontSize: '1rem',
    lineHeight: '1.25rem',
    fontWeight: '700',
  },
  description: {
    fontSize: '0.90rem',
    lineHeight: '1rem',
    color: '#1A1A1A',
    fontWeight: '400',
  },
  actions: {
    '& a': {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      color: 'var(--color-brand-primary-800)',
      fontWeight: '700',
    }
  }
});

export const mostBookedCardStyles = theme => ({
  root: {
    position: 'relative',
    '& .material-icons': {
      position: 'absolute',
      fontSize: '3rem',
      left: '55%',
      marginLeft: '-50px',
      top: '50%',
      marginTop: '-50px',
      zIndex: '9'
    }
  },
  locked: {
    filter: 'blur(0.75rem)',
    pointerEvents: 'none',
  },
  materialIcons: {
    position: 'absolute',
    top: '1.25rem',
    left: '1.25rem'
  },
  hiddenLockedIcon: {
    display: 'none',
  },
});

export const topVisitedUnitAnalyticsStyles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  bar: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    border: 'none',
    borderRadius: '25px',
    padding: '6px 15px',
  },
  bar1: {
    backgroundColor: 'var(--color-brand-primary-800)',
    color: 'var(--color-neutral-200)',
    width: '100%',
  },
  bar2: {
    backgroundColor: 'var(--color-brand-primary-700)',
    color: 'var(--color-neutral-200)',
    width: '85%',
  },
  bar3: {
    backgroundColor: 'var(--color-brand-primary-600)',
    color: 'var(--color-neutral-200)',
    width: '65%',
  },
  bar4: {
    backgroundColor: 'var(--color-brand-primary-500)',
    color: 'var(--color-neutral-900)',
    width: '50%',
  },
  bar5: {
    backgroundColor: 'var(--color-brand-primary-400)',
    color: 'var(--color-neutral-900)',
    width: '35%',
  },
  alert: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: '135px',
    color: 'var(--color-neutral-600)',
  },
  alertText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '16.5px',
    textAlign: 'left',
    textTransform: 'none',
    margin: '0px',
    color: 'inherit',
    backgroundColor: 'transparent',
  },
  alertIcon: {
    color: 'var(--color-neutral-600)',
    marginRight: '8px',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '16.5px',
    textAlign: 'left',
    textTransform: 'none',
    margin: '0px',
    color: 'inherit',
    backgroundColor: 'transparent',
  },
  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '90%',
    width: '90%',
  },
  value: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '16.5px',
    textAlign: 'left',
    textTransform: 'none',
    margin: '0px',
    color: 'inherit',
    backgroundColor: 'transparent',
  },
  tooltip: {
    backgroundColor: 'var(--color-neutral-900)',
    color: 'var(--color-neutral-100)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
});

export const emptyGraphStyles = theme => ({
  alert: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: '135px',
    color: 'var(--color-neutral-600)',
  },
  alertText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '16.5px',
    textAlign: 'left',
    textTransform: 'none',
    margin: '0px',
    color: 'inherit',
    backgroundColor: 'transparent',
  },
  alertIcon: {
    color: 'var(--color-neutral-600)',
    marginRight: '8px',
  },
});
