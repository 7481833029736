import React, { Component } from 'react';
import {
  Grid,
  Divider,
  Typography,
  Button,
  FormControl,
  TextField,
  Checkbox,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import { Fragment } from 'react';
import { utils } from '../../utils';
import { yalo_images } from '../../../../assets/images';
import moment from 'moment';
export default class PaymentSettings extends Component {
  state = {
    discount: 0,
    sendEmailCopy: false,
    rechargeOnPayment: false,
    useAccountBalance: false,
    responsable: this.props.responsable,
    responsibles: [],
    email: 'email@email.com',
    expiration_date: '',
    recharge_date: '',
    loading: true,
    erroDate: false
  };
  componentDidMount() {
    if (!this.props.resume.discount) {
      this.props.resume.discount = 0;
    }

    if (this.props.paymentSettings.expiration_date) {
      this.setState({ ...this.props.paymentSettings, loading: false });
    } else {
      this.setDefaultDate();
    }
  }

  disableContinue = () => {
    if (this.state.responsable) {
      if (this.state.sendEmailCopy) {
        if (
          this.state.email.includes('@') &&
          this.state.email.split('@')[1].includes('.')
        ) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    }
    return true;
  };

  setDefaultDate = () => {
    let date = new Date();
    date = new Date(date.getTime() + 86400000 * 2);
    if (date.getDay() === 0) {
      date = new Date(date.getTime() + 86400000);
    } else if (date.getDay() === 6) {
      date = new Date(date.getTime() + 86400000 * 2);
    }

    this.setState({
      expiration_date:
        `${date.getFullYear()}-` +
        `0${date.getMonth() + 1}-`.slice(-3) +
        `0${date.getDate()}`.slice(-2),
      recharge_date: this.getMinDate(
        `${date.getFullYear()}-` +
        `0${date.getMonth() + 1}-`.slice(-3) +
        `0${date.getDate()}`.slice(-2)
      )
    });
  };

  handleDate = date => {
    if (moment(date).unix() < moment().unix()) {
      this.setDefaultDate();
    }
  };

  getMinDate = date => {
    date = new Date(
      parseInt(date.slice(0, 4)),
      parseInt(date.slice(5, 7)) - 1,
      parseInt(date.slice(8, 10))
    );
    date = new Date(date.getTime() + 86400000 * 3);
    if (date.getDay() === 2) {
      date = new Date(date.getTime() + 86400000);
    } else if (
      date.getDay() === 6 ||
      date.getDay() === 0 ||
      date.getDay() === 1
    ) {
      date = new Date(date.getTime() + 86400000 * 2);
    }
    return (
      `${date.getFullYear()}-` +
      `0${date.getMonth() + 1}-`.slice(-3) +
      `0${date.getDate()}`.slice(-2)
    );
  };

  validOirderDate = date => {
    const { expiration_date } = this.state;

    const rechargerLimitDate = this.getMinDate(expiration_date);

    if (moment(date).isBefore(rechargerLimitDate))
      this.setState({
        erroDate: true
      });
    else
      this.setState({
        recharge_date: date
      });
  };

  setDiscount = value => {
    if (value.length < 1) return '';

    let floatValue = utils.removeNonNumericCharacters(value);

    floatValue = parseFloat(
      `${floatValue.slice(0, -2)}.${floatValue.slice(-2)}`
    );
    let floatBalance = utils.removeNonNumericCharacters(
      this.props.resume.contract_credit_balance
    );
    floatBalance = parseFloat(
      `${floatBalance.slice(0, -2)}.${floatBalance.slice(-2)}`
    );

    let floatRecharges = parseFloat(this.props.resume.totalValueRecharges);

    if (floatRecharges > floatBalance) {
      return floatValue > floatBalance
        ? utils.removeNonNumericCharacters(
          this.props.resume.contract_credit_balance
        )
        : utils.removeNonNumericCharacters(value);
    } else {
      return floatValue > floatRecharges
        ? utils.removeNonNumericCharacters(floatRecharges.toFixed(2).toString())
        : utils.removeNonNumericCharacters(value);
    }
  };

  render() {
    return (
      <Grid container spacing={16} justify="center" style={{ padding: 16 }}>
        <Grid
          item
          xs={6}
          style={{
            borderRadius: 6,
            borderWidth: 1,
            borderColor: '#dfdfdf',
            borderStyle: 'solid',
            marginRight: 5
          }}
        >
          <Grid container spacing={8} justify="center">
            <Grid item xs={11}>
              <Typography
                variant="h4"
                color="primary"
                style={{ fontWeight: 700 }}
              >
                MEIO DE PAGAMENTO
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={16} justify="center">
                <Grid item xs={10}>
                  <Grid container>
                    <Grid item xs={2}>
                      <img
                        src={yalo_images.bar_code}
                        alt="codigo de barras"
                        style={{ marginRight: 10 }}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="subtitle1" paragraph>
                        O boleto do pedido será emitido e entregue por e-mail.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant="subtitle1"
                    style={{ marginLeft: -14, marginBottom: -10 }}
                  >
                    <Checkbox
                      checked={this.state.sendEmailCopy}
                      onChange={e =>
                        this.setState({
                          sendEmailCopy: !this.state.sendEmailCopy
                        })
                      }
                      value="sendEmailCopy"
                      style={{ height: 10 }}
                      color="primary"
                    />
                    Enviar cópia do e-mail para:
                  </Typography>
                  <Grid container justify="flex-end">
                    <Grid item xs={11}>
                      <FormControl fullWidth margin="normal">
                        <InputUi
                          small={true}
                          name="e-mail"
                          label="E-mail"
                          value={this.state.email}
                          disabled={!this.state.sendEmailCopy}
                          variant="outlined"
                          style={{ height: 40 }}
                          onChange={e =>
                            this.setState({
                              email: e.target.value
                            })
                          }
                          type="text"
                          fullWidth
                        />

                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={11}>
                  <Divider />
                </Grid>

                {this.props.resume.contract_credit_balance > 0 ? (
                  <Fragment>
                    <Grid item xs={10}>
                      <Typography
                        variant="subtitle1"
                        style={{ marginLeft: -14, marginBottom: -10 }}
                      >
                        <Checkbox
                          checked={this.state.useAccountBalance}
                          onChange={e =>
                            this.setState({
                              discount: !this.state.useAccountBalance
                                ? 0
                                : this.state.discount,
                              useAccountBalance: !this.state.useAccountBalance
                            })
                          }
                          value="useAccountBalance"
                          style={{ height: 10 }}
                          color="primary"
                        />
                        Usar saldo em conta para recarga.
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Grid container spacing={16} justify="space-between">
                        <Grid item xs={6}>
                          <Typography variant="subtitle1">
                            Saldo disponivel:
                          </Typography>
                          <Typography variant="subtitle1">
                            Valor das recargas:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="subtitle2" color="primary">
                            R${' '}
                            {utils.formatCurrency(
                              this.props.resume.contract_credit_balance
                            )}
                          </Typography>
                          <Typography variant="subtitle2">
                            R${' '}
                            {utils.formatCurrency(
                              this.props.resume.totalValueRecharges
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    {this.state.useAccountBalance && (
                      <Grid item xs={10} style={{ marginTop: -16 }}>
                        <Grid container justify="flex-end">
                          <Grid item xs={11}>
                            <FormControl fullWidth margin="normal">

                              <InputUi
                                small={true}
                                name="value"
                                label="Valor:"
                                value={utils.maskMoneyInput(
                                  this.state.discount
                                )}
                                variant="outlined"
                                style={{ height: 40 }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      R$
                                    </InputAdornment>
                                  )
                                }}
                                onChange={e =>
                                  this.setState({
                                    discount: this.setDiscount(e.target.value)
                                  })
                                }
                                fullWidth
                              />

                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Fragment>
                ) : (
                  <Grid item xs={10}>
                    <Typography variant="subtitle1">
                      Sua conta não possuí saldo disponível para deduzir o valor
                      das recargas deste pedido.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={5}
          style={{
            backgroundColor: '#f0f0f0',
            borderRadius: 6
          }}
        >
          <Grid container spacing={8} justify="center">
            <Grid item xs={12}>
              <Grid container spacing={16} justify="center">
                <Grid item xs={11}>
                  <Typography
                    variant="h4"
                    color="primary"
                    style={{ fontWeight: 700 }}
                  >
                    RESUMO DO PEDIDO
                  </Typography>
                  <Divider />
                </Grid>

                <Grid item xs={11}>
                  <Grid container spacing={16} justify="space-between">
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle1"
                        style={{ marginBottom: 10 }}
                      >
                        Recargas
                      </Typography>

                      <Typography
                        variant="subtitle2"
                        style={{ marginBottom: 10 }}
                      >
                        Total do pedido
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        align="right"
                        style={{ marginBottom: 10 }}
                      >
                        R${' '}
                        {utils.formatCurrency(
                          this.props.resume.totalValueRecharges
                        )}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        align="right"
                        style={{ marginBottom: 10 }}
                      >
                        R${' '}
                        {utils.formatCurrency(
                          this.props.resume.totalValuePlans +
                          this.props.resume.totalValueRecharges
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={11} style={{ marginTop: 15 }}>
                  <Typography
                    variant="h4"
                    color="primary"
                    style={{ fontWeight: 700 }}
                  >
                    PAGAMENTO
                  </Typography>
                  <Divider />
                </Grid>

                <Grid item xs={11}>
                  <Grid container spacing={16} justify="space-between">
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle1"
                        style={{ marginBottom: 10 }}
                      >
                        Descontado da conta
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        className={`${this.state.discount === 0 ? '' : 'red-info'
                          }`}
                        align="right"
                        style={{ marginBottom: 10, color: 'red' }}
                      >
                        - R${' '}
                        {utils
                          .sliceToMoneyFloat(this.state.discount)
                          .toFixed(2) || '0,00'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ backgroundColor: '#dfdfdf' }}>
                  <Grid container justify="center">
                    <Grid
                      item
                      xs={11}
                      style={{ backgroundColor: '#dfdfdf', height: '140px' }}
                    >
                      <Grid container spacing={16} justify="space-between">
                        <Grid item xs={6}>
                          <Typography variant="h2">Boleto</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="h2"
                            color="primary"
                            align="right"
                          >
                            R${' '}
                            {utils.formatCurrency(
                              this.props.resume.totalValueRecharges +
                              this.props.resume.totalValuePlans -
                              utils.sliceToMoneyFloat(this.state.discount)
                            )}
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography variant="h3">
                            Vencimento do boleto
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputUi
                              small={true}
                              name="signature date"
                              label="Definir data"
                              value={this.state.expiration_date}
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true
                              }}
                              InputProps={{ height: 5 }}
                              style={{ height: 40 }}
                              onChange={e => {
                                this.setState({
                                  expiration_date: utils.capDate(e).target
                                    .value,
                                  recharge_date: this.getMinDate(
                                    utils.capDate(e).target.value
                                  )
                                });
                              }}
                              type="date"
                              fullWidth
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={11}
                  style={{
                    marginTop: 50,
                    marginBottom: 8
                  }}
                >
                  <Grid
                    item
                    xs={10}
                    style={{
                      marginBottom: 16
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ marginLeft: -14, marginBottom: -10 }}
                    >
                      <Checkbox
                        checked={this.state.rechargeOnPayment}
                        onChange={e =>
                          this.setState({
                            rechargeOnPayment: !this.state.rechargeOnPayment,
                            recharge_date: this.getMinDate(
                              this.state.expiration_date
                            )
                          })
                        }
                        value="rechargeOnPayment"
                        style={{ height: 10 }}
                        color="primary"
                      />
                      Recarregar com aprovação do pagamento.
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    spacing={16}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">
                        Data da recarga
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputUi
                          small={true}
                          name="recharge date"
                          label="Definir data"
                          value={this.state.recharge_date}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true
                          }}
                          disabled={this.state.rechargeOnPayment}
                          InputProps={{ height: 5 }}
                          style={{ height: 55 }}
                          onBlur={e => this.handleDate(e.target.value)}
                          onChange={e => {
                            this.validOirderDate(utils.capDate(e).target.value);
                          }}
                          type="date"
                          fullWidth
                        />

                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Button
              variant="outlined"
              onClick={this.props.back}
              color="primary"
              style={{ marginTop: 32, marginRight: 16 }}
            >
              Voltar
            </Button>
            <Button
              onClick={() => this.props.next({ ...this.state })}
              color="primary"
              variant="contained"
              disabled={this.disableContinue()}
              style={{ marginTop: 32, marginRight: 32 }}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>

        <Dialog open={this.state.erroDate}>
          <DialogContent>
            <DialogContentText>
              <Typography>
                Erro: A data de recarga tem que ser 3 dias úteis depois da data
                de vencimento do boleto
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => this.setState({ ...this.state, erroDate: false })}
              color="primary"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}
