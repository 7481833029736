import React, { Fragment } from 'react';
import { Grid, Typography, Checkbox } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import CompanyInfo from './subcomponents/CompanyInfo';
import CompanyAddress from './subcomponents/CompanyAddress';
import PartnerInfo from './subcomponents/PartnerInfo';
import GovReg from './subcomponents/GovReg';
import FinancialResponsible from './subcomponents/FinancialResponsible';
import HR from './subcomponents/HR';
import Users from './subcomponents/Users';
import MainCompany from './subcomponents/MainCompany';

import { formStyles as styles } from './styles';

class ViewRegistrationForm extends React.Component {
  render() {
    const { classes, steps, confirmation, form } = this.props;

    if (!confirmation) {
      return null;
    }

    return (
      <Grid
        container
        item
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
        spacing={0}
        className={classes.rowSpacer2xs}
      >
        <Grid
          container
          direction='column'
          justify='flex-start'
          alignItems='stretch'
          spacing={0}
        >
          {/* INFORMACOES EMPRESA */}
          <Grid item xs={12} md={12}>
            <Typography
              variant='h3'
              className={classes.subTitle}
              color='primary'
            >
              1 - {steps[0]}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <CompanyInfo
              confirmation={confirmation}
              info={form.info}
              setInputs={() => void 0}
              setActiveComponentStep={() => void 0}
              currentComponentStepChange={() => void 0}
            />
          </Grid>
          {/* ENDERECO EMPRESA */}
          <Grid item xs={12} md={12}>
            <Typography
              variant='h3'
              className={classes.subTitle}
              color='primary'
            >
              2 - {steps[1]}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <CompanyAddress
              confirmation={confirmation}
              address={form.address}
              setInputs={() => void 0}
              setActiveComponentStep={() => void 0}
              currentComponentStepChange={() => void 0}
            />
          </Grid>
          {/* INFORMACOES SOCIO */}
          <Grid item xs={12} md={12}>
            <Typography
              variant='h3'
              className={classes.subTitle}
              color='primary'
            >
              3 - {steps[2]}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Fragment>
              <Grid container className={classes.checkboxSpacer}>
                <Grid item xs={12}>
                  <Typography
                    variant='subtitle1'
                    style={{ marginLeft: -14, marginBottom: -10 }}
                  >
                    <Checkbox
                      checked={form.hasStakeholer}
                      disabled={true}
                      color='primary'
                    />
                    Adicionar informações de sócio majoritário
                  </Typography>
                </Grid>
              </Grid>
              <PartnerInfo
                confirmation={confirmation}
                partner={form.partner}
                hasStakeholder={form.hasStakeholer}
                setInputs={() => void 0}
                setActiveComponentStep={() => void 0}
                currentComponentStepChange={() => void 0}
              />
            </Fragment>
          </Grid>
          {/* REGISTROS GOV */}
          <Grid item xs={12} md={12}>
            <Typography
              variant='h3'
              className={classes.subTitle}
              color='primary'
            >
              4 - {steps[3]}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <GovReg
              confirmation={confirmation}
              regs={form.regs}
              setInputs={() => void 0}
              setActiveComponentStep={() => void 0}
              currentComponentStepChange={() => void 0}
            />
          </Grid>
          {/* Responsável Financeiro */}
          <Grid item xs={12} md={12}>
            <Typography
              variant='h3'
              className={classes.subTitle}
              color='primary'
            >
              5 - {steps[4]}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Fragment>
              <Grid item xs={12} md={12} className={classes.checkboxSpacer}>
                <Checkbox
                  checked={form.replicate}
                  disabled={true}
                  color='primary'
                />
                Replicar dados.
              </Grid>
              <FinancialResponsible
                confirmation={confirmation}
                financial_resp={form.financial_resp}
                setInputs={() => void 0}
                setActiveComponentStep={() => void 0}
                currentComponentStepChange={() => void 0}
              />
            </Fragment>
          </Grid>
          {/* Responsável RH */}
          <Grid item xs={12} md={12}>
            <Typography
              variant='h3'
              className={classes.subTitle}
              color='primary'
            >
              6 - {steps[5]}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <HR
              confirmation={confirmation}
              hr_resps={form.hr_resps}
              financialResp={form.financial_resp}
              replicate={form.replicate}
              setInputs={() => void 0}
              setActiveComponentStep={() => void 0}
              currentComponentStepChange={() => void 0}
            />
          </Grid>
          {/* Users */}
          <Grid item xs={12} md={12}>
            <Typography
              variant='h3'
              className={classes.subTitle}
              color='primary'
            >
              7 - {steps[6]}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Users
              confirmation={confirmation}
              cadastro={true}
              users={form.users}
              financialResp={form.financial_resp}
              replicate={form.replicate}
              setInputs={() => void 0}
              setActiveComponentStep={() => void 0}
              currentComponentStepChange={() => void 0}
            />
          </Grid>
          {/* Empresa Principal */}
          <Grid item xs={12} md={12}>
            <Typography
              variant='h3'
              className={classes.subTitle}
              color='primary'
            >
              8 - {steps[7]}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <MainCompany
              confirmation={confirmation}
              empPrinc={form.emp_princ}
              setInputs={() => void 0}
              setActiveComponentStep={() => void 0}
              currentComponentStepChange={() => void 0}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ViewRegistrationForm);
