import { BASE_URL } from '../config/env';

async function getBrokerSales() {
  const token = localStorage.getItem('token');
  const brokerId = localStorage.getItem('brokerId');

  try {
    const response = await fetch(`${BASE_URL}/brokers/${brokerId}/sales`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
    });

    if (!response.ok) {
      throw new Error('Falha na requisição');
    }

    const data = await response.json();
    return {
      data: data,
      status: response.status,
    };
  } catch (error) {
    console.error('Erro:', error);
    throw error; 
  }
}


export const brokerService = {
  getBrokerSales,
};