export const ROLES = {
  BACKOFFICE: 'backoffice',
  PROMOTER: 'promoter',
  SALES: 'sales',
  BROKER: 'broker'
};

export const ORDER_STATUS_C = {
  ATIVO: 'Ativo',
  PROCESSADO: 'Processado',
  AGUARDANDO_PROCESSAMENTO: 'Aguardaddo Processamento',
  AGUARDANDO_GERECAO_NOTA: 'Aguardando Geraçao da Nota Fiscal',
  PEDIDO_EM_PROCESSAMENTO: 'Pedido em Processamento',
  VENCIDO: 'Vencido',
  ESTORNADO: 'Estornado',
  PARCIALMENTE_ESTORNADO: 'Parcialemte Estornado',
  CANCELADO: 'Cancelado',
  PENDENTE_PREENCHIMENTO_INFORMACOES:
    'Pendente de Preenchimento de Informações',
  PENDENTE_PAGAMENTO: 'Pendente de Pagamento',
  PAGAMENTO_PARCIAL: 'Pagamento Parcial',
  ATRASADO: 'Atrasado'
};
