import React from 'react';
import { connect } from 'react-redux';
import { modalEmitAlert, modalAlertShowLoading } from '../../../../../actions';
import { SUCCESS, ERROR } from '../../../../../constants/ActionTypes';
import ContainerHeader from '../../../../../components/ContainerHeader';
import IntlMessages from '../../../../../util/IntlMessages';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { utils } from '../../../utils';
import { pjService } from '../../../../../services/pjService';

import RegistrationForm from './RegistrationForm';
import ViewRegistrationForm from './ViewRegistrationForm';

import Footer from './../components/Footer';

import { formStyles as styles } from './styles';

const steps = [
  'Informações da Empresa',
  'Endereço da Empresa',
  'Informações do Sócio Majoritário',
  'Registros Governamentais',
  'Responsável Financeiro',
  'Responsáveis RH',
  'Usuários',
  'Empresa Principal',
  'Confirmação',
];

class CadastroPJ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {},
      address: {},
      partner: {},
      regs: {},
      financial_resp: {},
      hr_resps: [],
      users: [],
      emp_princ: {},
      hasStakeholer: false,
      replicate: false,
      dialog: {
        open: false,
        message: [],
        success: false,
      },
      loading: false,

      //Stepper related state
      activeStep: 0,
      completed: [],
      activeComponentStep: null,
      hasActiveComponentStepError: true,
    };

    // this.handleInputChange = this.handleInputChange.bind(this);
  }

  getInputs = (info, data) => {
    this.setState({ ...this.state, [info]: data });
  };

  setActiveComponentStep(component) {
    this.setState({ hasActiveComponentStepError: true });
    this.setState({ activeComponentStep: component });
  }

  onCurrentComponentStepChange(hasError) {
    this.setState({ hasActiveComponentStepError: hasError });
  }

  defaultState = () => {
    return this.setState({
      activeStep: 0,
      completed: [],
      editing: false,
      loading: false,
      info: {},
      address: {},
      partner: {},
      regs: {},
      financial_resp: {},
      hr_resps: [],
      users: [],
      emp_princ: {},
    });
  };

  //Stepper related functions
  handleNext = () => {
    if (this.state.activeStep < 8) {
      this.state.activeComponentStep.updateCurrentState();
      this.setState({
        activeStep: this.state.activeStep + 1,
        completed: [...this.state.completed, this.state.activeStep],
      });
    } else {
      this.setState({ loading: true });
      this.props.modalAlertShowLoading();

      pjService.setPJ(this.handleSetCompanyObj()).then((response) => {
        if (response.success) {
          this.props.modalEmitAlert('Cadastro realizado com sucesso', SUCCESS);
          this.defaultState();
        } else {
          response.json().then((response) => {
            this.setState({
              loading: false,
              dialog: {
                open: true,
                message: response.message,
                success: response.success,
              },
            });
            this.props.modalEmitAlert(response.message, ERROR);
          });
        }
      });
    }
  };

  handleBack = () => {
    if (this.state.activeStep > 0) {
      const completed = this.state.completed;
      completed.pop();
      this.setState({
        activeStep: this.state.activeStep - 1,
        completed,
      });
    }
  };

  handleSetCompanyObj() {
    const { info, regs, address, emp_princ, partner, financial_resp } =
      this.state;

    const brokerId = localStorage.getItem('brokerId');

    const company = {
      name: info.razao_social,
      cnpj: utils.removeNonNumericCharacters(info.cnpj),
      printed_name_card: info.printed_name_card,
      printed_name: info.nome_fantasia === '' ? null : info.nome_fantasia,
      principal_company_cnpj: emp_princ.cnpj
        ? utils.removeNonNumericCharacters(emp_princ.cnpj)
        : null,
      cnae: info.cnae === '' ? null : info.cnae,
      industry: info.ramo_atividade === '' ? null : info.ramo_atividade,
      year_was_founded:
        info.ano_fundacao === '' ? null : parseInt(info.ano_fundacao),
      number_of_employees:
        info.numero_funcionarios === '' ||
        info.numero_funcionarios === 0 ||
        info.numero_funcionarios === '0'
          ? null
          : parseInt(info.numero_funcionarios),
      exempt_from_state_registration:
        regs.isento_est === ''
          ? null
          : regs.isento_est === 'sim'
          ? true
          : false,
      state_registration: regs.insc_est === null ? 'Isento' : regs.insc_est,
      exempt_from_municipal_registration:
        regs.isento_mun === ''
          ? null
          : regs.isento_mun === 'sim'
          ? true
          : false,
      tax_regime: regs.tax_regime === '' ? null : regs.tax_regime,
      municipal_registration: regs.insc_mun === null ? 'Isento' : regs.insc_mun,
      address_type_id: '769fec6b-3363-4e56-bac3-56d4391616a3',
      zip_code: utils.removeNonNumericCharacters(address.cep),
      street_address: address.rua === '' ? null : address.rua,
      additional_address:
        address.complemento === '' ? null : address.complemento,
      address_number: address.numero,
      neighborhood: address.bairro === '' ? null : address.bairro,
      city: address.cidade === '' ? null : address.cidade,
      state: address.estado === '' ? null : address.estado,
      is_mailing_address: null,
      broker_id: brokerId || null,
      phones:
        info.telefone === ''
          ? null
          : [
              {
                phone: `0${utils.removeNonNumericCharacters(info.telefone)}`,
                phone_type_name: 'Celular',
              },
            ],
    };

    const phones = {
      phones:
        info.telefone === ''
          ? null
          : [
              {
                phone: `0${utils.removeNonNumericCharacters(info.telefone)}`,
                phone_type_name: 'Celular',
              },
            ],
    };

    //24.295.395/0001-65
    const users = this.state.users.map((user) => ({
      username: user.user.email,
      is_admin: true,
      name: user.user.nome,
      gender: user.user.sexo,
      cpf: utils.removeNonNumericCharacters(user.user.cpf),
      email: user.user.email,
      user_type_id: '3f88b9b9-2687-4712-baf3-d5e8326602d3',
      birth_date:
        user.user.data_nascimento === '' ? null : user.user.data_nascimento,
      phones:
        user.user.telefone === ''
          ? null
          : [
              {
                phone: `0${utils.removeNonNumericCharacters(
                  user.user.telefone,
                )}`,
                phone_type_name: 'Celular',
              },
            ],
    }));

    const stakeholders =
      partner.nome === ''
        ? []
        : [
            {
              name: partner.nome,
              gender: partner.sexo,
              cpf: utils.removeNonNumericCharacters(partner.cpf),
              email: partner.email,
              date_since_became_partner:
                partner.socio_desde === '' ? null : partner.socio_desde,
              number_of_partners:
                partner.qtd_socios === '' ? null : parseInt(partner.qtd_socios),
              partner_also_is_contact:
                partner.tbm_contato === ''
                  ? null
                  : partner.tbm_contato === 'sim'
                  ? true
                  : false,
              birth_date:
                partner.data_nascimento === '' ? null : partner.data_nascimento,
              phones:
                partner.telefone === ''
                  ? null
                  : [
                      {
                        phone: `0${utils.removeNonNumericCharacters(
                          partner.telefone,
                        )}`,
                        phone_type_name: 'Celular',
                      },
                    ],
            },
          ];

    const responsible_companies = [
      {
        name: financial_resp.nome,
        gender: financial_resp.sexo,
        cpf: utils.removeNonNumericCharacters(financial_resp.cpf),
        email: financial_resp.email,
        responsible_type: 'Financeiro',
        birth_date:
          financial_resp.data_nascimento === ''
            ? null
            : financial_resp.data_nascimento,
        phones:
          financial_resp.telefone === ''
            ? null
            : [
                {
                  phone: `0${utils.removeNonNumericCharacters(
                    financial_resp.telefone,
                  )}`,
                  phone_type_name: 'Celular',
                },
              ],
      },

      ...this.state.hr_resps.map((resp) => ({
        name: resp.resp.nome,
        gender: resp.resp.sexo,
        cpf: utils.removeNonNumericCharacters(resp.resp.cpf),
        email: resp.resp.email,
        responsible_type: 'RH',
        birth_date:
          resp.resp.data_nascimento === '' ? null : resp.resp.data_nascimento,
        phones:
          resp.resp.telefone === ''
            ? null
            : [
                {
                  phone: `0${utils.removeNonNumericCharacters(
                    resp.resp.telefone,
                  )}`,
                  phone_type_name: 'Celular',
                },
              ],
      })),
    ];
    const pj = {
      company,
      users,
      stakeholders,
      responsible_companies,
      phones,
    };

    return pj;
  }

  onChangeStakeholder = () => {
    this.setState({ hasStakeholer: !this.state.hasStakeholer });
  };

  onChangeReplicate = () => {
    this.setState({ replicate: !this.state.replicate });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <ContainerHeader
          match={this.props.match}
          title={<IntlMessages id='pages.pessoaJuridica.cadastroPJ' />}
        />
        <Grid
          container
          direction='column'
          justify='flex-start'
          alignItems='flex-start'
          spacing={0}
          className={classes.containerWrapper}
        >
          <Grid item xs={12} md={12} className={classes.rowTitle}>
            <Typography className={classes.title} variant='h6' noWrap>
              Dados Cadastrais
            </Typography>
          </Grid>
          <RegistrationForm
            steps={steps}
            completed={this.state.completed}
            getInputs={this.getInputs.bind(this)}
            setActiveComponentStep={this.setActiveComponentStep.bind(this)}
            onCurrentComponentStepChange={this.onCurrentComponentStepChange.bind(this)}
            activeStep={this.state.activeStep}
            onChangeStakeholder={this.onChangeStakeholder.bind(this)}
            onChangeReplicate={this.onChangeReplicate.bind(this)}
            form={this.state}
          />
          <ViewRegistrationForm
            steps={steps}
            confirmation={this.state.activeStep === 8}
            form={this.state}
          />
        </Grid>
        <Footer
          activeStep={this.state.activeStep}
          handleBack={this.handleBack}
          handleNext={this.handleNext}
          steps={steps}
          hasErrors={this.state.hasActiveComponentStepError}
        />
        {this.state.loading && (
          <div className='loader-view'>
            <CircularProgress
              mode='indeterminate'
              style={{ color: '#071060' }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(
  connect(null, { modalAlertShowLoading, modalEmitAlert })(CadastroPJ),
);
