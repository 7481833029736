import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Button,
  Paper,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Divider,
  withStyles
} from '@material-ui/core';
import { connect } from 'react-redux';
import SphereIcon from '@material-ui/icons/FiberManualRecordRounded';
import EventIcon from '@material-ui/icons/EventRounded';
import { ordersService } from '../../../../services/ordersService';
import { utils } from '../../utils';
import ButtonUi  from '../../../../components/ui/button/Button.js'
import ChevronRight from '@material-ui/icons/ChevronRightRounded.js';

const styles = theme => ({
  container: {
    background: 'white',
    width: 'calc(100% + 26px)',
    borderRadius: '4px'
  },
  titleContainer: {
    padding: theme.spacing.unit * 2,
    fontFamily: 'Sua dr consulta'
  },
  buttonContainer: {
    padding: theme.spacing.unit * 2
  },
  listContainer: {
    minHeight: 214
  },
  loading: {
    margin: 50
  },
  button: {
    textDecoration: 'none'
  },
  listItem: {
    textDecoration: 'none',
    border: 'none', // Remove qualquer borda
    outline: 'none',
  },
  listItemText: {
    fontWeight: 'bold'
  },
  divider: {
    margin: `${theme.spacing.unit}px 0`
  },
  actionButton: {
    textDecoration: 'none'
  }
});

class LastMonthlyResumed extends Component {
  state = {
    latestOrders: [],
    loading: true,
    error: false
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.selectedContract.id !== nextProps.selectedContract.id)
      this.getAllMonthlyResumed(nextProps.selectedContract);
  };

  getAllMonthlyResumed = selectedContract => {
    ordersService.getAllMonthlyResumed(selectedContract.id).then(response => {
      if (response.success) {
        const lastOrders = response.response.sort(function(a, b) {
          return a.created_at < b.created_at;
        });

        this.setState({
          latestOrders: lastOrders.slice(0, 3),
          loading: false,
          error: false
        });
      } else {
        this.setState({ loading: false, error: false });
      }
    });
  };

  componentDidMount() {
    const token = localStorage.getItem('token');
    if (!token) window.location('#signin');

    this.getAllMonthlyResumed(this.props.selectedContract);
  }

  LatestOrdersList = order => {
    const { classes } = this.props;
    return (
      <Link to={`/app/mensalidades`} className={classes.listItem}>
        <ListItem button>
          <ListItemText
            primary={<Typography className={classes.listItemText}>{order.company_name}</Typography>}
            secondary={
              <Typography variant="subtitle1" component="span">
                <EventIcon
                  fontSize="small"
                  style={{ verticalAlign: 'middle', marginRight: 6 }}
                />
                {`${utils.momentFromDate(order.created_at)}`}
              </Typography>
            }
          />
          <Fragment>
            <Typography variant="subtitle1" align="right">
              {order.order_status}
            </Typography>
            <SphereIcon
              style={{ marginLeft: 16, width: 15 }}
              className={utils.statusColor(order.order_status)}
            />
          </Fragment>
        </ListItem>
        <Divider className={classes.divider} />
      </Link>
    );
  };

  render() {
    const { classes } = this.props;
    const listItems = this.state.latestOrders.map(order => (
      <this.LatestOrdersList {...order} key={order.id} />
    ));

    return (
      <div container className={classes.container}>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={8} className={classes.titleContainer}>
            <Typography variant="h1" color="secondary">
              Mensalidades Automáticas
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.buttonContainer}>
            <Grid container justify="flex-end" alignItems="center">
              <Link to="/app/mensalidades" className={classes.button}>
                <ButtonUi variant="link" endIcon={<ChevronRight/>}>
                  Ver Todas
                </ButtonUi>
              </Link>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            className={classes.listContainer}
          >
            <Divider className={classes.divider} />
            {/* Loading */}
            {this.state.loading && (
              <Grid container justify="center">
                <CircularProgress
                  color="primary"
                  className={classes.loading}
                />
              </Grid>
            )}

            {/* Error */}
            {!this.state.loading &&
              (this.state.error || this.state.latestOrders.length === 0) ? (
              <Typography
                variant="subtitle1"
                align="center"
                style={{ margin: 'auto' }}
              >{`${
                this.state.error
                  ? 'Ocorreu algum erro, tente novamente.'
                  : 'Nenhum mensalidade processada automaticamente até o momento.'
              }`}</Typography>
            ) : (
              <List disablePadding>{listItems}</List>
            )}
          </Grid>
          <Grid item xs={12} className={classes.buttonContainer}>
            <Grid container justify="flex-end" alignItems="center">
              <Link to="/app/cadastrar-desligar" className={classes.actionButton}>
                <ButtonUi variant="primary" >
                  CADASTRAR / DESLIGAR
                </ButtonUi>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ contract }) => {
  const { selectedContract } = contract;
  return { selectedContract };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withStyles(styles)(LastMonthlyResumed)));
