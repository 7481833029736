import React, { Component, Fragment } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { utils } from '../../../../utils';

import moment from 'moment';

moment.locale('pt_BR');

const LineBreak = () => {
  return (
    <div style={{ backgroundColor: '#EBEBEB', height: '4px', width: '100%', marginRight: '10px', borderRadius: '5px' }}>
      {''}
    </div>
  )
}

export default ({ contract: el }) => (
  <Grid container spacing={24}>
    <Grid item xs={12}>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Typography variant='h4' color='primary'>
            EMPRESA
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>

          <Grid xs={4}>
            <Grid item xs={6}>
              <Typography align='left'
                variant="body1" >

                Nome da empresa:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" align='left'>
                {el.contracting_company_name}
              </Typography>
            </Grid>
          </Grid>

          <Grid xs={4} >
            <Grid >
              <Typography align='left'
                variant="body1">
                CNPJ:
              </Typography >
            </Grid>
            <Grid >
              <Typography variant="body2" align='left'>
                {utils.formatCNPJ(el.contracting_company_cnpj)}
              </Typography >
            </Grid>
          </Grid>

          <Grid xs={4} >
            <Grid item xs={6}>
              <Typography align='left'
                variant="body1">
                Empresas relacionadas:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" align='left'>
                {el.company_relations.length}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

      </Grid>

    </Grid>

    <LineBreak />

    <Grid item xs={12} style={{ marginTop: 16 }}>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Typography variant='h4' color='primary'>
            DADOS DO PLANO
          </Typography>
        </Grid>


        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid xs={4}>
            <Grid item xs={6}>
              <Typography align='left'
                variant="body1" >
                Gerente da conta:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" align='left'>
                {el.account_manager_yalo_name}
              </Typography>
            </Grid>
          </Grid>


          <Grid xs={4} >
            <Grid >
              <Typography align='left'
                variant="body1">
                Recarga dos cartões:
              </Typography>
            </Grid>
            <Grid >
              <Typography variant="body2" align='left'>
                {el.should_renew_recharges ? 'SIM' : 'NÃO'}
              </Typography>
            </Grid>
          </Grid>

          <Grid xs={4} >
            <Grid item xs={6}>
              <Typography align='left'
                variant="body1">
                Data da assinatura:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" align='left'>
                {utils.momentFromDate(el.signature_date)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Grid>

    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>

      <Grid xs={4}>
        <Grid item xs={6}>
          <Typography align='left'
            variant="body1" >
            Gerador do lead:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2" align='left'>
            {el.sales_promotor_name == null
              ? 'N/A'
              : el.sales_promotor_name}
          </Typography>
        </Grid>
      </Grid>

      <Grid xs={4} >
        <Grid >
          <Typography align='left'
            variant="body1">
            Recarrega ao renovar:
          </Typography >
        </Grid>
        <Grid >
          <Typography variant="body2" align='left'>
            {el.should_renew_recharges ? 'SIM' : 'NÃO'}
          </Typography >
        </Grid>
      </Grid>

      <Grid xs={4} >
        <Grid item xs={6}>
          <Typography align='left'
            variant="body1">
            Data de vigência
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2" align='left'>
            {utils.momentFromDate(el.closure_date)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>

    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>

      <Grid xs={4}>
        <Grid item xs={6}>
          <Typography align='left'
            variant="body1" >
            Renovar assinatura:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2" align='left'>
            {el.should_renew_plan ? 'SIM' : 'NÃO'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>

    <LineBreak />

    <Grid item xs={12} style={{ marginTop: 16 }}>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Typography variant='h4' color='primary'>
            ASSINATURAS
          </Typography>
        </Grid>
        {/* Primeira Coluna */}
        <Grid item xs={12}>
          <Grid container spacing={16}>
            {el.contract_plans.length
              ? el.contract_plans.map(plan => FormAssinatura(plan))
              : FormAssinatura(el.contract_plans)}
          </Grid>
        </Grid>


      </Grid>

    </Grid>

    <LineBreak />

    <Grid item xs={12} style={{ marginTop: 16 }}>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Typography variant='h4' color='primary'>
            VALORES
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid xs={4}>
            <Grid item xs={6}>
              <Typography align='left'
                variant="body1" >
                Mensalidade:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" align='left'>
                R$ {utils.formatCurrency(el.negotiated_price)}
              </Typography>
            </Grid>
          </Grid>


          <Grid xs={4} >
            <Grid >
              <Typography align='left'
                variant="body1">
                Taxa de venda:
              </Typography>
            </Grid>
            <Grid >
              <Typography variant="body2" align='left'>
                {el.should_renew_recharges ? 'SIM' : 'NÃO'}
              </Typography>
            </Grid>
          </Grid>

          <Grid xs={4} >
            <Grid item xs={6}>
              <Typography align='left'
                variant="body1">
                Valor padrão da recarga:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" align='left'>
                {utils.formatCurrency(el.default_recharge_value)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    <LineBreak />



    <Grid item xs={12} style={{ marginTop: 16 }}>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Typography variant='h4' color='primary'>
            TAXAS
          </Typography>
        </Grid>
        {el.contract_taxes.length ? el.contract_taxes.map(tax => (
          <Fragment>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid xs={4}>
                <Grid item xs={6}>
                  <Typography align='left'
                    variant="body1" >
                    {tax.description}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" align='left'>

                  </Typography>
                </Grid>
              </Grid>


              <Grid xs={4} >
                <Grid >
                  <Typography align='left'
                    variant="body1">
                    Valor:
                  </Typography>
                </Grid>
                <Grid >
                  <Typography variant="body2" align='left'>
                    R$ {utils.formatCurrency(tax.value)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid xs={4} >
                <Grid item xs={6}>
                  <Typography align='left'
                    variant="body1">
                    Valor padrão da recarga:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" align='left'>
                    {utils.formatCurrency(el.default_recharge_value)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Fragment>

        )) :
          <div style={{
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            height: '77px',
            backgroundColor: '#F6F6F6',
            width: '100%',
            borderRadius: '16px'
          }}>
            <Typography style={{fontSize: 24, fontWeight: 700, fontFamily: 'Inter', color: '#707070'}}> Ainda não possui taxas </Typography>
          </div>

        }
        {/* Primeira Coluna */}
      </Grid>
    </Grid>
  </Grid>
);

function FormLine(label, info) {
  return (
    <Fragment>
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Typography variant='subtitle1'>{label}</Typography>
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 16 }}>
            <Typography variant='subtitle2'>{info}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}

function FormAssinatura(plan) {


  return (
    <React.Fragment>

      <Grid xs={12}>
        <Grid item xs={4}>
          <Typography style={{ marginLeft: '10px' }} variant='body2'> {plan.name_description}</Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'start', marginTop: '16px' }}>
        <Grid xs={4}>
          <Grid item xs={6}>
            <Typography align='left'
              variant="body1" >
              Quantidade de planos:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2" align='left'>
              {plan.qtd_plans}
            </Typography>
          </Grid>
        </Grid>

        <Grid xs={4} >
          <Grid >
            <Typography align='left'
              variant="body1">
              Quantidade padrão de recargas:
            </Typography >
          </Grid>
          <Grid >
            <Typography variant="body2" align='left'>
              {plan.qtd_recharges}
            </Typography >
          </Grid>
        </Grid>


      </Grid>

      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'start', marginTop: '16px' }}>
        <Grid xs={4}>
          <Grid item xs={6}>
            <Typography align='left'
              variant="body1" >
              Mensalidade:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2" align='left'>
              R$ {utils.formatCurrency(plan.negotiated_price)}
            </Typography>
          </Grid>
        </Grid>

        <Grid xs={4} >
          <Grid >
            <Typography align='left'
              variant="body1">
              Valor padrão de recarga:
            </Typography >
          </Grid>
          <Grid >
            <Typography variant="body2" align='left'>
              R$ {utils.formatCurrency(plan.default_recharge_value)}
            </Typography >
          </Grid>
        </Grid>


      </Grid>
    </React.Fragment>

  );
}
