import { SHOW_ALERT, HIDDEN_ALERT, SHOW_LOADING } from '../constants/ActionTypes';

export const DEFAULT = 0;
export const SUCCESS = 1;
export const ERROR = 2;
export const WARNING = 3;

export const modalEmitAlert = (msg, typeAlert) => {
  return {
    type: SHOW_ALERT,
    payload: { msg, typeAlert }
  };
};

export const modalAlertShowLoading = () => {
  return {
    type: SHOW_LOADING
  };
};

export const modalHiddenAlert = () => {
  return {
    type: HIDDEN_ALERT
  };
};
