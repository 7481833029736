import React, { Component } from 'react';

import moment from 'moment';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  TableSortLabel,
} from '@material-ui/core';
import IconSwapVert from '@material-ui/icons/SwapVert';

import { utils } from '../../../../../../../app/routes/Employees/routes/subscriptionDetail/subcomponent/utils/utils.js';
import { utils as util } from '../../../../../../../app/routes/utils';
export default class ListEmployees extends Component {
  state = {
    columnToSort: '',
    columnDirection: 'asc',
    showLogs: false,
    cpf: '',
  };

  handleSort = (column) => {
    const { columnToSort, columnDirection } = this.state;
    let direction = columnDirection;
    if (column === columnToSort) {
      direction = columnDirection === 'asc' ? 'desc' : 'asc';
    }
    this.setState({
      columnToSort: column,
      columnDirection: direction,
    });
  };

  toggleModal = (selectedCpf) => {
    const { showLogs } = this.state;
    this.setState({
      showLogs: !showLogs,
      cpf: selectedCpf,
    });
  };

  render() {
    const {
      company_cnpj: cnpj,
      company_name: companyName,
      effective_date: effective_date,
      id: id,
    } = this.props.order;

    const { employeesList: employees, page, classes } = this.props;

    const { columnToSort, columnDirection, showLogs, cpf } = this.state;

    return (
      <Grid container direction='row' className={classes.rowSpacer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => this.handleSort('name')}>
                <TableSortLabel>
                  CPF
                  <IconSwapVert style={{ fontSize: 14 }} />
                </TableSortLabel>
              </TableCell>
              <TableCell onClick={() => this.handleSort('cpf')}>
                <TableSortLabel>
                  Nome
                  <IconSwapVert style={{ fontSize: 14 }} />
                </TableSortLabel>
              </TableCell>

              <TableCell> Data de Integração </TableCell>
              <TableCell > Status </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <RenderEmployeesList
              key={id}
              effective_date={effective_date}
              id={id}
              employees={employees}
              cnpj={cnpj}
              companyName={companyName}
              page={page}
              columnToSort={columnToSort}
              columnDirection={columnDirection}
              toggleModal={this.toggleModal}
            />
          </TableBody>
        </Table>

        <Dialog
          maxWidth='sm'
          open={showLogs}
          onClose={() =>
            this.setState({
              ...this.state,
              showLogs: false,
            })
          }
        >
          <Typography variant='h6' className='p-4 m-0'>
            Status
          </Typography>
          <DialogContent>
            <Typography color='primary' variant='body2'>
              MENSALIDADE
            </Typography>

            {_.chain(employees)
              .filter((emp) => emp.cpf === cpf)
              .map((res) => (
                <Grid container style={{ width: '570px' }}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography variant='subtitle1'>Nome:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2'>{res.name}</Typography>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={6}>
                        <Typography variant='subtitle1'>CPF:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2'>
                          {util.handleCPF(res.cpf)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography variant='subtitle1' gutterBottom>
                          Benefício:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2' gutterBottom>
                          {`R$ ${util.formatCurrency(res.yalo_benefit)}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider
                      style={{
                        marginTop: 16,
                        marginBottom: 16,
                      }}
                    />

                    <Grid container>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2'
                        className={this.props.item_color}>
                          Data de integração:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2'>
                          {` ${util.momentFromDate(res.created_at)}`}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1">
                          Data de integração:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                           { res && res.response && res.response.integration_date && (
                             moment(res.response.integration_date).format("DD/MM/YYYY")
                           ) || "--"
                           } 
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))
              .value()}
          </DialogContent>

          <DialogActions>
            <Button
              variant='text'
              color='primary'
              style={{ marginRight: '16px' }}
              onClick={(e) =>
                this.setState({
                  ...this.state,
                  showLogs: false,
                })
              }
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

const renderDataIntegration = (emp) => {
  if(emp.response && emp.response.integration_date){
     return moment(emp.response.integration_date).format("DD/MM/YYYY")
  }else {
      return "--"
  }
}


const RenderEmployeesList = ({
  employees,
  cnpj,
  companyName,
  page,
  columnToSort,
  columnDirection,
  toggleModal,
  id,
  effective_date,
}) =>
  _.isEmpty(employees) ? (
    <TableRow>
      <TableCell>
        <Typography> Nao existe funcionários para listar. </Typography>
      </TableCell>
    </TableRow>
  ) : (
    _.chain(employees)
      .slice(page.offset, page.offset + page.limit)
      .orderBy([columnToSort], columnDirection)
      .map((emp, index) => (
        <TableRow key={emp.id} style={{backgroundColor: ((index+1) % 2) === 0 ? '#F6F6F6' : '#fff'}}>
          <TableCell> {emp.cpf} </TableCell>
          <TableCell> {emp.name || "--"} </TableCell>
          <TableCell>
                {renderDataIntegration(emp)}
          </TableCell>
          <TableCell 
            style={{color: utils.getColor(emp.response, id, effective_date) }}>
            {utils.getLabel(emp.response, id, effective_date)}
          </TableCell>
        </TableRow>
      ))
      .value()
  );
