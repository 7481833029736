import {all} from 'redux-saga/effects';
import authSagas from './Auth';
import contract from './Contract';


export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        
        contract()
    ]);
}
