import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Grid, Typography, Button } from '@material-ui/core';

import { utils } from '../../../../../../../app/routes/utils';
import { modalEmitAlert, ERROR } from '../../../../../../../actions/ModalAlertActions';
import { ordersService } from '../../../../../../../services';

class AguardandoPagamento extends Component {
  state = {
    downloadFile: '',
    pdfDownloadError: false
  };

  componentDidMount() {
    this.downloadBoleto();
  }

  downloadBoleto = async () => {
    const { order } = this.props;
    const resp = await ordersService.getBoletoByOrderId(order.id);

    if (!_.isUndefined(resp) && resp.success === false)
      this.setState({ pdfDownloadError: true });
    
      else this.setState({ downloadFile: window.URL.createObjectURL(resp) });
  };

  render() {
    const { order, modalEmitAlert } = this.props;
    const { downloadFile, pdfDownloadError } = this.state;

    return (
      <div className='app-wrapper'>
        <Grid
          container
          direction='row'
          justify='space-between'
          className='mt-3 mb-3'
        >
          <Typography
            variant='subtitle2'
            className={utils.statusColor(order.order_status)}
          >
            <ins>{order.order_status}</ins>
          </Typography>
          <Grid container item xs={4} direction='row' justify='flex-end'>
           
            {pdfDownloadError ? (
              <Button
                variant='contained'
                color='primary'
                onClick={() =>
                  modalEmitAlert('Erro ao fazer download do boleto', ERROR)
                }
              >
                BAIXAR BOLETO
              </Button>
            ) : (
              <a download={`Boleto.pdf`} href={downloadFile} target='_blank'>
                <Button variant='contained' color='primary'>
                  BAIXAR BOLETO
                </Button>
              </a>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect(
  null,
  { modalEmitAlert }
)(AguardandoPagamento);
