import React, { Component } from 'react';
import { FormControl } from '@material-ui/core';
import { ORDER_STATUS } from '../../constants';
import Select from '../../components/ui/select';

const orderStatus = [
  {
    VALUE: 'Todos',
    COLOR: '',
    DESCRIPTION: 'Todos'
  },
  ...ORDER_STATUS
]

export default class OrderStatusSelect extends Component {
  render() {
    return (
      <FormControl>
        <Select
          value={this.props.value}
          onChange={this.props.onChange}
          label={this.props.label}
          options={orderStatus.map((el) => ({
            value: el.VALUE,
            name: el.DESCRIPTION,
          }))}
        />
      </FormControl>
    );
  }
}
