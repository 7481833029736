import React from 'react';

function DrcHeartCheckIcon({ className, ...props }) {
  return (
      <svg
          width={20}
          height={20}
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
      >
        <mask id="mask0_2233_988" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
          <rect width={20} height={20} fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_2233_988)">
          <path
              d="M15.2917 11.3542L17.5833 9.0625C17.7349 8.90972 17.9116 8.83333 18.1135 8.83333C18.3156 8.83333 18.4931 8.90972 18.6458 9.0625C18.7986 9.21528 18.875 9.39354 18.875 9.59729C18.875 9.8009 18.7986 9.97681 18.6458 10.125L15.8125 12.9375C15.6643 13.0903 15.4884 13.1667 15.2848 13.1667C15.081 13.1667 14.9028 13.0903 14.75 12.9375L13.3542 11.5208C13.2014 11.3731 13.125 11.2007 13.125 11.0037C13.125 10.8068 13.2014 10.6319 13.3542 10.4792C13.5069 10.3264 13.684 10.25 13.8854 10.25C14.0868 10.25 14.2639 10.3264 14.4167 10.4792L15.2917 11.3542ZM6.75 15.5C5.65278 14.5278 4.73611 13.6667 4 12.9167C3.26389 12.1667 2.67361 11.4792 2.22917 10.8542C1.78472 10.2292 1.46875 9.64236 1.28125 9.09375C1.09375 8.54514 1 7.97917 1 7.39583C1 6.15972 1.42014 5.11806 2.26042 4.27083C3.10069 3.42361 4.13194 3 5.35417 3C6.04861 3 6.71875 3.14583 7.36458 3.4375C8.01042 3.72917 8.55556 4.13889 9 4.66667C9.44861 4.1375 9.98299 3.72743 10.6031 3.43646C11.2233 3.14549 11.8764 3 12.5625 3C13.6458 3 14.5938 3.32986 15.4062 3.98958C16.2188 4.64931 16.7222 5.48611 16.9167 6.5H15.3542C15.1597 5.91667 14.8125 5.4375 14.3125 5.0625C13.8125 4.6875 13.2292 4.5 12.5625 4.5C11.7708 4.5 11.1632 4.69792 10.7396 5.09375C10.316 5.48958 9.875 5.95833 9.41667 6.5H8.58333C8.11111 5.94444 7.65625 5.47222 7.21875 5.08333C6.78125 4.69444 6.15972 4.5 5.35417 4.5C4.5625 4.5 3.88889 4.77778 3.33333 5.33333C2.77778 5.88889 2.5 6.57639 2.5 7.39583C2.5 7.83153 2.58681 8.27375 2.76042 8.7225C2.93403 9.17139 3.26042 9.69792 3.73958 10.3021C4.21875 10.9062 4.87847 11.6215 5.71875 12.4479C6.55903 13.2743 7.65278 14.2847 9 15.4792C9.34722 15.1736 9.85764 14.7222 10.5312 14.125C11.2049 13.5278 11.6806 13.0972 11.9583 12.8333L13.0208 13.8958C12.7292 14.1736 12.4097 14.4653 12.0625 14.7708C11.7153 15.0764 11.4306 15.3264 11.2083 15.5208L9.5 17.0625C9.34722 17.1875 9.18056 17.25 9 17.25C8.81944 17.25 8.65278 17.1875 8.5 17.0625L6.75 15.5Z"
              fill="#3A10E0"/>
        </g>
      </svg>
  )
}

export default DrcHeartCheckIcon;
