import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { drc_logos } from '../assets/images';
import {
  hideMessage,
  showAuthLoader,
  userSignIn,
  setUser,
} from '../actions/Auth';
import { UserActions } from '../actions';
import { Grid, Typography, Icon, IconButton, Paper } from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ArrowForward from '@material-ui/icons/ArrowForward';

import TextField from '../components/ui/TextField';
import Button from '../components/ui/button/Button';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    backgroundColor: 'var(--color-brand-primary-800)',
  },
  container: {
    margin: '0',
    padding: '0px',
    borderRadius: '0px 48px 48px 0px',
    backgroundColor: 'var(--color-brand-primary-100)',
    height: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '832px',
  },
  form: {
    minWidth: '368px',
    margin: '0 120px',
  },
  rowSpacer: {
    margin: '24px 0 0',
  },
  iconButton: {
    margin: 0,
    padding: 0,
    maxWidth: 16,
    maxHeight: 16,
  },
  panelImageLogin: {
    backgroundColor: 'var(--color-brand-primary-800)',
    backgroundImage: `url(${drc_logos.drc_bg_login})`,
    backgroundSize: '547px 832px',
    backgroundPosition: 'top left',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'unset',
    height: '100vh',
    margin: '0 var(--spacing-lg)',
    width: '560px',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    margin: 0,
  },
  logoLink: {
    textDecoration: 'none',
    color: 'var(--color-support-salmao-600)',
  },
  logoTitle: {
    fontSize: '26px',
    fontWeight: 700,
    lineHeight: '26.6px',
    textAlign: 'right',
    color: 'var(--color-support-salmao-600)',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'normal',
    padding: 0,
    margin: 0,
  },
});

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: '',
      loading: false,
      redirect: false,
      profile: '',
      error: false,
      messageError: 'Senha ou email inválidos.',
    };
    localStorage.clear();
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    return;
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
    return;
  };

  loginSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    const { username, password } = this.state;

    if (username === 'yalo-sistema') {
      this.setState({ loading: false, error: true });
    } else {
      UserActions.login(username, password).then((response) => {
        if (response.status == 401)
          this.setState({ loading: false, error: true });

        localStorage.setItem('token', response.token);

        if (response.token) {
          UserActions.getLoginData(response.token, username).then((res) => {
            this.props.userSignIn(response.token);
            this.props.setUser(res);

            if (res.response.user_type_name === 'inactive') {
              this.setState({ loading: false, error: true });
              this.setState({
                messageError:
                  'Atenção: Seu cadastro encontra-se inativo. Para acessar a Área do Gestor, por favor, entre em contato com o suporte técnico. Agradecemos sua compreensão.',
              });
            } else {
              localStorage.setItem('username', res.response.name);
              localStorage.setItem('useremail', res.response.username);
              localStorage.setItem('userid', res.response.id);
              localStorage.setItem('usertype', res.response.user_type_name);

              if (res.response.broker_id) {
                localStorage.setItem('brokerId', res.response.broker_id);
              }

              this.setState({
                redirect: true,
                profile: res,
              });
            }

            this.setState({ loading: false });
          });
        }
      });
    }
  };

  showIconPassword() {
    const { showPassword } = this.state;
    return (
      <IconButton
        aria-label='Toggle password visibility'
        onClick={this.handleClickShowPassword}
        className={this.props.classes.iconButton}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    );
  }

  render() {
    const { username, password, redirect } = this.state;
    const { classes } = this.props;

    if (redirect)
      return (
        <Redirect
          to={{
            pathname: '/app/home',
            state: { referrer: this.state.profile },
          }}
        />
      );

    return (
      <Grid
        container
        direction='row'
        spacing={0}
        wrap='nowrap'
        className={classes.root}
      >
        <Paper className={classes.container}>
          <Paper>
            <Grid
              container
              direction='column'
              alignItems='center'
              justify='center'
            >
              <form onSubmit={this.loginSubmit} className={classes.form}>
                <Grid item xs={12} md={12}>
                  <Paper className={classes.logoContainer}>
                    <Link
                      to='/'
                      title='Dr Consulta'
                      className={classes.logoLink}
                    >
                      <img
                        width='260'
                        height='auto'
                        src={drc_logos.drc_logo_salmon}
                        alt='Dr Consulta'
                        title='Dr Consulta'
                      />
                      <Typography
                        align='right'
                        variant='h1'
                        paragraph
                        className={classes.logoTitle}
                      >
                        empresas
                      </Typography>
                    </Link>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={12} className={classes.rowSpacer}>
                  <TextField
                    small={false}
                    required
                    name='username'
                    value={username}
                    label='E-mail'
                    onChange={(event) =>
                      this.setState({ username: event.target.value })
                    }
                    startAdornment={<Icon>account_circle</Icon>}
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.rowSpacer}>
                  <TextField
                    small={false}
                    type={this.state.showPassword ? 'text' : 'password'}
                    required
                    name='password'
                    value={password}
                    label='Senha'
                    error={this.state.error}
                    onChange={(event) =>
                      this.setState({
                        password: event.target.value,
                        error: false,
                      })
                    }
                    startAdornment={<Icon>lock</Icon>}
                    endAdornment={this.showIconPassword()}
                    formInputHelper={{
                      title: 'Senha',
                      text: this.state.messageError,
                      showIcon: true,
                      isShow: this.state.error,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.rowSpacer}>
                  <Paper className={classes.buttonContainer}>
                    <Button
                      variant='primary'
                      size='md'
                      type='submit'
                      color='secondary'
                      isLoading={this.state.loading}
                      isDisabled={!this.state.username || !this.state.password}
                    >
                      Acessar plataforma
                      <ArrowForward fontSize='small' />
                    </Button>
                  </Paper>
                </Grid>
              </form>
              <Grid
                container
                direction='column'
                alignItems='center'
                justify='center'
              >
                <Grid item xs={12} md={12} className={classes.rowSpacer}>
                  <Typography variant='subtitle1' align='center' paragraph>
                    <Link to='/forgotpwd'>Esqueci minha senha</Link>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                direction='column'
                alignItems='center'
                justify='center'
              >
                <Grid item xs={12} md={12} className={classes.rowSpacer}>
                  <Typography variant='subtitle1' align='center' paragraph>
                    Olá! Caso esse seja o seu primeiro acesso entre{' '}
                    <Link to='/'>aqui</Link> e cadastre uma senha.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Paper>
        <Paper className={classes.panelImageLogin}> </Paper>
      </Grid>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  setUser,
})(withStyles(styles)(SignIn));
