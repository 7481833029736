import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import 'react-big-calendar/lib/less/styles.less';

import 'styles/app.scss';
import '../app/routes/NewOrder/cards.scss';
import portalTheme from './themes/portalTheme';
import AppLocale from '../lngProvider';
import ModalAlert from './../componentsPortal/ModalAlert'

import MainApp from '../app/index';
import SignIn from './SignIn';
import ForgotPwd from './ForgotPwd';
import { setInitUrl } from '../actions/Auth';
import RTL from 'util/RTL';
import asyncComponent from '../util/asyncComponent';
import ChangePassword from './ChangePassword';

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

class App extends Component {
  render() {
    const { match, location, locale, authUser, initURL } = this.props;
    if (location.pathname === '/') {
      if (authUser === null) {
        return <Redirect to={'/signin'} />;
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return <Redirect to={'/app/home'} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }
    const applyTheme = createMuiTheme(portalTheme);

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <RTL>
              <div className='app-main'>
                <Switch>
                  {!location.pathname.includes('/app') &&
                    localStorage.getItem('token') && (
                      <Redirect
                        to={{
                          pathname: '/app/novo-pedido'
                        }}
                        push
                      />
                    )}

                  <Route path={`${match.url}app`} component={MainApp} />
                  <Route path='/signin' component={SignIn} />
                  <Route path='/forgotpwd' component={ForgotPwd} />
                  <Route path='/changepwd' component={ChangePassword} />
                  <Route
                    component={asyncComponent(() =>
                      import('components/Error404')
                    )}
                  />
                </Switch>
                <ModalAlert />

              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { sideNavColor, locale, isDirectionRTL } = settings;
  const { authUser, initURL } = auth;
  return { sideNavColor, locale, isDirectionRTL, authUser, initURL };
};

export default connect(
  mapStateToProps,
  { setInitUrl }
)(App);
