import React from 'react';

function ContractIcon({ className, ...props }) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={32}
        height={32}
      >
        <path fill="#D9D9D9" d="M0 0H32V32H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M8 29.333c-1.111 0-2.056-.388-2.833-1.166C4.389 27.389 4 26.444 4 25.333V24c0-.733.261-1.361.783-1.883a2.568 2.568 0 011.884-.784H8v-16c0-.733.261-1.36.783-1.883a2.568 2.568 0 011.884-.783h14.666c.734 0 1.361.26 1.884.783.522.522.783 1.15.783 1.883v20c0 1.112-.389 2.056-1.167 2.834-.777.777-1.722 1.166-2.833 1.166H8zm16-2.666c.378 0 .694-.128.95-.384a1.29 1.29 0 00.383-.95v-20H10.667v16H20c.733 0 1.361.262 1.883.784.523.522.784 1.15.784 1.883v1.333c0 .378.127.695.383.95.256.256.572.384.95.384zM13.333 12a1.29 1.29 0 01-.95-.383 1.29 1.29 0 01-.383-.95c0-.378.128-.695.383-.95a1.29 1.29 0 01.95-.384h9.334c.377 0 .694.128.95.384.255.255.383.572.383.95 0 .377-.128.694-.383.95a1.29 1.29 0 01-.95.383h-9.334zm0 4a1.29 1.29 0 01-.95-.383 1.29 1.29 0 01-.383-.95c0-.378.128-.695.383-.95a1.29 1.29 0 01.95-.384h9.334c.377 0 .694.128.95.384.255.255.383.572.383.95 0 .377-.128.694-.383.95a1.29 1.29 0 01-.95.383h-9.334zM8 26.667h12V24H6.667v1.333c0 .378.127.695.383.95.256.256.572.384.95.384zm0 0H6.667 20 8z"
          className="icon-path"
        />
      </g>
    </svg>
  );
}

export default ContractIcon;
