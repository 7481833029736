import React, { Component, Fragment } from 'react';
import { Grid, FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { utils } from '../../../../utils';
import { pjService } from '../../../../../../services/pjService';

import { styles } from '../styles';
import Select from '../../../../../../components/ui/select';
import TextField from '../../../../../../components/ui/TextField';

class PartnerInfo extends Component {
  state = {
    partner: {
      cpf: '',
      nome: '',
      data_nascimento: '',
      sexo: '',
      telefone: '',
      socio_desde: 0,
      tbm_contato: '',
      email: '',
      qtd_socios: 0,
    },
    touched: {
      cpf: false,
      nome: false,
      email: false,
      data_nascimento: false,
      sexo: false,
      telefone: false,
      socio_desde: false,
      tbm_contato: false,
      qtd_socios: false,
    },
    errors: {
      cpf: 'Por favor, insira um CPF.',
      nome: 'Por favor, insira um nome.',
      email: 'Por favor, insira um e-mail.',
      data_nascimento: '',
      sexo: 'Por favor, selecione um sexo',
      telefone: '',
      socio_desde: '',
      tbm_contato: '',
      qtd_socios: '',
    },
    personFetched: false,
    loading: false,
  };

  componentDidMount() {
    this.props.setActiveComponentStep(this);
    if (!this.props.hasStakeholder) {
      this.props.currentComponentStepChange(false);
    } else if (this.props.partner.cpf !== undefined) {
      const errors = {
        cpf:
          this.props.partner.cpf === null || this.props.partner.cpf === ''
            ? 'Por favor, insira o CPF.'
            : '',
        nome:
          this.props.partner.nome === null || this.props.partner.nome === ''
            ? 'Por favor, insira o nome.'
            : '',
        data_nascimento: '',
        sexo:
          this.props.partner.sexo === null || this.props.partner.sexo === ''
            ? 'Por favor, insira o sexo.'
            : '',
        telefone: '',
        email:
          this.props.partner.email === null ||
          this.props.partner.email === '' ||
          !this.props.partner.email
            ? 'Por favor, insira o email.'
            : '',
        socio_desde: '',
        tbm_contato: '',
        qtd_socios: '',
      };

      this.setState({
        partner: this.props.partner,
        personFetched: this.props.partner.personFetched,
        errors,
      });

      this.props.currentComponentStepChange(this.inValidateForm(errors));
    } else {
      this.props.currentComponentStepChange(this.inValidateForm(this.state.errors));
    } 
  }

  updateCurrentState() {
    this.props.setInputs(this.state.partner);
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    let value = target.value;
    if (name === 'cpf') {
      value = utils.handleCPF(value);
    }
    if (name === 'telefone') {
      value = utils.handleCellphone(value);
    }
    this.setState({
      partner: { ...this.state.partner, [name]: value },
    });
  }

  handleCpfFetch = (response) => {
    if (response.success) {
      const person = response.response;
      person.birth_date = !person.birth_date
        ? ''
        : (person.birth_date = person.birth_date.slice(0, 10));

      if (person.phone) {
        person.phone =
          person.phone.length > 0
            ? utils.handleCellphone(
                person.phone[0].area_code.split('0')[1] +
                  person.phone[0].number,
              )
            : '';
      } else {
        person.phone = '';
      }
      const errors = {
        ...this.state.errors,
        nome: '',
        sexo: '',
        data_nascimento:
          person.birth_date !== '' ? '' : this.state.errors.data_nascimento,
        telefone: person.phone !== '' ? '' : this.state.errors.telefone,
        email: person.email !== '' ? '' : this.state.errors.email,
      };

      this.setState({
        partner: {
          ...this.state.partner,
          nome: person.name,
          sexo: person.gender,
          data_nascimento: person.birth_date,
          telefone: person.phone,
          email: person.email,
        },
        touched: {
          ...this.state.touched,
          nome: true,
          sexo: true,
          data_nascimento: person.birth_date !== '',
          telefone: person.phone !== '',
          email: person.email !== '',
        },
        errors,
        personFetched: true,
        loading: false,
      });

      this.props.currentComponentStepChange(this.inValidateForm(errors));
    } else {
      this.setState({
        personFetched: false,
        loading: false,
      });
    }
  };

  validate = (e) => {
    let error = e.target.value ? '' : 'Por Favor, preencha este campo.';
    if (e.target.name === 'cpf') {
      error = utils.validateCpf(e.target.value);
      if (error === '') {
        this.setState({ loading: true });
        pjService
          .getByCpf(
            utils.removeNonNumericCharacters(`${this.state.partner.cpf}`),
          )
          .then((response) => this.handleCpfFetch(response));
      }
    }

    if (e.target.name === 'qtd_socios') {
      error =
        e.target.value < 0 ? 'O número de sócios não pode ser negativo' : error;
    }

    if (e.target.name === 'telefone') {
      error =
        e.target.value.length >= 14 || e.target.value === ''
          ? ''
          : 'Por favor, insira um telefone válido.';
    }

    if (e.target.type === 'date') {
      error =
        utils.validateDate(e.target.value) || e.target.value === ''
          ? ''
          : 'Por favor, insira uma data válida.';
    }
    if (e.target.name === 'email') {
      error =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          e.target.value,
        )
          ? ''
          : 'Por favor, insira um email válido.';
    }

    const errors = { ...this.state.errors, [e.target.name]: error };

    this.setState({
      errors,
      touched: { ...this.state.touched, [e.target.name]: true },
    });

    this.props.currentComponentStepChange(this.inValidateForm(errors));
  };

  inValidateForm(errors) {
    const { cpf, nome, email, telefone, data_nascimento, socio_desde, sexo } =
      errors;

    if (
      !cpf &&
      !nome &&
      !email &&
      !sexo &&
      !data_nascimento &&
      !socio_desde &&
      !telefone
    ) {
      return false;
    }

    return true;
  }

  render() {
    if (!this.props.hasStakeholder) {
      return null;
    }

    const { partner, touched, errors, loading } = this.state;
    const { classes, confirmation } = this.props;

    return (
      <Fragment>
        <Grid
          container
          direction='column'
          justify='flex-start'
          alignItems='flex-start'
          spacing={0}
          className={classes.root}
        >
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='CPF'
                id='input-partner-cpf'
                name='cpf'
                required={true}
                value={partner.cpf}
                onChange={(e) => this.handleInputChange(e)}
                onBlur={(e) => this.validate(e)}
                error={touched.cpf && errors.cpf !== ''}
                type='text'
                disabled={loading || confirmation}
                formInputHelper={{
                  title: 'CPF',
                  text: errors.cpf,
                  showIcon: true,
                  isShow: touched.cpf && errors.cpf !== '',
                }}
              />
            </Grid>
            <Grid item xs={8} md={8} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='Nome'
                id='input-partner-nome'
                name='nome'
                required={true}
                value={partner.nome}
                onChange={(e) => this.handleInputChange(e)}
                onBlur={(e) => this.validate(e)}
                error={touched.nome && errors.nome !== ''}
                type='text'
                disabled={this.state.personFetched || loading || confirmation}
                formInputHelper={{
                  title: 'Nome',
                  text: errors.nome,
                  showIcon: true,
                  isShow: touched.nome && errors.nome !== '',
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='Email'
                id='input-partner-email'
                name='email'
                required={true}
                value={partner.email}
                onChange={(e) => this.handleInputChange(e)}
                onBlur={(e) => this.validate(e)}
                error={touched.email && errors.email !== ''}
                type='text'
                disabled={this.state.personFetched || loading || confirmation}
                formInputHelper={{
                  title: 'Email',
                  text: errors.email,
                  showIcon: true,
                  isShow: touched.email && errors.email !== '',
                }}
              />
            </Grid>
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='Data de Nascimento'
                id='input-partner-data_nascimento'
                name='data_nascimento'
                required={true}
                value={partner.data_nascimento}
                onChange={(e) => this.handleInputChange(utils.capDate(e))}
                onBlur={(e) => this.validate(e)}
                error={touched.data_nascimento && errors.data_nascimento !== ''}
                type='date'
                disabled={this.state.personFetched || loading || confirmation}
                formInputHelper={{
                  title: 'Data de Nascimento',
                  text: errors.data_nascimento,
                  showIcon: true,
                  isShow:
                    touched.data_nascimento && errors.data_nascimento !== '',
                }}
              />
            </Grid>
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <FormControl fullWidth margin='none'>
                <Select
                  label='Gênero'
                  id='input-partner-gender'
                  name='sexo'
                  required={true}
                  value={partner.sexo}
                  defaultValue='Selecione'
                  isDisabled={
                    this.state.personFetched ||
                    this.state.loading ||
                    this.props.confirmation
                  }
                  onChange={(e) => {
                    this.handleInputChange(e);
                    this.validate(e);
                  }}
                  onBlur={(e) => this.validate(e)}
                  error={touched.sexo && errors.sexo !== ''}
                  errorMessage={
                    touched.sexo && errors.sexo !== '' ? errors.sexo : null
                  }
                  options={[
                    { name: 'Masculino', value: 'M' },
                    { name: 'Feminino', value: 'F' },
                  ]}
                ></Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='Telefone'
                id='input-partner-telefone'
                name='telefone'
                value={partner.telefone}
                onChange={(e) => this.handleInputChange(e)}
                onBlur={(e) => this.validate(e)}
                error={touched.telefone && errors.telefone !== ''}
                type='text'
                disabled={loading || confirmation}
                formInputHelper={{
                  title: 'Telefone',
                  text: errors.telefone,
                  showIcon: true,
                  isShow: touched.telefone && errors.telefone !== '',
                }}
              />
            </Grid>
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='Sócio desde'
                id='input-partner-socio_desde'
                name='socio_desde'
                value={partner.socio_desde}
                onChange={(e) => this.handleInputChange(utils.capDate(e))}
                onBlur={(e) => this.validate(e)}
                error={touched.socio_desde && errors.socio_desde !== ''}
                type='date'
                disabled={loading || confirmation}
                formInputHelper={{
                  title: 'Sócio desde',
                  text: errors.socio_desde,
                  showIcon: true,
                  isShow: touched.socio_desde && errors.socio_desde !== '',
                }}
              />
            </Grid>
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                label='Quantidade de Sócios'
                id='input-partner-qtd_socios'
                name='qtd_socios'
                value={partner.qtd_socios}
                onChange={(e) => this.handleInputChange(e)}
                onBlur={(e) => this.validate(e)}
                error={touched.qtd_socios && errors.qtd_socios !== ''}
                type='number'
                inputProps={{
                  maxLength: 4,
                  min: 0,
                  max: 9999,
                  step: 1,
                  pattern: '[0-9]{4}',
                  inputMode: 'numeric',
                }}
                disabled={loading || confirmation}
                formInputHelper={{
                  title: 'Quantidade de Sócios',
                  text: errors.qtd_socios,
                  showIcon: true,
                  isShow: touched.qtd_socios && errors.qtd_socios !== '',
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <FormControl fullWidth margin='none'>
                <Select
                  label='Sócio também é contato'
                  name='tbm_contato'
                  id='input-partner-tbm_contato'
                  value={partner.tbm_contato}
                  defaultValue='Selecione'
                  isNotRequried={true}
                  isDisabled={loading || confirmation}
                  onChange={(e) => this.handleInputChange(e)}
                  onBlur={(e) => this.validate(e)}
                  error={touched.tbm_contato && errors.tbm_contato !== ''}
                  errorMessage={
                    touched.tbm_contato && errors.tbm_contato !== ''
                      ? errors.tbm_contato
                      : null
                  }
                  options={[
                    { name: 'Sim', value: 'sim' },
                    { name: 'Não', value: 'nao' },
                  ]}
                ></Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(PartnerInfo);
