import { useEffect, useState } from 'react';
import { contractService } from '../../../../services';

const useBankSlips = (contractId) => {
  const [bankSlips, setBankSlips] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBankSlips = async () => {
      if (contractId) {
        setLoading(true);
        try {
          const response = await contractService.getBanckSlip(contractId);
          if (response.status) {
            setBankSlips(response.data);
          } else {
            console.error('Resposta inválida ao obter os comprovantes bancários');
            setBankSlips([]);
          }
        } catch (error) {
          console.error('Erro ao obter os comprovantes bancários:', error);
          setBankSlips([]);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchBankSlips();
  }, [contractId]);

  return { bankSlips, loading };
};

export default useBankSlips;
