import React from 'react';
import { TableCell, TableSortLabel } from '@material-ui/core';
import { style } from './EnhancedTableHeadCell.style';

export function EnhancedTableHeadCell(props) {
  const { order, orderBy, onRequestSort, labelId, labelName, aligment } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableCell
      key={labelId}
      style={style.header}
      align={aligment}
      sortDirection={orderBy === labelId ? order : false}
    >
      <TableSortLabel
        active={orderBy === labelId}
        direction={orderBy === labelId ? order : 'asc'}
        onClick={createSortHandler(labelId)}
      >
        {labelName}
        {orderBy === labelId ? (
          <span style={style.visuallyHidden}>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </span>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );
}

